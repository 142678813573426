import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './Britain.module.css';

function Britain() {
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState(null);

  // Use a key to force remounting of the images
  const [key, setKey] = useState(0);

  const entries = [
    ['Liverpool', '15 Mar'],
    ['The Lakes District', '15 - 16 Mar'],
    ['Glasgow', '16 - 17 Mar'],
    ['Drive to Fort William', '17 - 18 Mar'],
    ['Isle of Skye', '18 - 20 Mar'],
    ['Inverness and Loch Ness', '20 Mar'],
    ['Drive to Aberdeen', '21 Mar'],
    ['Edinburgh and Amsterdam', '22 - 25 Mar'],
    ['York', '25 Mar'],
    ['Oxford', '26 Mar'],
    ['Devon', '27 Mar'],
    ['Cornwall', '28 Mar'],
    ['Cardiff', '29 Mar'],
    ['The Cotswolds', '30 Mar - 1 Apr'],
    ['Eryri National Park (Snowdonia)', '2 Apr'],
    ['London', '3-4 Apr'],
   

  ];

  const handleEntryClick = (entry) => {
    try {
      setSelectedItem(entry);

      // Increment the key to force remounting of the images
      setKey((prevKey) => prevKey + 1);

      const imageBlockElement = document.querySelector(`.${styles.writingCard}`);
      if (imageBlockElement) {
        /*imageBlockElement.scrollTop = 0;*/
        imageBlockElement.scrollIntoView({ block: 'start' });
      }
    } catch (error) {
      console.error('Error in handleEntryClick:', error);
    }
  };
  

  const handleCloseDetails = () => {
    setSelectedItem(null);
  };

  const renderContentBasedOnEntry = () => {
    switch (selectedItem) {
      case 'Liverpool':
        return (
          <div className={styles.imageBlock}>
            <div className={styles.writingCard}>
              <h2>Liverpool</h2>
              <p>We've touched down in the UK! Off the ferry and straight into Liverpool.</p>
              <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPZ05fo8QEdxiECixi_dX3jXCGz1eq1BSKcSd9WCHc2vm7gDM61_4joSJ9759bfWXPbfxx77nMPaAjoWqtill_epYxvnpJPDukzhHFWrGxlKWoVjOGHKjngtfELLxs0zGDLgFlgZ_8UpLfbwpyAmOCrMw=w1200-h904-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Proof that we were, in fact, in Liverpool.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPqZMycWrqN1da12Lz7khiqctgTJHD6jZsZsFW9aMgPiAWQhIHGkiwpg1Axkgnaa8h7-CLVzkE-ZbxNG7zR1HYnCTfw3Fea4jAJrt4tzfwc1OCGWvkok3ewQ8aI_BKoElcAP2-Hw4SYBPrIz4j0L8-3ZQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Down by the harbour.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNZ3knET4ptvtNEUn_418sXRhN0aS6EwghaIB9hOXkcnFh6rouHAS-7o0eCzaoDQQlDTWi5BICsgEWNnw6wcCUhxzA6ife0IreAJEuWaZG6tkfkSm5MGUHQZS48Uxw80SjfPXjqwgWILeM48Q530rQBIw=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Stop via the Beatles Museum!</p>
            </div>  
          </div>
        );
      case 'The Lakes District':
        return (
          <div className={styles.imageBlock}>
            <div className={styles.writingCard}>
              <h2>Windermere and Ambleside</h2>
              <p>A brief drive through the very pretty Lakes District.</p>
              <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPepcaLmdtpsbqoTThqMrEhE_9pYwu0VTiAAP3QuMQsQFiX8Olc8vjIdu99G9ouXeO7meQnJ7Alt-Dxxme-Nbz-PcDTSL-26k7BBczCEZSSXxg9gaq--fp95wxJIDtvPoF1hYVc3c9FNYN5GQOnwgVRMg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We stayed a hostel in Windermere with a beautiful view out the back of the communal area.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOTcPpxEyAICIn__OF7NyW1D3k-KW5JbwaN3SPQIXQ3e-ysRewOk4AFPDbizEklYwsmdPX8nPOYO1mtOUFwFDNTj_nnaqespf1vnWbOBME9FsP1Ljz4VaswrSt2xcNpefPJXm8ENSGxutvSHHB9DiDhow=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Very foggy lakeside in Ambleside.</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMPBPlfApayVTBUqWkTBXcissu7F_rL4s8KBMisvuHzuiEr5Nzaiw-AKo6ltYYg1NTNEDXcje2oaEPAV7zS8r2B2lToUHHjQVx5hMnENVHktM28hBpDwa3YRJp-I32IRyHLsdQRwhYz7K8sqT6qyQ-NJg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Some cute buildings along the water (and in the background, the hostel Paul stayed in few decades ago!)</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOgxBDNc_vjYtDnsM0il51wCACcGxkqDkX6C0J5q7PHz1bj5ZbW5QvWYp8Vx9exsf1m_EmoMvvypOx2Y3inty_-WDWEXPYNJK7zMxrN0RPGbFceTid_veVXPcIbs28CNB65nuN_bEmnYzSBGfGFjWBb8A=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Driving through Ambleside.</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOdgGZPJ3bs_2z9n4ZsRhAmKyJDfEc5Sn277POhHtXfFUdpfQXZcBrJ7rSyK_bp4cXouYaw8vF_j4ATvt4vOs_-iG-bI7UVQgN3vQOr0LhvNjBPuTs3NSLKT30YZqXdn1gqc9nfPmvb2mOc2EJEzST12Q=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We stopped by the oldest gingerbread shop in the UK. They also claim its the best gingerbread in the world, we may actually agree. We demolished the whole packet in 2 days.</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNpqs5BO98Jx_LreqWZKP2LecH9F7D0_VPvh2RZebHh8BZFX5L30tUO9AXU-JKE7nTOGiRSU-3fwKA2brD3F_o0zSY5LhHgtxoIH8h0F0zD1fZDLqRyD3YNZVu4PSyEkU95jCfTgDc5JypPabywatCfpg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Very cute bridges.</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOMLjYFIAsWzGR2Y7hUQV4G8NF4vle3khwvt3qkwtrBlvtMkJFm1_qjk2MhPs2uUyJsiimWFghHhWnjCF1jkdo86H4bxz7NtWTvooKbR4knoDeq5UQbTOL5ApN1ZSV7BoOnFwz09DH_1ZE6AhuIyT5EdQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Some sheep!</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczM2clSQtSps_3cpxzxd_U0lL_S1s5uUayTD-znugVYYE-pPqQVQSk8u2VVaLcj0VgWDLJceIZTFD06Dos0TeA_Jy47NiIIMkbS1a_DBiYpgjliJMAaD66QMjPA4WhiBJ-GNxtfWNH2PBko2Co16qec0lg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Castlerigg Stone Circle.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOUeQ85_yz6VMfPLhFYGWR7-D9jj8lyYqpxJZMjMEK3-EXXlgYZ98a8-ohuhdqPR6Gp9-HyoeXYjzh-gFkB5-QDEgDk9Dg_NyYJ_IzLAMqSRLdTSnK3qFOPmZKbEPhAYcsykumZPc4wK4wPAjrxEP28Dg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>A little snippet of Hadrian's wall. Specifically, the bank's east turret.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczP2qEkrn931e91ZAS0Py8VJctBzbQUTYTjO2t5rPq3BtruyfKaR1fehrwpAIveaMTMtQnArGgd9JAgS76o53Goxju3ll5EuP0X3oZdujv1s57c41hYwvHSu_N-LSm5ycQRLMLWnAJJ8LiINPJDCespqcA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>And we're in Scotland!</p>
            </div>   
          </div>
        );
      case 'Glasgow':
        return (
          <div className={styles.imageBlock}>
            <div className={styles.writingCard}>
              <h2>Glasgow</h2>
              <p>Stop 1 of Scotland.</p>
              <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMKgbNzt2k5u9bagN3AL1VCp8sw5YN1Dyh9yKU8eWvLc03yFQ81XjJAIIC_Wf5p1mYj6Q6NSJGDebAgOAdcuj4pZPWP3Shj7BFHpXex8TE-O-vGH5PUZjcobyL4AemLpp8mhnKlHqJNXLjsuoPbuVrjHw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We needed to take a bit of break from enjoying the city to do some clothes shopping! James needed a fresh outfit for a job interview on Friday. Wish him luck!</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPIIPhcrcieodwfdnuzgIKCuD0So2q-wP2mEIXiF4_EqIrDR-DdAjedSjciaZghSt3zunANUB53slryP6PhuukG8_5k4xBvxTKxrwvAP3znFV9to-SE_yNwwzdDvoy98o9hWRweQeE-mmsZ4gSrb8-5dA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Apparently Glasgow has a very cool dining scene so we headed out to a very Ftizroy-esque Indian restaurant.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOFjA5KLgpK8A3KOlvCGrp-LW3vp5JE-rclpi3-O-YXAJ_uHqDR-Qy2Sl-NRt0NeCgooxQWVJ7TxnwvEQGfR-xecVhiIDaTX6RVlDJ_eQhufdDfP7EPCDZ9QBU0W6gUJIiqVDDqDhpqX_MI5p-ZY4oDYw=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The sign above James' head reads "All chai is coming strictly without opium". We did some googling and cannot work out what this is referring to. Please reach out if you happen to know :)</p>
            </div> 
          </div>
        );
      case 'Drive to Fort William':
        return (
          <div className={styles.imageBlock}>
            <div className={styles.writingCard}>
              <h2>Plus a little view of Stirling!</h2>
              <p>Tootling through the Scottish countryside and some beautiful scenery.</p>
              <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPb_pgWV6yyo6pjvJV5BRFF1yZjG_x7b4FRfBqo2-feLmplD1mqeB-DGRFMohL7xs2RSWTIiLAxv0-sk3n9V8BldXa94NSXVehwV4w_ZiMdpO1sb3OmY75CtTEzhI2UWJr4SP9dHoduI6hBChEtQ7EPjg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The grey streets of Stirling</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNYnAbjhnyINUZi-_Mhuy1RArWq9TacZG5HPGdvwRoeL-2w9gpRFmPjSmQzEFyPr95zU4krpolnebKQahxqp6LDqGUNps8xsAf8YG6Sme15E6rORmR_-QrfMBhri4Hsi3HPbwDJz3xOaMGSAzGRhYHDeg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>A very foggy Stirling Castle with a couple of sheep in the front.</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczM62JXa6cFvk8VjmyGSkyO2RvlnAu-MBFN5whU1YCRuP3i4vxlRftQN6dGomv-szqvj5AIHfdejQZmiQAU-6U8iFI-hwDXwPtjLfLAjbIabMz2ioCCgiBYQRrEF8Ugfb2YUKyompBdgnvEJDYW8B4tX1A=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Bakeries are an incredibly important part of this part of the trip you will soon see.<br></br>This sweet bakery in Callender - Mhor's Bread & Store, is on the list as they apparently have the best vegetarian sausage rolls in all of the UK. We'll see about that.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczN0S3vVMYov7M-_BFnvsTcrAXAFyQRdnHbGgcUzEHlbmbzGY04byKyMfeT3c2UrLueeGfWamWMale_x9s0EbitAMdlgrDJkqlLUjALar5Xhr-iO4pgv8qdsiX8UgER2HKzokXlonvf5fAxIjAzTxy4yCA=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>See James' face - it was a good sausage roll.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPuCVyOAkZqeYnJImD4o5HVyOyehD0Rr7D42pB5F0Pm5mooz8Znp-r9ITJ0ls-BxHPI65gymGg2EisEdIb0BGg5QqHczAOUKHYlb7YMeJLoceAU-QO8fm4iQ7wiP-SeECo8s2y8jrzPtYSI3FGH7yuZPg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Loch Lomond.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOOjXT72hsHZovbPhI24bMlvni8QIlNgThUlCCI7rEw8XymMR1EJzSBdcVlsqYAS3E7_OMSbBKZplleFeexF9_UNZ0AQAnIM1aATYC5A-CpvTMNjVs1EbtMRWcyf51KMARdxRrhUywD2I75q71ON5Bezw=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>A foggy drive!</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNeVTG5unvbMXqG9xT7D7F-hZaTIep574CewToYydnfrduY9HISn9Uj0Or_7cWYCeP-L7e3K-nJ4OTVUStAJm0W4N5xVqGHpHEMSXHZNzmye77ylAHWfIygm9IrizD1QiVbtXPpsEeDMc5x3GYbpZqYpg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The meeting of the 3 waters!</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczP3fOZj-0sPYTtxoSL-bADI3ycUae_DI2gldQNBnXB90ZPrONKQWgOIP3Mox1ivFqOYAKX8-W_Qbctl9QxPy_clPhpVP7CpUlw2IronvoM4fMRJf9NRBmQ8tuc25dBM-DtjmHEeO49sSFsIyBFIGO_Unw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Three Sisters Lookout - me provided for scale.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczM9UrwxyhPQ5488k9nNLxlPaqDQZWWM-h-LTjV-q1fwoF4qS0td0HZAQf1nga7YjdzYZZ2mttSjWfAgIOMzSX6q04u_HNCooZzaMejV8_x67KAjAYmMw9I2PDyE1XFDcCm9lIz5RbTZjDseXa4l61PKaQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Opposite side of the Three Sisters.</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOsm1xlzRAWjci8WZEBhtbLNc24ue2QDBVLq9QkFaMX5WHXCnaCGHF6jm3uC1pNk4P7ujS_VUn1lFzosD-FL1d2RV0Ewx8v_EvLjSM9hqWZlXIQPGtY-9QCiIMyzIsW7MaXu86a1UiXhyVDodFwDVaojw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>These guys really holding up traffic.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczM3EG4s5gdFsJOYg_3OnM1ZDO4xQwFr8zO4MXnvzznUlTCyT_1vC1QaywY3Ff-bVkfSOCtkzfLB4WPnZTFDWIfmL-gVfnrSZgbYUWO2XMH4EhmPAz0D5GkKSWzFm9vwbdlA5TVEXO4WT1c0oIPiGdqJRA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>And we officially survived the long drive to Fort William. Note: turns out we booked a hire car that only I can drive for silly reasons. Therefore James was a passenger princess all through Ireland and will continue to for the nearly 3 weeks in the UK.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOcWwcpKQwOe7WaPocKM0rlN9EOWKqPUBFliAqKUUQ4NEsp8gkP2zeWQGKabecNZvrkz1gjK3EGldOkvGv8Ddp8FqZOviDel4Wst-D7tV-G0sPWoH-2xvXSAsOidWQwBeDSv6nMoAiw7uJ-fZJUhPiK_w=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The Glenfinnan Viaduct</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNKbTmdXeLvLQ82xq1k8eWwxCa_SpGtvtuzDgeV-n-zdZLu2Jop9E-0MNV2zDlBKe1MhGqT08NU-iMIpp-5jECEzRIiji1XnK5JCgak7XHU-2isRGEmKDaZ2aXf1GuO_20WumF7d2T2pQDolfDu0Dkc0g=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Pretty cool thing to see in person.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMSLzG6NuFDQkjlnqXIu1XpKGhj-EVBQKedbILXMDuAf4jhcpl1WOMcqr0i4YL78PNnxjOzg2WGmcWg1CEZDsnbC8FHxQXf5GEIbPOop5y3XXmif1hG8VeUw9Ojv7Vl4-dWiTPb4cxCoBdhhqGU98VLgA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We did some brief research on 'most popular drinks in Scotland' and #2 on the list is a soft drink called Irn Bru. James thinks it tastes a bit like fizzy banana-lollies and I think it tastes like very bad fanta. Sorry, we forgot to take a photo before we drank it all.</p>
            </div>    
          </div>
        );
      case 'Isle of Skye':
        return (
          <div className={styles.imageBlock}>
            <div className={styles.writingCard}>
              <h2>Isle of Skye</h2>
              <p>A couple days in the beautiful Isle of Skye.</p>
              <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMqIItlrjvCvsJuXtqyIiYfQiTuT22JQ_UA6Exexc1g7P_Gjpw6d8PN32FA7fPKnp_R71IP0UPA6PQ7N3iyzaGtjQ_SaVhozl4_6JE5EXySIqa2T62yFNiKH-GLPwVhMJ2snd1yS9sFAHl0pD1mqA-cRg=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Today is an incredibly exciting day. Out of nowhere James spotted highland cows along the side of the road so OBVIOUSLY we had to pull over immediately and do a little run towards them.<br></br>I was pretty excited - all of James' footage has squeals in the background.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczO5fY6xw9rsJw_TVbiASbA7BIEH5Wfi5gQgYDd-scWY29GX-rDZaUIFEuSQXd6VH_BIt5hMwOwIbzFRw05_iaJDKXOIDqaGxjLN7ESohCvoindnx_3dRMqcLaheWa5VdJV8ciYFVWJaeli6BCr2cEBDzQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Look at them! They came to say hi!</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOZecv_f06D9ZR2Q3Xf_LdyFkfm99S9xLjl11UapoU6SCv1r61yGGqh9BjGhKbSK2D3q3JsEKbAYaF6PqpofwfQEr2_HFRp027fHYJcqi_UE6vPKZEzPO0A2VBth06kedX9Ntn0skJU6POY_W1iSkiZgA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Fancy haircuts!</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPr_rjuZw-wzZjaSugkfsowsEx8_IPZVRb8DCLOwTD4t9nQe9-2OA8cQoDvl4k-rw7MBTjQCeOvjWShEvfKIPM0FBBnQB_xNrdJ2By6wRYVjjuIFGVeF-gqXeWY011oJLb5pFqs4sTepx29FD6vjdbCYQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>They're so cute! What a day</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOsbL2Yuc-qIb9YLCKXMSWJybSs3h0Nvd38g5M6DKZFhN37qW4lZqRuHMRtsIHMwDEkMD5GGT7OrlAWDG71zxWnMFuckF7j_rUNXJ9zUxKslqj7IGWes5YSGXEdW3I5C95ES-n08U4yge2ssSKFHyJXCQ=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>A drive along the lakes.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczN8IJGe9S0eswkLf4HIS-ph9g1tWruPUxCcSYOj-IJ7KWT1feotibN_T9wIYRxT4xrugaZdZXztwOzZpuvk7608UHivSS9XuTQoSCgFjBdfS-ocC9yQ0Ooo-cvY5ja7qDdj1r4Q14Ptwj62zlajf1prxw=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Cows round 2.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNg6cQYS1Xk3warN9qQNIHcA2uccA3rVthiXm3s_ZNaFIm1VpKOGACfx2s1YIwXNLuTp-r4IM3vpGKBSRXxxJtWeXp-uKffY2JkdB4TjlCFrTfnxSzfeunDIf7ngs214WVwDT811Zs4LZ8jvQfrL7Gfbw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>More cows! These ones did seem a little less happy though which was a bit sad. These cows are very much on the tourist map and there was an entire tour bus with them when we arrived.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOzB_jU-LBjkT8lnaa4V0mrxMievmD0ydEu7-CL7jZgcK0ynFoHskxM9gRpPdadT3vB0rw1x1wX6UUCyqVlaRtlzCPCOQ2ivOfnDlnMPWg1Y1lpM8Kpfo3Bs-U5OWox86e6D6Gn5ej1nePZ_FaJ6p_rdw=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We had a very cute lunch at this local cafe - Coffee Bothy. Its hard to read but their toastie special of the day was Haggis and Jalapeno.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPMZFriUSt4agvoQOxZ34i_JD75h2MZWZvBRvule_5ZCA64Bdl8L4UtjkhFBxO42_TIVub6ZWtb6U24zo2d0d1cuW4nAjkl07MMGfUw7qLAFz5fuVSTH9VRMPDYGQFnvrn6fE-VG46H2ogvWHy2cT36Hw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Sligachan Old Bridge. The legend goes that if you dunk your face into the icy water for 7 seconds you'll be awarded eternal beauty.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczP6WELNbK33xe-gX45VHTF1vR3t_43QNKwNlBcWBWRbBNtGwafQuZ9tKSAeBeGzcRxs3hjzPC_iW48lchPXX5VEhUTmrbX-xUzcpnrYneOqZMIOoJmAfjZJKT94KIG69CuKgnyduJmKeUNe6DvH4nZ96Q=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>It was VERY cold!</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczO1o0FEZ4dNfGjKYmJ_yQPiDzTrEH0d8XQiWleFjiJU98stuI5ge22cIAkbdT50C8DaS-lxDQ9X0huTtURwT25G3keW1sAiZkis1O9rtvaQZZBzv5694MgZSGLxNg-Le6DI8rKYsOSsWVnc_7mj5BeEHw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>James didn't quite make it the 7 seconds, so I suppose he only gets temporary beauty.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNuniF3vZ-RXal72ngtkpql4Np3C4bbJvmmFIh-j75HeiAHJyzp47RqEJGgtJFiEyO1JcRqSyf1WbavdzO2N56YOMNMBZyX9YovFKeRTRS0BUdGGUQNCbcz_3d8KqlZqSnj5icN6uvIItPSxvl3bMIG8Q=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Look at this guy! Just hanging out in the grass.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNyjePj-1j4BYrxb5H9O2Eyi8ZqFmKylj-_ArpQlBOQtC7C3FuCCVLeJ4IZZFtbY1O95CmHwme4VmtTb_3faMqdOLoUANXnrvfynI2XxxZWoQgca6x2JfG-9rV3M-xfYKQHzB3vfhJmvdyLexyGlUTxgQ=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>A good old game of pool in the hostel.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczP5DSgl2Whf4-9V_50PvtmxUarqj2C-3Y8l4f6MZOB930DGsUbJEENWdTYfR5-gAyMPG5A7CTBridrVeC19vS2U6mznSrrPtH2_3i79ouqgjzDLfUwc3gw3zaDfcOHW_xeH2kF846E8tpA0lHO0ptpDUA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We drove up to the Fairy Pools - they were very pretty pools with waterfalls flowing between them.</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMq2vz2dSaZKNgrLNFeAxHMaGnrZMBl2nllBkgUdQ0yxt0uzQ7D3IbamNcRwu3HvR7sq6nMmOf-HccNfTzpSc6ETZLpbq8H-TREzYI9mmFQ9fn62WinYzywH1hsVf9fX1nRIjQl3dsqiKETuIGKRpmN9Q=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>It included a beautiful walk up to the pools.</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNwY6buvRoWedg5qxBSLz6PdVVChbKyoX2k5bWIElN0vuwTqFO8-PjNGKYbVVd58VAQ6nvFuf_inMQRyTZ4Gy5dXaGRTDV94KbnEpxC9E-h3GNMC6MQszrZwfKmuQoO2Btj3zPfRG7fWhPGbYZOMIGHsA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Bit further up the Fairy Pools.</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOCjv9UE4UxbBZpdqkgSqf2ypNY1wSj4E9veVGmUv9NyfQHd2dLuHzcJqsnPDkhZRr1cRGGbp5VtAIv2r4fKcjID91iVdmntYjNt6aCulj-gtSUvQhFzA7sS9Re3eVbLc2jYCjpMC2Fg6iUPW0MUHhFYQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We then tootled into Portree for a wander around.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMhK46oV0AjJ_I5jEXV3dYlMGuI-t49ZsyFwAxWxN89GZAaz8KM0O_sxwlDHAZL61qf-f0t6WSRSEDVa3G9lLeA1icA-j8ORbRk2s2aOScQS3Y0jR99c3gxvHO0eHhHMX3SQn7qrmgwx5xYnobrRv7WTg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Portree port.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczO8hNRaVNNCyJ4-Pklq6bnJ0XnrO12hMjtu-BlQL2BGdWvOfMlhIQJGC4DaZxVgn3-IpD68unYbeyIIcRdL1Cmr_FLxhihzuZrTeGx0L4jjcfTNuPUtObcq6WT03HEeH6q4pvuK5tpuk_CSNQ_hdop9zg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Our next Isle of Skye stop is the Fairy Glen! Apparently there isn't actually any Fairy connection here, legend or otherwise, but people just thought it was cute and fairy-like.</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczP4Rx0NlaGD6CADwchOGCzbBYc5TL888TlroxYZCdhl8vEK6kJADQTx-yu9IzqOLgu3QyOQY0SFtuAzZrpzWruHuvxqWCTZB8ZEvpj9li8hKzpBRvjeh6Tw9Sd9UTZ4uODrIjzstsww_WgkDK5SDBN4_Q=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The hills were mind-blowing. The shapes were so odd and the way the grass grows along the hills is absolutely bizarre.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPnRWbtsbSvGPjm5EXaqqNm2wJeIEgQlrYCAG3UDU23tuXtVCTLMTumDsK453BQNmP1QYRCsml-SEW9uTBQrelmX87XRmR6dISlecoPWWIS4GD7LghvzdRW7wVYONyCFADllxr6KXW8nU-F-UT1urtWJQ=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>It was very cute.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczM8jVstOKC_v7ALQdbwFd7no5mEMpobReWGxzYtGzK1GZ5D6dr5S9DYzfd5hSjqDU_cqp4d4KkFcIjELy_fjAIUvDcIQt9i-9NHtr9kLgrr-X9P0lCduJuTxIBY29vewztnxrdahIaVWic7_drwp9p1IA=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>And James climbed the largest rock in the fairy glen.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMoRIVS1McZg1uh-aveISKwkCwUibML4AvUvp6cGrdBHxVephX99uqcxSbrB_WmY18NG1cdJo-4mM5m-ndeEK94DjSDOMjZ5Av4GJTwT1jGjsyKlc85vc7M7Jo1zTabsWVqv7HoYNdBgSt5cMUNu092lA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Our very very last stop in the Isle of Skye before we head off is the Fairy Bridge. No fun stories for here but it is a nice bridge.</p>
            </div>    
          </div>
        );
      case 'Inverness and Loch Ness':
        return (
          <div className={styles.imageBlock}>
            <div className={styles.writingCard}>
              <h2>Time to meet the monster.</h2>
              <p>Today we've driving through Loch Ness, along the beautiful lakes and across to the bigger town of Inverness.</p>
              <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNz7p2R15DqBX_sJn1bjkntUF4lPjhr38ku6nQtuW0yL7SQ0kPlD2pjiQ5A-F47bWjiGV5CgPHFwbweAjn40Rlt2h-MEpRHffynF3rqoiuJTAIkZHODwUDRCEp79Nv1mg0N-1yw96FDzZayl7nIPfl2ow=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We had truly the most glorious warm day at Loch Ness. </p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMttVCocI4cxp5Q-OQTIwqXrourK3_9qo39bYRElsz8mOBAeHmC98tQdcRBQGbAJkrmuhxeEj3Ka35sa_g_fT_TyxPAJm86_hUiVf0zTpTUN9aDX0bmgXMctErD_mthZ-EHMA-ddeftQ-zvvvfQN65YCw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We do imagine this place may be a bit spookier on a cold or foggy day, but today it was pretty hard to imagine anything sinister could be going on.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczM9GGNmM_Trz1dBTJQSaPqvOGU5hJ7qiuzqnP7L3kEZK2g4Nqm3qbh7jfdcuEFpXXyFykLOz6moH_NmQug8NNw7kthwnMvhEQL2kNcbgMK2eex--z-sXqdjMSWQRg8kr6lijgKwX3IZg1-hrSqr9oRc4Q=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>There was quite a few of these Nessie statues around.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPAfh_tcN1i5wI9NcVIYd3M5-kAJggh0DJORmeTAJBQKwdCktPBdWgbE1fGo7s_JOkeAK_ihLirdfHYJ9snzsIYsefAJjW0tabH2ykKRQ7FKywtCaitIdJz9vowvmWByzNRNfON70XAJ2lgAT8wVc02pw=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>In Drumnadrochit (the town most obsessed with the Loch Ness monster) has a very fun museum which presents all the evidence for and against the existence of Nessie.</p>
            </div>   
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOrucmn4Q-5pjkr5Ls90H7fV3Oj5Jnir38KebLQhKM6WbqnGqdwbbR0MbOoPvR0kH-MkVMW6zcBoSC4POZ4Vb0QLVEPTibK-oksPG7oQQyIlYyY5fGGkc8LsFej-9h1igwf_QhmO7v_KguJlcooyZ-gIA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>This was actually an adorable experience. The key researcher of the Loch Ness monster seemed to be the most skeptical funnily enough.</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNSAsK8I5OL_QlXolFls02xQrnEoriOGhUr7opcXMzG__SQ1yZzbIrgCR_WaYD0bAm1uNXbMQhX-negnifAuyiuz3TcRV3bpOcadh3aFTQZnitWG9s-0IRQcUMSw2BJKxc2RaQNSithMiVXiesqVmt1Mg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>At the end, it asks you to decide whether you think Nessie exists or not - here's what James thinks.</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMS6ndVnrfmZvCfojOZAIxEl0IkLsngawLUHhOLpBBH7wuW6tUFiIttWMd1JIG9YJwekGEpgN9f-sv7SFmqXT7Gb1aIQFXGcJuFSnkz4MbIwCaJDNJdy6ZQjTBNZoIAKzeCANcB24Ytwm9BiBK7AmPKFg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>And a cute central market in Inverness.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPRofe1Iko9RPqggC1cRN6iMPvHm9sYOvpieh8SSOqQUK6s3jzadK8JIDwhVJFSBXRf568vi6jdCvBMWvrm6Qkb-X0AhDWf64zE_ifcAXp-86ZlB3CmKn1w8Gh1ccyvF7kBjEE83MJixGdJnvW-C_uVxQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The river through the centre of Inverness.</p>
            </div>   
          </div>
        );
      case 'Drive to Aberdeen':
        return (
          <div className={styles.imageBlock}>
            <div className={styles.writingCard}>
              <h2>Also our anniversary!</h2>
              <p>Today we're off to Aberdeen with a lovely drive through the last of the Scottish Highlands.</p>
              <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOwPWrMsqJMUkBeAb9-lapr-4huTR1e4qkiUm9NK6MqrOwxPlLxKj4vKtqS-aDRDb7gRitRbdSkhfzpQq9-NPg8K4Z82uFxL2q3a6lshhfbwwNNiavIRuzdz5uxAiU_y-2cvoL6CbYpjl_fFMz67w0Q1w=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We drove high up through the mountains, with snow on the hills nearby and the car constantly dinging at us that is was very cold outside.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMX0pGAS9HqnlSXRDBxZ7xY9amEipxbww25jwzWYZEJOXoQBrJAb_VzDRxbWdVwqJ4CA0B1trcCPvBBZI-xdBSDsbr50CDPFjtJV3Y8DVCcxt8eOhox9hTr3DQZZNTw6F5ZdftbgvxSF3ekOFrNVgHkVg=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Now one thing I am good at is tracking down cute cafes. We have a very long list of bakeries to stop in on throughout our travels.  </p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPVgWafgR8INjG5mWi4owVFG-TJpz3Ep539hlE_NOZU73nUoCj4TaKuICxiyrP-7GAkwWtZlVvbLwNBwWFkcEkIxvF7gOhrRABiO4DtK0Lr7nZf9UiPgw5QaCFapIkYoKFlWP0E_kRqmTjr59Pq1Fz81w=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Now this one in particular was to get a pastry called a Buttery (or Aberdeen rolls) which is surprisingly savoury, very buttery pastry.</p>
            </div>   
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMKLL2c610v81PxeqkLzQ0Ga1bJVaiflrndoq8ImAimb8NUjDMxqZhvsEpifjAR6vJ5a4Fula-21Mdv7gS3epVPu3v6p2NqZPffUbD3xc_bLr74VXuuIL1vtsk_ih3x8HeWq-tIua_wWrQXmR0QiVQ0Ow=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>They are served with butter and jam in a cute little enclosed deck, complete with hot water bottles and blankets at your disposal.</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNrWG3euCeLCge27Pum__Q73gUhlG_8W-8FprvdNbuwMnzqw4HxLp4r6u8Mx9xZVfJi9bc13C__GpErkGdJ1ZLwIgn7RichyO8_sizrIUoZ109EqExixTGYkoQtVxSOW1BSIavlg4z9g0dZR7M1ndJ7Eg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Everything was so good we had to get a few fun snacks for the road too.</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNHI3GVZn9JFpvLQg3zPdFi3HhWvXMLKw-VaHfEVzPx79zw1qUSLT9XKMc6fsdaFONbaYFYli44LHejaGZkcYyCu8L9y6N3I0sKpjBP8BS25qcsWQTDYFMz4rUqPhxRWQFXvHIdLHspyiXdvFV0gKrikw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>And we made it to Aberdeen!</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczObAf_NtcdJ7_QDeHIEimxukzifOA1Ec1VYPkApgQ7AgT1T1Jsun1DPpLYPFTO0_oRVZ7qWirrJkOR9SO1JfnU_jOXseBRvXl4v0b0c40eoQ5YrC_8iKZHrhdl_GAfAmwmoZ2d7vVuBv954ts5CbZX-Vg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We went to check out Aberdeen's Art Gallery.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMKjihm0Tc4eFbJyZ6mEJtK1PNpaZOVkqN0hcXFiILSuKJzE5qDYB3gmixPJseSL49qaRZ4eZUM7wyGAndvW9s52QpEJvyoJfkV-Ld8uy8kStIYfr3NfwUwfOYDjTdZgqLI4iOFMeuvPhnAx4fOpdtv8g=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>It was a very very sweet gallery, with lots of interactive kid bits and pieces and lots of super local Aberdeen art.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPRcsGc5pb764IrtTq5Hggd1dRmxms15Bwa6VIcMQI0UManLNIoV1sZIKK_D0_ju-OQoX3eWMu_WsYGOtAVO4WkCXWWiZqxLZIReFCHV-ioZiiilKLY_zQKQQyxs2xtGpnyrqcXofQCkrrLEDMDS8ycdQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Bit of Japanese/Korean fusion for our anniversary dinner! Super yummy!</p>
            </div>   
          </div>
        );
      case 'Edinburgh and Amsterdam':
        return (
          <div className={styles.imageBlock}>
            <div className={styles.writingCard}>
              <h2>Edinburgh and Amsterdam time</h2>
              <p>James was flown out to Amsterdam for the day, to be wined and dined, toured through offices and perhaps interviewed. After that, we got to enjoy Edinburgh!</p>
              <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPdBJUtN42gxvON4KxtiMSgjo8zIUijRAGBdgVkyJcy86vjabO2KDznupLk0quqnmfkDUDdB-jCN7DusCcbk3uUyZNofAjIRilW1vMQJj-MZqMKSWpCEvT3IZ7-GALpfqkvdlyCACEYogciFZrm4cJPDA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Today started at the lovely hour of 4am. We headed off from Aberdeen to get James to Edinburgh airport for an 8.30am flight. This is the bridge as you head into Edinburgh - aka we made it!</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMHjKL9nwUKUkbK2VQzM2zvCoI1hYmbjEeSSE6ZXXOkMhsYwnl06ZMd6LNOuFj6mlVCOaIPgY6_Fx3AwUSlfDr0abp9P8aorvVJEaQJ_KSEDOEz75S9TEaSOiPMBgwF3Ji8cl2vHxBmG2SoeyCZHwmV0w=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>And James made it successfully onto the plane!</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPOcNvqi1PGmKxdvFlP5GAbS0ZSOTy49mtNoTZ69v3ME-Ro5Ee_HZac8AMofXdJN4MezLoldr1n4QN52nj1GtBWppme4-dHUv--YdnDQnW8UcU08TX21cdiOdD50UJq8OlT8W7Lc8zAOzuRvdwSQDbkpg=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>In Edinburgh in the meantime, I treated myself to a cold and windy 7am walk with some highland cows.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOKrrbhvMPBM2qpvqWNjanjWpWg26WmBPdj-J44BX9CeKvN7DMZmpEG-Jx7x4LSBLFP4SA4WOxpvOLHEYGbQOt-E_JNUsJtwzsJSMauqH94SwL-ZmRmdEcFpVn6ri-zkr0n8QZlTW0IasrW4fqNH8Fo8Q=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>A very cute strip of shops in outer Edinburgh - it was full of fancy bakeries, cat rescues and hairdressers.</p>
            </div>    
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczM0tkXAS9gQo1qjRzPNCK322PgOJBUqzMhgAZBgcJYGg7zXGxNQgQn1i6-SSwAd_WpzsiPA-xvyUyFPEteZK-0urwNkX8bxr-RrR33Ugqecn_fVWu-j73ikA7uPK589RmRWIh92EjOQm_ue61wJjk-eAw=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Little Fitzroy cafe! - Basically back at home.</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPj6Sf9wdEeQqglt2qS2vNwsB4BQkzdaPVGB1p-kbN-F7UNUILPvDSf06sWf1UPsVgJ6wLrPrW1ED8FjAyzv2nQg15SNOPc0HSa1Iw0gxBn-ULoaJY7OkopjDjW8vf7dyYQwT8TMsKpfBnA4_qLwKvIpw=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>A local artisan market in a cool old church.</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOmsc2Es8-8i-NGk_UDhkR7uTGKGm1NSbLTWd-OnhSB2e9fUNVv0AerfXnKkPyfWUgvt6WJSZBVGoqwhsr3_OkkaGSwZQGJU2S-V7YWDm2HI39Z4PxETbPNOFsy-uO1YZvtoyvS71LFLpFfTl7kjJv1Fg=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Today's weather was absolutely absurd. Rain for 15 minutes, sun for an hour, a hail storm, sun again, pouring with rain, hail again and then sunny?</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNBMoIWG1bVRSl41jl7tq16CZ4Ux-gtNCfFI6MZG_HMaYb6mggStITia9c5VSw2TF0Yu1NzJA9cnSRxmsFpKU3VylWRMRtKUq_kSilnZJPq6JOCNPodLIKqMmY7gsWbmjw19AHc_Ns4tBQgwVOtuvip1w=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>A cute little gateway to a stairwell up to the Royal Mile.</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMMLIxSmqtfbQSsNmH2Po9GezRAzhwOWgGF9t3Rd8V_0uVLVexgoUSPu-MebvwH18Yzzsp5jyx1A01Z3hoFbzKT07HXIemtLp_PmZO-KmLIF8_ZS-NFfvJlqoDTnJd0yvu6-X2wRAC4r0DnMWkwqDyW8Q=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>How very Edinburgh.</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOGPE3H0xq-uD7fxMzcxRrlcce870g4ZLFoKQhspJ_mzauY4cK7mNqzHKeFZDNyRJsc2RlgeAqiRL9zDInwJMIiwbgjqaRuCXqK_WPaYrySOWzvW_BPpyKtsyP1K-Wx5cAuzaOpC3CY34UHQdVM47R5Sw=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>A rain-cover stop in the National Gallery.</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczM98ypN_8YtKnZQaY5sZC0RlTylmLqyEOfpfQleVleqtukxRNOO0_WT4TnZchXrgZ-OqrA6sB-6jlGnjdamBhC7nsHVWB4ZriCYWpStC8iYdrRIF73Uenmv2_rTxOSql_kGuIzJqEyKzCljHth3Ys4Rgg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Reporting back from Amsterdam, James is being treated to a lovely meal out. Burrata and bottle of chardannay not pictured.</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczP3qqLSWTSvxPfPhhXZ31ZfEvlwTdf7ahpRTD_x4TWWfk7s4OOZRSLgNLuexW2ZXsT_cooTISc9plsHsVMCRtySlXJwxNKC45W0QiFrIj4YbBaZm2RxsYw4cbAU0IuRwYV5qMIHeh69FBYtCDkrR2GI_g=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>James has now flown back to Edinburgh! This is the outside of our hostel.</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOUUOf8cPHtcrESgme8S1xj0JxuBRxheLmoPtuWbpRrY33-jqAigawwRQq7_gnZEQub9tvMvASae0n4eVIhAc8Z1fchQeoFyxWwyjuElhyReuNSlLiIRuXlNPWoXlZxuQ-pow_TFBkEb48-aaeshoIbWw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Taking a stroll up the Royal Mile.</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNjcRkXjJUMMANohBmHn05DdeOPpU4tMaFfB_g1TkMkT_6r-dfLNZDE32nBnA0Zl_x5M10eCK0sONQt5hBDqhAZKcGLLsVzNeo0pLDifsgE3jNI1WKWe2Hx_tegZWfBrrNYNcR3cNXt74wngfMlbvhnoQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Couple of scones.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOinRqP5dhzIjfEtzcf2QcG0yO_yrW3AsfTzUPIqnxwPRxXQL9DwQu4gWFh7kEheBtWVx8rBhucfkEe6e7qhX4bDss5KL11bLqh5HsiiZgnHg5JdcCL0vpJwvpE4xR5PwwDELsNRfDQCBVAPQVV4LLxoA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>And James is off to Edinburgh Castle! I went last time and also have a lovely round 2 cold nightmare so shall be sitting this one out.</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOkwcXxBjLy87kMIRLdkV9rBVGhYAPK_BaGxehuEA3tely9o9N8bIfrRUelDYhp4UeatokuiCVPEGZGF4xHobZFRSfJRywDJp2iHDeqItfXAvvJaHhnOqOkIyXXQS7-vJ54hqBDRo_wkcqJ_ipDx6DrfQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Edinburgh Castle with Arthur's Seat in the background on the right.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPdz1FVy0dn_DSZ0YyoT8d3XPcks51yogHR31FN_emzmex6tdQyzxKpruyzIIghQA0cwy8c8GIqmjVw_27MlTckvZqXPhfqfzBOl-vCk8xjTA47pdMIcVcIwbD19fJ669Jv76OLGrvSM6Z189e3E-k11Q=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>View from the top of Edinburgh Castle</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPpQxFTl3_MK8vH1oOXRl4LEVMBkOmu1ESUnDc3EQAY_c-5GbPjD2ia2uthiPlxX4CgtLqYEdHuec7mD9SFl1b8wkDrJrf2y-kl8BKIZtc5s_q3AJ_sw0ZM9alU9GLXGnn92rOeePOMl9zzmBZWGb1fBg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The great hall of Edinburgh Castle.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMAoMp9_hjiEKRVRxW5tkdfoRT7A3C_b-Os1dDSex-4hWG1ZNlw-IMlKU22-ozqD2JYzB5bEdlhp7eier2JPYT-7APYerRRWoqE_F33fT5ddy2wDM9q00hoN4E6MlUoXhzAyfnobsYfwZaM_7QgvbGvnQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>A cute plaque about a famous little dog.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMdE0AXZBuYhN1OTLlYMqsDAXp90y1hfejPHKvGbCYpDoyNmE4Hn7lAVBEuvLYLWyKcrN1JykbYqUqweVhbrkIk7PMpyEQB30LhQh6V86tklxBS1oZWtlInhNjoK6djcwWnhErENBuPh-q0-vIKM4e_iA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Greyfriars Bobby's grave.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczN7GYqEoFq_xD_N-uZd6eyVmfkvux159zBR6WdBTimUwri44GptD8bn_59zozcHa6saSux4DOBuhet5BJx9zrIM7O-yDwl87MTN5DgR7qeBwOHHMXxo7uGobKbCVE0kwBSKyosLE4eKUQzE9v2m1HNdrw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The layered buildings of Edinburgh.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNMYYv6RLfDCYSsQ4y8Cx2V2CHEEY0SgKiG1hYJExJeO_r00RT5yn-Ly5dEOWPC-yIhZxGxQ7AvcSAdoPAeBnKmb_YelHE1b9ONMEYTq_j07aG1Bu6W2xas8IMWr2RnxwMFySfL1wxQfpLBUbHkaQAaJg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>And finally a little peak of Edinburgh castle from Grassmarket.</p>
            </div>
          </div>
        );
      case 'York':
        return (
          <div className={styles.imageBlock}>
            <div className={styles.writingCard}>
              <h2>Its England time!</h2>
              <p>Heading out of Edinburgh, we made our way south to York.</p>
              <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOSKhzIbmQ8v0vld1nyJ4jHxlb5LXChLaRTmjkVkftBmT6HhuHFqV6azOHTrfsPNuUvuUXODBv_a-hRi8HvLkwtD0yK3EET29hksyOUPBIAAx6owNssYOR0lRF44P9rYJ2Cpx6O4obu1zRyZsa0DvXXCA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Bit of an english pub meal - vegan fish and chips!</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczM697xFJ1LzKXtNYuJUfaCBMDeuamUYAkZIJqrgAyWmvv2J1xCKUBdatde55Ns45M1MeTEzrDbhL8aeRyH0NKqchO761rQQa0J8rByio-Z-qgzCGyopM2skMbI17On0ZTESlPY8Xas-_f7OL2vRhVVlOA=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>James trying mushy peas - not entirely convinced on them.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczN6ohZbRBiK7d-_eBTw8yzI1KJrnDCop3NrRrIbVroqn2sEhCmc8hNyHdrP1mQ3SuEcSIar70xu2sLJgbf0THhUqSFORcwiqQemINqk1V4IqYPX-Hp7QOuaizWz7oECsnNJdmTD4RCSmkXLNQtG2N9WZA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The city walls in York.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMCQDrTNboYnjozhQ5AN6fUc_6D03FtVyqnoCCdEMsZOu7xewoNGmf7va6NrgaKhOUFDjsAdQsQivg60c7ydGr15Dx7xXnFWIQEaVpcHqzKBNoe7xQDd5LrGhLigRvkuuOEgxl5vR9LROXvU_rkRQ6a0Q=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>York Minster standing very tall in the centre of the city.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczN85n9xeSoeCYRyK0qMixJLlF3t02zfsUGwpLNzwPVG-Deui9FZCgpDsgbq4Xb7OJhdERMldIy4QsahRR4DTqnvexh3tFeNEw-mBaMfJlbdxnLAgN9Hn8tk1kvdWSmzI8RdxzrTy7_RgMjhj3HOgbJ8oA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Strolling through the little winding streets.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczP9sW1oC-K_E2CGvlHvA7Q7qdCq6XXNzGPaFMcfBWywISWCXZLATE2X0VfIWkad1awJNrl8iXt6Zhz0jrj9s29C47DVJ_OEU-OFdlFmknePxS6x6MeFGHm5aXpn5BPjmoJjgBnVnLvMsPpww3x_s3rS0Q=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Its a cute little city centre.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOV5L6fc8y6ssNNSclgS3zPz-r80AkvhalpeR0Xuyr2eFn9lF0moG0XjtO2mx8TRS1IqJFArtKeKE_1T7Q1oV0ZBd09l9FErl2b2gDpmV5cKxCoMKJWRGAW9jAtZia8CyJ0zl8qSTBNgnR83SxpbQQx_A=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Stop for the first of very many cream teas! This place was just off Shambles Market.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNmt_1CbzBctojkjsy1dAmL5XIQ9CwWfnOhtRtW0c7UYZ7XowPqXSEEQEMGXmvtXcbFzYzRdS0UW3kSqdqLOlUHuGKMS6R_XMRmr4I9zb8VEoDEGFdW4wfHFNKB47BZriO68DjcGSj9VAhKQLsZD0w5NQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>This tiny, tiny shop was 4 levels a tight winding staircase with cute tables tucked into every available pocket..</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOmVpt4i_SSWsMa7gKBkakbJJgk1AQNu0J6bSjUJLUNFkrcWEBQ5qGuob-5WwghgULT7X9sq02KUcWAVm5ndKWKNY7GQYBBVvEFCznR4wRSo-SCuyYhgf-REiniICTY7LzzPq4Y0NdM0b8qxcBwZavkOQ=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>This place hand-made their scones onsite each day and they were very nice</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPTVzgONqVCHzF2syTsZMtzlACnHqKOg4W_AxsM-kGMmHgbu7l2vd81Lp62j0qy07fl0jPuoz3zuYVwVOj2eeG9nOX_3nlTu4raI6FfgarSt1ZSDn6EY4N3HVaGXRhekJEHjnRg4XUgcdLZAzfxgooPKg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Shambles - a famous street down the centre of York with A LOT of Harry Potter branded nonsense going on.</p>
            </div>               
          </div>
        );
      case 'Oxford':
        return (
          <div className={styles.imageBlock}>
            <div className={styles.writingCard}>
              <h2>Oxford</h2>
              <p>Next up is Oxford.</p>
              <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczO_XnovjR6YrSOXGf67Ftf2wICAfhPFTYUG-XgtHHS9N_3JwWdTLxPgj_7Pj9zyAERXnjvJf5A3Lzm4eA-P0ArOGJMjA_fZhKVl3SzbRJIN2EKOoNh5NKCZd6HvSEOQa4J6nhiPvSyeSMUG-LpL66ctzQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>A central market down the centre of Oxford.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOXM0UHsnTuOwh2l4xoShnRiOW9WbWFIv8zo4PvibUeGp4OuasmUl9P42TqIeIkXD_e3iH6bayz1YPfDCkH4BTRzFtrAQVnFzKp634XsNN6xpqg2FdBC8UcphLNmK9tN-UPsTU0PZLZ3CNsW-BnXPtYdA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Oxford University's fancy library with one of the many memorial/protests for Alexei Navalny.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMbZqJGRs7kt-YwAZAUjv0R4IW2QaSvW3UiGd8I0WnT6JltqCBoeq5WkpDMPXdcap9StcR2wE2rPkIAZ4VkTw7a2cXupN5q4wTHCoVLsYkR_GAqjAHyrqvnpHmeryKnK7MYz5e3nEumFKMHbJvoDA_VAg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>What a cool bridge.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczORzeZrX0txp9dlG8PeT_QwwsRxzeD_rF9pVKYsb2ta-My03BEryzj8lbJd_xby-TQpfbREdwXZr85Mdt9c0fA_42rCp5Gkx-1yWrk7t60s5a5pgDXWAT5hFwBTnK3PRthl01awUra1AF1NLxQERytwfw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The Oxford University Museum of Natural History.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczP1KJQnMF0o83sE5bkaU5WJxla-hEZT-0G0BV0AsjY2hX7kqODV7x0MU5ZErv_GiRel0Yt9InygioZSM6imUaQ-afNA0qap2wquJeEn-PPrb44ofke6WtW5y2QnisFZRKEhLh8TQkJ80gXoXaQraAsPYw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Bears and all.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNK0MugJ2B6tMuzciFvT2Rhpqkp9iEw-c5kWlybP3tMK4n8UbYSgigucKfdnFoQ8cgxnJKG5H0c4lXtCKSN0cveb5nk-BGXq1-SrOyFH4P-q4WBkksn5Fnzpsf07vdB7lM-9xfgZ7Rny1JiXvvvYrsNXA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>And skeleton casts.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMHZdkqv7BSaa6r1pA2xR5lOQmMZlI1D8FDcuEkixel_utBNwPf1sA9TCSDBO3RHpHisA-dnG5CVeam8l6ZBrfTMw3kDtvkmA3rJkFEjKgKAbz0QNYi0ajZyGkSzY2Zy_C1GGMT2BVb6VXc-WDbJaE3jg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>They also had a section where they store everything they stole from colonised places. There was even a fun sign that effectively said we'd love to give everything back but the countries can't afford to fly them back.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOa_7fvB4y4Qhu9G6at_4VB3Ux3qqhCN88VvPy4tb2YzShid2AGx-mNnkfBaLYhZzG6uCbliFNACHpB1yOyl-BQ1OMOQrgEnsGjZOR1vYQgSe0aDB26H2C49clpHY7Nf6fmx5A7QcceTiJSKA1FBL35_g=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Anyway moving on. Here's another cream tea! This time in an old converted church.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczM3On6M3Yx0vVUcv84PgXIoXGO2elgXU0tCgDcoJOMPcOAbTDi4wc9tc2V93idR72qrCGBd6eWQS6kCl5JoJ2-r_Vq9HJ4MVwAWoYo32paUUpdd6qP3RuaXK9_ypfrQv2-JFWXQkVNjRlZ01P07QVNHLw=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>yum yum.</p>
            </div>                 
          </div>
        );
      case 'Devon':
        return (
          <div className={styles.imageBlock}>
            <div className={styles.writingCard}>
              <h2>Torquay</h2>
              <p>Devon takes us into Devonshire tea territory! Plus a little bit of some beautiful</p>
              <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNCUdvt05fuMgfYs64F7Qub61j1jxx-MrSpO4HHbVVe7q_1zcSGF563bwuG548IYht6MRmG_vK-vcKcdwqUOLOkgjTAyXsVGUTqSA6mkbWE891agbUjqBkQhzKGrdyEgv7rKoyVGYFO9N30dTF9VRJd-w=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We ordered some afternoon tea and my goodness they went all out.</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOKtQTyRlHN12ABsX90I5nSE6fucELLr-sd3W6Do3gPA1wB0i6tOTuIohtoxGB0i82uWCKGXl9Wwkyp3DixkSc5MitkKYDG96hHb-7CIOAAVVaWgRIYddHV8-kqdzth0Tqtj_AXCXgXe4O5JvTgzTB-Ow=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>James was pretty pleased. Its a cute place too.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="" alt="me and chippy"></img>
              <p className={styles.imgText}>We had some reallly crazy weather on this drive. We even had to pull over a few times to wait it out.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczO9QtuOSsQeM7fnOd9871ZKf_CYX4Ct9dFIIuWlje2sJeAhLXTIwaQzZHPuNsfTL6cZ0BqOXpRW1h-v26sMO0KPjZYujQmDFYEwJhgc9qW9KgfF1INgWEfVRtrGH5biAJ1ENQ2O3P4yZJ8KVSx8Qtr4KQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Coastal view from Babbacombe.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczN9XYITMh09VujpUFTz9S0uouoR32ihntBHhdqWU3iJN6mkgZKlI7kVyVbiu3_tM9JFIWz7Yy1STfgqQ_rvEpFKYO839f_uSbG-tUClIOWnb0ot_Dp4YtvkFnxzKvUnnln6LYNmJB76pW3sgytBLrGidQ=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>And we wrapped up the day with a quick outside soak in the hotel's hot tub.</p>
            </div>              
          </div>
        );
      case 'Cornwall':
        return (
          <div className={styles.imageBlock}>
            <div className={styles.writingCard}>
              <h2>A drive to Land's End.</h2>
              <p>Today we're heading right to edge of Cornwall, via the cute town of St Ives.</p>
              <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMzr3w2ywEO-_P7Z-diJqG9rTBTvdSA7QJdmhatDdygrfAv67wqnhnQet97fD5mF3szXOTS2NSE37cfgg_0p9HfMxwJMbdsx3Ed0vwKF06ELB_nZ80IjPmbyUHwDHTkNvqLEjbKfQHS3kt29XNT127Qtg=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>A cornish pasty in Cornwall!</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMbv0bdlLm6QAp3AzN2lKfDnkcHRDRGEOKef-UQKF_IGQHESmGJA7w4kShfuJ8EEqOctWe5snftH9OZJCM1_qrJdxouXGFW-YDhhf3t5h6U_QyenB7_10NPJjhCdEDRTP6IelnJIcyz2l1V35IpX4BzKA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We had the most beautiful sunny day.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMqeTStyYgEAClaMupN0oKlkOzHEhOkFW-MIy8jd6AHG_k_CzI1td1YyDy92A2n46uJwe3TM18ma0_o7Ff-rj34PpJ5m-A1oqNiRJjDz3VXkqyINGn6LuMwxT73E1jrHfJPdlahH1MCI8t_0eSdARGgwQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The Tate Modern St Ives is placed right along the coast with the most amazing view.</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="" alt="me and chippy"></img>
              <p className={styles.imgText}>And some very cool art from a Swedish artist.</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOZ91fD6UItdXkVXJCArFQRRAIczLEnaPEuTSSUUnhPp2VaNJjXZoy8MKh1kMifA8Qc4jtD7A0QyhnPTVF4XwsO-D4JM9ia5C99sOhavABIhETTt2_YjUfdxcg113jl14qclrvA0NgWori_guN1ksNkjQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Looking right out to the ocean.</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="" alt="me and chippy"></img>
              <p className={styles.imgText}>And we made it to Land's End!</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOF7WV-4-S-bb2sFrNGDiXN_n7-lXssmFwpfuX3ZnMCy0xPbhVRKK2dyPuOCi03YHC6gzy1VONAFyzib_z13YvuR9_jZchq1Zp6yhdcC9w0JsUdu59E8iS-bculs0gy_mmMRftXln9WBdy-p2THZdCVxA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>It was crazy windy and the waves were so loud. The hotel was using secret side doors because the main doors couldn't handle the wind.</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="" alt="me and chippy"></img>
              <p className={styles.imgText}>Bit more crazy ocean.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNmV-3smfC7w2ejHHgMHlauDQpiVD1GTiS-N3zJzfpSziaOw8aU8OwtjpgSvFNDhDj56oqIbd65DgGwPaQiTsiAuNdLkQ6EqZ7I2SC4hmXwfor1WT3eHs2IVKCEzqslC18Q6fjOPVgySDoL0P_qPDPGzw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Land's End!</p>
            </div>   
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPVF2X4yVYw8FJafJYGYbytTaFj01v4VIn5wSc8RVYT1qkSiKbZz7jOS6QfbtvJ6LUBe6hp2p28lqIPvtm33DBk_TK8aY42e8bVBlR_RMQM7oN1UjQVCSXyvQAXxZYjl7dI1RyvtUfjCzY8S-0JIi-Tew=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Now this is a bit exciting because we have not had breakfast included almost anywhere, let alone a hot breakfast!</p>
            </div>   
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPKt1InJ6yZpmeTwzGwY2o-AOMkFv79t3KktSpBke9zaRhm8tOXKx5ncH4Za2XVTj--0tODCy-Wsz_iuh7LD_l8yLfFWpLH94-oV6VD-ZecX_c-m1H1suEdnH3mhv5SclieUFyjYscjKzwWzJgbFrK1MA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>And what's more is we ate our hot breakfast with a view of the ocean!</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="" alt="me and chippy"></img>
              <p className={styles.imgText}>Look at that view!</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMDR5bSBdZ88RbpHDisH52PHlar1XJtOFZZDFNIo6ndsqUgSLPiIxYGptUfWD33GZIi7WS5CvKGudkn3zLwFvfUyqpN-6sLKnllkYdkw8dudbcCKjd5YFoS-zef69lgGZXCkwZ2WKFQzxvAT4T_DXu6YA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Well would you look who it is! Back on our way through Devon, we stopped in on Freddie and his grandparents for some delicious lunch. He was more British than ever.</p>
            </div>                      
          </div>
        );
      case 'Cardiff':
        return (
          <div className={styles.imageBlock}>
            <div className={styles.writingCard}>
              <h2>Hello to Wales!</h2>
              <p>We've crossed our final border into Wales.</p>
              <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMyO2msKzjGlZ53AyBXfwWCz_maxAcrNe8rQAeA85c3dOWxb_OxxBt9OQurYRvxg7RPml8Ik7-ffPX5MW89P0mNIR0O_LSA1OBDdgnQXp4z610kB-phA09uzUT-UjYuSoPnZ1VCz9i_v8aKWkAY8P3dMw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Hi Wales!</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMI6ZP0_zqwROoZSHwm_mzVq3LuQSLVmd7A37V7OC-Dg_m1wvOK0ozwsDmSomNqT3Pq3enSiIz75yIAYfnTOXn86OP95OnzjZ-roAGzSnsiSJOWYDX6wqnFQDmEfVpBGiwJeG8IjG9bnv-ZrV8GV_7JTg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Cardiff had lots of pretty alley ways with shops or bars.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOCB5AZ0ixzcW0osze3_rX12j7C_uLysD55pmHjiAxwnJWVLXX_sJXhHfXXxrlRAkgVZB2JeHiI9T8k_C-kto2pMLmF7h-MLKa2GdNM68ds7_lqO7NPhDML8hJJD8fVNbr9-DcnRI6D5OQkKM-btiiXyw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Cardiff Castle.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNebHWlubXLb3uJ90GjgSvKFBaZt-oW5pQ1GtTLn4s6wf39c9MGUB1LcPhj1fVaNIsl2cA3FWn8JZ5Nq43MUl6r8CB8yWUCiT7l9Ulj3AUlwQP_NmnKvnFj_jREr3A50VdN8UShlDpE3OnRO2GjRAAKNg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The Cardiff Castle grounds nearing sunset.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMbOEnDQttFDcnso2i9O3r7O3TCU8l2MgxW_FutzUg1UCH25iIztBmt3XOcCItWwfLUMLKYcZi0pyqDW5sKqtAxkWkGNbRen5MFmeRLM2-tr1PMyc0veikGmPOAKtrVamtkHt0uhc_DDESjWNsR6Qv2sg=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We had a truly horrific night in the hostel in Cardiff, bad enough we're probably now done on dorms forever. So we packed ourselves up as quick as we could and got the hell out of there.<br></br><br></br>On our run from this horrid hostel we found a cute little market!</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOdFcE2iIm1QufdnllwSeWXHPN9zSD48dRFU9GRxAjf-y87zf1rFhcV8M4H_AshGWsfa-LFucAII2e6sLaHdVnOmOKE3DdjtAhiM5vaBCFnpZIeane1tgPX0BQASA-Ela5PgSxZHSYOeUn41TW6bzBd6A=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>hehe bread stall.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNj2ow8EzzSP8QxbpRd7JQ2-493rPFqAZM70We8VT7h4zHh4FaoP_okZXTDnYU_j9aNvjYf7akanFzDYqOa1oWjjvizt9jrcxhytEghu89e8yfnpHCdyNaxUIjfis7ozgexICg2xnVOxZgIjxEWPlNb7A=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>This place sold Welsh Cakes, which were bascially very flat and sweet scones.</p>
            </div>                
          </div>
        );
      case 'The Cotswolds':
        return (
          <div className={styles.imageBlock}>
            <div className={styles.writingCard}>
              <h2>A drive through Bourton-on-the-water, Stow-on-the-Wold, Moreton-in-Marsh, Cirencester, Bibury and Burford.</h2>
              <p>Back into England for a cute few days in some pretty villages.</p>
              <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNox_GZ-zyLqWN3fr_PwKU56MgOVpw_OP5ATUZbC9tzh4GoNGAjMF4Y-NBoNkmPiIUcIxrk_2cs28xBipYywNNZr_IjUlwb0ooNqbqc6tK0VhRvQTnADz_EZPnmY4Nw7sZYVN5kbSs1sI8mQijQz3BVgg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Cute back alleyways on the walk to Bourton-on-the-water.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNH3KxlmyRO2CNNu11bWSdfxPsoBV4tcfpwuAQsyj8BIU2za3-Jaho_JTpFmQJl2NiHi5blGexUTfV651a5gN-l6lxAUQxfOWrZ3roYV66NH-maLmp7whnKEAqhr5_0sz6-qwW-AqatkpvvpI-ZnIkKxA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Being Easter weekend (oops) it was very busy.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOsPwKj1GKc5w5x7rbShLzF0bf8yctAmDOZRAdMz4lY4Dj_tNF4-ysLaP8fR-0gn59obheuoxY8tZWN3lzclnuZdi8JtoroNDBxJJCjjQLLVRn0BcG0CwdFb1deTG3muWS0Nj5d7DCqoEUWinlo2DtKKQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The bridges across the water were very sweet.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOhCePtviuUmmi8AW3HBp6plgvIQHSgZPlhkswqwZerKQrMKTGgUcSdQJkkX9uqX4iqCUWJVRuenc6HSXORWH5mZl0btGeIOyU4PtGuYdwchuLubr7ZTK1ZACuq1utk5W0tW-S29BfFmp5a4wmJRtAvgA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The water flowing through the centre of the village.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMDYrpFBW7FGkWR_y9WxvyP0MB5m_Ldc9noSauxs_v89_OGbEInvoXzTRThsGBepRbXhqEallHprdiAnkUv2N4IhKXVxx2Srpt6bCMG27rWvf9KpV2yNUSKgsk7pIWuhianbDlws24qXGqu34mxTV7_Ig=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>A cute river-side garden terrace.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOy4DxsJ1u8hFLe-ix8MkP5EkhLnhu4_rCV6uLAXlK3tHnUqiN2DMK2GmdJMA2VQS4f8vPO2xkjoI_0sr6qUJGO843ElUkvcP3sMTxiITmboGL-JeV2lVisj3mgjJvOW28pwcjrWHvZjeMQR-CJzvMDXQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Complete with a big pink pot of tea! - Heaven</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMLg1oq0j69FKtI5BoxK3mmQ8euGxMV8bbvZzdVrQBTPh6T_GUwNSedD744-78ww9jWO4YjQWPlxmu8kFYaFQUZGJvsUu_Rzd1Yx4ujRjEEq1M5kxtvZhczKwh2YUTKxD04o6uj7VVIsr6OVRKeEvlmOA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Some Cotswolds chocolate easter treats.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMtpfYGbpTv6-_sjC3EiAc5aBe2z6DNXCnXOS0adDITOVQUOcT3RTEoQjAOOVtiUnGH_E3uDpHDo8mnMHn__nxHuYR5IyUetoIr6XWPh4gpIDjCbw8pFrU3rrdaDT0iLbIFuPvWgw7GOi-czNrisTHVIg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Crooked little tea house.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMh-idjbFoP1Btj689jk30cHllTIXxvBW7WQAUA15aOXB5GCd5qTTshyuh4681Iu--rNqAgS52EL6me_PAyOFOtZmLZUTI7FqTrd1shv__tlbKIkhk6Ylbckl1y5C9GRsStRkfUNbnKTiUtzRFUc187XQ=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Another cream tea in Moreton-in-Marsh! This time we also got a cheese scone for a bit of adventure.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOEAJDX23FZpvqt2GJ9wy75MAHhgiFbdrcRAor5lQ3bIOx_Pr8RoApdmawPsHFRl4Bm6z217FLCbi2XvX7hyw3DcF8oY0BHPPupBE3SEn2ycJG2pEezi2IrTG93EncUzA7rDecEC6L2Hk426pvc7UEFMQ=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Easter Sunday morning - looks like the Easter Bunny found us all the way over here.</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOoCfDe30FjC_aRnYIW7sKQKfgJdgp4z7U1gTU0YmKJcuKMssTC_stmqyFX509bysHQRVU6cjMtBODFk-gab_NOMpsWXxsmzyHql8--0wE23UJj6dryRAraJmyUg_DJfobanjD9lTh6K7uWqVtArauo6w=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Cirencester's Cathedral.</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPufSXfcVwhqnvO5Zhi59nmqgFVa-XJGhfQS1c9PtPe2xHarp01-sYs6HuLy64zchQsZIwF4T2B20GPl1xpyOa456WhG01AKQ3-net4UVVEjPH7mYFXV14mgWL6ZITiEjB3pgoHfurLRRvsuoFFHxvhRg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>So many little cute alleys to draw you in.</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMRirsYz3ufWH7ZCotSLc4pwGfN-d0nsWoQs6l7P-68WvMfpTVapqfg9MKHo6wW1s9smG-GhTZsbczV3ilj0z20ltd3obQ6D4rEI3gUvzGMOyD63tL240zP1gb16-pf3qJQtm5M-Pe7G9zAbhH8HZLs0A=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Pasty 2.0. This time an aloo gobi lentil one.</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMWnNdCchdteohjroFOGaH5ruZWrpg_0juTgjXzBeaMMiwJ_GFg7P-YOxvyscHl7LovFECc4sEMKvd-_EboVvksTb6yUFqjzubFoNVku27cQAdaahYn5DHt6CKnVNyqe6HywvVHYqTuCYqPmY3WDIwEyA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Next we tootled into another very busy spot - Bibury.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMpFi8eGVis2ZLn6a_-Vy5UbSe0v8JVX689KW7KsKd4rXtE_VroFomEoQ7oUWZlFal1kKHLSixVPkcos5NnP3yuI8YEaxXdmKRr2N5O6y4a3CL7pul62COomjNcRaXj2qhO_DD2UUQVVTjGuzJ_w6jUEQ=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>It was also very cute however most of the town is a trout farm now.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="" alt="me and chippy"></img>
              <p className={styles.imgText}>I call this outfit "All of my clothes are in the wash". Cute little terrace tucked in behind the main street of Burford.</p>
            </div>   
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNDWQW7VEm31owtn1KjISFVeSCcyrQzgTwb6-C-IAhj-6fxBuuGFT8eTaSo7TzsZ55NAlvHjxXVr0boKkd-5f3PdbZ08oCJfFGZJL1A6ut0HVpegXFD-XwWclQTCAwuc9UBsl0l2A9vcX9mBD3CogH3yw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Main street Burford.</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOxhhrTKJReQGfSc3Qy1LLETLVopr0SItE4eeymf1LjqR6hVwifj5m4MWhvyVUlWiReqQGymcdpRrEKGpuxHIySuYUf_7rx5L_4ILpCSt9S_W1d3iio7XpJbjXfR5N0JMDU-yVSJinJWcaNQ55EtMUc9w=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We were determined to find a proper English Sunday roast and we were finally successful! This place was able to do a mushroom wellington which was nice.</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPNNdDlzo6nuc7_cCSg14kBy5gFAw66BwCxDA3PDU8PsJbFWtRRbXq_BY7FSFNtCDCUWSoK1H054IPFz9FMsx_5Exjc1LbBHfIalHph5SaT_hiqSspQn1aIBz-ktxY8vZMhD__TwguBU8IJBfOxDOI7pg=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Such a cosy pub.</p>
            </div>             
          </div>
        );
      case 'Eryri National Park (Snowdonia)':
        return (
          <div className={styles.imageBlock}>
            <div className={styles.writingCard}>
              <h2>Drive up through Northern Wales to Caernarfon.</h2>
              <p>Today is the official last day of our giant trip!</p>
              <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczP6hKrLmmqK8-lNAZltyeeDlHslYV0fIsjtNf2DSnnY0aqO-lVByF6X8rs-EbK7bhhc-LAS-zMdtO1nrJRMCcdOMZcjUu2E98Jz-R1pwRULtQ8GTSIeayGtQks-IBb_B2_qod-jn9X9vx2RKe0_dT0vHQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The tiny town of Dolgellau.</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPWoFxBEi9eVOjYtmaAGeX5uV_pywVik-himwpqmB4AlDTxPJiQRELzqT18ZNk4v0S4CzdJw91HEv0GMxSm4GiLwPPhO0SzxB_QRcOxmE0tovrstfmQ20KnFn9B82z4ErlpmNkiTosYpfg7eXEcZoTMaQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Some nice stone houses in the centre.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczP65Ng1mkiPtkRDrM4n9U1KXRRXG4PKx22WvAHt1BTWhGLDWS1Ai3oeG1TepBFP-bHChGTPz6KpNsU4d4lm0d8vTVnX_ueSNEeOmaVwt_t8Iia00guVK03od-MeAuXnHXVSORA19IT0RjfLTWErncTdrQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>In the Eryri National Park, theres a beautiful road called the Llanberis Pass. This is us just about to head into it.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNyhiSsGcCKR4QR0hKHVUUfyd3de7xORntp7INiIivFkH-HqZM4qQnXBlc2oOrl4I1t6Ux8VnfTXUNOojV4cHIdiMtd2VOZ6y-nNGseCSxhMIHK6p45AmDIQcbe51jKGLUT9mLEK0h88lhRDn3IxTI9Ng=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>A view down the valley at the top of the pass.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPxh4Rq56TnLfeFrmv9nvH13G8zR1ZdvlGYNZugxioLPECqZfWqlA2WY18QlmmYO3cnqACwKhdd0-j8WUQsLno7QHIx2WfSleoh_lzRNJFARAI0u1uVRQZKrwladtaB2Z95DJo-rNMbBwKXk0iW_3vTsQ=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Enjoying the nice view.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMwRgpqE96pei9hY4YLh4G1aWjvgggxJvjyGy7oxAp18JZJ24CCGN4sIU_EGdFdBGa1DWNzDKJX7RYTnEec6LrX3rS5Cb6yf7wxLmtV6cUpT8kCZlELNFoL8u1ByzCxYyNO8JacPm3wmvC2Xcbu6Od-yg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Drove a bit further along for a slightly different view of the valley.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOk57AURotvi0L49cs7cHqCEgbjdVpGP8ONBDxsrEMgyTdBnZnTyxXdww_lE6pLW1no-Kle6NluT4AFfvGrTFcAVD59dq828XhRsRX5dkx-0jaAYahv4jjKnMIXyF6DMBH8tBuNL8E8I4XXSE_mVKpGfg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The road with stone walls on either side. Its a VERY tight road at times.</p>
            </div>   
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPCVW0H3djgkIfqVXAAcZIRmiiVxLWMcQnNrF1q0j1Sb1PFiv_LpNZqooaRcTzrXun7jLc02-dCrgU5OMKA8gB_QZ8fwJ8fGd7mYlMbjVYtk58b9bwBe3bKpRSZn6EDxmZ_6P3si9R2zIELJoLERakdVA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>And we made it to Caernarfon! Its a sweet little town surround by giant old city walls and a beautifully preserved castle.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPbc0drhcWiYr9clfoqghMY3gHSH9kKiV4aOsNOKnSh59e0Esym5nFUIQZNOtdOr0pNdlIu9pow3L-OLgWbYkg-xhLNlfDpN9Xl6TuyDPOxgGdlDSf8I9H2oflGK6uCl7k7r_aFB7hhOgoi9ohApL5f_g=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Strolling around the castle.</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczM14JSjIOeUPI6HJMW0MCzrxExFu6uGYNFPqmHc10XSlRszbszHsIHTsjHTvvtbZHH1UBS9r_oko51vOMV8kTuBDRJMYDGOvwox3ClUqDFVBanr1bodZtQFSvNp8RdNRG8stMZNc0-z5ih4ElbkMzoXRw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Entrance to the castle (if it was still open at this hour).</p>
            </div>            
          </div>
        );
        case 'London':
        return (
          <div className={styles.imageBlock}>
            <div className={styles.writingCard}>
              <h2>We lied - the trip is not over just yet!</h2>
              <p>Time for some West End shows!</p>
              <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOLh6vZkGGMcqbD0GunX0-cBj0OsZ9zXbNtqmC37vNTa-g3celv54qElTK-7X6IaWhNJlnHecx0I60XI3mgS4xIkKUFxCf0bJP19hRVXrcVHdNxT_dSoswZIJFrU_egNbGML8ByMYcJqtjUM3L7SulH_w=w1200-h904-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>On the drive to Liverpool (where we'll drop off the car and get the train to London), we got to drive through Llanfairpwllgwyn gyllgogerychwyrndrob wllllantysiliogogogoch - the town with the longest name in Europe with a lovely 58 letters.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNCxvy5SXmHFF9zcMAeEPzWe_2bIDZz5zUesjG3l8eUjslJz3fJFxU79EXRarYRKt0mYoHtDKkI7cCmigj6tpDLugCEMQDLaMMjgaBnBHkm34hiPtOWh0Mg9YqRbgruGKP81l8sVMnApdLBl8h0KizXJQ=w1200-h904-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The tradition continues however it didn't fit into 1 gif this time. Let me write it again: Llanfairpwllgwyn gyllgogerychwyrndrob wllllantysiliogogogoch!</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNkYWxKHr4Zmhw43CiG_khRPhmDcO-LTqN2Vpm77ZF3ffVPxQApLv5F3Xi7J23Eq3kyiZRbFkO7uztgqEMaqz6dME2bG7RsORRd4hZSze4OwYOR6-spd6VywyL61jjTfP5bSmrhgKWSLGjAoRRztSB1CQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The gift shop in the town. The sign is really silly.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczP1YEHpP3PPhhb5sB7mL9oB_QTV67G1rAJF6aBPpjEYrJm4nwWbX8NTNwV4-Vlu3s0rRsy5rKAWl4cJFjUeQw42Esuwzpoz4pZq-epFf6uygZSI6EIx84ohi60QFMdHPzP3y9qHjV6u7_o6HogcM8449w=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>And we're London-bound!</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPvJTKpRUZeqVYC-VbQnxwRDdXOrS2WdJRjyNu3_OFKlxRseiy4VcREDsROP-fvfdvRQMZiVorgCMbmyRHQmdhE1O8REd_wqvzIFazdm7J94MXE3OiLnJ-NUqJJ7HBcSKTyA8EoEuY5Y0cIDLdHUi7c1A=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Dreery day but theres the London eye!</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMeNcKn1h_bPhjvfpxJBDS1aTARwikn9v5wq8vInRuQyPms0l07YYS9F8W6JuQMyV0ayS5TMefoyRbZEAYQ5ugG4W0wUKtUAFwoRfrtnbhZbexr6_oBxbxD0VqobOs2CsM1PJY8H0l0n2y-g9lRaM_GHw=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Off to the Borough Market for some food.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPYFaTFcNTGBrV0MsuUCMLjUMQRmkGTg4LVrUcqNevRWa7RiNTadRLzlDiMPMfwTp8Ra2v_bVcGsNfqS-2vxCD0hW0aaTmhluMiFefFLIWtsVSDpLwqrJ66VrfwVlqWmHtsv4RJP1E3P7kWXTqOwBXMnA=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We could smell this stall from a mile away - wild mushroom risotto calling our names.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczP8jQiBjhXcRKYvIyYGEbjP5xhDVk9MuDnsQQs3ZaQ8uG04esTv8vljZ5ykF7C2JvxaSXJUpFnE0u6DBJkTztZj3S3YllIcuYHWgXSOn8rlTSVXyxGEdICEzu3qvN1bIQwbjuuRmo-Mqk8YFWkJnj1YGA=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Said risotto and a happy James</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMdmnYhK83HHdF6JMFwVrPYVHLLvw_1SnjlaqndyCLPUTyuTP8oH4mxsFMYeNkHKpYP6hgSpHHubEQS4UdIOOysqhaRZM-cnWxlvkGq20ysK-_leyzPFOFPcqq90vy4M9ghBfuo04sl0ZNA9xLWo7YLFw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Next up we were off to the Tate Modern.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNB_EaktcjDTlsB6g9COHWfqkTd0ik4zphrtRwGj-tDiwPZAQdCGbnDjyG2njyEiigvBanfCuADwv5Wfslg0GXCyLWwQ-5Y132Rq-bGQXlyQ8_MUr8lKOIbPuU9B8KupIOq6UE_C04ymBLMAfwc0Xzmfg=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>An installation made of old radios.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNyAJZAgMrQfL396SqZCfEonvCMn9riu6x0LLq2gvh3pLG_z5sXYiYMrmFsdQWu28oNqCTToKk2k6D6RvfDdi-K0laTfL_WBZaz3Wzhb8MzUTS61hKUGxh3W3cUUg9Yg9H07LmQFEXAhozdEpPF8KeT8w=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The exhibition on the Guerrilla Girls.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczO_wQdasXoxfnJr_wcq7SKisPvrZJH56EbBPnE4JtYKFJaPZIKD9v8N9poezrCnFLCL-Lo2NSMnNLbz5lKC1BeS83Pu04ZloQykCMCTepTr_Hqev7ZufYMcX6VYT2SJd2vu1XzOHoknr59I_V-Pdoko0A=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>A final cream tea for our final day in the UK.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOZxicJiaQGKYQnyomj-pjcDYx2XPMygLUFOeiZX5AdqJZL8dOf2-ujThdDio4_099V8c3Jj5yQgKEHn5pfSTjdl29NW-h81xa9Fgd7_2LRNAE8dUTcKTtwreKn-u1BquQy9PcnFnNU04qv-e1voEqSkQ=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>And now we're off to see Wicked! Funny that its showing in Melbourne currently.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMERiO5gu-ypaacKSyKl3k02WBqOAVflS5m5w_0d4NSvF6o8F6TS79HVQhD5qSlRy-ZT9aP3w02eHLaSiNN_QjMcq-z74AdTlw4TtrcDIgsfrp_RS_cPPI1a_pGSP4Cwt0JxSbulzTZ4a0ZkjXjAqQpbg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>And the set just before it began! And just like that our trip is all wrapped up - we'll be on the train tomorrow morning at 6am journing across and back into the Netherlands.</p>
            </div>                
          </div>
        );
      default:
        return <p>Select an item to view details.</p>;
    }
  };

  return (
    <div className={styles.britainCountryPage}>
      <div className={styles.countryHeader}>
        <div className={styles.returnButtonArea}>
          <button className={styles.returnButton} onClick={() => navigate('/Home')}>X</button>
        </div>
        <h1 className={styles.Title}>Welcome to the United Kingdom!</h1>
      </div>
      <div className={styles.countryBulk}>
        <div className={styles.entries}>
          <ul className={styles.listArea}>
            {entries.map((entry, index) => (
              <li key={index}>
                <button className={styles.Entrybutton} onClick={() => handleEntryClick(entry[0])}>{entry[0]}</button>
                <p className={styles.date}>{entry[1]}</p>
              </li>
            ))}
          </ul>
        </div>

        <div className={styles.entryDetails} key={key}>
          {selectedItem ? (
            <div>
              {renderContentBasedOnEntry()}
            </div>
          ) : (
            <div className={styles.startPage}>
              <p>And somehow we're on the final stretch of our trip. We picked up our car in Liverpool for a giant loop around England, Scotland and Wales, wrapping up again in Liverpool.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Britain;
