import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import './Login.css'; // Import your stylesheet

const Login = () => {
    const navigate = useNavigate();
    const [password, setPassword] = useState('');

    const checkPw = (event) => {
        event.preventDefault();
        if (password === "stroopwafel") { 
            navigate('/Home');
        } else {
            alert("Sorry, that's not it");
        }
    };

    const handleChange = (event) => {
        setPassword(event.target.value);
    };

    return (
        <div className="centerContainer">
            <form className="formContainer" onSubmit={checkPw}>
                <input 
                    className="inputField"
                    id="password" 
                    name="password" 
                    type="password"
                    value={password}
                    onChange={handleChange} 
                    placeholder="Enter password"
                />
                <button className="submitButton" type="submit">Verify</button>
            </form>
        </div>
    );
}

export default Login;
