import './App.css';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Netherlands from './pages/Netherlands';
import Login from './pages/login';
import Morocco from './pages/Morocco';
import Spain from './pages/Spain';
import Portugal from './pages/Portugal';
import Ireland from './pages/Ireland';
import Britain from './pages/Britain';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route index element={<Login />} />
          <Route path="/Home" element={<Home/>} />
          <Route path="/Netherlands" element={<Netherlands/>} />
          <Route path="/Morocco" element={<Morocco/>} />
          <Route path="/Spain" element={<Spain/>} />
          <Route path="/Portugal" element={<Portugal/>} />
          <Route path="/Ireland" element={<Ireland/>} />
          <Route path="/Britain" element={<Britain/>} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
