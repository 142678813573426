import React from 'react';
import { useNavigate } from 'react-router-dom';

import NetherlandsMap from '../svgs/netherlandsLow.svg';
import MoroccoMap from '../svgs/moroccoWesternSaharaLow.svg';
import SpainMap from '../svgs/spainLow.svg';
import PortugalMap from '../svgs/portugalLow.svg';
import IrelandMap from '../svgs/irelandLow.svg';
import UKMap from '../svgs/unitedKingdomLow.svg';

import Star from '../svgs/star.svg';

function Home() {
    const navigate = useNavigate();
    return (
        <div>
            <header className="App-header">
                <div className='Heading'>
                    <h1>
                        G'Day to Gouda
                    </h1>
                </div>
                <div className='imageBlockSVGS'>
                    <button className='countryButton' onClick={() => navigate('/Netherlands')}>
                        <img className='svgImage' src={NetherlandsMap} alt="Netherlands"/>
                        <p>Netherlands</p>
                        <div className='newContentTag oldTag'>
                            <p>We'll be back soon</p>
                        </div>
                    </button>
                    <button className='countryButton' onClick={() => navigate('/Morocco')}>
                        <img className='svgImage' src={MoroccoMap} alt="Morocco"/>
                        <p>Morocco</p>
                        <div className='newContentTag oldTag'>
                            <p>All done!</p>
                        </div>
                    </button>
                    <button className='countryButton' onClick={() => navigate('/Spain')}>
                        <img className='svgImage' src={SpainMap} alt="Spain"/>
                        <p>Spain</p>
                        <div className='newContentTag oldTag'>
                            <p>All done!</p>
                        </div>
                    </button>
                    <button className='countryButton' onClick={() => navigate('/Portugal')}>
                        <img className='svgImage' src={PortugalMap} alt="Portugal"/>
                        <p>Portugal</p>
                        <div className='newContentTag oldTag'>
                            <p>All done!</p>
                        </div>
                    </button>
                    <button className='countryButton' onClick={() => navigate('/Ireland')}>
                        <img className='svgImage' src={IrelandMap} alt="Ireland"/>
                        <p>Ireland</p>
                        <div className='newContentTag oldTag'>
                            <p>All done!</p>
                        </div>
                    </button>
                    <button className='countryButton' onClick={() => navigate('/Britain')}>
                        <img className='svgImage' src={UKMap} alt="Britain"/>
                        <p>Britain</p>
                        <div className='newContentTag currentTag'>
                            <img src={Star} alt="NewStar"/>
                            <p>All done! last content update is in!</p>
                        </div>
                    </button>
                </div>
            </header>
        </div>
    );
}

export default Home;
