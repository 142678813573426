import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './Spain.module.css';
import Bus from '../svgs/bus.svg';

function Spain() {
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState(null);

  // Use a key to force remounting of the images
  const [key, setKey] = useState(0);

  const entries = [
    ['Granada', '27 - 29 Jan'],
    ['Seville', '29 - 30 Jan'],
    ['Cordoba', '31 Jan'],
    ['Off to Portugal', '1 - 19 Feb'],
    ['The Camino', '19 - 23 Feb'],
    ['Madrid', '23 - 27 Feb'],
    ['Toledo', '26 Feb'],
    ['Valencia', '27 - 29 Feb'],
    ['San Sebastian', '29 - 3 Mar'],
    ['Barcelona', '3 - 8 Mar']
  ];

  const handleEntryClick = (entry) => {
    try {
      setSelectedItem(entry);

      // Increment the key to force remounting of the images
      setKey((prevKey) => prevKey + 1);

      const imageBlockElement = document.querySelector(`.${styles.writingCard}`);
      if (imageBlockElement) {
        /*imageBlockElement.scrollTop = 0;*/
        imageBlockElement.scrollIntoView({ block: 'start' });
      }
    } catch (error) {
      console.error('Error in handleEntryClick:', error);
    }
  };
  

  const handleCloseDetails = () => {
    setSelectedItem(null);
  };

  const renderContentBasedOnEntry = () => {
    switch (selectedItem) {
      case 'Granada':
        return (
          <div className={styles.imageBlock}>
            <div className={styles.writingCard}>
              <h2>Granada</h2>
              <p>Stop #1 in Spain was Granada. Our adventure to get here from Morocco involved a ferry, a bus, a run and another very expensive bus, but we made it!<br></br><br></br>The plan was quite simple - eat Tapas, see Alhambra.</p>
              <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86E1LE2Cvh1GENIN7eRv4mPs15Om-zJYbdgLqym2hEes3jdGUCzqAGPkQzC5dlZg2LoBtYh6ftuhTrEeIw7WbNTGPkhpwI7y0qDO-P0sn7exOy0_r0rboEUylTZP423cQ7A_LAIz6YPzobXChdsWd0mqg=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>On the ferry from Morocco to Spain, James perused the over-priced pizza menu.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87hKWgno15w75-K-hn0aVn4RgUPE3C_fIVHyGn30yZJXSKwG9ub0TCZhB4JEiR7AepldM3njbmgZWJfV4dP1tS0l7GOsRhucyGSw4CL8yZvRPXSYOQk3aKnrfeOPn5oCtgyHrsUArbh1vATVKl_Zc0MBw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Dinner time! The waiter at this vegan Spanish restaurant very politely made fun of us for having no idea how to order but here is our little plate of goodies - paella, tortilla, chilli and veg meatballs. It is still unclear exactly how we were meant to order at this place, but moving on.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84tywqRU_TiXWzYzyAIJvjQ-WC4q6HwkMV4KWtiOnfHya7ml6eaZChugu5caLfTsZ75c5QoQhZ49VbTjhOKnPXtk5QGdvncq6zX-xaHJmPJJTiXdVSO7aUYAV43quREsoqYrm6hMghodsfyo_FymafnTg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Ashlyn in front of the Alhambra - we'll be off there tomorrow!</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84RnJkTpUndsfLoZ4CPlbBwXhsqlNbvfdHyohqjZ4xRGa8xDALRK78-84VNPnZ-I6GFAQVVvCezeH7sm3a613TuwVZOFG7PqXvr3sI0zTeojnUZRiopBlEzsjJ0znHYr2-ATG7wS0MOmcVha6hj2lGYow=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The little red framed door was the entry to our airbnb. It was off a very Moroccan/riad style central courtyard and hosted by a man named Jesus.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86nezkUx_MJaGMZgRlascWb8ViWddHgqep5vycLZqCqfxM8tTL7ZTu_oL3F4nZWvdJAFKwH7kFSNGYBTwvr4uzucDMLvVqmuP65azgPrEHPrCT7GDpGE7JS_PQfeZCrfCA9g0Oc9ZWQz3z4bwuG912ACg=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Trodding the streets of Granada.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87cUXMnewok56PRCZmH1oMepzP_VgxjUmzMcNjGt4S9FMrqLM09yMx49RVIfZ6EsvfC5HwO1lEBtVYv96mF9oTOVZBS1uyTm0_nYnPUs0CW0YL2WEqL_pu46sDK_OS_Yady_NLdK9YJwMehwl68VXtnBQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Enjoying the cute little alleys - it felt VERY suspiciously medina-like.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86a6_hkGus05dDoDiGFp_YswaFopyOqE86iSSrJZ1PZVzGhvXWy9bR0rFtQvXGArwffIu1BmyDdH4Ti7THx4EFEe18_woYgLMceAFsM4A9KjEy8_RJ9Bbhg5SXxe6_W-RjdeQbUHu9Fao89Ak937wmACg=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Another bewildering dining experience for breakfast - the guy behind the bar kept wandering off while James was ordering, but then telling him to hurry up and say what he wanted. Still, a very cute and VERY busy place.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV878MH5TlQT2H6YQdR64UpJ1pPTT0IMkDbaviJ1JfP-3gXxOp-POJJQ_MW0dXlEgb1K3ot3ZL6TywVkrleP_PaTshfccvO4YLbBQEmCfnAOh7ntXgX7VGncrpPh7S_qOiC3xniu0JJ-IHNRbMEfdwXf7yw=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The busy touristy street of souvineer shops and a hundred walking tours operated in Spanish.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86LxO_9XwzohLLHFranFvUAIjK2BLS7rteBMKX4xJm9QdGUHaMZ0vM8emUlzlzSaHmqKZXFBvn4OEYtUHGwMrYjzhJVZKgq5bCGKGS3qApMffT-Ny_3_3nY_m5BlwcKNt6zttJjLUhOO8PvK4a31D4LFw=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Plaza Nueva de Granada at lunch time.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84M5D8gIKtTiD4kPCq4mG2DkYlJQN6tBe7TFAmVmCTkjrKhWAcEpNnPKeT6Iob-dI0XCSg1deSQ_rAjMI5Vu2cbkO-6mO_v8_IFBS5EorJh1fLJwwqNatCMMK6-1Bg2jtpZuWQxzczxam9bLGMCuAyOmA=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We're at the Alhambra! Here's James very happy about it.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87AMP-va03s83u8DfESb28svA-wKBFNZIh0C3Hn-8VNDhPim4SMnRdnSu_ZMaHsxT0WhZonUgVPcIOW0-G9_M18kRohM1VBIyBQVhkGixwu_HB2UncXPN-f0ohJMO7U1pI20K3lbJeGOv6jrKwXvzN8ig=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Up in the Generalife Gardens, looking down on Granada.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87SYC96Zeu2lWlyvQ47YF8eglVbEQK9bAKaZLDfeYKuTZ9yEwr-gvwsUJd831vOeou5vgbf8hGbthcGexZdkW6xaeNbHDRAL6TpVFz9DtunKD5hxWm8xFFcbex7-hLV3Uvc3FdBPhX2x8Z1muJy2DobtQ=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Cute hedges in the gardens.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86Sr4qEjGVghzSXro5T5VV4NCbwTzRDg60HOlbBxxkbeZ6m3PjGZBB8GFolNJtpXHWRztyQX9fGS-2reRxy-TLZs2wQzltckyVzN0ZLuVYxEDrReSv8j6AzK4jt96BoIRtDc9p7O6ZkK6xEo4vHLRBgfQ=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Internal flower garden of the Palacio de Generalife.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85a0-WUx8aU9Q5otf4TWfv3s_Z-Yh_vlBkKLlQlUS8llHuWPxvBHs8ZNh6KlRcjUqfo9SZT_CokrlY9yRhSSpi7u6ot4Kx07ENQB3O1_MPBQVV5bmIu0XsFrr2oR-IEdqv8T9UEdCdSEfvy6XlyHsLleQ=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Spot the islamic architectural features inside the palace.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86xHb60x2rJ-ZGbciJnMzH0IgYxP9V11ReJdzhoCMX9nWPbbnQYm7wtfhXu05KZnY2ut0D9I11VRjlljtD_P_2uG9ufVvukS8kYD3EqzF-vYQMPlgiZ6drDtalraw5V4XwWBaKy0m6SbyslWD3rzZizeA=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The photographer.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV859osCmMJQx21NqY_aaKVnrHIQLzUn9tPqZm_oOFgrXcdWb9-f0F9vACbWtkxPaLFlPrwYKLzM1uP2CJYs22algj1VFQF1YRU2IwIz0wDY9nZedwqEoUh-6CXlqbbaR83UimfbqDIMVaGiOPhvg3vyRrw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The photograph.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87taGLjbfCzeBF-7JGOKQ4QqANJAUUKwZ7aCUovD1gAQTcKcKIEPGZnnNjwYSj4MEg6T3L5xejaGDrBacm1WB5WmL-dSxasyswu1wg6pFDkby1f_oTNMAg9w8w0VI-cUx32ekXOlueIT4SiDHVGw2aglw=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The main courtyard of the Palacio de Generalife.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85VpsQhpJcmZGXZZ7VpSJFWK2j79S5xaZ2x5nLQy7DNT7ffVXXXKlS9E_nu96hodhGStFUBIo8r1W2EIe-dG34EyR2wvM9IMs4uhuTXL06H4oRphrW1zy6UpFUN6qAyW1h0_LZNcZ74Ew9oIv2JfC01KA=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The ancient aqueduct system, supplying the gardens with water. These channels run all over the Alhambra.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV851u6SXQIzI8NFGoZA9A6Mrdjp-nW1X4UMlAsvgjaZP6DJ4v5K6fO65_rYBR-sFjZZWN27rPKXuD_aFTz1YTVaSdHe73IAgFEBvUJySctBDaI04oLet6DzIrr6KGXpXShHW38pj8Tp_ZyV605WKF7Yidw=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Palácio de Carlos V</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV867yWuOZ5maWclLknV8WbztEiIacA7T8kcK__1zBR_NsQp4QdhjCultd5neTub-MgdqfCG0skbrUI-ZtzkYdFB-Hmx4qEf4qB51AgBxzvPjXekD4Wi2sqqc7dCHXo3yE-oQfw87e-E7d_GCh_0ZkqygxQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>One of the many intricate ceiling designs in the Nazaries Palace.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84iL8mPm7qRBWPsX6fWL3QD8dVKJGLkKfmgOkqgJmgeZBRS9hsHe3aroAe9jUsGZ4VNe-qJYAQimTof8RhK9ExEiwVv_ZiMXzvayvAHE2SbfTMS1WLGNQxvQ_9YNH-mX9FU_GlETK5lRguAbpkCCKknHQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Central lush garden in the Nazaries Palace.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86YVcdDkEMshqqEpx3Lc0FXFpB1ffNuyX5I6_TWpTDsDcpxofw9z-aZf3wKkLcjzyZE57eVEPrM8k5XzbPd-OXIU0SHOYzzu6U4jXqYPb5l4A1eXKi_oqxU7UjQ-Ei89OIRHYdbGcKMfQARb9sLJ5AWLw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Water from the same aqueduct system gushing down to the river in Granada below.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87csLYpKdJK9OCBH1_5P6wOkVF2N2KP3p5NDqCaYZD7IJG9UCo_e0qqL4QSd7iqDe3Eqfyb8VIiKWJT4AG20OYeAbGWQ8vp5PeQ3ME47wa9zMxoBf6b4baQWoX0wLHHd1PGu5I6bFZLz3yZWH2XYLjZFg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Looking up at the Alhambra from down in Granada.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV865NtRkAH1QY8MA6EQjZLwAGOlzptnXdmqJsftLr96zFbi66pNG9nPbAC0qncUqjeVsED1QYtRAkTz-jzBfGgBVuub2XDxagD6iC78bq0w7uaBoROM0g0MmYOAN4AJXTwV879KUZrShqtTR5rWeWYNFrg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Apparently, Granada is one of the only cities in Spain that still follows the tradition of a free tapas plate with each drink. So we were determined to see if we could make it happen.<br></br>Stop #1 at Bodega Castañeda and no free tapas arrived, oh no. The wine were ordered was delicious though - a Tempranillo</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85jCCllbXc9C0Po17XHAZmvlMAWu1FxA3jQg2j5PSOP5dJCx2m3WeyeHR9TVeB10wxVq1bhQePVBCDNp5nQekFSDL9tOz0o31BUXeHq01sDg7jllTmdK92Je6LiHn2AWvEpkTVT6YJT-DH0nIvfEi5nrw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We gave up on the free tapas idea for tonight and walked to a restaraunt we knew we could actually eat something at. The Alhambra was all pretty and lit up.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87fYYYJrkrVzPQn02oPlVFsI8wyKu9hmkFBtRH6AvbQNtSjDRMeJRhO0J7TEmdXxSJAaCdpCUF9vQ54SvQp2wjKNHxCjV5mgRWEv7orPxaAbgGsEjxIkCXcMNst-NEdFRfV4QV5YVZKF7-ZYwbhgrNuPQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Actual dinner time!</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86LMNHsL9evrRNVzgQiUQM9_xm4w8z6U6ljAMCrWB0n1IwqLtBMgmmOQj5952-MJySWP1AC7hf20pxBIDequ-D0bY8FVgnwluH9EtNceQNesXSWSejOCSQ5xE31bcdt_u5lsR6swwk8McPaAm0eMbfR7w=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>James' first real Sangria - how exciting!</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV841zgL043VE9s2sZQdw2A1SVDB9G4vq4RLQhoD7r_pJCAmzHXSwfTle-n_8DprHyhWlJIXvm0bNeWbwebCSlvmwWtCuQS1axLi8s9mm3VuKOhKFKl_0KfVQY0BsZfjJ8LavTinQn1Qv-YAPmtIb09W6Ew=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We have accidentally cracked the code and recieved free tapas! This was some vegetable, we couldn't quite identify, deep fried and covered in pomegranate molasses.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV857yPA3qoWd4AnH4WVwdy52gCjHpDWN1fJRP4rxJEMwiFhcw5NYyvDMn4YgFaqpRncp6kV8V_0Zq3gPSeF8Zqe7YlxVtx40xWsL1U7wAEPdpP0qq24_sevuKsX5YPKTrf7C5dDiZxq7gyb86SrRleA47Q=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Vegetarian paella had been secured!</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85TnE2u6NMpK8k2CZ79VnQWBtKaHQWFS3JVTmsPOVk4bUrypBoDDu8ysjJHsiFv7emsrYgKCgdFs_GspIP6zRPqz1kNMfsWNBPgWPiz_eb8H6pHWCwNxusWq6YkhdioyZtO1aoAlRklY_2BrY-4BEy50A=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>And absolutely demolished.<br></br><br></br>That concludes our stay in Granada - see you in Seville.</p>
            </div>
            
          </div>
        );
      case 'Seville':
        return (
          <div className={styles.imageBlock}>
            <div className={styles.writingCard}>
              <h2>Seville</h2>
              <p>Welcome to Seville!<br></br><br></br>We fought hard but managed to find some really delicious tapas, saw a flamenco show and cycled around the city. </p>
              <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87_f_evLTGCTDqJ5zVo9_Zy_24f8LZNW7zqWzoCKPEBfKzwbKA91s9c1Rk01hfGJ0UObmlsfcAo1cWkQM0LAr2YrXQV9wserug3d6AlsG_Q2qUO3OdEXCPW0H0xtjSy0f-Uv9ZxYJpaCbBcNpaJihQRdQ=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>In Seville, we're staying in the very cool Triana district. This is one of its streets.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84TslFhIX9N_6W2-ZukLg7Xr7Wq52QEU4iFgEZfwfdoZt76iEtyZ3mBPQ1LkhvGN8hal6DsgWk_1T7JJDbLCmLgULyMcsLmsbhch2XEnD7U3m_KqqAO-lPsARkwZu97n1p7zRwN683NVpcMtc-zaqMIWg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The Torre Del Oro seen from a bridge crossing the river to Triana.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86jLMjegr591LBgtnI97Rm-WOs3punTB_r7l5NpMotxCYNsMI6ma6Oo2QXXiviTWZ8KsrhhO8lD5f8ku0gKGoLJ6g-u9pCan2JTcTWlBeM-7DQmK3zMrVfxujsa75t4QBfZX5VpsS8lRaD8UypGoinlOQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The Catedral de Sevilla lit up beautifully at dusk.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84NkKhMD9dTJiqsqM_GkCNpXpC8C-rihkEo-P6MBThwDA2inG8ONkdV9HDk7ruL3QUguZhEExrVOlERrqKtqPKMtmj1ieqBP2gvHMxA5QJFHRqJW6f3h0ZSr93cNLBB_tMERqOtNboFTR01dP7JElEiaQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The tapas quest continues. This place was described as a "contact sport" so we really should have known better. This photo really doesn't do justice to the chaos of the place.<br></br><br></br>Alas, we tried to order a few things, were told none of it was available and everything that was was covered in ham. We thought it best to try somewhere else.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87aKOqh3HpkB2jbdR15p-zyMxx6esC-rkofka41NiuODtDtcbL5oPPNrryPHkrdZ0c9rN-EjEUQl1duKIYYTEwqpWVetPgUEwXzYNrLckDOSKeGRTnhLnZ9vSnIYG9t9o7IbP9QAtgaEAkuZU06l6Fv2g=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>And just as well we did, because this was delicious:<br></br>Spanish omlette, Spinach with chickpeas, pisto and some sheeps cheese.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV863D-SEqWnDqUNfomQjPUnpd65V9AGJpKI3k7QbiG6OvTRucqg-1BiQ7nH_8IISTKHD-jgumC8_NGN_ajP66wSIf-sBALeAcGxG7inJKIJHMma7UtVPGYCtjdED9NpILJdi5CQzhcpWKH35umockhzI9g=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Everyone eating dinner at the absurd hour of 9pm with La Giralda in the background</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84so2T2Fm9G6FH-nfEIOSsqb2Rb2w7-8mBvokjttqPqWqmK1q-gVkOaN60YbcJgQLRUCqgqUaRAST3XmKKdcYOxmMFG1yZ0e8qgt0yYEVybDnJvbecH2E9oIfckWm84qzXftNJPwKBJ7sW31DvpnuDWDw=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Despite being the completely wrong time of the day for churros according to the Spanish, James was determined. After all we've been in Spain 2 whole days and he hasn't eaten any yet  - ridiculous.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87NE_XK_QaSN-mtioB38N5HRXznxgtmSOOC3vsLfRWuhQSgMzZjDc6JvOz7XbP1vVlMvVu0T4mLUlt09DvJQwiBDx04K2ElbDVESa0rRYctUZixyh-lVLVNqC8IEgkz-Smchl0hnbROBXBbDrLQt0UxYQ=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The churro gremlin.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85Uhp_ojPYjZcE0qc17P2NUieFku3PuWFugY679EfBwOw4VtG7wzg9aB-TsFIab5xVlj6UScFB6AhPVw8Y0T1BUScWtlWxryfD3qEq1ImRUo1cxtOr9pLE0ypr4HgmH-k69dN0iVDLZz17o8m8PuImGSQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Flamenco show!</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86I_djLVhq0LBjuyTAAo6hrsPob7_dpoQaLPF5NIMQLIPoKWz9lhaSMgrflOwZU9aKY831GhzlZlV1TmGmcbS-UAzXvOR_bvKqVvo-w6SYBTP5Qd_QQ_hWP5yaUxn9BHl7Pc_-tvG5hi6du1kza2oeeSA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>James standing in front of La Giralda - it used to be the minaret of a mosque, which the Christians added some of their own features to and called it a day.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV876AidpkxvT3ZtQhEp1iMeT0UG12gLJ2gzvf2lXB_s2GZyIHzVLOayLg15XLs0zSNXJrXdAfXYs3XkeGuMcxYjib2rdxmgulrNTT9_j65qKwqOQFSbkxPu9NVhI7RJzv8MGL-5Qo3gVV9PaoqPK8XaLBQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Strolling through the Jewish quarter.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86V_nRmp44fhQQzvUCFbhGK2hmjpV0ZEGMfjwBcYkodjzyexMlY5GQ8W3uHxwlTHmq5uTu5p_PdWfpTZnjHP2Xr886S9uKj6VVJyydMXW7R7ad9LJbpKiH5rBaqLZyQDiXHqyWvSCDsE6_39f5z69EM6g=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The Plaza de Espana built specifically for the  Ibero-American Exposition in 1929.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV849TopA-TMG6ST4UqFdmn7k17QkXFacYA5B4XpXk1eGQ7oxC8DuDUEdTIp0cd1rMRfwTsKerYPh2X1hFLY_KMBUcLOVZe0RmrMB6RY6k5ghO6fn3O5psuwKAcuGmprevIgGLwDui7WE5LUxlLY4UG-Owg=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Its quite spectacular with the moat and intricate designs on the bridges.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86M2VHezhePonR0OBne1hFnSDRS6_QrpOSJ4yiGwNq1KIQ4AKOO0mDgS_RJiH2pSStrutpEHen7m7aXBd2plHJFZcK28KfuR2ar2j2sxC_ic-WsacyBXmNRdlIYVZbKlBkupxe-X5yTgtdl0sHefdvuew=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Each of the regions in Spain has its own little moasic display.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85YRR8Oug5DmkLGK1z6AsHrOyRL36RgX6xsMyFerNY82cOx9HY7c3TGqUGZUmxBExhpKM9vDA6QzYmjI1xpM1hLsNKx5piu-Fv7hXlE47kVG1z84Du1adH1NZiPCKr0uRS1xA4Oq2Xs_6ptzwygHzgfyw=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The moat that loops around the plaza.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV869h3wId7O49olRZI8e9byMSI5IIBqSzgU9DmzbN-X76HdFzauXe1d_6bJo1Oi0gGpz3K6PhXwBRXV0xPbonEbK138QHQUqWUHizRRZtHAoQg_uscAwGHdUfMJJ7xdVqioR9egDZvVSHIyVtrp97uFvaw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>And we're onto the Alcazar!<br></br>The facade of the palace is covered in beautiful Moorish carved designs.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV853shb1TtNPH4XH2zJukda0rTl13KhAeUSx4mmFwzjKIyrwxKLIwvLMgRlNfEUxg4UeUiCDgVINJbRCZUxQWDYRfsPhozchkx68KmGperIYfzNcmJS5FfHCRpMut8RuVaDHl3nprNPOcLPQL_b6U_1VLw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>In the gardens of the Alcazar.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86bAviwobMNRpm5-nNJI8Zr9hvqriWZZSaPctbxtqaS9leAotNawNYOOA37JaxzQssWwgXqY6_wAVlrYTnxL2j8hFLTiR7ZeGkPvWPNqZM00ObNcl_yXjHT98NFEVxu23OGJhubNnlUiBB2c1zfk8392w=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Spot the James.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84TkbOL-X0YFQQa7M4Lx0X8uz_hdyWY6r11TeHPbL-eTKB7EzZAXesY69UOjYSYnHHL_m3OHekUaW7GolSHfwgsW79DrenCVKE07PJQd6WF-6bQMOsGoQf1XQTxQJ__1SGmqOkjzd4zlOFlUOE0alxZOA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Spot the Ashlyn.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84ixZAPQDFUB9VAgEeHAubaMGRipUU0M0NXCNCmstq2R5p3CZVUDFrpbQgoSO8IR18XZt4WTMLCBJr1XwuT9rh1-ywnIp310D0x7RuEvprh_xvTKHEsxp_KW68WcJCUZJQBqwUd-cfJp1VE_lGyCluayA=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>And look how beautiful the gardens of the Alcazar are.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86TF4FJHnbkjsAsTRt3FbsSLt2wBEBgh9l0Wce5fT7yAHl3lkmbJrOcCTjNrmmaz8GGP5iAj_BOA7p4JZ0QAdrXCTaXBqu90cFQzbWMqo9JW2Qptb4f9gmPNdlP6n92Uq3jqme_fPEsbN5Z9DaxK0WScQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Ashlyn was very fond of this pot - we discussed how heavy it would be to put in our backpacks for the rest of the trip.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87XZo8CTp6JG9tjZ-fiVZwi5EXkHyB4ijw6mhXykkcaY1fi9Flvd7BhMo0t9m-0PYDme2mzxQizkeOga1IbIsBiYbPZd9rMNfCvtmytk98UCXdLHIuJa-wd21mtS5MF0LuLx-lZNknlKTMt2cSRthDKMQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>A very cool tile design inside the palace.</p>
            </div>
            
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87RlPgEynhFIVe8IcjjrFSI0NsCEaG27EtW1Me574wW76Fj4PGHT5xKFVBJy_Seu6cgPpjXj4pVwsGvdns2qwg98g9ssZSvx6EukbICuT8bVNmAhLba3jLB_eYON-kBWEvNMQEAnFd3OG-rpgqUAP5wPw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We have successfully secured seat inside a busy tapas bar after lining up out the front. The menu even has a few things we can actually eat woohoo!</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV866eSdNmTHFlAY3dQOBjlldICqmVtbcuj1Ai1xXiKz0b9bQLWIn0iSLYMXHgtWKICbabOzCiE1vrNrqOAS8iKKvsMwe5hwKimi1eFw18j-Ia1FYwqaYcx-E85d2iFXsBJv6jfy_W7lTXU8oToLcyKUO9A=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Salmorejo - a cold soup/dip with tomato, garlic, bread and oil. </p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84QTQBzs8ZHd74is9C2yezf8Sc3mzNb-muC4j7pQLj9k3PBEdR64Bw792o8uwPNQbi4SbAxwOd-F_VPvJqFv1DyxS-uCo-GKvrfP7LurgX6QZrSQ2ecPjyAUI7QaIlCy5f6h03vYKjTk-ciySHtBQcYHA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Melted cheese and marinated eggplant.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86uaVRIzkdWVQgxPMl9yJgZFbMYE2XbhkXbQoh9WWLc0C0raS5KQHWy0Qz3CqzrD3dcXBx6ZB_uLHWMGVBJcmZDoR-Px-3rI0aCL-7_Sd24A2It4uaXeQjit471lM18Rw6qvL9SJTb5NTYtNk0_mawq0A=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We were very proud of our tapas success. A lovely wrap to our time in Seville. </p>
            </div>
          </div>
        );
      case 'Cordoba':
        return (
          <div className={styles.imageBlock}>
            <div className={styles.writingCard}>
              <h2>Cordoba</h2>
              <p>We took a day trip from Seville to Cordoba and went a walking tour to learn a little more. </p>
              <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84G5i7Dft9FSyC9XFOMp4lK3fV6huYrm2SESYGjN6qZz0X2fTmzzaphIZgr88Zv66QhoqtDYcUTD3gEqMWc62Whq5OTdI1eXWWIzQY6PWt1CU6_3XHhZiTBHVukekGa5IqKHAS014iDn9pSknwWrmfufA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>One of the city walls, still intact. Sadly most of it was destroyed to expand the city over the years.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87oONWXjqcPbrMveMztv9pbbE3QPa_VsYne1EsdIRViGHTvDGOZhVkN5b-trsw1gGXefNdjbJu_PJ2lUOuCpnGoTki9beh53KMJHvQiSoIk6i3raC-ZyGAEQ30OhkP3nOQSuYjsbL6O11xalGpzA7U2Mg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Walking down the street.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86VnjDq64rIP30era5FQnneIooB3h1Pv1okfRiQeWiu_shbULRbiJybj_AhRRHmI8eMeuaqcGySw32JrRp94X5ox3_9O4xbjhCXsNPjTwIbAS_Gb8ip9mwwK-oQuku05hR5t6_-YBLMhbInrbfqKG-66Q=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Inside the Mezquita - a mosque turned cathedral where they kept most of the Islamic architecture. The red and white striped arches cover the massive internal space - its quite maganificient to walk under.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87U0KSnLcRw8y_mZ5JCA82GoXitXr3Elf_qKt26qDTNQTzErL08yCBYAcQLkEhbBpErix1OQ_uUIq48WnO9eYNMNEwgh_Kese4kKlpSrpeVdyoUPI6ynGLtmi1nJZi_JS5qwxWys_zmKX00EvAyj7bRbA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Some of the more catholic additions to the ex-mosque.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86c7av4ZnOSguUExvxYOdP1o1he-_QkRntQFR99OmQof8Uvo5m1qi65Sq8Y14FoS9PsKts2HEyJJmFNa1H4UTlyapv1sSEemh0qDIeXcO_zZNu5RT9OhQVjD27dGoP2j9xVdcAo9KJUZA5E81Hf1FduKg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Some gothic architecture inside the Mezquita.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85gi6yYnrp-GBJr5F5pRRkx2WPXqYQ10x1lxn00RBEykoMvfIGYS6gFVT_yed4NGSMFydrWSKrqHgTHc62grUOJHnNVySjqVafVkNsXw7dFrDEwChELtANs13oodgUFHjoUoeJZHH85AvJnXOe8RtRXkg=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The clock you can see in the top right plays Flamenco guitar on the hour - very cute.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86JQBWpngEFwURnc6Xcwl5907d9JEf1yaYvtuHvf9KKqKDFZylpQaxVkjR6D_tfHWF6rBNL8xnxBq8uo-EUagHdMuSXYHghORsyzwLINjgoGXwr9RTS_wQKRobtjxhq-Yun3PvB4qlAGT7oi3WdBvYojw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>There's something very funny about the Roman city walls now being used as entry to a carpark.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87TAu0K_gK86WGt5uoW8bB60AZHdkmUNQUm0KRbxMfE-VeQct0-NvWd-cL52pDtuZkKc8j9cQBOrOG8wfm2TMJTXHpneRzggy1GfzPJJ29S5jJDpqvCbl1fynQ9gkS2CLdaCUglxIEnD3i3IbhkwGO6nw=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>This was our walking tour guide - Angel.<br></br><br></br>We learnt something from him that made a lot of things make sense. Angel told us that when the Catholics took over from the Muslims in 1478 (and commenced the Spanish Insquisiton), the policy was Jewish and Muslim people either had to convert to Catholicism or leave. When the Catholics weren't satisfied that everyone had converted, they had ham added to every dish served to out those still practicing Judaism or Islam.<br></br>The changes to those dishes mostly still hold today - hence why we can barely find a single menu item we can eat. There you go.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86IDDhtXdgxMVkOzs08bN6PSUkf2NYI4LMK-KUHQ3mDVc40_rdnd5lzwlkGrbn_gjs8EJHCFiLxGUifgkd6Y05W01lpK4acv4q_ck_a4LqHiE9rXOPOcPKkekKySoNiINf8k-tYV3gJ8w3NOb2asWv3kw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Behold our solution to the Spanish eating schedule - surviving on hommus and grapes until further notice. </p>
            </div>
          </div>
        );
      case 'Off to Portugal':
        return (
          <div className={styles.imageBlock}>
            <div className={styles.writingCard}>
              <h2>Portugal</h2>
              <img src={Bus} alt="bus"/>
              <p>We will pause our Spain travels and coverage here for the moment. Off to Portugal with you, please :) </p>
              <div className={styles.portugalNav}>
                <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
                <button className={styles.portugalButton} onClick={() => navigate('/Portugal')}>Go to Portugal</button>
              </div>
            </div>
          </div>
        );
      case 'The Camino':
        return (
          <div className={styles.imageBlock}>
            <div className={styles.writingCard}>
              <h2>The Camino</h2>
              <p>We've popped out the top of Portugal and back into Spain. We decided it would be a great idea to tackle the Camino de Santiago (or at least a small part of it) with none of the appropriate footwear, equipment or time to do so.<br></br><br></br>We dropped off our bags in Santiago de Compostella and caught a bus about 60kms away - the goal was to walk those 60kms back to Santiago in 3 days and no more. After all, we had already booked our train to Madrid so no time to dilly dally.<br></br>Good luck to us I suppose.</p>
              <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87r5n4XmjK2ZMEfDSfhse1CohoMoMnl3_aTDNxl0SEzTdHSzYJhgBDkyyxq18mjrQJLnkBUkrx4YgBlRyfW_VxjQ85fFHr3SPZqgUWhZ6tOR_RvpjfxwrXAEkBtZ5-64oV_NWftj8sQ7HHLbVLGc4SDKA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Spirits were high as we set off from Melide. This was our first marker and matching ones would guide our way over the next three days.<br></br>Looking carefully you will notice the Doc Martens in lieu of the more appropriate hiking shoes we could have done with.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV8601EQp72LppjKpXtfhg8nJypFdKGwCMd10IO0G4ctoFqz_V84M9zpKiRWrvLZgJv3c14QfcT_KKERBDBv90dwhniYBEU_ix7JVOXd6FoNO5DkXa7QEYqNgFNx87oRNZZfv_fVEgjcGyTSzQu3DZsP8Nw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Breakfast stop at a cute river crossing with the ultimate camino breakfast - liquid yoghurt.<br></br><br></br>Also, sorry for flipping you off, I didn't mean to.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87kmVVAG1eSZgIgZrqWgmwvZrgA-QsEPbiBLA22rxOrgGa9_PRCR__UtUmOHPKcjK42AqQ-jbctp4qKOJKQ0i5d0Rdu1-aaBCOJ6ePHT9P-Eb4JbLQ9sevV21nepOQI3cGzl7anicOJtkeVzqwQvfcmdw=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We strolled through the cutest little towns, over bridges and barely saw a soul walking the track. We had realised in advance that you're not really meant to walk the Camino in winter - EVERYTHING is closed and foot traffic is very low.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85NgZaxa7xqGY5ls9WMpPUVkhYb3EosXDtgtlqnydA_mfRbf9nUZqGIPOKWdOfKPDnG1DUrGIVj_4DN4JipFCYXm_gvNLJlztZkYPca4bvg_tysXEm5mvZeLeEGjI8jOZFINOiIxxFC8WpjuH7PcEZHNQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Spanish people going about their days would always call out "Buen Camino!" and that was very sweet</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85KQF4Rd3PucyvlftRipV4YP6ucWGbmhqvq5pZWnY-oo4QPLIePex6XvjwSPkp4k3jSS0h83ts34S8wJjdF2rChakflC6_1oW-CCYz3xLHHqYeTuBpWVWi6rXEc-lGohn3LQtMNxyoQedneiaT4NBDSXg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>At the end of day 1 we had walked all the way to Arzua, and to be honest, we were feeling pretty cocky.<br></br><br></br>There was a swiss couple in the hostel who had walked around 200km so far! They seemed REALLY tired and over it, having dealt with pouring rain day after day that had the paths more like rivers.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84p2ushw2gWBI4cVVvjSWa8WJLSDav1AE4V0cu280i-E6rCwd6nR_Vc6i3PMJjANe-B0E54Lu7ChV7QlcF9TLKx5R5jQUzc5VQSfG7CTX4wiphwWg9x13PLxZ-tA5HQNyNirc9PxidMIa5OUuRme3veQw=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We treated ourselves to a supermarket cheese selection.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84M-DX9wLGfnFp5zXMQst2haTqS-L7CCQIRQhxBYWEz47kkiav9nV-paSuGNlEBtLKuuCgBLR0hmKggNf7JXbSD0sKskUZ8fVu9mjY32Pf5DoKHL6j5x4SI0IADzX2EEozN29NlhsuPKiX-VoOkpmV2Yg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Day 2 let's go! Our accomodation for tonight is 26kms from this marker so we better get moving.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84I0e1G5ZgB481l7gJbpH55CGYxEOvkRLSfPK-NdJT201k5BJk3cwynxt6dYeSPckXtPv4YD_fmHvABeKMbW8JFEv_B6X_UM-jcm-2AOb-_pSlnH1sJKVXXp7vz9SErS-zDlJXqQmn5lplmKYYzWxvETA=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>And we're off.<br></br><br></br>There were actually a few other people on the trail today. At one point we saw a man ahead standing on the side of the path staring at us. Mildly creeped out, we walked up and it turns out he was not a creep, just a french man who has not seen another human face in 7 days(?!). He'd been walking since Jan 5th, camping along the way so wasn't crossing paths with anyone in hostels/restaurants. We chatted to him for a km or so and then he powered off ahead, making us look VERY VERY slow. He was planning on walking about 35kms today so could not have us slowing him down.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86z35lh7ksiTNAXMNVvV7uzk5vi7HmfZRz0AQBtevaj26HD4UQYSlHRwLiu-FhiF3xy1G_IoNQhFr8T2T6ZwhGua4ZHph828i6dbKz3pPbP5FMy8BrHRT-_hFOhKFmBaPRYXwQR6kkbGjP94Chq8TmJkQ=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Sharing the path with quite a lot of cows today.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84acUt7-DjGaFggKj2ok0bAw9C_yfsNjV5OqXyOBfZpZb61DiU6S14cX4piNWSRaGD7ihtizA9_vMrPWr9J44NZ2vIIhQi2lTO5ScPLG1JEyh_aPjk8Y0HWZxq2LU9O2LvPVz2ODEgAp6LriyeKxyygzg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Decorations of hiking shoes left behind.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV860ecNVyVhO2N0Uu0HL2mB8JWpfL0kSxnsng74CtwR45b4gspxXg7LfcCrsTK1YRJPocM6h3nHEHf99IWFQaNOn2dKIdwqH2VZwGsC8rpcuJD1kKJ0CZm0JDl_tAt6pxOgLUKSzDuT4CsdpT0M8LmkRSw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The paths through the towns were very cute.<br></br><br></br>We passed another few people too - a mother, daughter duo from China (who were very clearly sick of eachother) and 2 young korean men who were even worse prepared than us. They told me they got lost off the path 4 times that morning and lost their water bottles somewhere.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87O8ZsdeMUDMBkqfLS59rl-RE3EoF0UzenITgsdXzUA7jnL9hASxsRwMxDItqy_GcNKDg5H0rAWUmPLtrPazlGh5en32L-IWsydTl-R5P67Hw5gnEeHui3zM6FpmKhIhHHhqSWOOcMqSuvHf7P0yMlrZA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We officially passed the 30km (half way) mark!</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84B5ejwRZexjRTepT1DOl8ROvxPmwb-1aNL70sqzOs__VZWvf7wUYITDKnjg4Jz-FgH9jH76GSeFgE3fR904B8ilZ7IlUQGFupynPrMOP1Temf0c-a8lcOGcoVdR8i9OlwzsahLijyOE8-fTChXDcrJYQ=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>More cute paths through little towns with nothing open.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczM8fzxFRjzvdLwKMwJ_-ZNhXor0qnB9NO5QyKXS5d5Dv7TkefDEbNaVzCI3XcXrK8KSu7cWdasxBfOsf3mbSJMzB2GnxIfOjaO_kmU1oiTnTEHvHfwDQki1OB7V1x0Ik7ZlY2HOe311CrVhgWuUPapdyQ=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The feet and leg pain was starting to kick in.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84PSZawZcmD8eDuMpOOzUDHr8l-zae3Vqxvak7R9gY8Xs_3NblwKea4K2LdQZXl22PxsmUgK-GdTxRMBuRvtED0y_LzcCVp3ahZUcvfeJPUBWhjxuRjlb5g57nlYMdrXbTJYSQWyZqH7AQJUHWzXiq2SQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Hey look its us.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84zgDrLyFE-D33XlILwfqFdgLbcp_FX5w7Vro41gJE2GYLStglnYCJnaLIuZWvQr0tz4AGgn3Ec3kP3DNqxgmjEzBKr5dAnlHVo_ke8GDT0ktwag1ZquLF6d6f1ONjdMaciyFTXaI3Gp-GOSBmVuZY8kw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We may have started to lose the plot at this point, but we did officially pass the 20km marker!</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84y6PUZlEp0oes5S5wgQVBgdg4Pj1Um8WGag5QE5_sfM4vUDDd_2ZW3QzD4-FMU32O69YIsmAQatycU9WMQsGNdqMzacpageCM9WKr5g8Z9Bhwci3V3dyeWM7y7MMScHUqqujn3aVssxeF57jiHZxIHpw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>After a very, very long day of very sore legs we made it to our accomodation in the middle of absolutely nowhere in a tiny "town" called O Amenal.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV853RXmekHVjQNXG0iTEOAmIiupnGJE64ckk9uNP_7Pb9j0qdGE2xq4VzGfgWaYJhDTJ4ELTDggG7wr4BnUehtWV9aKLCPGbNK8o9-gjmt_NpLm5fw7JZUgC4bRrtpWR8Dau_ru4c5NY_JKxtDB62puwYw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>You may recall I mentioned earlier that winter was not a great time to walk the camino because everything is closed. Well, this is where this will really catch us out.<br></br><br></br>During summer, this accomodation has it's own cafe because there are no other food options within walking distance but funnily enough, that is currently closed. We were given a delivery menu for a pizza place that didn't answer the phone.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV877wQOgK77EIjFbrprmY2OLVl7tyl-QZBeWY8V7e762uI3MR3UnUWORuvOVSXc-1NltztjbDniNA3h-UFk5JElDWgKvXAs-5c1bx7bhdiEuW9NRQSVdz3Qj8Pgchi_YaJ59sqk2UzRcNrnq5eBMBvjc-g=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We sadly made 2 minute noodles with hot tap water, ate some salted cashews and called it a day.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPblOVoTXbawj5AGAFynvKK99cCWf8dM1kSs0hJWsMI93_KemPGtU21rK7I16lc8o2fN0cq0DJvvNCiVV0lKPWGRn6eWrlYzxu02Ewd5Ij7qqNUr-lm4q_zWfZfuu19X2_4BEqKIs84y8WBGx_u-qh7Rw=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Waking up on day 3 we were greeted with A LOT of rain. This is not ideal.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMESEvQL_wcgSwNVfs0k20Jwu6UjUoQsvaOqAragtsctnogCWno4aMiHCUR7Bn37LHGJo_AIL9Zj4RTl6eDYH0ldI7tL85Ul26yuFLClc4bjaj6xLNGLAd3kDsLETGnkexgQt_BMtBnglFTZFw95JVm6Q=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>During the night, I (Ashlyn) was also attacked by some random bug across my cheek and on my eyelid (rude) so will be walking day 3 looking like this and feeling sorry for myself unfortunately.<br></br>I took this beautiful selfie to send to dad to check if I was having an allergic reaction. All was well.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86nGM4Bhn1wm3527ffoI8t2CkkDEJTQA44Z3x6U8gbh6etciXnWfzpRI-yiXwJ0AWADSvcQ14Qoivi7XsMSOW9pCrEO6Y-Unvv9BNJq7f-yPQ5_75DBVVvxDjaSkP02dZ4YImxYNTz7oyLJZMy6OVGlzQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Puffy faced and as protected from the rain as possible, we're off. The final 16kms to Santiago de Compostella here we come.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85qRI1dU1DdVMIV-xc_-Zedq5Ho1LRG8sBRenDAcaIdq2bwSeHBubLCHxQz7naHY7BPHv0fFt-d5F-JLPe3MBqWJr4cs4x6ZX0_3Kwh9uB3pYdqgFHBjy0UbB5yD_VVEsVb2Lq_eZVl43l4-8GD7l-AWQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>10km mark!</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87I20N69kcuYySVYmrIVWG0Igp2jXZKWB9zYTnrTuw4D3iJDZeGFEo1knFOulImtrMpLtJZdnyW00bqaovl50XHLLosDz01S1onQZTDTlp1gtaWzbinIrT0GEvFsc2ASxsts8qISJmBWvvzjtTv9yyXzg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>For a break from the rain, we stopped in for a hot chocolate.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86loOwkpPqf3qlEkN2-QNx-L-vbk3OTMZ3ccIkPzmh2gkKqGsj6MglCpkn4bRkPjXytJ79E5ya_czaE-RO2YmarorSssZcLPZfK-S8GPeZjbr88UtZUJCE5Mn9Rtg1JGXyU04e2Un7r9baVPuQHBotc_w=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We're almost there!</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85rcLrwTIZkLlxlu4mQz_oXrm8f19qot4f98AeIunq0Rb-VGH9nSvFLc_wUnx7bmdnszmW6FgvYziqZXyBauAlHGbpDT4hgoZqvPkTyhqvzXCV2fU2JaFbX7neZM4B9RodUJKwaW2tUp7H0HxbNGe9Dzw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We're in Santiago de Compostella! All thats left is to make it to the Cathedral.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85IAWen4GHge9jv4TOyxXwGbW-E3UKxqJySEz73s4NfL8RfM9Dng_xzV-pNHsB8VIiNs_zPHUruZUT8L3TUSXwplRdTymEOMEUYI9VbKoJlmwTv2pTJ0DfPxk451K3frOVZI4x8peoAuWBK55K_8xGH6g=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The city is full of the little shell markers, leading you to the Cathedral.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85lxDPAcGyL9VdmdZznUUSakU4CISXgDTMtOQKaC6xU0iMhH6MFWyvktBm6cZSuI29fhV0r97T8GvUUxiBBLSdf0U9HFCLBqqJokg2-qMGhh8Why1Ez1IFejSJCGLoxdM_WqcGuoWeylZzrZlxKCRfYwg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Ta-dah! We made it!<br></br><br></br>Would we do it again? Certainly not in winter but I imagine its quite a lovely experience when there's lot of people on the trail. So watch this space.</p>
            </div>
          </div>
        );
      case 'Madrid':
        return (
          <div className={styles.imageBlock}>
            <div className={styles.writingCard}>
              <h2>Madrid</h2>
              <p>Next up, Spain's capital BUT more importantly according to James - the churro capital.<br></br>So strap in, there are some serious churros reviews ahead of you.</p>
              <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMCUi2KitN8SXRmyQnYJnRlTWeNbHY10tu7fn-1bq2Gg4Zsb1IUi61HmJ9pgyy87pDgYfSEwhXIJSLu7eseOdWOjD2x_poTrRyX0kbJdDP5jJTpbmy-_dz7lbywXDXvMSonhf-ZH8267NdG1Gt62WdfGw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We arrived late in the evening and there was no time to lose. Off to churro stop #1.<br></br><br></br>James' review:<br></br>-Churro: round-shaped, you get 4 with an order, not very sweet, a bit oily, perfectly round<br></br>-Chocolate: good consistency, smooth, not too thick, not too thin, just right, could've had more of a chocolatey taste, had a cornflour aftertaste though<br></br>Overall: 2/5</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMl4ZNyOl-dCiPzEwAxO6BlYt2mQSBBCr3tliemUsnaMry4I1wDqaLKUNP6kvyUuxpm2pG5_0yWFUDNr6HBXa7Jwo1SGekJiRtwOKPy8cHYG93J3x0yX1GlPKUfs95lqj9ZbBMP94dfqihyJaJsqh7qIw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Day 2 and we strolled over to the Mercado de San Miguel for some tapas. This place served potato tortilla with a million little toppings. Ours had sweet, marinated capsicum and some fancy salt.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMwdQcl-SGZPSf7eF2OmGiflzROLQLPTYGh2hYF6w76IkNzZtc5s-gzWcMSvDp_lOCvG7_4iDi0MMZaE1Z0xwiGXBTMUMSoksXAO3Am84USeKsbSpZHL1q-01U3-9xtJ7F4XWdhv8zkMVoXjPTbhEYNUw=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Next up is a giant pile of burrata on some bread - too much cheese but very yummy nonetheless.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNzUvixV-gsdOsXNkY783BnVFMBNtENje1e6V5EEC5YW-GrmUEXO2L0TAyE-7NospYsIUbnQfG4BpYgR4dmOe1bSUq12E93q2KRWik_gHN-KppYq5xYvksNe5K6djjHOn5CZ9-fc4KW-OaKnxBCSGsfWA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Little line up of empanadas.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMX8P3qQqbP--CxNc8-DKt-S2KCJleW_oxDVGsyrBeiGzLK3e-RxZ0hOYStwGsY1VpKNGeDEaAmeDOi9lqtcJxIBLkvTgLipwYK_AWELGkGm8xJf9WoqLDZ1Ks_lC0GAtCeXhIONcjTV34-_q1EgUo9Ug=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Caixaforum vertical garden.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOnLFs7JfnbEI-IBZAiOil0n_FfKIVFFhARmqWa32TIkOqCQ94fZSCatJ8FRikq5f5JOTo-umfAtfJzq-wCCDAhjmb3HzP9G3FlRJtHuTV--cumbXxPlx0mZaPM1iZS8r179tdO4z5V-1Yc7ikIriQoJA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>About to head into the Prado Museum.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPSwflAbTc5OaBluO1Kg6g0xiaynpcxH47tpUPt9lWbYe1grKHu-qOHJSv10hniCQDwP4PTJ2hyI8bN6uSedqM8qk4xJAeOtx5B5sEGvSyLJhrVFEu4Mhe1Ti34ws6LekHTj7kwRf8U_Rhwn5fusinxMw=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Most of the museum doesn't allow any photos but here is James looking at some crazy old and intricate statues.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNTX6WRr_R5j1Zr6Mi4Y_eSV-OYu1-vaYtFB5BKfO_N2BTL5mJLXL476D-WAHji_zwbPYAFpJ8YSI1aaHLLNIFlQPcdRZr4cVY7h5gjYFb4WfHXWZNQyldtU54AeXLOPmsKsDjGvjt9tQrsTncrMv9r-w=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The absolute highlight of the museum was this incredible temporary exhibition (no photos were allowed in it so heres the sign at the entrance.)<br></br>The exhibition showed the back of the canvases of famous paintings - some of them had other artworks the artist had given up on, or practice letters to lovers, grocery lists, practice sketches and hidden descriptions of the front painting. There was also an armour sculpture and you could see the almost-paper-mache structure behind. Absolutely fascinating.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOmlDxoDQSxUXvZhYvH03RYQdrr2Lc5LuFnT2D_m8J_7I2t-JBgVm3O4NjU0hiRqWeMhknH7Bsw5B5uJ_DUFncV9TKBhM_7YgBliuCZ9g1AqujI2qphd3LvKvIUZ-KM4ZEoBQAF7BA4_t9GlQNDM2fdWA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Eating (cooking) in style at the hostel. We found a seitan/tofu mix situation and it turned out delicious with some miso soup.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPPU61P37X5LDPWj9iPN2ZsToYfHEVNHZTaGM_e9LdWRNU9HIVqJX_6HfKW5jwiPPs6RXXP0vaTp6ln06Br60lAYVbxXLnEZ8k_Kw85K8k4CgOQZRR6Gdcy_GS74l7qPFoQjJLBLD2rT-NIsQnfJEqoyw=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The churro adventures continue! Post-dinner we headed over to San Gines.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMVcL4WrB7Ui38-muHveK1IVHGznuHqGxz3AYbctYI9GHSrpDoxklTKZwF1-57Q7VJDWL8Gg46FO4wfMAcRDDyT0pRGYRa_QpiGsDiLD7A250ZNpJ6BZWPchO4m3fxzux5Vy1sBpSuTyG33eJOR5__PJQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We sat in the fancy downstairs green leather seats.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczO2WehM5EnUpgt6H2gXcy3vjCkuof6NdmVVGjzWX48jeMdP8AuM8zWebTpmq2nkR0N5XPzHH7-FBagnAMD3c33GWfuNhxXptWaKe9k4PTH7tEV7CAbUYiZrZo55qjxGbt7_vXoJ4WsCj2i6B6myfAS39w=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>San Gines review:<br></br>-Churros: star shaped, get 6 with an order, extra crunch, less greasy, no spongey texture, pretty good<br></br>-Chocolate: good sweetness, good chocolatey flavour, good consistency, had a little bit of a skin which wasn't great. Satisfying to drink<br></br>-Overall: 4/5</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNAmn8V3ex7DpPVKeLy6veT1ZHyiK21WSSNpbluDzxquzXnAklllRnI2-eK2tj1VaM8d7UmIsjNa7xCIcQETQkoxhb3VMmV6Spo3itlQTKSliQLrIqRSYJ8DJqLl0CT2oJISPEbehKejgHy19E3rN3odw=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The incredibly popular flea market was on - El Rastro. Madrid hadn't been too busy so far but this was chaos.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNDFhh8S4wIRk0Yl7Xej-3qGcKYVKsWpj040OiU6MZPO3pw2lAE6yybClu_qxKHmvEvbUjqt7r_WwVeZrq3Cf9JDgcBqxRM_k8DLh2ePXofZiiXYlINVZHUURd2yx-pQOIDIZ7rRaz4DmKBus2Lqil-_A=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>James trying to get through the crowd of the market to find some cute trinkets</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOVY6ZaRSPUY52PxF3DQeFTl7LdQr1A4pkvpnV18Y8dOOaL2OX8Y80h6pVz6DnVwQKn9wnWR-XY_4sYEmu8iXa92lNO5N5G1LML8zRHsnrLQIOEFeI4zBxXONJ3mZqVyp-6vL_S06KzchgZ4mL6qWPy3A=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>There was hundreds and hundreds of tiny stalls.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOdwUkJSQx0pEJ-zFIPZDAf6IpeBiD_ELsJ8WMr21oVvbLOvbebIIYm8Gq4dN0RQla7zhRM1i6L5mhOalWdOn-eXtA3m3olXEvM0gtEmatHmF7ad_61UJmiTUs-x8ZpJd8KezDNOKAE6T4q8JiEogPO0w=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Before we left for this trip, we decided we wanted to buy a cute souvineer spoon in each country we went to for future tea drinking.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNL5gCJUq0DK9b5n4ZGdWl3RqcKymnlbELfwwKlBSeKteGyO5xEJ1aG_3Hx5Au8Aqcwzhx1duRGQvTrCIw5teRMZpraVWgYBYpoLH7gI1N4qbrrzmVL1p7tapbnn0dkbP4jCQFliCtyMmZ0kFdwaeq1cA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We've already collected our Spanish spoon but this man had a fun little Australia one that we had to grab. I was pleased.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNiy5bMw6j_FpksswmW5lLazE4_qswNmK5YG3TNHkwhYJGMG4vwNwZx_tF4v7outNgxHkfDcpw_KwxTqfUkq_HoKkX753-hNZ3CstpUWynMTQFEmA4YZQXpBZxhJ1lg1p7xluIv-TmNR6pJ0lphoo2bQw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>A cute quiet area in the Lavapies neighbourhood.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMe3C4qZqVd1BKQVKqLncSWxK_SQiSz_F5QRXssAsuNgehberYtXffrJkTKVelW5o6srHu5ZtmkFYInTTxbvE45vR-pZTJ8dk5V8JNvCQjhMCTVPBB7HonoetuyKqzVMSPDbldkU5TeAiJ6s_eJmG_8Zg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Patatas Bravas and tinto de veranos.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPjGAuwtizACVSNX-SSIPQHg1AveknFlWiBBDxDbUIwCXiQ81LHYYqlwt7KqjwWgiU_FRMULor5lnso9bfTa7OVlDRUYiEg6nAJ4U9_q6HkRlOtFIVQhzG4xraq7H07yBVmgm4q2oIiUzP8z09Vm9xGYw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Night 3 took us to Maestro Churrero, a more casual late-night churro spot.<br></br><br></br>James' review:<br></br>-Churros: star shaped, get 4 with an order but they were round so good amount, a bit cheaper. Good price to churro ratio. Good crunch, a little bit greasy, a little bit chewy.<br></br>Chocolate: a bit milkier, closer to a normal hot chocolate. Good flavour. Maybe a little thin. Good sweetness. Yummy to drink<br></br>Overall: 4/5</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczM4AOAB2IHm46vTOABtqJHE4EJyWGCcMS-nT2lOxRnHogqiRDXDBQEwd6md2koMHkTDiB_wW0gH2XwFV-tiBQr-fyr1LwnOnyvvl2VbzQ8ygdy2V7WYaLdv0FbF4Ub-tkVCLGb4Q1lkB8CgPAxARY_DOg=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Look at these churros being cooked, isn't it mesmerising.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMyNEwLpJLfki7UHJgkHLTGY7LgpTXOBwdzE82Kp4VkPyrIRfCz5rR0Pxee1FpJQa7jvtqewyhJ-uwHaDx_W4Su92dypiiqf-pgUAoYePj7ZGm_ajeLEAurHglpoC4Ld5RIq6-Ly9-rcSnzYEOflFLr1Q=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The next day we spent in Toledo but on return to Madrid, we went to our 4th and final churros spot at Churrería Chocolateria 1902.<br></br><br></br>James' review:<br></br>Churros: star shaped, get 4 with an order, okay price. Quite greasy. And a bit chewy<br></br>Chocolate: much milkier, quite sweet, much more like an extra chocolatey hot chocolate. Really liked it. Maybe a little thin but still good. Yummy.<br></br>Overall: 4/5</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNnRaDSGaQ-_A91RDXjXMdlo0rxMY0UI7GtUGrGVEsxGPUqcpkm5lwb51MoI8uWXTH0H44wJmbHK_LMxNLd9W9C1xudd_2-EGl70URtJ3EzT2hjmtF-ZNfCyyRPiJCFwyBfVVmNZqph4UR5ndJ8qION1w=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The winner of the churros adventure is not an easy answer to come to and James has refused to give one. The favourite churros goes to San Gines and the best chocolate award goes to Churrería Chocolateria 1902.</p>
            </div>
          </div>
        );
      case 'Toledo':
        return (
          <div className={styles.imageBlock}>
            <div className={styles.writingCard}>
              <h2>Toledo</h2>
              <p>We took a quick train trip from Madrid to Toledo.</p>
              <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPQV6s_EPE60887LBowI9jX89KMD6QUrhHn28HTMDkeJknqIu05Md2wQHza941sO2fXFk_a2Ja22qoCuQJ-hEcS0zkgrmmqcJskfispXsjKoYCO-UC82L9eqhSrd33cLGVVa0LZWyjvvcXBRqm-ndO9fw=w1200-h904-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We're in Toledo!</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPBKSft3wrIaVOT7FGcNnyvl2zwYYZ8YoazG2Gx6uChId_hGsk_6Z1SSgRqkpXHGMdIJaUDq91gOzs_UgOb-II03MieHjH4gx-s0VxZaKQsA0wWqPwiS9jsLFq9S5Nm4WmZFJMgv1bTbpL_ExmCfPl3uw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The view of the walled city as you walk up.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNZomnRU6YBHfCjqRuVw-o_OvGqil5ge-YZ2QdyHloBtL-nYKgbf6-aRDUWoPIvrW4J7FZ4wDr2CVlFteQPmIhxomB2mvGFHOh0vGAPCMdUH9HU88B-KwJDBn6NA9o_sV2faEKbooU94sYlUt-StdDRlA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The bridge across the river into the city.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPvRFoSMjYRzs6cHdoX9yZVFDIQQKbPrFBxL-F65GgnKm9BSb6ppRypqApITFrqhw9txOZ13tbmh8qrstYa6lK40aewKjLhZO5-tX9F53I-XsTFzde9aP827T0sxGXOxUwj6Xpm8vWkqWUwIecMQEkYAg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>It is a rarity to find a chai latte but we did - exciting day.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNoCcQE97_9UpRPrMJ5krStpD_HOxt4bFOdb-Mv_vJBUhme8CVZ0lemh0M1vymtvQ9C3wYNMdK8usACZAy3fKZbLmg8TxwBCZKcCL_PTCA4O_QJVKSw2pCHZQWR09foWcWolVr7ebdXr_C_JMRHzQbdig=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The medieval streets are very cute.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOUde4z_-Os4uWh1kTIo1OpwLuRivxOFlJIZnLkM5ByF3tgsMjviOAwCT60SaIjh5_QKgR6bwkhM_LteCqdxjX3eyt6SBMzJzBtzGjg2UhM6mTeQwfTNkpPELa0OtpK8BBovcJcAZCyV-o-o1hCo4l95w=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Santa Iglesia Catedral Primada.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMk8Mq5o20JLeuWlweTbOt8Fyq_henxtSoQ5FlGvE9rz_kWEb8dvZRdiehJPpyoyKcpDuRvQj4J3AdamJsg16fLO_BDrXhbitiUDPcVv47xw1AVw3WdAmlFO42rhLLd7fG9COjTtKN-K8JXx9ST2o0sXw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>View down the valley from the top of Toledo's old town.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMnBCyokAUPJg3HTq_irxBJDhC1o0fbE8nygoTTs_2u2QA1YPIOssdUfQvhJhBiDACrGhYuIoIV4-SOnZ_KWi0LQm8cu10USXsgLVCS7O6EMLXpKUbDhBHuH78So-IB5POk7Zw1jZ_ro4tyJFTGtM4zVw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>A statue who was a fan of James it seems.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPNX2kxv9UCnBbBGII_YhpyjJEQlj1V9EHVQgrl2AGAxYtfRgfq8n67AU1SRtI7Bu_38Jgv9KZ8Jvrg8nYOKBvOZvSXEyb0tw0NbohA4IkkG72NquOkQREgCgtqboQkQu-890DBzUR02NxwojImgzrZcg=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Jewish quarter</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPAwWddfneiqLuY3DiILd-bdI3ZCP-zic0Oy5YTx7wieiumVVHDxfsl1DredhFajJFQOIizs_zENnjcGhbZSzvUGjcltd2P52G9nVLhWsPy1mjWWgbhWZmiZ8r4n22OzLzeGlphQiGxPDDExnFh60li_w=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Map man enjoying an embossed map.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOxhYEz4byT9XG7YYHCQqR5hCxbFbdud6FE7W9rIXBTSpwQN7Y5ylND6AJ9Ffi0_r3-HjUTSxwIGXkfH6SZ98ur2DSxz086gYQZTK0rmXk892JWhC1ffZ41ZdlqT58mNqYIHvBM7BHNnYau01xj2DYeVw=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The mosque up on the hill.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczM3Hv8Q4cJ78mamvWODvc8i9Ao5UCDzyjBKtkp6j9vGz5Kh_QzkSNGUmnNVlJ8ij7o-b9BfKyudDZb_4lknv27LqQBMpNS3qSTYmMEMCjwFdMWWj1dxkiUJd2fzvdJCZSoOdTlzTq6Qg39SAfDhegfTBw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Apparently its good luck to get a photo with this guy, so I did.</p>
            </div>
          </div>
        );
      case 'Valencia':
        return (
          <div className={styles.imageBlock}>
            <div className={styles.writingCard}>
              <h2>Valencia</h2>
              <p>Welcome to sunny Valencia!</p>
              <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNy1__3Pz20JDu4RtQULj5AUOuIWVLoH3d18L98Wjs8Mw7QwdzOx4aqfl3sAyva0Ys6iPG0OJc2qAsq28iJs458BnsS_OgBnBkbD9XGULsTxaWmiQoIv2q15hjGt74XWb7T8T-O031X_pzx5AGHHOc_0g=w1200-h904-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Big sign part 2.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPapeJM0qo2J6wHkyXHitdrygndeAUx9kXyfTcymod640J1edFaTLpq17jmBQ2_6SD83YyovdltzfV87kF5gayqPv2TmyhoxXvkj2y2oBjhTP08tP1LOzQd-8Oa8aJHuN_gKJ16c8CTHSWYIu4Qu4XD1Q=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Down the centre, where there used to be a now re-directed river, they put in a giant beautiful park.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOojYJ9r3P77bSq05M22fMHwHZTr39VuxxlLEK_QN5A4fJmW6-cUmKJz1Di28GUsd1fKmsjKwqjhxE5P7Lmh1q-tug-3nJrE260BNDUe1FyMnnypsLmpf0eQyA4qQiiZL7uFRvSHq_iNcnDrOW8ClejRQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Central market Valencia with some beautiful architecture.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczM6qq7c_ykdChKPbJprRFqsEwWEm5HaXpG38FIodRDSnzyRYBnSxsan9OFs87nrJ8IjUE9UY2jdby5hsgF3pDcNVbJiaESahrxeBQQXpZ3J_d65QxrE3jF_v5eFV44pqS4Z6cdCVi2Jl5pWgNDPQ1oIwA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Soaking up the sun.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOoIJ4dKBVRUMv-Rk58kujRgssPij46zPPWyhQb6FEdWkY8Zs16HSp6YaCCsqAL1G23S7Ydnk33dm59Fdmg2ondNppbQT90wuPYoGhcQhGSjGUy7vDRDKmhRQ_uMJiX2UvdHfDQO6i1_rs9EzU75axRDw=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Our first Valencian paella - a much more brothy tasting version than we had known before. It had white beans, green beans and mushrooms.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczP1TQ8evuL3wckHIcVCDzodIUHahehoFi9f72mQyayjHX7SdlT24ZqQX9kHpm4Ok-Np05ienwMARq95zL1YXvYUslgAY--SvTs-uEhm_eIQ0xkTw1AWr0KU9GShnVk5FeEpr8LQmac-HTlcOkjCei1C-w=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We also tried a glass of Orxata (horchata).</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMiKjfIpneibszs4eCenyTNh0mmNkZqlD_B48bcSNDNdL-g7g9z9U8kmj88JfOhiFtj6coSlwNMUtdpOloNnGNP-AbeaQIaWbNKld4am0tyoE69ad6b_k-tHW7gT_uEssQvTbyagRkzgm9RJBfuBdnKEg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The central square.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNVlyYCkjWkg-fqk14BPH4PAz9dQXRX0f-DNjMgZDAJzAV6pJTxn5IUv5RN3UnB1nhaJZQzVc2ePgBI8nYWxtfknMcy7CH_2TneCA-6wus7v4yd7XMqqnZS14fL12K-eLqbvBw7P7eNLLvDXjzNaUE8jA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The silk exchange (La Lonja).</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPSdlCyH0JKRc2CndIWTv1VxxKRVPM8hT5gQCHPYnhLWS29BB8omZAJb4Z8gGQ7ZkQFH3CD2Da549temtijb8a6EHt4p3HV6Vu-Bi_AqVcqQACeFfRm4dErUYAGYg4G0bpPlv34WslSUVI--TYTSvd9ug=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>And the inside of that building.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNEg6LACsbcmWBuInZfOqkdBhDtYN6pWr30lXMtqyty_Hyo0bWs3YKtwmLqWM1thrJ5vzCi68CSeHJAkflmsnPCgCC2jQ4cpSlUZgmYl0x_4WN0G0gQ69asS_W1c-e5tEYfsrjOyZcQ1Eh_SuQT9fYGgQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Pretty colourful houses in the El Carmen district.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNeyPqBnD9cybaF2a11BeDrPi7lprxDed2dp2IZG12yWEY6a94qqlVkzeiRNwsARzq9MZomxa-g7pulXsGMq2dyGht8wAKUDro_yEsdyTgWeqRWRG5GkTZQGzTJNKYDNe57QljrVlsTYCka7451ObeW3g=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>A tiny cat door installed in the wall! We did actually see a cat scoot in there.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNjabP7Uo0BBdaQKfWC0LekjNmAfmox1Hs6WnPNcIldd7L-LrZjg9Is6JFhvwmSSklsfqcAD902O6mhjnAugQXxU41XxHsl9Xqew2WdiM_UBHpcodwR9bt3Xw7zbpgXXG8DVotUgizeTEqZFttBseYuvA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The cultural centre/museum in El Carmen.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNHc0dXu8DpIlRsVwcS__rd3euAHtsrHTEpDm3PL2Z4wtsSEUPLwqe6tlAvat6zvhOO5fOH2aMVASbk7jE8EweVvGUtjQ35u8ql5YDYN-oc9uL45JPGdBENWKtQnzJ8XIjyIELjLTCv0auz3FIivO-epw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>More pretty coloured houses in the El Carmen.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMUqJ8RAFT-fuBIBP7jDWTs-1fYQ87ghnBu_dMQX9UcVp7ci9QNCm914hIiNErmfo1Tf_Pd-WEjbTtD1Ha8xWtK9x1gD0YP8gq-zYEUf_CvfkSQVmkKWJNTatZfC0WJAh56PQTo6KWMXP3pGpCDfFxrgQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Giant bridge across the central park.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNMzG2PcyS723BlEOAqYSnIvMGoWsFwaXSKK-OYvxpB9wue6yWkhDEeObXu2Sh7BGgGy_PI7LLrZnpV3eDoBLTx7rTNLpBchsVj_ap9t5tgtgdsTlOD59RQWN-DlPh47IKe6Ky64OCvhQeNClneCRuf0w=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The Palace of the Marquis of Dos Aguas</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPmzK5fQf6vpdsVVk0TYL5eYVCJMooXuZbhnpSBzKUgpgnluYVuu5S2IhqHOA34qrUZWq-9qx7eSYpWup7bo969n369NZdkfcjJJITQsPNsugPoQ6_csi2AGeKON0eJthUhaIfFF8M3_p2gbIRhKC9IMg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Buenelos are famous in Valencia so we tried some - sadly these ones at least weren't great.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMc_8mb7nQsion3N9jx4c0dKoUs4uGHfpVwSeo13hyyy3SGjNYDtBomx2ntzMTRVWFVh__uY3-cXNh0erVcGLUmW0nozjzDPIAvKVpKKfux4VS63iGSiVDqcMQ9DbaXhBL_ndqTtzkEjOpnzJw2rb8-iA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The balcony off our room in the hostel.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNcKOqYlfamFUvWXU1VqXQH4MxbX-8CwV-76ljRjcGCkhfHS022XzFOlihPdLlTiD1P-evjz0S7ZRIHq5SuitaglV83mIS8dh_jwVP6t4pRusePJfdBdQbvcIDDktAztd_fSXv3m5x0lxct9Q_q16kDqA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Pretty fountain.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOdvpstg01Z0VO1Njpz2kBLaR8RSN1auq4WQ4D5GldBVePNOmBNctOqb_4rQ4EKa6vSd7aL5bM93p51jG46Qd-XOZ76x8ezSmDVelxnJXC2E4pSGx97zx_LfmOcmwLwy9I1vsukbvhbLB_vvyVG4kHQug=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Inside the central market.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczM963JxAvCJvfUNO9CkflaMEiQR_sLOFRKlLQt-IBIRlHkbpmw8lMQ-6-v0x4yoAp8gj4VHVAlA8X48XsbydebE6uHsNxXx2qFXjeRJ8lhPXnGNFVzn4pO_m_FshZqhmhdsulhF2Ccu7Z0N-s5YsmUSEw=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Cheese!</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczM-tqTSzdfE8lLPl3ELye9wH4UHgF2qq4_ehWLAhRKZLo0nnRL89y42LOn0mrfgxn7fnV08QscIZP4UJe8osLN2zYas8m4n3qB5q5PmU51Wqbi1sb773bR4av8xWtwUetMqczc8V9DLwX48Azw_5td4bQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Success getting some snacks at the market!</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPYQjlkFkZcYJDp80l0YlRTTPM7oKJWgJ9p0o5Zx9Tiwd4fmtiMt3oYas1z429OfEjfOd9ftPYTjkvt1Qln4o-jCKYY0wekVJ5OVMU6OWk4Fqj7512dkg5p8JxZ_ZMyR6MgrXkiqasoptWn8tdIw9TaYQ=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Eating some lunch in the new part of the city.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPieWnwTXUtI37EtS7wqKnjyX5GHpcYjkw3fJOsIdBFUMMgIW-4VocBr9f95ZbyG260tJTnhmtO_QoTBiP1xzQ9un4QBLk5ADFj389ibdIIzNmWUYaFmQs_Dk5TTG39rPzVd4QszJruhTci3P0gy-iHvw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>City of Science and Arts.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPucFaag8UqAoe3aDatGUC99tE1FQJAuLKRT7iLGOsgLSA6IMKsR8IrKmuAvjQkeWx6h-DawiNkxTjPvKt0wb9MRpnoBlYOpayQSuMoiexKP5L79DcQB-rRCVxIksp7sZK4HflKs0godKqR0HszKfGgqw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The giant beach.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNHAlzqg7c1AZVR54VUPZsjrQAhqjWle_ywe4Yk-oQmAAxnM_tF2Qe7xd0w07GWJMsgi-Z_ORE7e8cdfoUBOcD-YaI8Eh1BlcvdZ95hBdJNYJ5HBG6TMnEGsyaS6MRz2xX69voTTSO-I5xYFDAvbA6nrw=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Food truck park near the beach in Valencia</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNKKCklD0oSHgL0v8VIP7aUrjgnI2li8TZt0SbAAiNL63mHUeezH4DclE_5pQmUnka-AnJPZnJmVYte8ICQZ4it_5CWDWF5kgS8TYUgWqZmvpKxUYPTMkhu29SNU3k57DzPOYB55jP2ikhSxSkeH4jw7A=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Enjoying a sunny terrace. We're off to San Sebastian tomorrow so see you there.</p>
            </div>
          </div>
        );
      case 'San Sebastian':
        return (
          <div className={styles.imageBlock}>
            <div className={styles.writingCard}>
              <h2>San Sebastian</h2>
              <p>Next up is San Sebastian in the Basque region! We're expecting to eat some yummy food and wander along a beach.</p>
              <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPfF9zzi9qJfpIf6hCXszZv_uBHizt5Bgl9G1liIrKtnQ0FXgIOvXKH2r8tsgf0eN9-3RmSbIx5aof_T2for5YPpWZ9WdbYnO_C5KSAO_aLzWewEtKFf_wPuJ-0z8tp02KiEro-2oHz-RHFQZcZDmRx_Q=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Our journey was somehow incredibly stressful but also very smooth(?). To get to San Sebastian we had 2 trains and about 9 hours of travel ahead of us. Our first train was 45 minutes late so we spent the entire ride stressing about what we were going to do, given there were no later trains for our second leg to San Sebastian. We had about 3 backup options in the works.<br></br><br></br>Bizarrely, our second train was even more delayed than our first so we made it.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPCDYZNsaUM16ciQGZbe_08tA7CZ6ixxmjTVpuygXReEMVc1-YsN_-rWqLAj_rWoD-Crmonw3yPg6wKQShaIHK4glla9reKBAESgJ3hNvDtdA9mpj2wYv0xHV4qDccbU1a5WhY-wAHj3c04Ekg06jFK8w=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Sunny beachside!</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczP4AP6tENt5ILX-YLfklOEH_zBsBqon8JLXeBqJ9MMwJOzmJ4H5KtLXZiINouXLMcJJmpXdyZ-otDx0RE-E-ZxnFtTvgbn6iEAUUsTFi7I9dv-AGd_Nk47DvAduCw8717ekCZ4iTAA838sWOOVYoRrPiA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The ocean meets the river and make the craziest waves coming into the city.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPJRXEt5GYnI72xLVTef7JyrIEPprPTvl0F_O1ySOlMBiEPfVDc7xvrHn_UxPpvhzhuS-GCCzECV8M7JKsmD1Qz8v6n8-5b6Z3UHSN1F9syN26ANCFuJvXOm7zi3SzXXHwFeMtsg3fNaehMTd47bdLlrQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Beach number 2. This one was so pretty with the ornate fence along the boardwalk.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNO8aq8UuxVOIcJLS38NazYnNhMFBghIIf4cc-JxUVv2NWtll_S3A-_VkvosqAno7f1RLIG2KrUZVuNsNQcOwFYNSVHUjTBxlejWSxae9HcgL1UVJlF_7RepFo07Z1rlh2A_kW5vVKJWy0RCEJxVR5v0w=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Someone was making some fun sand art.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMUpYbECRDWitLuwdQolGiNTEF0UTAev2IVLaczhw25xqDmcnBl6wVkzPLSar3mDydjedSq3Xq85s4sRLSa6unUSRosb21IQlI_OZmpgPHahheWseSDR_74EPOY1DBLGkpLp19SSahTxHgu9Zz8lM1WNw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Strolling along the water.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMIENPS-EAYGZOznGsjaipUvKjxp7HO9ELFZI3PaazLdwV64lSXCXsSixgAwECA4CnAQBufQ3vEjQzTMZDg_xPLosy1YaIg493ZHpHCG5Pl2FZqAiI_1zSSgE6ZFzzgEcQyCTfPB6NsuBqX-J_SKLhLng=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>James is pointing to the furnicular we are heading to.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMSjLWYpUeCCNnAmI8FPP4xI0AmhSHEYCfTpmPxsUaNmufXnMLWrXcWzFPUTHBuYsT7p139yTB9B5xd8HjAadCOU1AX3_eokEJRwm7Yv86nA5RCmjrPvTiUzUw8S-QR8yuLipvGr7m_x_LZD8cZ0w47XQ=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Up we go!</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOYx48fGFhHXYPK9USDMUFinOddTzb9Nf3f7hbCNYj_wlLKWTjpeTSFzQrGkX8koTalYP6umuaSgeG3DzUbIJGsXNBw8X9TQTOWHr2DlYYvAtGlQqG-wiq3X6SUGOQW7rajtGhMY6j-cqf5Y7_ywy8sPA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The view from the top.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPCXbBkL_q1wsoqOVs8JBMdRJf7EemJAuSvJDQvXMgUM6Hpqsk-B0H7oKNqF8C_cz4Vap0B9CTIvZjJA480_oxx9hKjNir7gELsiWJIVepZX4RcUg24H_jgTexhtmoSmsJv5CE3Ha6I-b0FS1C7icbwRA=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Very bizarrely, the top of the furnicular is a theme park of sorts, which is fine. The weird bit is it was completely closed but we were still able to work amongst it.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPiFu5slG-EMi4aIe43CbMGuRa_7816A6WfP2iwLqMevbKFc8pc6uHKqYPWxO_BhU8ba077cTlItKvz1912-zWNcuofUrIaPNESKB7gXB65PmJnUhJ07MSszWTqrPdoShFcuv805EUaoZJBep5ixo1HEw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We found another chai!</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOOn0n6D46ZEg6U4kp0LDZW1X8jXImFEF1TdKNnhNqPmkgZ-5Zax5usG1dCNOsN7BwORuthEtUtefIW1x2_5g5L24pyZw1aYWmFL9YFbl4EqQHt5MATeQcFTAmvi8t_NTHLFx6dfKlnIHiaW-tQdKApAg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The old town.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOd5I03kZSphHuff3qNLZ8dcaBmf4W7ZC3-QE8hHYiBA8B4Ibr4tFvnH_EAJsjbXcHmEy-B7TNQGmld8BzZz-0biOb-aiKU5bkh0B7uPM-Pt4TWd5pRtygMGoEZ1RicLC99dz147vQKu4ielDNHJdtZ0A=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Trying the famous Basque Cheesecake!</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOPzUODQTDeZ2vYmEGXO5RWoVNMBglMPynJPGJ_CON0YklH5bLJV4OtmUQe8UpVxpbtyGg5z9gBQH3eCO2z8WABd82x4eKasK8tT95--ZGz9d-ij8H6Tan186Mug2QtiCxx5K1ae4NWtR1qqjD9YmcKtQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We headed to Bar Sport for some pinxtos.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOZu30FMysgnIT3Rt7IOTPfmnNmwVOjXJ4Y56xNSikKuUrcqZtzGT1QGeKg7WWcCgs8OnWNZXH_F2ItafaU8KuNeA5gNZY_TrE8DX4iykL9sdZ3XCxwR9bf4MaPCE2acLgNfBlhSEplydwKBGwX1xjUIg=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>It was a busy place.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNQz10WThlYKvN7p6sAAmBnpnTBLAl5Yr1HBZibMDRvH7FOCPVGtM5y0wJgGTClIJyhWvRZtCm5kLJjE6q_w6eKNW9qqlsptosp5egpYgoLFcRfrocZtjR-ibDAmkt2_fIgXlIm_0aJKc8EF7kl5_VoRg=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Cool guy ordering some food.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOgzdmv-sydo5OPSh0GkGbveZX3wwIW-wvuXcxBvYH4ZfTsFKOqDTdNfTeNjig_RVf5mZngSfiRkYEIlX6LvtCQaXJctcCpZv5V-px0VWspfpqlcvbYmu4GJT_CGfftuDxqE_BGMrQp8qeEVJuBLm2uyQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Some mushroom croquettes, fancy flamegrilled mushroom with a yummy sauce and marinated mushrooms in another yummy sauce.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOEy8SiCf6m-o17QO-M6qAxa1UL5UlceQpCRapznlBjWaaatPm21EvXAkGkbXKfVv8ygIE1REUJCM_2ypNy5zDSYqeAgooLln7NZ9NUoGx6VoTJi6mm9Dz6noVsw6yK7_kLc-EoYOEl0qaIdQ4ux_HwbA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Basque cheesecake round 2 to wrap up our stay in San Sebastian. Up next Barcelona!</p>
            </div>
          </div>
        );
      case 'Barcelona':
        return (
          <div className={styles.imageBlock}>
            <div className={styles.writingCard}>
              <h2>Barcelona</h2>
              <p>As with Madrid, the following will be churro-heavy content. We'll also check out some Gaudi stuff.</p>
              <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczO22UW_6wF7YFIYLEsP67IBhQNBn2TuZZx8yjfnvx72zL1GUn5gRWTHUKZ1VDaoM0kUFA_miiTY_iZ3o-Js5rhCjRbEOa2ELnq3AJgidxRvMaxXY68JRY_nIYjNmOppgyZwtGEXc5QsdA4krScZJqXznw=w1082-h1438-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We'e touched down in Barcelona. This is one of the squares off La Rambla.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNDCB9OGDNDpZJa5UjKF-9JROO_0iz9foUnVmhpjVJo-nMm-e5ir3PVD12tfqOt8HYLvNyzAPZKp4aYj6V4YRIpo3C01VqEOq_y9qbZ9wk5osThOu1GXME0EXAM1POPy6o0ASBiguWCrW7o-ZKSQO8kcw=w1082-h1438-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Even the lego version of the Sagrada Familia is under construction.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNp17R_iHZ-898oV9qMXivnwTSLpGTapi_DPfKva4zW9BK9iQybSd5-XU-5kZvGt-DRP1x0Wdq7TSLsWU97U5iURZzGE6iCZ2Du_WeF2MAiT1VN8a0Yq226L7EgRtbKXHytHnjV_rRch37z-djGVMOE_w=w1082-h1438-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Casa Batllo</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMoEXl-Ed9tEE3D54JdZW4RLh9CMNP2yKUUIojsxoW4G0xLsLB6Q91DZ0yeDPQuIoxTJ2sP4on6QRYbZIC5shlvIvs-3ELJKs59ZnJuFoCML04gDb_vrEmbb_rQwKEgJpGj01DB1rS7ErK3ltpD7jbheg=w1082-h1438-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Funicular up Mount Juic.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOorknBsPIBm-_hF9uxpvqeZwB_lFzdw9QVWqs45cqsSHJbMPb0wL3X0oCOLuhu1Pipt0SgSDJYqHRuQBUSRmlj5V-vtYhlnhSdd4DM8n_LjC0JY-cfdWKDsOJJbyQQHbXPqIHl9WpaBZwc9DMKgr201A=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>View from funicular.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNqLQJam_rlOUS_TTEk9Vji4lC0ebtMVijic7BjJi0xAv7Sm8A4XxJic18tfVO1FS2m4loAf-wEciy7D_3EuviAMnbLzglGVBV58zdRBpNotJItuH-VLp9riVNv-nybrS9rMBw9PHXW2TUFzKyPAkIQpQ=w1082-h1438-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The churros tour continues - First stop Granja La Pallaresa</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczObi33aMqp9iQEThamJg2qP2JZU4jVWawsatFWIxdQtg9fLHcbZ2l8fBuvPiifh7C3iqIiMzHFSoNZ_DluPL1iVL-ZeZHBxopnMLLX5Rzz-ilJRzkYm-bZEA7XB2bHKlmG64cTsLRF_VJQKjwR2OT6XyA=w1082-h1438-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Sadly, this one doesn't get James' tick of approval. Either he's become picky or the chocolate was too gelatinous, hard to say.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNXzrId0su863S2r8hRpW8Cm7vXBcTIso2BiAVIWGr3OmtHk1TnzvNTe1qhpb2Kvvj1YFyq8sBSHE32iGnDDCFMqHQgMv1tJ_xjq_BIKsp3bDCuRVemTAXeDOiPW5W8cr-UIFMTa_PG_smquZbx6puIqg=w1082-h1438-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>And we found the real deal!</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMUr127rIw1hAlui_RUjGiTHBgEk18kl7c5rkoBgfIt2gM-3R0M4qr4ujjFNWurs-KoE0GP2n4Z2i5Gpl-zzAzD4ryNbbhM1sAFhmz5LZhkunf7_x8OUw2-O00_r4A4al_tmwpP4ujv9bGO1rE-mr2zJA=w1082-h1438-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The incredible interior of the "forrest" part.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOhq-sX0D2e0RncGNjHvf8e4f0d_dspkXtNvD0eRsshrQlqbSzZTsI6TMU_h4I-kYo1Mk8VpszRKJROZOnXGC1mXwbOQTSiu2jZ1ljKdnDsl5u7aJYBPUgCKWOuKL9dgJJu7crAJ8nv9k4nVJZCkdgdhA=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Busy but beautiful.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczP7nkmbyDWmJ4U00A6VI9daCvuieeSSujcxooqdZ07hCxpR2Sl7fCdHYhVDpRCnkmqGUw1m-cn__vzlFLabHmhN5IVJLI8yJmpmEjWCUiDqmmoa7RW3N_6Wcmm8YCuz4XfpK3WlHVKjOttoSagILT0H2A=w1082-h1438-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The passion facade of the Sagrada Familia.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNSGIqevduaJ-WiAB9oN-rz0X5cd2xrGqEJ-rH69zqIENhn53K_h6TOGibJ16xhnYR43AxRDuZSNGqClA0Gjqlamr6JApiPTzFmSS77YTME_IWDVuLc_DkDHl8r4h84Fz7Y1dorCE1QWvY9aUeqJVnp0g=w1082-h1438-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Not sure what this building was called but it sure was cute.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOcGacLKqYV2hdjXXN7aEpXyY_jqTW-Jfn_WraOjFHtyRu6S3kXDKCY5t57LPzKSm8MlRRpkbFVRNYU_6a2x1Qvs9ia93Bbd2rt_WQPcKt3ws2fVsimCc7bChrvUZ7LGesgeFJYGUWnu0tA9un5ASJvhw=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Cooking up some porras.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczN9ryaDVcRp-4q-i-1pWSY3wdP0WslXta_fnjvOd7hWJaD5aVrsah-m8gYNlUuWA7xOsmV7nUoeon7BlHBe91cONlDba6SQzCX776yF6L1KROLlZZiR51pDFSVKG-mQp-BOtJRtl1w_ZtB5hLQDiIeLpw=w1078-h1438-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Churros day 2 and James was feeling hopeful.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNJOAX7vO3mbih4lK-8Oz7JAvJgjrnBmJRYwZ6Hz1gGhuL4wHApYUMRaf50HB1PS7c86-7zE8CWHFq7qfxlMgfRUeKVBRVIL5pM7AvIwCBKBPdWuLNn1TMv8QiB8EfBGilyAJkEQrK2XbPaWuZPIpnlUw=w1082-h1438-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The hope was well placed. These were delicious 10/10.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNbU9GP1k6Yvs3du4yXshq7F4bM_S6KMD9WINfG7PRwYG3Fqxo5jZeaOYSBq6A1vCJbIk2458hQ0EkHREgkJ9Je1GPljbCGcTxaoTh_qAmBsS0tqtQzd6tet16wF2vpGbbjMF7ISmXnTocXo-YTi499sg=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Some churros cooking this time.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMw_v8qtXC5rE20gblQWjhlg7MU3_6doZB6VSDaELrDb6xoYFy-GLj1S3z_mzEAUn2_Njw1nZ5DDvxH40GQCHVmKeKjPNX4wYT_oRjKYFpfxAbwIeoe8mbqgtL-2v0_k-Wc2-xnmFjz4QeSCT_K-eLrww=w1082-h1438-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Next up we're off to Park Guell.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNHmipBTzzIYB0TVRvRMe_tIUfODcp083U5uLmQz3i1qUOYKjkfw9AY73Br9k823hovYyA5oS29dyx6McgBJRvpT0NxV0AWxQtppUuHLCuU4haKb7vz853C8J5t0d2hXPkbOfXSz_vKK9bRys7Ej_uf3g=w1078-h1438-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Pretty cool ceilings.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNSp3GpQSoIvTbGdOp2ZEZkuL1UsqfrQCbWikPmY1nxDCcd1scC079a0Rtk6iCPMuutLM4ur1ro6ZmOgaaANo-haGSIz0Xt1X3as_69e4DiTDevHx2OIokKFLjIhJ1zXdLM_P0QYNhaiz0uew32VQDTZQ=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We copied all of the other tourists doing this little video thing.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczN-Qyt8HCNcMYTN1IffJ4ViecLBmQKxD8bp_P4PC8sWa-68RCAxFCe-3sQziMSHSOXWBDlOU0WXPqJV5qBFggk0pEYMCrNfk5rhLUJ3tPc0utLADnAoyJ4j7bK6NIGL3_73EtCrEZ4_DzE7QdyEWh-HcQ=w1082-h1438-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>A Gaudi halo.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMNjLls-ZmreCrbB0JXF78Uy9gqRJnEzrgGmTtzqn_jUJsmQz7okAxk3IRUQdEqLnGt4DpETtIVoIA__zmdNeqAY53x1KDdBtrshhDBHJ-RkrS3Xm8MmKO5_c9PC9TZ36PNZ2rlqSGLkl7719VqN7RsRA=w1082-h1438-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>More Park Guell.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPQ-8lc4SdYO8jb0mbQgdASWk9TE_gSVNUstpfXIgL_v47ds1jIKGB0P51JOruh8NeFsSs9TUCclQRraE5FaCLSA4_rlYSKpZ5m0GgEry7mQEJUnaCW0H7uldgEyH8NjAvZDGCBmGgswtUr3jhQIcBY9w=w1078-h1438-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>A very serious map man interrupted during serious map-business.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMcFfcR3HTodSbePuo0nSaEGwj3buzxY_nVlCQ7NYHInao0nalYaKIehaqKhPCxAmXs-l80cooTuea80vtIqZTg1yROZG7jgE8s3mtyXlZz8krv_T0AXENsi2_n7htC2zVl4sqJ2BJPjOOWYaC9QJ15sg=w1078-h1438-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Casa Padua.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczN4H8dGC0H0K8gdC2PqQHR9jUZOGNC-NzDamLibVcpoYjGG_4lFRUwNrgo_UdmuAknLjhdPkT2yjep8LwMKanPRimrBwa5rebvXJwNpo8cK2cacxS9Y014iye5h2IwqjmdCa64SbiarcqDNW73NuGm5Cg=w1082-h1438-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We know, we know, we're in Spain. Anyway we had some korean for lunch :)</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOK6Ah95JIs0pq1aG-rm5G5B2YitjMCyZJ2tR4CZ2VJHmLV-8FBxN77rHRL7rKAdU9cGY7SmraYOPYV4hOdr8stJpOT_i9rHqCtgUqKUZf7UA6WuNNExRqv5JBqDuYHF2nqFJv9sh2ip89tVVAfHTRNiA=w1082-h1438-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Casa Vincens. After this we did a walk through all of the squares of this part of the city - Garcia. One after the other had very happy people sitting in the sun, with a drink - seems like a good system.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczONTbCNLcLJ8l-Qtyc7pFQaG79Fvbg_xFqhkGIederqscoJ6XCpS-i9rVvUQXgvujw4IJchPxfWSEoLLcncGxkj9Dim9gZg8vGvW2IGiGyMX_VabcSjfHpDXguCW0h17T5A9KdHrP15DysOLQzyZtoIcw=w1082-h1438-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Mosaic even under the balconies!</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMlR3ORzbxXsAYftgec8u9zbuoL7Wj_XRWNMuajJvcy7e9LKhdEc-bgWUweB7_bv2Vj_PuP7nmjszknB-eQUeAoeBRjgEhekXJF9bf55URP8yw-STffvNYd_4fnJcCtregkYtLNu7Xeiiv34nFA743QJw=w1082-h1438-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Churro stop day 3 was Xuerria Trebol</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczN0wmQUmAipy2s9Xp7--he9SeO5BlYi-I5aVRrf1otR0OZF7puGyHo528Ofc0gpGsEjQb8tTTMe1IpxSboHEcsgvjFq3Mpeg0Mn-IOqM_WEK70FTj699oQdV3bO4RPY7fQpcqgTp6HzmaDG8cR-hkpbCA=w1078-h1438-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Verdict: incredible churros, fabulous chocolate, happy James.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNqnL2vNiQIZ6SU7SJf_gArUI_PTRzR9FFwXy4p7ENJr00smzoE82q9ZWll3AQ3BaY75-RFLxlHJQHi2_l2uvf0mCERYzshgLGfZ1xGFv7v1iLJHjHcbsMaZXpDDqWuJm63yE2b1DKA4K5wzns-WaRZJQ=w1082-h1438-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>A sunny afternoon spent in Ciutadella Park</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMKgoJ-ruVOJQYyf5tKkkY393qF-pdhsUKunZug54wRbH5w9Npy7trX03DP4ALWRbrq98k8RZ1GLekCuO_HsEVfRod1YXorHSpsSZyBccuDppwRvcJQAsbsxAuK3lo1OuXtgbGVbOcBUSWpBJs-EllLZQ=w1082-h1438-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Time for day 4's churro stop - Dulcinea.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMLdS08e_sGRrfwlSGu49ng3ox7XMqKVFzPYiaDloWBprb_KAqH0denibh6M36kr_PEq72RkAdQFW_WeHmRXKo-CexdAL0uEHI0EIHWXT5YEmQx1A14fIEpYugYzCA5sbJMTipRlSZXAZVz5DYz8P7Mpg=w1082-h1438-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The churros arrived and James was excited.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPXnxUYjNLLfiAujUAyTnqrVp-FRag8tjLVhJtIedfqdVL-nekxSHVqgxTfRXauTOGZyIwR1Lt2mPUJ7xljQd7nAfEflBT9-2dHyR7KzN51Q8imcFLks6vQVX8JmDqve0bNYePpK8oIb7-1sSsKVciWgg=w1082-h1438-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Okay they weren't great sadly which may be a strange conclusion to our time in Barcelona but we can't be getting stuck on bad churros - Dublin here we come!</p>
            </div>
          </div>
        );
      default:
        return <p>Select an item to view details.</p>;
    }
  };

  return (
    <div className={styles.spainCountryPage}>
      <div className={styles.countryHeader}>
        <div className={styles.returnButtonArea}>
          <button className={styles.returnButton} onClick={() => navigate('/Home')}>X</button>
        </div>
        <h1 className={styles.Title}>Welcome to Spain!</h1>
      </div>
      <div className={styles.countryBulk}>
        <div className={styles.entries}>
          <ul className={styles.listArea}>
            {entries.map((entry, index) => (
              <li key={index}>
                <button className={styles.Entrybutton} onClick={() => handleEntryClick(entry[0])}>{entry[0]}</button>
                <p className={styles.date}>{entry[1]}</p>
              </li>
            ))}
          </ul>
        </div>

        <div className={styles.entryDetails} key={key}>
          {selectedItem ? (
            <div>
              {renderContentBasedOnEntry()}
            </div>
          ) : (
            <div className={styles.startPage}>
              <p>And we're in Spain!<br></br><br></br>Initially we came into Spain briefly, stopping in to Granada, Seville and Cordoba before moving over to Portugal.<br></br><br></br>The Portugal adventures are now behind us and we're back!</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Spain;
