import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './Netherlands.css'

function Netherlands() {
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState(null);

  const entries = [
    ['The Flight', '27 - 28 Dec'],
    ['Cuijk', '28 - 2 Jan'],
    ['Amsterdam', '2 - 7 Jan']
];

  const handleEntryClick = (entry) => {
    setSelectedItem(entry);
    const imageBlockElement = document.querySelector('.imageBlock');
    if (imageBlockElement) {
      imageBlockElement.scrollTop = 0;
    }
  };

  const handleCloseDetails = () => {
    setSelectedItem(null);
  };

  // Render content based on selected entry
  const renderContentBasedOnEntry = () => {
    switch (selectedItem) {
      case 'The Flight':
        return (
          <div className='imageBlock'>
            <div className='writingCard'>
              <h2>The Flight</h2>
              <p>This was a long one... our flight from Hong Kong to Amsterdam was cancelled while we flew cancelled, so wound up going via Frankfurt. <br/> They also lost one of our bags.
              </p>
              <button className='closeButton' onClick={handleCloseDetails}>Close</button>
            </div>
            <div className='imgBox'>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86UkMfXqM4eUvEFIR-Si6SHCY3G4mgPvaM96GNbZ5VcCXagHwS4MsMb4nUc49m_IbCAfe9Xt9RUD2kliINaS2qcIj3hohkhQsA15aTnnDJ9DpFTsBrSiPcmrGHJcOGZVKAPjKG7hoRYk50I2lCuUa-dAQ=w1118-h1486-s-no-gm?authuser=0" alt="Me"/>
              <p className='imgText'>Testing out the new neck pillows, it sort of worked</p>
            </div>
            <div className='imgBox'>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84qDfdA3CsgP8UxrFNUCLBqhtNHx-lJ2X6RVQskg2FHr2nulLHOGC6Zu_xXC3f0APKnFkHqV6zpmBRnzQ4KNNpmYDfOVqbdK4Bchb77bzrhAT4nP1-lQCXdfPafWOI8lMilXqU1Y57TXQknnZDlTaRWhA=w1118-h1486-s-no-gm?authuser=0" alt="me n choccy"/>
              <p className='imgText'>Honeycomb chocolate from Melbourne propelling us after more than 30 hours in transit. On the way to Cuijk! </p>
            </div>  
          </div>
        );
      case 'Cuijk':
        return (
          <div className='imageBlock'>
            <div className='writingCard'>
              <h2>Cuijk</h2>
              <p>We're staying in Cuijk with Danny, Ashlyn's Mum's cousin. We visited Nijmegen, the openluchtmuseum in Arnhem and celebrated NYE with olliebollen.</p>
              <button className='closeButton' onClick={handleCloseDetails}>Close</button>
            </div>
            <div className='imgBox'>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85qv5WbhddVh-B_KNBcDZ4UKa2zRZBEYY4_rUWCqguv5g54k4Aten9wmQ1jFw9Wuq4UxLc6_8cHeGls13WcGvXuu84b76sYOTUbFxC_FfqB5Ph6Nmi4TI5cPQrNKj8hosMg90JRTPyP1dZ_xUy26reqag=w1118-h1486-s-no-gm?authuser=0" alt="Ashlyn"/>
              <p className='imgText'>Goats cheese pancake at Pannenkoekenrestaurant 't Hoogstraatje in Nijmegeen </p>
            </div>
            <div className='imgBox'>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87SgFGB3tXMEVIPWl3umraIuTRdeekPHl9YwHe0R31Z-xHOTB35OcffYbDnjd4ltSp_QbFTuZwj9tEmVLY66WJT_dYJPTTEIKEskBcJzGB14YOJmhLMVXpa6oYLtgA7kvG-cThNq9UlpGQAd2JDGTRKqA=w1118-h1486-s-no-gm?authuser=0" alt="James"/>
              <p className='imgText'>Back streets of Nijmegen</p>
            </div>
            <div className='imgBox'>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84yQmk866MtQUS77L5KazQ70bdfLANpC-I7AtOF67SC53Q6-B2SuBOmtjecnCHTSKyr0v9-_QFWxumNekMNr06Q_jFDcu9QQ6IiSdsH8me5twrVR-ofsiMRNgtoMLfMlMQ-JgHzPHmo2FDcas2n85wkDQ=w1118-h1486-s-no-gm?authuser=0" alt="Ashlyn in Nijmegen square"/>
              <p className='imgText'>Boterwaag (city weighing house) in the Grote Markt, Nijmegen</p>
            </div>
            <div className='imgBox'>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86gzi1_FGyaCnc_tq0faFm4xxBaQsH5KC3-xIGnU9h8YZ3uaLjqjKxFdXW2PqatZ1_o9nItM3xljOzoUEAJuH3Y_1Z2cnrmB1giRoO0adn0Y-2pgHOCeitaxUADm0g_LhKOfhjCRDYlwSeQkkteX4RjKQ=w1118-h1486-s-no-gm?authuser=0" alt="Ashlyn & James infront of windmill"/>
              <p className='imgText'>Open air museum in Arnhem, same place Danny took Ashlyn's mum and dad back in 1991. Danny says it looks the same.</p>
            </div>
            <div className='imgBox'>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84qRpzzC6aCIS9QM7zDAVbH25yGThrF23Hcf_z9DVqRC_IhIxxr_nlIKsPq-qflQ8kGC0EsQjeYOGEnBeqHwo45SHLtE_6rQrJ-vFlF122Kbjp5jf1ZnY7TuNnQrY3WOumRo9fikZvo-cLP5U-Y9pO_Ow=w600-h800-s-no-gm?authuser=0" alt="James infront of windmill"/>
              <p className='imgText'>Windmills!!!</p>
            </div>
            <div className='imgBox'>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86RUQQkmQBzvBq7oqygawkc7A8XDddpzDdkekSX40ZRRc02_H4BXOYWFv1GW7Ra2hiD6qRvuKWfxNAjZwnZMANzLKLSfHUbte9XtKhisw8y5VVnOQltw9hep7rgGyzHoK1PLCNY5InHcYeDSN-dE_8aWQ=w1118-h1486-s-no-gm?authuser=0" alt="Ashlyn & Danny"/>
              <p className='imgText'>Ashlyn and Danny waiting for poffertjes to arrive.</p>
            </div>
            <div className='imgBox'>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87Ro03l4FcP3tJIqFA4HycQlBjQkZ0V9rXL-Y8MoGBlSzBqweJiaJG9W236WhJCICZNHcJLETlLdbB0RnKJpRBtBMj3UUQowoW0eCJpYWLYwabgv_w9FJl2j-UuCn4HxoqDANRWnezeDcO4uHVw7JTW9A=w1118-h1486-s-no-gm?authuser=0" alt="Pofertjes"/>
              <p className='imgText'>Said poffertjes being cooked.</p>
            </div>
            <div className='imgBox'>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86lMjmshtyjcGtT3OuvzemdyL2emAy4JDzj5Nr6uFr68TVFCXszRua4F1xwNPRQrbNMai66vBsOndhZ_Tg51jTYkQmaQBPp5z9Wz-C6KuV_bA37IRx9vuKn8xiOOXh6IZpLaHkZMxIbVVUF8QByCCdPyw=w1118-h1486-s-no-gm?authuser=0" alt="Olliebollen"/>
              <p className='imgText'>Freshly fried olliebollen in preparation for New Year's Eve. Many were snuck through out the day.</p>
            </div>
            <div className='imgBox'>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84h3TrlxgL1GBBFvAfKr0AkZ-vdeUhRGYMLNMjMxcw0HYeEPAydOj3q4sjkfx_Q8DOyBJEPOhlnm00WD8zxmUReS2diLotaFbJR2qXj_kXovbTb9z_0mljCP-A61WTNuk5xaU70eufaJ0mFBaRrT3o3Vg=w1118-h1486-s-no-gm?authuser=0" alt="Dipsy"/>
              <p className='imgText'>Defiant Dipsy (yes named after the teletubby) </p>
            </div>
            <div className='imgBox'> 
              <img src="https://lh3.googleusercontent.com/pw/ABLVV8756u2aCz0yeGXGl_XSc9PlRcTd020luqhyCKI_WjUGr4b9eoz0wWh57e4bS1mNdou31NA1J3P0LXARUoIpfH-aMPT1Y9wVt6PgmB7_gLWOeRv3rSrHjsMX_KwC9iDpsC23zWkDkznCe5ueFRIAuUffhg=w1114-h1486-s-no-gm?authuser=0" alt="working on blog and spike"/>
              <p className='imgText'>James the dog whisperer, with Spike, working on the blog you're reading.</p>
            </div>
            <div className='imgBox'>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86nyxNrpkp7k4AoFIaBgP6ti-ta5xHrwQLBAhToCEbSh7egUGdQWpoFVGFTb5AJo0DT3w_70ULu1RNwLe3dRKghNjO9lyX3Wkei1eP6YOD9JuWD0ovsJdCI4gVYmn62rJiuxfVLkVQ0HLn30S-mhkMZLg=w1114-h1486-s-no-gm?authuser=0" alt="olliebollen in progress"/>
              <p className='imgText'>Just-fried apple beignets.</p>
            </div>
            <div className='imgBox' >
              <img src="https://lh3.googleusercontent.com/pw/ABLVV847IrIvH-uhtgiVrWDRAAypfQ0fWew-wzh6TCjTcp4DScXtyRkkubl_K8mNjpQ_7QOTvjWOS0DCDLgfG7GV5wPXKe7L9KXTysy2vm7gSAvDcqm7TdEHwQu4H0iBCYLTDcALuzaWrMFXDaSqrTPjgSqb7w=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className='imgText'>A typcial breakfast so far</p>
            </div>
            <div className='imgBox' >
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86g9nTSSrd3WkVGcvOR3-ttnd8uay4mJBYmbEMjI3XXNeKcGUe5qUHIZuJwx3l46BpFo_-3CCQ7B5loy10Dh8oILxQpiL8tA3qdXVeol8pmOC9W3hjZE6QPZ4B1AJ21FZYlMVLBLxVDsL_kxtNyUHmoNg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className='imgText'>Giant chip man of course</p>
            </div>
            <div className='imgBox' >
              <img src="https://lh3.googleusercontent.com/pw/ABLVV866zWTSPKB93uDIhGh6weiF9_yYWk9kc4i8qZGy8DmpbmJTjUTXL-jQLvrdY9v9SX3nGJ6QsFv8FZkrGJUImorJxeiVuUeuFUGSA_fxRbW7t7N02Vrzq8tMTYSnZo1eYGpoiMkYmmYEVJfoTLxHzVpr6w=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className='imgText'>Little day trip to Venlo, Netherlands</p>
            </div>
            <div className='imgBox' >
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84Qye4dqgtMTOF9NtHrQY7UZBnXmkakhy-idj-K7Hcay9QQs9PldoqwPiH-z3Ir_7HcdzLGdjIKnPe1Wqto1SZdO6bwP5h6AUi4mtJLueMwRlA9hrL_8wZmEq8kKAACdx4IhgRgt7DrjcQbLmzSOb26AA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className='imgText'>Saying a temporary goodbye to our 4 suitcases. See you in April!</p>
            </div>
          </div>
        );
      case 'Amsterdam':
        return (
          <div className='imageBlock'>
            <div className='writingCard'>
              <h2>Amsterdam</h2>
              <p>We made it to Amsterdam! A few days on a houseboat to explore.</p>
              <button className='closeButton' onClick={handleCloseDetails}>Close</button>
            </div>
            <div className='imgBox' >
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84Hf3czFer3oZ3QxqAA8KXG2LwMK3aFMV57J-5XjOKKh3leVvJIKHawk_g3ld2pBytA8Oucvpuo8FDhLTVLDHa7Htw0BBzPK_Biz1ZR2JdH81yieANgQrMJ3bU70Rfp8Tic9AkmdFYx12_p5ntGmYMhlw=w600-h800-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className='imgText'>Packed up and ready to head to Amsterdam</p>
            </div>
            <div className='imgBox' >
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85BkTrpLOVgXCnSQx4L5ZVfaxH0FgfEAZb-MhY0sS_1_1YBJ_BgAkaPnp5KjWzOTkRNdScDJaPC55pa_XPCyK5OgMGQrwvfRDt08cRmcJtysVAqKaoCYrODQOQXzboreU3SLfvpIulytfEHLBiw5o-3Ug=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className='imgText'>Our little home for the next few days</p>
            </div>
            <div className='imgBox' >
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85s8EgMJWqFel5n4CvnrUNY6H4SIYIFtbIff4qG7fIIdzqCACQmU8SFUWHYvDwAJPU_PuIVy4di9aOLEgHilVRDfPkF2ipCsbxeKZwTUu5rAec2sAOh9VNevOpoaWqNqq4xvBs2PqJA4383Bmm96YYuvA=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className='imgText'>And yes it was little</p>
            </div>
            <div className='imgBox' >
              <img src="https://lh3.googleusercontent.com/pw/ABLVV856rsvTbkBH_xfEvoV3KaNV9asDWh5EpCBDo0F0wPNll5eD75CzPfdcJaxXkimFNftCgyYsgs2YdXyzOS4-179u0xdFuATdqCqyBNa2r6s2TEKVcrqfk9s380mJqKHb-4KlClkpNlo1ZMaWdd9M5TMqSg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className='imgText'>It rained and rained but Amsterdam was pretty</p>
            </div>
            <div className='imgBox' >
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86gFHVZgJZaEubNxaef24XwdnIDl7-2vxlBix0QBbWrOPpqW3x2qKCuLwshkg76fLMfzATXtLj6gp4XQVATKEQGuiOjvZh1ae5Nf_czgTb0_avd1NK07VUjr5QEtHbrOeWU4MpYSrkxjMoYJZum2Ro17Q=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className='imgText'>Houseboating</p>
            </div>
            <div className='imgBox' >
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86elmCD-zwZpbkB1SiqN-nAyGkvaZAszAkuFr_jQLkHTo3u7OC4JXgSmX-rCRLUa8NvFGXdT6UI9Grtf9xUhUy9dSRN59y0TIdsQOooKIfJBNvwllWLI669vRPO9SBbWZ7IaDU6W8B62mrBK1DgMszzsA=w600-h1067-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className='imgText'>and boogying</p>
            </div>
            <div className='imgBox' >
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84FsJh5ZGbVvEvikDxu9Y4s2gboEA0RuPlsyZgxc85yATTj47eYK3MiaK8hr5FFxH23mCU8H5mndtEb0Gbtco1H3hmEkGQ1i7fAspAHFN63jMgLwBFAufkhQT2LWZsC2NBjfG0mtMaAazurpP2w9XSZpw=w600-h1067-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className='imgText'>Ashlyn exploring her love of NFTs...</p>
            </div>
            <div className='imgBox' >
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86L_A-xFIKl9gPocMzTsLlii7D3SniuWozJDAMns-QYSC9zOI3QDHHKLRaYD0ngS3OQU79Pk-mwkcLN7DSndYG5cn33NGrzvlrTgSczIkvopAL855MrhFZow6Hg8C9yB9_v6kU4HVvRuUEkdI5X5ANtKQ=w600-h1067-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className='imgText'>About to go in to the Rijks Museum!</p>
            </div>
            <div className='imgBox' >
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85uUQ9iD47AoZFw2upbr_RfjmtnwAdNTVtWYWQOVxtqlUVN6O9dnICiPOCPj-K-3A9JhzXusxrJ9964tya8590XouCcMHZ2gwFAAfuSzmCrdhyVV8rPzS7slUJAJJAYIoilPPOK9QZFnL_QgD-NR_jSzw=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className='imgText'>Rijks Museum part 1</p>
            </div>
            <div className='imgBox' >
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87AFRb28z7V-CNPS4fodnS6PHva92xqPKIMeVtAz07xQT7wSYbopSmTg3WVvfRscgsM4yGilCR8CkrBs3lF7cxq5nr3OC3gI1V4-MqI2dgBZ0Q757VjuvSktEXlUSCaOvNSXUPrt8c32gRM6Yd0W4kFRA=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className='imgText'>Rijks Museum part 2</p>
            </div>
            <div className='imgBox' >
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85NxYRjSc5110i1uYQLxujSKghm1nKe2pfSKy0709oXvEAqZ6tWCIueH_mPZyGifWextxM614d8-db50g3KPWVSiqtBDk4RXjNmiP45cBYyTcxl-Yr4J4ZEB-own63Od6bdC_2X0y02SbXMhO_eMiBljQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className='imgText'>Rijks Museum part 3</p>
            </div>
            <div className='imgBox' >
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85TnRzaEVJhynlg3DQg-qUPppz-x5hHgMQFHsVuzJbKzkxn7DMUCwcI4hndvATTal7VXS52UDzySYBtE8x54y4CQOX_Zk8oyWu-dWpD6dMWgAmemaPN6r72DUJ-5B6LETe_7r78BATRyMzGmU-Z2elrhQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className='imgText'>Dam Square, Amsterdam</p>
            </div>
            <div className='imgBox' >
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85-z3PbHgyvN62MMrdF1WEBtSlx5Y1Kt_ad1yo5xmOy-8lwb7wanaHYDcu9C9F5gYOj4YejHMVPE18Xd3DewX-rxLbiiEgotp7Iud_fPd_gCA2H2nYxH1qWawwEPJavVNydHpgm6Rn5H9HenZ_wskXTfQ=w600-h1067-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className='imgText'>cheesey croquette goodness from Febo</p>
            </div>
            <div className='imgBox' >
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84nEmZ7QZGFWkDZEzx8aQjI4RWaxEPFtK_1CA1_3RNR_GqggPeLXspDMxIbx9Cyp0XElJ8VmYLV26CFEStpQLmNOp-rQPXbdvbF1NXCQmmJ6nMq1Tc-4L5g1miwX4EkLobYOSQCIRa7l0VvVzs3mO_rWQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className='imgText'>Taking a stroll through Rotterdam to see if we might like to live here. tbc</p>
            </div>
            <div className='imgBox' >
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87nG9ZGlWjaY3q6HN6EWgduaodbCh3uZErgMF2i7isRWCjHYj2bEj5bhORLoCkSeYqYGic5W1SULAXLJcXCFuutrPFc5zALemwHTQkCspF-GdypSkOS-O74B0uuq-uoa_GbmYJuayvx1dB9N7hdsFO2bQ=w600-h1067-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className='imgText'>Even more strolls through Rotterdam</p>
            </div>
            <div className='imgBox' >
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86Tn2W6vkUOrGtU7DZHgR9QVlruxqHKOpcyynH1Tp6MYuXaNBkHW8f38jBu-cNFs5wmbyMoLT4KaeFf_wlhYPWx1Gb5K6mF0g2NTa7ZX-MAsfGxCR3nWNe8iKSBMLE4U_LU0Rsvg5GtOWXnA1U4Z6VgPw=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className='imgText'>Rotterdam does have one pretty canal</p>
            </div>
            <div className='imgBox' >
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84SNMo12HCocQPRQQGjvrTKUiAhvOj9CLhSkh-7mjWIQ731usXqqFdMZdBdFYwwWqLs5k-ONlUtOc7X2w6_2H6J_DW0MQMG3hrM2yW9KlGfHmlTVTLvBn-QGVZVO4crrfd3lqOu8RElA-Q1mJElcGnOXw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className='imgText'>And some cute tree-lined streets</p>
            </div>
            <div className='imgBox' >
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86yAqHqmeXMjGef04_3FoaNOT-fL5FrzF4X2-T3WHV09s5CpmNFht6pJ8ZOO_1CZPUP9_rP9iaaderUqf-8u_br0UaN5h5mZIaTwwmrnk09PKirtsHOeCtYVwjE6hO2WWLW2frvzJ6bHW_89iWbYfjoug=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className='imgText'>A little cafe stop in Utrecht</p>
            </div>
            <div className='imgBox' >
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86TGLUsyP-FJENsStDaGv-umWKNY428p_HLv9pk3nwbzLmg_0Rog6zulA9PSNS01ItBC52L_yWYs2p8mrAwVBIfAaW_RVR2iWKuZMmpxrEuKnuCOHO_gU4ReMBOzq1LVRIMxPtvEuTMk5_00WlD5LkTWw=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className='imgText'>Back to Amsterdam! Cheese Museum!</p>
            </div>
            <div className='imgBox' >
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85wqCNhKRkl5gEEvDCr-pAO2gmFfkJs0VfSYq0CaWBvX7A1f066GkmtWOiDQupRwpRyCYK-YO6tPGXdTrnp-DFt0ZEuVZy084PhWxWYSVJbbedQTMiQEZ2qwvufBtaNNL53UdhlzKWvjxOsI2XxPUwjsw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className='imgText'>And the tulip museum of course</p>
            </div>
            <div className='imgBox' >
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87vf79g2B19fGH-TM3h-UR_q26_BaP4hO-Ez-UcIZkMksBDhku05jVN25HF-UJW7B2q4ghj96RwnwsG37wvqHKb-NDCgNHScU2xMaKaByEOUtX7zAsHFMJSc7UTZVzgmtzHBbsMJ4n9p8a8gDPabaSU3g=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className='imgText'>Windy canals</p>
            </div>
            <div className='imgBox' >
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85EHjT7OVi2c4B8UsNjXoHBCamBDQ_6n-Ne29Zu8qkfzgqyDksQW99S5lZi8UmxJiTmU6-Q5TMJHylu289l5YKPZ0qWRbg07Jos1MeVdGqane5_CH6mTZVT5Y9XnBZh_6gJFbjoRi6XH1axFmWo7fC46w=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className='imgText'>Patate frite met fritessaus, alsjeblieft</p>
            </div>
            <div className='imgBox' >
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86ig2xzyw219-RP10XjLLRe7yfZ6lN_1oXY_2kuKzpPDh8lKv9cVrE7hQhQy5xIlsBnyv3OGL2ZvHoZtWu__nrX5AOXNgTx3NRo_cpuM8QdenxM6mv3DAICAWgMX5T5eR1NxxqcvWXQsB1KE4Z55-77YQ=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className='imgText'>Little houses in the Jordaan</p>
            </div>
            <div className='imgBox' >
              <img src="https://lh3.googleusercontent.com/pw/ABLVV854G8Oit_WkPtto8fU0_XIHMvVO5lVLjZynIBS984s5RqRt6tecer63pr0xzeWWnWdc2muskthpkZfRilqy1YaDcPCcq51dB9LGysUf3QZz_YwdmvYulfQm3WkW2Di-O1_KRinuna5hzNby4B_hpIdHqg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className='imgText'>Amsterdam Light Festival canal cruise</p>
            </div>
            <div className='imgBox' >
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87KhvRVwW9YjdOORGUIQ8yCOuDyuWuO5UvRpY8dky89Chc5CaJ-yQSz02q8COQkrj3F51QZFz6gob1z7HMYNHGuejONeqT-BbNZg9vuPz4O7lwMwuX7VvWDzi4C7VtlnbwmWjoTjja9oIl17f9KezKdsw=w600-h1067-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className='imgText'>Amsterdam Light Festival canal cruise pt.2</p>
            </div>
            <div className='imgBox' >
              <img src="https://lh3.googleusercontent.com/pw/ABLVV860v8OG2_60VwR6J-RSflUWBFdj61Krw4JTjOY93FyzWIp8CgeFmS6lIyXWZHsaYNqBNyvrZ8Mvf5N4BJbiSgLAlv7-8DUFO9Zk8Afq5NaEOvPoK4WDP_PPWk0yXypLxru_4Y-v1EEawvBzf8U0SKjTdA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className='imgText'>Day trip to Zaandam and the lego houses</p>
            </div>
            <div className='imgBox' >
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84OdALFUSfA0XxzXObIZDQ4l0nwPNNiOjCg_-Il_4xnvFKeISaLgdlbf3XeUcJ5DiSl7WRTG2kwcHB_iBKfH_8bGh9Pm3BuBysQ_keAxixjgrEh26wfsPpRmaYzXm4Bh0f7W3wq6RTIO71SC1SLjYEJRQ=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className='imgText'>James and his Lego houses</p>
            </div>
            <div className='imgBox' >
              <img src="https://lh3.googleusercontent.com/pw/ABLVV846FbivP-EtMqrJ1UnmfN1OaMWE2QUzZymTz2GY3pqhaa1C7vBWgA_4KGDBfcZpCVb6OqPRYDO27LjCKi2oDxFclb0BLF2HaMzhUKjYuou3sKWKobnVVjU7ftbw_ME_4o0ngwtPc66eahil9qgC-t_ssQ=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className='imgText'>Clogs</p>
            </div>
            <div className='imgBox' >
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86kG3yL1YM_ceQcc2MbJ3YX9n0QSsN3BvkcY9qvKdqQ198kdo_1ABFeSt2O7oAE8gQdT-GwMGjlQaeJvp_FzcYjvmoNTgltuZKl3e1G1K7NmBha4ZPAcRmOADzH3hGEkbHz2JTogEBoMGuEUx-uowkX4w=w600-h1067-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className='imgText'>Fancy Clogs</p>
            </div>
            <div className='imgBox' >
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87cYCerN3T4u4AARWTgPJYafuWcth8VuiP8orqpAfORGrqwkUFfE4AVshgohhZ_rXThmeP3ufV8Q6_cj2NcWsLNrh4XI8tpX2N1JVOR2CsGE_sbnsrcHWJ749diHIUO8S5DbN_zj9_Kb8xCLwEwzewxdg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className='imgText'>Royal Delft blue showcased in a cafe</p>
            </div>
            <div className='imgBox' >
              <img src="https://lh3.googleusercontent.com/pw/ABLVV868Vnm-mzMOwxORlFkyWKvJXLnzfnaxConfTF1qnyi2yRQAr7nJF4U69LpiaijXmR8DOfRhYzRMcAEzfCux2CzrWK1pt73zcTPscVoJgXeT4ABRXj8iPZEKXWKuycse90qdI2ZA9W6qYLNzXCYvPb9Xlw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className='imgText'>Windmills of the Zaanse Schans</p>
            </div>
            <div className='imgBox' >
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84bArOf3qUtszLP2ETPL_-2IEwnxMHO0VM8dn5TF45vFBpPzzZsyDVISEAo5vNt2A74MlonisDBX-joVXdB0ZgX8LuqEGxLuZvm494YgmBIHbWCc1ENPfVuq0Voh2o71tJ7YyrihMIiSMnHU9vP9eL_Gg=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className='imgText'>Cute houses from Zaanse Schans</p>
            </div>
            <div className='imgBox' >
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84_6AIpOWHiZOIesB7EsSPPSFj25ls1zCogCLH2ByiSdSZn30MzlHMaMSLWqkWOBwcor2g2JtHgwgi9TNd03r5iL02qKBU7GT8cW0AAu8wMcazay8yeJxNHaxcYIkxHwz5QeiKN_Gxgds_2FN850gN9qg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className='imgText'>Final breakfast in Amsterdam before we fly out. Everyone kept trying to ask Ashlyn questions in Dutch so maybe she'll be able to blend in afterall. </p>
            </div>
        </div>
        );
      default:
        return <p>Select an item to view details.</p>;
    }
  };

  return (
    <div className='countryPage'>
      <div className='countryHeader'>
        <div className='returnButtonArea'>
          <button className='returnButton' onClick={() => navigate('/Home')}>X</button>
        </div>
        <h1 className='Title'>Welcome to The Netherlands!</h1>
      </div>
      <div className='countryBulk'>
        <div className="entries">
          <ul className='listArea'>
            {entries.map((entry, index) => (
              <li key={index}>
                <button className='Entrybutton' onClick={() => handleEntryClick(entry[0])}>{entry[0]}</button>
                <p className='date'>{entry[1]}</p>
              </li>
            ))}
          </ul>
        </div>

        <div className='entryDetails'>
          {selectedItem ? (
            <div>
              {renderContentBasedOnEntry()}
            </div>
          ) : (
            <div className='startPage'>
              <p>It has begun! We've crash landed in the Netherlands, ready to head off on our adventure.</p>
              <p>Click on one of the cities on the left to take a little looky look.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Netherlands;
