import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './Morocco.module.css';

function Morocco() {
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState(null);

  // Use a key to force remounting of the images
  const [key, setKey] = useState(0);

  const entries = [
    ['Marrakech', '7 - 9 Jan'],
    ['The Sahara', '9 - 11 Jan'],
    ['Ouzazarte', '11 - 12 Jan'],
    ['Marrakech Again', '12 - 15 Jan'],
    ['Essaouira', '15 - 16 Jan'],
    ['El Jadida', '16 - 17 Jan'],
    ['Casablanca', '17 - 18 Jan'],
    ['Meknes', '18 - 19 Jan'],
    ['Fes','19 - 22 Jan'],
    ['Chefchaouen', '22 - 24 Jan'],
    ['Tetouan', '24 - 25 Jan'],
    ['Tangier', '25 - 27 Jan']
  ];

  const handleEntryClick = (entry) => {
    try {
      setSelectedItem(entry);

      // Increment the key to force remounting of the images
      setKey((prevKey) => prevKey + 1);

      const imageBlockElement = document.querySelector(`.${styles.writingCard}`);
      if (imageBlockElement) {
        /*imageBlockElement.scrollTop = 0;*/
        imageBlockElement.scrollIntoView({ block: 'start' });
      }
    } catch (error) {
      console.error('Error in handleEntryClick:', error);
    }
  };
  

  const handleCloseDetails = () => {
    setSelectedItem(null);
  };

  const renderContentBasedOnEntry = () => {
    switch (selectedItem) {
      case 'Marrakech':
        return (
          <div className={styles.imageBlock}>
            <div className={styles.writingCard}>
              <h2>Marrakech Round 1</h2>
              <p>We split our time in Marrakech across 2 sections, with a trip to the desert in the middle. Heres the low down: </p>
              <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87jzcyJaopIhIhrOTJdwc_5NkH_2YHhpOwAt2bfzlfLzbbTxfJo1iHBSnyDlJfEdmeqVyUP1T1i3Y9hBcE4bhLgrkGBBBKOwrHKQROFQf9oMoM1SZjIttagSA8pjxCIqXIP5N8wcFtkjDAU_sQKO8uNlQ=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>After being picked up at the airport, they chucked our bags in a wheelbarrow and we blindly followed through the dark and windy streets of the Marrakech medina.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87BoLK3o3BKEa7cJUO5v4QoFgftaf5nfTbFnBgH2BXKyIACWs0TE0ZEjeMfyD1okeDEYEI-llA6m_17SA19w7if5f-Nvh0MszuskcnwHCPgpARngUyR5cXSq5VapMIOxRX2I7nYy-abjTy9BDaONYeJkg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We headed straight to our pretty riad amongst the chaos of the medina where the owner gave us the safety and logistical rundown of Marrakech over our first cups of mint tea. See an excited Ashlyn above.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86Q7E-v-AM_amF2jjV3Ba6_cVJ3SQOl1AZAsCD0PyNr8fZmAG9iPGfFNFlLxDruXhd8diFUg0fgNunRFuvEQQJuSMqu-UMowrbjxbETmnz5v65loEDd86VcVwU6tq3HTeCQkQnbwwcwm1gsc9fVa-kzYQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Post our info session at the riad we headed out for a 10pm dinner on a rooftop a few blocks away. Tagine #1 was on its way!</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86a3jsRNW5CQ2_r0mWbyJN_YMX44M3vwB6fZCYUxo9i0eCgvbvnPc3NpiHn8TTgziEgtrEvYv6VcFxh2_9gwaJeus_EFuFnGnoJ-Y7qeRZX58mrhskG4oih4BMjuEFoo4ov_ceyOPoyc0LX6VxUncphCg=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Moroccan mint tea is meant to be poured from great height, to cool it down and produce bubbles. James is determined to get his technique down-pat.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84sr1km_fW2Xw_onFThaFF6DOY5j8Re-IV6nfi_aGkwkZypSN_ZYlIQQbbQqREio_kT87EgyI7cyGcdbdLqYT6P5kpMEk83TwPlrt1a6bTlFP8v-iYfjEHhW1zhnSp6aUl83976Wao-rJEI_2DOgUS_eA=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The cute little balcony attatched to our room looking out over the riad courtyard.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86XnbIV_EZNSArKp7gKT5bwX2doftKQ5a0u01aUeyiDJpX8Zr3uM8t8wSm6W5D0sXQgyoMQ3ubEl7_-IQYPjO29xCGFbSc_fDH15ET_dkfMrYWGp2GR-L2Ij_Ow1xdxIgT-A_OeVwVcK2cS_GKNQ2dM0A=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>This tiny door leads you inside to the tranquil courtyard of our accomodation from the shouting, motorbikes and general chaos of the medina paths.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87_MOpxisgu7RMGTR2PVD11jKyHxuaK4EsBZe7IHj2NoPj_W5IhJh0h6p23UTipLsSjPS3edtgjemgs4rg3-QcoTzA32JYdgtIfhofbVi7GPtLYqCv3yDiKkXSoIu6kspSgUKHPxo78bVU33f01_PBgoA=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The Sebbaghine souk or Dyer's/Fabric Market, only a few steps from the front door of our riad.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85wr2wS5JQxCW95ovA1QejB7jvCnD6yQvNzLPM2_PTN5s0qcXuu7bArVf6QSgZ4UcWH8YEuHo8BB-2ettxgWb_x2wydkbjlpaXQGsuV2yo40BG8lPxXTXsJkyBsjgI8mmHWfsCIM0247P8xJwO9HhIYng=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The next day we ventured our to the Bahia Palace - a beautifully mosaiced 1860s royal quarters.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86_fky0FcnsRcMd3vAtYVALBZeh8NBuWHZgfcLudt7HLWrVO1uCc1xT89cmNIzjhMW14psW9MMkFGhOcPh2vNiHzvNeQv3eljsrtlZbZ3TZVcW-bYmwyahvlUM16rKnAq-r-CZkrH44eOAOUJlpjED-qQ=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>A doorway to a bedroom in the Bahia Palace.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85EFeMM5MN-2KlPMvjKmAgfR833a02-DI-hAaYTb9bm1Kxb2HziwcSCqz78cbHlz9wrrmawIHttVOaKa99oOWXzm5D7OmBLBBcRd660HpAy-BRmfo1-OUD3tYD8jA9k1VeplfBi_BrNfJJWs9iYGvrweQ=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>A tile closeup - all handmade.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84D8Iaz764GX1oTbqWnV6tLPMiJ3OB2lhS2AMx3Y7VFXlqw1DvQWRTwa9v38xVQEm8O97s2OyqRPArhbUv1A8oc5B8kSvr1wuV706W1nbyd79l56biH8IhiUjcQ6Ez_lolKFI75uuCWlff8uV8rTffoFg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Next up is Badi Palace, a government building from the late 1500s. It's mostly in ruins but you can see some of what it would have looked like.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86auw288I1vNTXFHF6EI_TN875q3BpmBwSB3U6zWnDeiIBbHkJvqNhtB49r2mcdO4owobYD4-KyrAFAh_1YXYBKmj5M8XgTuGTD6jBux-j4J1VfO523gOGrSSU5HyWBf5fuPA5K-SWnKLxfy8JdIRXcmw=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>As with everywhere in Marrakech, the cats are enjoying tourist attention in the Badi Palace courtyard.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85qTN56EUoWpmi4JZUYbVav81U2EPSXvKIaFjIvw9LiYl9-3-ZMOCoHHP3PRaCIFvKV2gO9iSc1aU_vVeFG3Yf9cWowF9emEfeRngoFjfmF4Hp38G8YprO50I0zAqgdy_wIBh_CshmQus02efAxEFNXCw=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>And another one throughly enjoying the sun.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85ypRvjkP7ql7XR9hj0wy6ulEQRqGFPNdICWoGDugkngShzH9CyMM6Gvj2F5Jj768cDac_ij2TA-AQmajxrUMtewv-NM4X7g7ia5YZ9I8j5b5243b2pCEQ6edDiGdo1RGR7gQU5irinEXwKx9SuJCdq1w=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We then wandered our way over to the Saadian Tombs, the mausoleum of Sultan Ahmad Al Mansur.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86Sye4xBgDjcMcnKwOqPMiYaH7hd_s8bAwdtKtn3kQFGNlvyuqdZCMeFKpk33kuhwYTGo1AQAJVgz8b5y_VknhrBXthoQjorD5-1TClxfAfRGY2YJEIOLQEJFRBGLE8ns6-Mt6f_0D-tJqyOjOTvqtWHA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>There was an absolutely massive queue of tourists to get a look in this view.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85-ZBqeN6dzLf584KKjpQcJIDdBWOZEE7IxbO9j3wkMq68trjcZAYe2tmSMeqSUs_GGrPc2oDTvyB1utY49EKMFr-ihhu9lzEUTsxrgNxALr2krKlqPxxn6EpVSEzuGvHqssaiRxdjbb1-0BfwdLyb8Qg=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Getting in and out of the tombs takes your through these windy pink alleys.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84EzXT8e4Iy1ooo8B9_xgHTp46DkY9-ZJ6fZj86bjcwn7TEqC6JSOAEzCGlJKZh_QieWRTXb5x3AGnO_5eL35paw9TGxksufCu74OEih-rsMXs_tVmiMdGt7XzSHeH-yI63u0dgzexwxqKM_JHb__WAOA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Further pink alley ways.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86Du5yuHQ6wAOeAIOCsSsvEXZbtnV50ft8K-Fudl650izUt7_ClmXOSgk5olMHXzsYiW_tmboMN6uQnmJC9be2qs_d41hpo3tkoT3J3jXn4qLG_4eOOoRDz2iWSrMD7GTY0ax84M4n6O1Hw3fSHkQsnhw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Lunch at Cafe des Espices, up on a rooftop looking down on the Spice Market, or Souk des Espices.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86gVP64ViBUWaNey0zZT4Q8LigY6SpF1t-TQdsKKvWZcIjLehXFXQiiVZUATIIzq9wDKlHBVYzLjsaBL2oq4Nh3VL7qIEshThj0xRGQie_knJp7T-qoCeVJVObHEeuy-mESEzDJs4JUMGlh35_KqhF7pw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>A sleepy cat on a motorbike in the busy streets of the Medina.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV875ru-NR135LK6w5Aeb3-Ymkv0Ad9Iq_8MzdFtKZd6Kjm8-v10eTNPwe56B7dlI4QZRFEzU3gODDs2CZvZJlNQ92yOZjz6fXRu0qbr6cExbFEIuMdRGGqX2TPmXv59Gjo0uAZmI-O6gjlEHP54buyeALA=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>For the evening we thought we'd try out a traditional hamman. Ashlyn insisted we go for the most authentic, local experience. We were separated into women and mens hammans and intensely scrubbed down with clay by naked strangers who spoke not a word of english. Here is James after surviving the experience. He was very clean at least.<br></br><br></br>Ashlyn's time in the women's hamman was much more of a beautiful community experience, with mothers, children and their friends hanging out, washing each other and chatting away. It felt like a small insight into what the lives women of Marrakech might look like, given you see so few of them on the streets. </p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86fnkEiYTTsSguzKxzJcaRv2qgg7907spAtnE01JSR_OX8F45dhoiXJH0EZqUQ78Fr1Jz3VLNWN2bqgml5RVvvjQmyBGlG4ZBFfIEGZN_25KbGLeDYxq1n69aYpdeCu1MR9NHaOgf5qncajYr1UmkqwNQ=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The Riad owners bought us MORE TEA!</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85Oo3CmFWBx27y54eGR6gjrBBBsATxs4Jz-bFFwEwXDA6idpYSEChMXIzaZo3VDT8capHkpYXkMn3tPV4EyUawYcsaFTETKARRlzjV2WU-vneguo8NROzm-l_T6zRwCFwc6u5Ssb9YK96ErhDiitdLdFg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Dinner at Souk Kafe, up on another rooftop.
              Vegetable tagine and Harrira Soup to fuel us before we embark on the 8hr drive out to the dessert tomorrow.</p>
            </div>
          </div>
        );
      case 'The Sahara':
        return (
          <div className={styles.imageBlock}>
            <div className={styles.writingCard}>
              <h2>The Sahara</h2>
              <p>This was an absolute once-in-a-lifetime experience, running through the dunes of the Sahara desert and meeting some of the nomads who make their lives out there.<br></br><br></br>
                We picked up a hire car, on one of the most chaotic roads we have ever seen, and drove out of Marrakech - desperately trying to understand what on earth the road rules were here and whether anyone intended to follow them. 8 hour windy drive, here we come.
              </p>
              <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85_K4gLPIdIrOTGw0b2tvLBm7j5Tx4eLu2jKka4esrlSGdWVHGk-Wp-j0ZNM52z1p7fe8xP7NA6N7UEj4VnJ1H6wQZR81y1ZWX9zd13r5yIzcJ9gNZa_LmDH4bC9MpnJ7uwhesuhixGvx1GXHlJHmtfFw=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>And up the mountain we drove.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84jcj1qd0wSfryedwuA7wsgdzlnYVXO6C7VEOYwY28AXK64xn35eLs6SRSNZFKwihxt1UWvqsUjuqE387-oGSiS6ONjz31cEuj8GJJlQrUazPOADJueteAcVwo5EPvVOIWn75Uq8gXbHe03l8P8_P0_5Q=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We were tootling our way up the windy roads of the Atlas Moutains and had no idea how high up we were until we bought bought a pack of chips that looked like a balloon. Turns out we were 2207m above sea level, the highest road in North Africa.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV857lX0-gYni5tJ7m7ZIXaJWpskBfYj-cwDYNS3lJ29qJbzc8jLL54v7r434OzdLPM6UlKq_neFHe9XVk0RIZSceaV3N9_dDJlm7vah4rf03vOKNgiJ0zv9yGshZ6UwT4Vfxp8xLUDPVEpjX_nbOM3v5JA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>After being harassed by various men wanting us to stay in their hotels, we found our desert guy and followed him 10 minutes into the desert, to desert camp #1.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87XdL4H6Ax1Xuxuy5ha0sDAlhv6CCdtZO7ScbIwiw2IXVRHM5UkuRZJkDWNlLtz0PuPO7ly9GXwFh9X0PjOpUXGGHc1u1YFSqTp3Q0lD7uRg_cuSu9ni5lVXBjXYCw6Crnup0BHpvZEx0_P8kH83KE9Eg=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Gallopping amongst the sand..</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85cA0GPbx-3T-m7R2751sAlWWdAaPA0lq0QCPRclcrwGLriLBDSRd0NzJXpl21wHxKtKRQ_DAkd5kUdzYovecGsZWkjnJlUk4k4ffbYFhG3cuSzdfRTzjFMK7Lb8P1vEegoDWjuBqBJlkHBaZvQAQ17GQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Climbed a little dune and looked out into the vastness of the Sahara.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86SRU5gC_qOjTQKUMki81pDqgRRkxWDRTPy7TB6N5n7DP2b-8exvQwSUR7DZu1fMA4GVdS0NUeA2dj8zNLnq7EZ4sab2cfNL1zGOW8wbNk8I3bBsoSRABRG7vnA9W9yRtjYh05kvVsn8AWYaq7pJ0gRug=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Here is our first attempt at astrophotography. Promise it looked better in person.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87VKRFgOrTAtyb-4HRiafB-a6ayAyFDUGRy9sFHKP2divnQMqRS-ktmLLnOR8sRcL1rBoTHa1tuNyZTjKZbTq2-C_H-WQRyxN4j84YXT4wBSt5EsprVMKukv0H6smgAxIuG_KeLepm1uGW6VHhH94AX3g=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>An icy breakfast at desert camp #1 in preparation for our 4x4 journey into the Sahara.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87F8i4gwreO6nEZx4S6rUBug_Mgyy4ALl9SmS8b18Wl-M400P5LR8ET0DBI4rz-M5WIhS1eeWc8ZS3McFDTIljfhIrO1RAoHhepOhH84VClNTocTqfQ6dVvKmes8LaXnwinvDnRmLS0s63EJQ3j3uHB0g=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Our driver, Akir, picked us up and we headed off.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84FjOk2foR-g9kEeZeBG6f3DKkWbEYKcJiR7b3Gh-NFnf26hqqtEr9MEZdv8hxcMLSal5i27LxULybI7oqiNquZjKaitORqJBL_jIXSgPBumQYHpvpn9MT7IEpU8Qu-4xmLuHe835zDhmoixHbpfqEQzA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>This greenery is similar to rocket. The Berbers use it in soup as its one of the only things that will grow out in the desert.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84p_xqiWPnRPkJgGgQf3jDmOTFWys2RkjM2KE7CSuaVFCNvwA0Y86q_c5MM2YIhWiDKwUjKgF9bWatRcq7usSMPcyAdkAhDpeA7div4xtnKbAQWL4D3wDvYYNvoklzMN9JyLkfMB4Z5WeaBH9UeQNnQ3Q=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>A cat wandering through a literal oasis.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84E3Cj8TfhE_EhKIpMrAH0p8qRxN6iL1zd29V0kCcYtRt9BF7wYhiCdP6CZibUNDv79p58QhWae3z-RUH64NTahNrZfy-QCt_yGWBJlTbYMF9COW3ZWYuEsSZcSYT0QGlS7Aixz7TmRafhU5GB3zT3Bjw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Relaxing lunch at a restaurant in the absolute middle of nowhere. Yummy though!</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86buyuILf_cFDWPGZNdKKdAHh2UZzQRSXq2wIucazux-9a1S5iELWcS87aIX-8Ag4yqEInKHjbWeZZQopMf2RfCabEdB56lqHqd7buongkNyjvilhbvGS4MvkEL2okPkDhyoS4Ptf5X53orgbXEssET6g=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Mint tea even out in the desert.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86uTedihYkAFTDnOS37ksEVCg49NE4wM2ahq0Dr1J85m8Rq4cb0Psc4OlI_fv42Z_9CVxWiLNzoNS-YqDSgO3QRSGmJabqsPNvL8r-zwjDabijLBO479XGTa8O2HaJCwJaSeloRrK46tyNWeLJVq0yu2w=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We arrived to our Berber Desert Camp, 60km into the Sahara desert in Erg Chigaga and hiked a small dune to have a go at some very slow sand boarding.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84BcbW_tQLYmswmF6QbhvjvOT-7DYlq1KLnn9tfjYMo_I4ByhWnUS6GsiuAh4cbwd8T-zAl4iFQnNJLugoZ6XSnz8AiwM9d2rVXExuMrdy3GCanbirUUg2bAMRofCmEOwEdtXsxW-bUdxOQ4Is2TZx_TA=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>James showing us what those years of skateboarding taught him.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87aBE09ky4-G-xhd1aeBp6niLjl0iGzcXK-09-yY293f7cgXVaPwwIvYIK28qt1KWJ4gy8ffI0kNhnLBBnVzw88tXztCA0uRvqCWV7n5l1mIWqBVpguI9cWT_BfRHlaS6EBlHvC5swGF1RWsbQYZ5t-3w=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>And Ashlyn had a go too.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84YM1yxSPVTXFCaA6aY10LaGfsuUvBJrU0gx5sXqZao6UEScx1YgLMWMetzrv48lxHQI80zYpIVEBmQ13wuBpLcLzi1qeoPDC6g2nmnpucKAiFfa90U8aSbgZbf5pt8xzork7IkPv5NpdVMjHaBvdGxsQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We were taken to the "highest dune in Morocco" although google does not agree with this version of the facts. The trudge up this dune was truly brutal, for every step up you slid half a step back down again. From the top we were puffed but amazed. </p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84iHxq9-UZFeFGH-j1sgyetYTRQnE-enjuBU1ye3B2nsAzYbg0q2WY-MGZwhYZ5Uj884kiZeoljL9ZiUMoIRZlOfcqET1XBc_6kvkG93IC0qQkLCZ9awtPV0b6ngkCRawQij8qkqdXZnKk8z4gnqxKD2Q=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Hiking on up. </p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84Gm7QWPLIG9fpyfkbIkKWXrlMqPHeFOoTGSxcEElBSAHSNeIJcmEuJy9e0hpFPSFtteHzMJXHTXr-JKUcNhIOTDoXiNfaAhwVJfX4IUyBeKAvHhqnnoErht607UfWm-IKXdkug4qjl_SHDSb7B_qLfhQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Perched atop of the giant dune, watching the sunset and trying to not fall forwards or backwards back down the dune.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84_brjlDSbPrf4Bi-foAbYXa9una6z04Dm6pg2pCaUrf0q6g-vuZJH7LSdiQ1jWBsLAgCidlABiaBdISgF0vfpw_rokABibL5AwKOLkoGF55BcgRUO_LzmmQk8yUM5xvfx40Ezs0umuY5Q4ZztEAOgUTg=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>What a view.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87yyz2zu7AbrRVlcXzs7AxElGb2BFbL_ucn2gUdtRUKvHmSA4D2d7EgPNqFAjC55urCAi_lVoe_F_D1fnaS9stNsbIe485_KzJe3wOdcCgAOXME4J4UIeXWrv-GCiFsicjKpBugNA3PBHXlDDD3bO-TFg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Sitting up on the "highest dune in Morocco"</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84Ypdl4RQZwUObbeHmLfPI5SnV-oJMg_Lqnj3mfmdsJq9TnrXjSFYnT7a44GzpJUgYv-2efJM9wgqc_yNrad3yvn2JsW0BASCDEHscC10EgAgQON5sk39kOCi2ggW3Di6KP6OXnL_kRT16qYkxtYLgj9g=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Surrounded by sand dunes in every direction.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV871UY351nBeEh-znZIhSodsotHjswX2aPiK2Vt5UWNV70kx0ldfQFThNtFV4FoBNANg78pVWJsEkon23PxJrqn7f8ir56-jbDxNLTe-gOIl9zszHyGXnF_GxSimO0FMseov27wNC-57UWH4-AY-RsetHw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Back down the dune, at camp Ashlyn was very excited to eat.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87RIqbvTMfc5dBUk7rBIFbL7Km0mhirJMMRB52y8i9jYNgGXdJkRwjVjpjxJIStrMM1GDkAVomrkQgQMG_HGxj8aZMQ4Hdb2312raTED_s_pRK07uiGJ1dGnXNXYKt6cTADivxA5v1tdAV0BFvVHbdFXw=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We were treated to some live Berber music by the fire, whilst the tea was brewed.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85QIz2lgaUtgK7QZzlaBESDaZi28FU0gMx7_DDuecfryk00zet9ovUulUGiSCBQ-P_aUsKnfXUDxnNhxlZq7GntEPbGjJgBjB0_3uzCW6eX8IxUY61Y6ohJQ1IIMsGCAIoy4kqCWHdMGa3RwQr14o26ug=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>They even let James have a go.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84VXKJuYQ3rcfzq_RUEWx_clGU0NdP0Yb6ATPG8QSe3Wl3yuadArJJcgX8L4m0fa2uSR_6AUvY6Qi-4I50tTUfDlSD98ng-24DqLDSJ7s3IgfllAKcCAZk4R-FGHA2B-xKY4j6uH61sbMX6sMNMqinGgw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>With almost zero light polution, the sky was absolutely breathtaking. You could even see the milky part of the milky way. </p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV855A0X1KHJxwzgoAWclFk9DzuvXGa_9kKcSHiYfFwRWNRddE5Ob0_QiRd2G8QxtypJdyLzh9jRdcU47C8quSQfS1xI_kc31-SBZIm_BOcZbeU2yv9t0pIt_PKIqVJCIbKQwugMXm6mD6iqFZpC4kGITiw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Standing among the stars.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86g-yC7bNvFXKtOKIJnKv3lmkL481tvO57IDfuUC4XGiGpkYO-8PSAN0vv_5xtEpyl5WAFheFj3GyxOPGMGvRizpC-a6fGQanogi1SMmKQmB_CThLs8jGK51G4orrMKHFbaC_FkmrymZaxgbODFK8gfbQ=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Playing amongst the dunes near the Berber desert camp.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86eGuk0tfbwvPKjNkxlmzsX3uJZTh7Jz7aCjJ5ZK-KFv4uZ6XsPXmnM4pW1iqTlNctuWugi5N2Ve6V-Eh8ahV-317SVrFKLSz7nVFIMRz5HAqiIum0oihoG3QklbueuUN4iRpDUBaud_1hzZ6gdmkE5qA=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Mid afternoon desert stroll.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87pzZ7wUMJf8BD2SYulbYPD7dXwihmIz8uNtXzyl3S03klOB7ek0yZxfgXg66r7nt7O66q8cPTQuf9Vl6oMuWxWYyUPKHW02MEvLlj1ZaXYrkh9M3SaJLDRTTkIczkp39wEvwE-L2aVWmNs-vvHGWtmjQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Just before sun rise, we set off in thermals and puffer jackets to catch the sun coming up between the dunes.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84f8tEaSODY5z-apCaNA_iKAPfEAk5EfLBKHc1fEf6tWXj_-hDrznQ9nUqxqzI_rjh8XDjG9dmKSGmxOIttOSwn39xiYZKhCK7iKJkTi4XRvDPGMiTQYJ_QJlZoVRh01xtAyhOYU-oHJkIYAFX1VP9abA=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Sunrise boogeying</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84zoTkZdT3XRp4TuRPiBq-XPTOdL38EmLBMMjK134d46TRHu0B1zVAXQ3vnUj5MNcSHrcG1gcS6b8yZPUsqlE1CW0qnbLNjL5lcLBOQl5ETIGjbuC-8vgzayy5leKZsDnupNLN0c5TzOIf9sUNRAUsrMQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>This was our home for the night in the desert! 13 tents pitched around moroccan rugs and a firepit. It was only us and an older French/Italian couple so it was very quiet and peaceful.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84BfsZK4ubJriJx87jkq-lybZRQfNXJ1TZe_yidNAt96u586wERhsRLP6DjvIQdE57sc7yhHCkyil_mDRcF6MZSx8Vdg2XwW1jTtiyHqYQ_AunH9PiaUkDwTomh5R_nQGactKSKTWWUMLWw4ZxftJjXSw=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>On the drive back to M'Hamid, Akir pulled over out of nowhere to show us fossils of all things! He said the valley we were in used to be water so the fossil was most likely a fish of some sort.</p>
            </div>
          </div>
        );
      case 'Ouzazarte':
        return (
          <div className={styles.imageBlock}>
            <div className={styles.writingCard}>
              <h2>Ouzazarte</h2>
              <p>Ouzazarte is about half-way between Marrakech and M'Hamid, making it a perfect stop on our journey back.</p>
              <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85m_CFL9c-hFNAmMFJLoWe6DuR4pgtJ32iM4x7yQan1fFdykItIiWF_80w19HJ-Hjhh7QVq6Fix6fLH4mX8STGF1UBJzMtFHIbvKs4AhEC5FVBOo5cpMbwfbKgiJ2Y-4ym9Q3V1K0zkZKZ12e_EMD4PPA=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>On the road again with the camels taking themselves for a casual stroll down the highway.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87X9010JDIF-HyYk_WMHyk0EA80Fx3YpgSvJYM1U17B1GjNQ-a07yutMDYRnZ2Yqf55ko3Ow7g_g4kPDD95fOHHKD9kHiELSIpOH7YyPIrfK4AtsSXvlJDtaCymmVUhUUObgzbDfBvgl8aBECsiJgm7Ig=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>On the way to Ouzazarte, we stopped off in a small highway town called Tamegroute which is famous for this particular green pottery.<br></br>We were vagulely coerced into a tour of the family cooperative that makes most of the pottery. Here is the 7 families' production line.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87OcEh4CMgkogeWJ7nQ66L7_VerbVgRwLmsRqhiJ-yXxik2xi84LDeLO71UR3EZus_c4Bd32PFOxl0ihJ-HAfQmgqq1QlacJphTkSUCtXwHPxSlqteIGeE1v_x_NRluR_tXx6_sVVl4IAnnhmmnJckM-w=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The famous green pottery in question. These are tiny tagines used for serving salt and pepper mostly.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84g94oa4IhaZZ7MznvUwWX3ly6pHiSAjs3ubdncbcx9P6Pn93bxBQJk24hCUre-OP9uE2d-x3L8vg0-07w1BjZtbK9DD9zc9NHUAd2PSNJ2PKzpv0K9gyVWDQLTDFMyWskaV-sDxrv60BjkUd7KlzwcAQ=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>A demonstration of the manual pottery wheel.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87go7lWdDJ2fjv3M20FT2gSQyQerJ_8WwdCBvkslRRKshCt-owL8GyqAFLKB-B8z8Qpcc7KjJyjuRaWKiwaY4WUUACBReD6LnH_lW9AI1Glxx-aVM69F6C-SVR6BV1Jy9dlrlil0z1CSttTj4W9OsC7XQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>A quick lunch stop over at an Ecolodge in a very small village along the road - Oulad Otmane. </p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85KrDvDQF7rDx3j2jO9u4NhFy5oN95i3ydMrtAlXgNuuOEmN3XBS8zeZVXZfQda-mWRFGTPnv7uJipUy8nYDwsRNmIeT4x00e4bxx32P_RBDXSwCwvWYgz_RI-ifJCx2cA81NP_a7pzJFmJfdU698pzTQ=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Arrived in Ouzazarte! This was golden hour on the terrace at our hostel.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84OcpzeNyl-DXEm4ZPO5EweSrPG8eluRKJrB3-onjC-3o4McbIsPtySPXJntMCPqMhNYxIo_3N5yA6E7xBajwSubJFWQkwhdhfNBWNVF-V76GYY4q8gf-ItprbxtcY5RlmclXWDED1vCPfywJgDr2Ehlw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Finding an egg chair where ever she goes.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86P51yCq5Q3ORnzDLKEKm2RZj0CS1Oob6f7xaH1n1RTZgJsJu6e3PnSyq3wBLH3wChgxjABqF97TfqRbGF56wrxxyDTtgIEtWotW8Xf_aUItAWgDx79X634LkQiJneJB4UbDJjrnR8GpUV4uvXFsRFmjQ=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Enjoying the sunset from the hostel's terrace in the centre of the medina.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87Pi5mJ3c-UZMX5duTRcklOuZxJ_fu36jB_IZDvmZAQZwPshqg-3QJkeeQWLwmv8DcY-EO-y-r3GAFZweMFbzhqidIDPRzBNnT-B5DcjtJZQfT827DgwHnzG4FUTwDidyWPc2v65qvTm8loz6lVuxb39w=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Next day and we're off! The draw card of Ouzazarte is the Atlas Studios where they've filmed a bunch of movies like Gladiator, The Jewel of the Nile, Aladdan, Asterix and Obelisk and so many more neither of us had ever heard of. We were taken on a walking tour through some of the sets so here is James sitting in the throne from the Mummy.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84B3opn8D3OsFnbkK1hfpJwaiumQjzboYojKP6LMSaWMvLcjcenUkTGgPQawcd4jETJ0HJBVTApDRy654QqgrVUEqpOZwiqBkyuJZMERnL2ygUM2Hdn8zQMcFhHzRmnfqnDjmg99FW3H6R7yhG21p8dIw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>And Ashlyn in a cage from the Gladiator. Ha ha </p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86zqSYlqXE4uw6LpkJ7JScBwsJ8vi5a4-xzwaFLLRBq1AipBFD5hrM8Gs_yt_syiPp8cDRTi4NetyPP_mUxcDUm7FJE1i7v1Jwc5wKJhr_64bZY0MQCpRrjEU4h1iMwj4EsNiMTqw_ShAb71iHLo8h8Gg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Quick stop to Ben Ait Haddou, the UNESCO World Heritage Site. It is most famously the filming location of some Game of Thrones stuff, and now the place where Ashlyn was shouted at and followed by a man wanting to scam us out of a whole $4 non-existent entrance fee. No worries, we didn't let it ruin our stop. </p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85_tMNvMnemtBgrRqJIBFOz3vLuTlPb75-EixyQ_PVAQ-ChLc8PJ7njzrarIr_ves6_PYHi0UmABPfXllIZrYcTx1xV_XoSSHGy5_Isa7uQ7enH1JGc1f67IyRbeZzFPh7P_93nzf3kk5QLjhYRc_7Bfg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>View from the top of the Ben Ait Haddou Kasbah.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85Zy1uaVcWJf7BmsX-ucBJMFw95L6WD-6ciMfKJIKmAgv-6-jSQRZ6KUJnKy33Y7ZXGGZeUocSe-mbpvbeaoYD0WHhWdHCGr2PbBSGvGJF5bhYNkubCzCnBtSByc-WBC5XQyNUeuF94r0mfSu7CF8uRqQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>For lunch we stopped in on a women's collective restaurant which was very cute and the food was very yummy. And as always there were cats.</p>
            </div>
          </div>
        );
      case 'Marrakech Again':
        return (
          <div className={styles.imageBlock}>
            <div className={styles.writingCard}>
              <h2>Marrakech Round 2</h2>
              <p>Round 2 here we go. James has got the knack of directing us around the medina. Interestingly, google maps is effectively useless here - most of the roads are missing, join incorrectly or head in completely different directions.</p>
              <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87bNO6W7FSKMXUxHNc9JS9vOEMj4ZBbX3kWUbLVNqDAd7vuUi_E2a2vyp4-fZC8yF_xyRtu2QLXrC_3a8C4neCHUINtRYNZB9p1_MGUJBuVqEhkpjHOdm0AIUvCGZplBMncccy7lMLK-MF6j9T6KTGpfA=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>And we're back to the chaos of the Marrakech medina.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85wl4z45yrQdWpfyUlmVu2-HI75YjEEjvPIv9qtIl4s2PYTfvvoAjUEiMIYwL5jCLjSrsgISbJChCqf_qpqk1Hl9ezJV9RvHZeNuFHF_CAFdUVb9NUfC1i0Phcs9l2VVokmpgc9BJQAH3KL8KWWEKtZeQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>First time around, we didn't brave the market food stalls but on round 2 we were feeling confident. This didn't last long as we got smooth talked into ordering more food than we could possibly ever eat. Yum though - you win some you lose some and today, the market salesman won.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84qUVzfjn5X5BbTzXHhfb7GHbbYWdx8mDGsbGo0tIS0wqjgLKlUvu_OUI5e_oUaO9eueVmwWpmHW8B1boggvrOOOZ8U5oITMSaEoBql4svjmak-DT5J_tj7QQu0Ub9-PpeeqhhEoyBEdGEqobGpwsK9ng=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Waking up the following morning it became very quickly apparent Ashlyn was getting quite sick. Uh oh. <br></br><br></br>BUT look how nice the rooftop terrace is at the hostel! They serve you a full cooked berber style breakfast up here for about $4, complete with mint tea of course.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84b97o1GA59W6kNmY-7r4k9E_S9P6bz88Nj7SkJdHaESlrfNkxrsFLGMOy_FQULTBz0PSpcVl_3y9IP4--JBVTzp25fbeYkZgA0Jry8GqNmSnV6Zpm7YVT0BFLY_x8CKVtcSdYkNDvg39_HzFktvj7DEQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Not to worry, we found a courtyard tea house in Le Jardin Secret - a beautiful and serene garden riad and ordered the largest mint tea they had on offer.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV873p81H2WtVbKkbndLum8w4xIY4rQm8JfPzyqT_Dpb119XgqSpt_3lp3NQ7AGs0U4lDFwYrgEJBXbVy2WOu3oZAxDKUY83QPbM_FGVITBTePykfNGjIMqMNx1us0V5bYAp2E33dqFQCDKD46XE15X355w=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Wandering around the Jardin Secret.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84G72Ahyq6v3Xrcrl7oh8kOJPDgfOm4fFeX2TYZJSYFfN2ElToFHhnrWBWLqC_e5z4Wk165fjdQxfiDu5j7-mSqfrDukHh7PjKafsYqMEUwWs6_8PEjoVLsq9v6DM9lbvrW1W7wrv8CfYdhRb6a3SuwkA=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>This particular garden has an ancient watering system, bringing water down from the Atlas mountains and distributing through the garden - pretty cool little bit of tech.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86W9cQ0O4kh6aZFDSLEgcU-qRn3MVJd2oV92WpxaXc-Mpn6FQdCtI31WkGHvcwAzgSojBOVqZDfitm-7bN2IQbSn4_zZblI4HOlNE0Ifpk7I7TBMFJfOyUrTlV2nl3T7KMK3l6mB9rjL4QnYFTjCq0zZA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Marrakech is fully of ornate doorways and cute arches.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86USDmNG2qKlys7nPlhtkKOEPsbs4vdwOBqC1U6uwuaUjvy1jX6kqP4t35X57KnhsUEr1I1sNnM0zJQWqS1ucC5VVMzhGwxtEkjgGWj5hguX70YWapx2ew0DOA_-oPn3v8Tzc8LvXJ7XcaS1FMfseN47A=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Interestingly, the Moroccan government has set up a regulated artisan's market, where the prices are set and haggling is not allowed. Being a much less stressful experience than shopping in the souks, we picked up a couple little souvineers and enjoyed the quiet. </p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86A3kfaM_yZiK5QGfStabJye1PLLkXh42Cl9oaANpOEbGaad49ykQGmjh5cUa8lYfyD2pKkWydcXa0Epfkh7OjVVSOXZK4vsu12vOiY9KKja0H-hBzYCLKZr1PdVh0LpV5-2LqUnZipDKIpxtT1Hh-TAA=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The not-so-exciting logistics of long term travel have reached us - washing. We dropped the dirty clothes bag in, paid our money and headed out for the day. On return we discovered that bizzarely, they hung our clothes to dry over the banister in the centre of the hostel - underwear and all for all to enjoy. </p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85J9tRpKH80EUa_rRK4Z7u0z0HuBbAzmfc1wy9bUH-OC8T0BXmx9BQTYme7qLUI7smhfdZXYrmagae-qZXgyMP76_2LPsQEY_SaGQxv2MFcfajZHp6KeJrgzQz1SABMUTfck1Ih9TwThOXPjPr9ctEnDA=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The resident cat at the Mosaic Hostel enjoying an afternoon nap.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86L63eTIC7aNXJ0omVXea9b-HSR0wTrdzQ1cPzsEphFaq8Rmo3wWidDYbldt-2iyd-ilynOOxbjSOe-ybdDHkjakprYusmKuZ0ANiBb3Ja9hbxrziwOVIaYKJys3doDshUW55r6YT7QvSf7qbF7M2AACg=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Although it feels like the Medina's "streets" are too narrow to fit all the people, motorbikes will come zooming past at great speed. Somehow we've seen zero collisions, only a few older Moroccan ladies shouting at the bikes as they zoom off.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV843ARP4CUQvdY6u6ckXEhh3_tGEr1jwEniC5VGexjNfRnFmImzA9kR_GfXWsYw9nWs7y3OQEBkfMAKNtE-ohY5-vRFjKgeKPkCCiIPWbqiJZSLSUMgAdjCJfV5PZFxqmvVXqVuIob0nys5Y6Zqg0Z0RYQ=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>A turtle friend in the garden's pond.</p>
            </div>
          </div>
        );
      case 'Essaouira':
        return (
          <div className={styles.imageBlock}>
            <div className={styles.writingCard}>
              <h2>Essaouira</h2>
              <p>Essaouira is a cutesy sea-side town a few hours west of Marrakech. The calmness and the cutesy blue streets were a stark contract to what we'd experienced in Marrakech - it was a lovely relief.<br></br><br></br>This little town seemed to attract an interesting and particular clientelle though - there were a lot of white people with dreadlocks and pants they bought in Thailand, hullahopping in the streets. Good for us though - this meant there was heaps of vegan food.</p>
              <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84J_Y7lZ6L5EqHwCvVxq1p0Pmt7h9O5xnaqyxG-x7T4r293zzMVO3B82NcWE3-xQO4apLB--uRGpdZd6ICCIV0p8b3u1SqGxCQOEwri1qaAFhVJ3mocnmhKL2nquG2d81qqsdTwVX-xjfPoyhLKpHx5AA=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Making our way downtown.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV875DoYK7Oo025BkWFEKR_UYJMn5DjpAwYT469PfyiDO4tQ6L-y8cz_vwUR_G4ZDpnWTX3BMjsLzROqXhBUhUqeNbGi5y_-2go-qtrCp_gyyYS7NDP94F6UIVQjeVCOHppz-MJhacrxxql4kRAmIpIHiHg=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Now this may not seem exciting but this was the first time we found a chickpea tagine! <br></br><br></br>Our beloved Moroccan cookbooks had us convinced we'd be eating all sorts of chickpea, eggplant and lentil based tagines with preserved lemon and feta, only to be stuck with "seasonal vegatable tagine" - currently carrot, zucchini and potato.<br></br>This chickpea tagine was not only exciting but very very yummy too.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84RBu7v2vzdJ4B9CD8S8PR0oF6C6o0yDl3HMCEHh9IMrES8OvkzJr3JzHg4Uy8T_K2a1XCMfKYn4NV6mVuwb2PZ0olCxdiVzhvByxUSiWhBLX1Idf6vwVakpGQkf_C7FPwdIZLVufN6vAc9DAwdjeVdtA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Essouaira's medina streets were much wider than Marrakech's, painted white with cute little blue accents.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87qgNtFQg7acP1x68NR04NnZ56WHn1cNfEGNysv0I7_kLq9oyEyR7SBuaitpa_HluKNqbSYqFK4NBdQOXIpAUOseldJvBrysatioFFasQ_DdpFh-UujmP-SVZYG502J7sRSCNtTa8FST-8lY-PNwQj7bg=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Some market stalls and a sun-baking cat.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86lQPqzJMrgLwfZDDJQ-og44DE0mYb4qkzsF5hwXGDI4EyZhF9W_BWpKyQKNVxDA7E3YcC71Y_noYrNcTs8J8P4YfkhyNhHqXVCGc1VzGeaU-EzAurVRd8T35LiKv8ICtoNgpXUfgJ_EpPV15AqIwlYlw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Essouaira's city walls are built right on the ocean so theres a lovely breeze as you look out.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84zAe9qX2iCpLBltlIhYMbxCr_iuPPDONwmX_abPt0MTjhBG8Jcu6NBEYlNqVkbyPZS8bbmtq6TB6Fsgc56MlmT2mmgRzJFxAG1asnpVK3IBgXq-koGWFSLEfN1-cHLne4VKEdmwv2Du11nPsUxWRH3qg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Another cat enjoying a comfy looking moroccan rug. </p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85nct-Q11NGz8BU4pQdRNxEwpqbSuV0OpVSuF33-hJ9w7-axMrsPMam3rTEB0NT7DMAxn4lI0eoMco2IlPcoi6pTqIU7bwKaVnzOp-LCBr2izSph4aJIRcfKgXEl5NjEsQvb94soS-OdnTl4YKo1nHDXQ=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The number 1 reccomended activity in Essouaira is to find yourself a rooftop and watch the sunset with the backdrop of live music. So we did as we were told of course.</p>
            </div>
          </div>
        );
      case 'El Jadida':
        return (
          <div className={styles.imageBlock}>
            <div className={styles.writingCard}>
              <h2>El Jadida</h2>
              <p>El Jadida was perhaps an odd choice of a stop it turns out. The main drawcard is meant to be the Unesco-protected 16th Century Cite Portugaise, however we discovered most of this has been closed for supposed renovations for years with no opening date known. <br></br> It also turns out, the locals of El Jadida were not expecting us to be there whatsoever. What we now know is, El Jadida is a popular holiday destination for Moroccans and a very unideal place for women. Ashlyn got a lot of hostile looks, most restaurants and all bars are off limits to women and we weren't made to feel very welcome.<br></br>We wandered along the city walls, ate a tagine on a rooftop and called it a day.</p>
              <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86wnlQYZmSYW0Jx_BjkE_LEidmpuI10EWpd9z_gJI2SW8j3RW70PddGPSRttNf6uvUI4Ulv8aE80PEb600TVHzUwhMNqEQjLYHsRa2nqZUxM0LNGFJHOUBAEXwcWJtVMfQVMKqLtiANhecNipFrm0xbWg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We went for a stroll along the city walls that line the portuguse city.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86x9dmjUZ9v57cbBBVr1UMiWHlKjSlqOjfWBJ1DzUhSc7BS924eShbT6s-gVI4axZnkRb9Aj3hvvlOQxYwn7Ftc5fkNmWb8hQfuvoms7zEyYSc8v2mR4B6ypviDbUK6PHXC7jnmvQrhE58q1MttumOBlw=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>This little guy had the same idea.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85KTKUbGREOxA7FHirxoIoOhq2gsj7G2u5mBocY-xvwVzshdXeBOZQT-r0LqEb2oN9bjN7iZs1jgVS-XEhqH9DyxdVOSX70NeibZWwAkKFubx4YipytV8vQKmQcZdwyWB7KDOsSp5wYiGXOXn4GXxmfHw=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>This guy. But from a distance this time.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84fA1cH3_7sNycpusyGh2zb7JZe5Jt2fnfx36046IDxdbRAZSPNk0D8IxRPXf0MIVU33BlX_-QGITYEhqSZoKptF6J9A-WJO-oveMndjlMrx_M0iGCzJzIdfECBWSjVrmsEaITJIyJcFGscBFJQ30TwkA=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>This man looks lost.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86WEX5wPxL6b6_r-N9UlgXR0Vw_NuSALeqsU2CxP-iKnQEafL7JSYXifxPgiwyoCRUY4AdxE9POl-5hv7haNT3sdF5JVL-u7kdpbO9MGV1SL78hcdMMywNDy7z4zLL7HoqDd27n5Go5RgReF58WS_FRBA=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>One of the gates that lead you up to the walls.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV875YtOZjuStTLkw11h3_bSbPBozs-nfoaxtW0bvBAOTEZmHuDvuHO413jD1LZWVDDMhNThmdQNUOHikw4JZcNt1LZS_UKFBimrBMDESv5vc6e-Iqs8K-LMNH5QFmIZYu78gtKW6H8aEaqTKjYexSSKiRw=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>A fisherman popped out of this tiny door in the sea walls.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84o7cSo-SGZ3xQ9x7rTtsCB369XPrzlqh9_3Wr8sgWbYaL8C9My_f-2QHf4CoPcHmjah9KHiF97_NXKMja4ZTGqYPKiyiOajQ34uSUFMubyM8jixdCtOQTmed4svBQUpvOvgLwNTJzwYs1lwMaMroPjGA=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Streets of the Portuguese city medina.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85eCaxK-lG3G6m-u4bnzOnN0soVlURpPCGRbFEUqBPH--ebe9_xWzKPIJAcSTAp5EYWxsxUi43wJKceEM6LJYFmobVEWhb4ScDC1XX6uVKZdbsNjkbnvJBZ6soIegb9NuZ2SFfk-qkNeQhB9tFLPE515Q=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The city walls jutting right out over the sea.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87QuyVZN8Q0QpXTEKVxcRtJtmzkalSnJtr055WcmlkZFfFR1N-_XFsL1Vh3V1mIF4h2TScR2qoQvqma8jmdAjK1jdpw-yk0w91pac7jLlKJ2HC916JAMfV5xpHchAZcPHDQ0ZiTo3NJibgULQFP1KqtZw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>so many birds?! Rooftop dinner at Le Lokal.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85uzsPF5k25R4n9vvMnTjcDCMjX0l1GK3IS-HdMVhrJ2fJvi3z3TrUssc59vcrpaR5VRZw2fdJflEf1k-7rvtQe5OHBalYFNVV_KY3-3UMa3I5yPDBsdbkb1HfMNgU0ITzhPQ7sEfrukZDsFbyA_EHTdg=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>You guessed it - another cat.</p>
            </div>
          </div>
        );
      case 'Casablanca':
      return (
        <div className={styles.imageBlock}>
          <div className={styles.writingCard}>
            <h2>Casablanca</h2>
            <p>It was only a quick 1 night stop in Casablanca as we'd been warned there wasn't too much to do here. We found some gems though:</p>
            <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
          </div>
          <div className={styles.imgBox}>
            <img src="https://lh3.googleusercontent.com/pw/ABLVV86p1MaMxRBqANlb-60Y9WnVeHYOY7xinq-ds67MsyCAwSxX14gZe5Rv4CeK_hN31pnlwK50Om82bSHlGHAU5RYj2kBqHysB_53ZC3DzWl6-yUjGl-FRSj5I1P_H2stQc8F2saDOrkyC9EYfjhNgwr-OQQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
            <p className={styles.imgText}>Welcome to Casablanca!.</p>
          </div>
          <div className={styles.imgBox}>
            <img src="https://lh3.googleusercontent.com/pw/ABLVV84ycF1OtrBIgB_w9Yoj5Prhlxf3RptNVoNryAmU0QDsbyOmk3UiNjO-0Vwmjhf_ZwI9HXHmAdFM9oTIhsbxP1Jyh85cozIfqF8G_w320DvY6p_iln8PB-QMlD75jtTK-htMn0Bk9-PU1ZmJ3KBR4HCDmA=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
            <p className={styles.imgText}>A tagine lunch at la Sqala - a very fancy little garden restaurant.</p>
          </div>
          <div className={styles.imgBox}>
            <img src="https://lh3.googleusercontent.com/pw/ABLVV86XSidWa4gzIsMEhNwNzhRA1xh7ILyt-Hlo2VrNofXJDCQFIdGoa2AmR0UG6oYADNywgwgL96sOBrwMjyHuTIvDyJGaqfA4Y_p1HUvU0h7n_HTZyOdHmohEYV7Y1z-9F2yRRMO-Uuda9TwryTC6SVlWdw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
            <p className={styles.imgText}>The unassuming entrance to La Sqala.</p>
          </div>
          <div className={styles.imgBox}>
            <img src="https://lh3.googleusercontent.com/pw/ABLVV843_38j33vVArxnP5veq7HylZB6HXBIVMc5RtL0rm9BwPQG1NrmcAd3FiIs5g-aGTK3LwEfGlElt5ObGqAUhtj5F3t2eRyV6WKJukK-Fl0p40S9nuWXuHG1GPAc0VwTr9wClpLOJlPd4HCJoFqOVkJ40Q=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
            <p className={styles.imgText}>We checked into our airbnb with the largest formal entertaining space.</p>
          </div>
          <div className={styles.imgBox}>
            <img src="https://lh3.googleusercontent.com/pw/ABLVV849kDApQDHD7MnpZgly5boMXaSChfYJ6rKUIWCOefNx1A2a2mN4DKt3-2dEC3EpHuEVwVb_921Jv8OiwDXrLtKYL9L8EWluDA8LxtyNXdZm-GIkSG9k4c--cS0saSVxINScYTJyKnGkPkhuM1bULcAo1Q=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
            <p className={styles.imgText}>The small medina street our airbnb was on.</p>
          </div>
          <div className={styles.imgBox}>
            <img src="https://lh3.googleusercontent.com/pw/ABLVV85IlGzaIIaNNts9QScMx1_i2BwY-H84mAY5-dkNaQId_vTP3b0PXlp3-55eJiVb0Rrd9VFnZV7hOYDyu1l0dvZuduAibpdsGwDNMIo4mID8sB_Fn4FINRPtsEKVsZjfXC1zReTRx_wRffO1Kh4ThA9i8Q=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
            <p className={styles.imgText}>Wandering and exploring the medina.</p>
          </div>
          <div className={styles.imgBox}>
            <img src="https://lh3.googleusercontent.com/pw/ABLVV84qAQCMfuNprkU3pIIa0P2S4a1ZtXT97GouLbJTOQA6asBbS8Iri1dZxxwq0e_baxEf6jesQ6Y7SDftIhZZkxN2JJAF05lOcpqVVQZnBVDughXHR1hMHgcM9ANi-f_tQcJIbgm1WQVUszOht1P4tWCOpA=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
            <p className={styles.imgText}>The local market and square for the residents of the medina. These are located on the outer edges of the medina so that trucks and cars are able to deliver. People then buy supplies from here to bring to their smaller stores in the inner medina which are inaccessible by car.</p>
          </div>
          <div className={styles.imgBox}>
            <img src="https://lh3.googleusercontent.com/pw/ABLVV84YY7kb4KosrevsvvBmRHTEv83rM6_uTzxQHeLqu1GSXG-uQQQXkozuoQBN-YwSWmi1ebelRY4nGBEusowuw5zteQIZtveJ26SCCQ6HWTH4xrpbJIH_qZHBjrNrwdAxzEXqI-_dsnxTeadpzBgsA29JnA=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
            <p className={styles.imgText}>More medina wanders.</p>
          </div>
          <div className={styles.imgBox}>
            <img src="https://lh3.googleusercontent.com/pw/ABLVV87Y3yiXTc0yLECcpLmHPVfk7ODUUJ6WVV_Ol7_MefDmUA_EKGJ2O7rugymj138jNNWG0XJU2DT0lChyioxv3tdJKGJOmAqLj07yBgSy5h3eqfmZFkUSD4z6oHNzw2Eke5_eBZ-yW_A5PLQmKyt9WS70IQ=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
            <p className={styles.imgText}>The guidebooks were correct that theres not a tonne to see in Casablanca so we thought we'd watch the movie and see what the fuss was about.</p>
          </div>
          <div className={styles.imgBox}>
            <img src="https://lh3.googleusercontent.com/pw/ABLVV86VQpYh1f_NB00C02CiSiLF8RSkYbkDUSDgebwS_v2yi3S2KpInSTKc46w_dKRO9d0rHSf6zGYdHI4LSwbtDkN1TiGqL1ghKcMNZM4GwdXY3Cw6k24cto-xeMVftDuU3iIRplUB1HaxaiKu-4xVQKr3Bw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
            <p className={styles.imgText}>The big attraction in Casablanca is the Hassan II mosque, being one of the only mosques in Morocco that non-muslims are allowed to enter, so off we trotted the next morning.</p>
          </div>
          <div className={styles.imgBox}>
            <img src="https://lh3.googleusercontent.com/pw/ABLVV87AmNxubQSNn0X7kqIDCqDD7SyNHNC9Q7SPSQkpbrdQVBeY5JQW_0F67eHJCuhiUj6A2V5WLe39UYC9zzdmxxJ4_fEgWjpccM-PHXdv5UY1NwIMYjXd1derUdyFtqo8PTnCbe3GTdYzwKgiOcFNRE24Eg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
            <p className={styles.imgText}>Inside the main hall of the mosque - we followed a tour guide around who explained that most of the mosque is built from Moroccan materials and by local artisans in Marrakech and the Atlas Mountains.</p>
          </div>
          <div className={styles.imgBox}>
            <img src="https://lh3.googleusercontent.com/pw/ABLVV85goBjwzQOLmdyzdEUUvyuz8taZgmle193mw9y_yFfmCoxhoSFPszwnZ7xkdhZeiMnng--OqT9evmE9isr3MI-vcP4Jq-op7VTKUruXfkI6pXbUYe12c9yW3R5MhiyoZ0WdnWeC5fn-dmFfNr21MZMXiw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
            <p className={styles.imgText}>The men's ablution hall in the basement of the mosque - for purifying before praying upstairs.</p>
          </div>
          <div className={styles.imgBox}>
            <img src="https://lh3.googleusercontent.com/pw/ABLVV84SGJ73veEGtmz0KXqrmFvNfAoCJQZG3KKbsHMTZUiKZQC_RQnrnFZK9Gv-Qw9uQNtJhd9DZMX-ku8YyEZe10Le_UqegmzH4olHeSlfjJlTK9uVEHaYa5ithv40T4JGx8uTWC0q2ERpE4hw8VDDwiGT_w=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
            <p className={styles.imgText}>The mosque's minaret - apparently the world's second tallest at 210m.</p>
          </div>
        </div>
      );
      case 'Meknes':
        return (
          <div className={styles.imageBlock}>
            <div className={styles.writingCard}>
              <h2>Meknes</h2>
              <p>Nearby to Fes, we spent a night in Meknes, once a capital city of Morocco under Sultan Moulay Ismail.<br></br>Unfortunately, almost all of the key historical sites are under renovation (it was expected they'd be completed by the time we arrived but no such luck).<br></br><br></br>The medina hilariously was full of hype-beast sneaker head shops and fake designer clothes.</p>
              <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85prnWOctUnpKlC_DW65wzYDDiX76w8DByjbmJAPbAchBzAWbUrMQl2ip7KRRxdejugkx3DcNddixFsVpQICqfFcyAisDX8pCGbsgBpzmpwKRJQhJexB2yIELYTGNw_O89gJ4Gn0oHNcU7M-v-O-thAvQ=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Stop 1 at our riad was a cup of tea as always. This riad was the most chaotic maze of staircases, hidden doors and secret terraces - it took quite a few tries for us to even find our room.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV8609O5QoQWCEhuf60X7fQ2PulZjZW9uw8fXMW4MZeuqkqZQSpgE6kc4hc6vYFYLdj-QNnWXrOvv_3nrUoEk7o_GT-XtnvjuVldrpEujeEfNOCqFiwgGNl96KcTTg2EHQS-rOlg18iRsXe9NvlbvWPJg1A=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The Dar Jamai museum was located inside some fancy old man's riad, converted into a museum dedicated to Moroccan arts - music and musical instruments, jewellery and clothing.<br></br><br></br>There were little speakers playing music from different ethnic groups of Northern Africa along with videos of the people from those groups in traditional wear using their traditional instruments.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85w2v7YW7d-v16k5XyEEOHZVdhRnafrXui70pyRQUKX7adMRHm_H5rj5CVbGXO1V7Cg3MSJ6kr9BKnLyQPBHOUzJvsvParENu4-wlRtgex0wHG3ag6a5FvfGGBVt86h2yn4nifKXcSjfU_FFKQIOKYvqQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Ashlyn pondering the exhibition walking you through the traditional way of making the instruments that pre-date the guitar, oboe and flute.<br></br></p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87HZ4WaOhlA_XkieqWsw5gTyOg54ImAITMAOzqKnbRS-xGZRSVRqWUnGCs9YGnXXuPUEexM46AlMC5bp9HsqcqA6auuj2pHfLqIG8dtN5RFNPwy88KRS4wGMA-VxsCMXMYy_siNUx6KiMVPhsAWDYA32A=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>This is the centre courtyard each of the sections of the museum came off.<br></br></p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87lENUFWB8JJEgK655OEE86HK8LpoBtiwROt-xlQ98WWGVsoKD9_7vE_XDHJhiCeI9EekEiCJhs-4L80mJB60H7yzQx4oJ0jPkLemP9_uwsw57mU2GDdlADvMsDIYEMvb0VqThcajjJKRm0IG4POk3D8w=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Beautfully spotlit ancient instruments leading up to the modern cello!<br></br></p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85u293E_7htMnQnAjJ7s82C-NVxxsSwxBYqGaTiRGQj6Nc5oprYoDmUrFD1OT3C_tMAxi8rTpj3KXJd0kXxETIwEz2JDt4TGJujNfaTDxFOJY0NmK1HJfrUuZnClfqsJzLbYWfZoA7QPyrGELYlgdLQkg=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>These were the gardens at the centre of the museum/riad situation.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87d713GBaR5IuePq7aU8IGr1psknbM5nYFoxtdyNC2Dq9oO3pn51ngoepPAsc_a2_EISXX459YM0lb4dohONvAP8b5GdmjyUnlnRV_j6teBstzqhHKHnv1-gwkdSDy8peSk0O8zthYhGxhrTSgAfrtkiA=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Complete with roses, beautiful handmade mosaics and banana trees.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84jTQ44ZMruCHdXAlS_JF9I-blUMxZ8wcDwhvbsIE4hJ9FQSJlrCXcxx0tzklabwLRR7o6tEL48URq_QRAXWbrc30rT0F4G0alnwnNe9vzLox05p-i0iCKjMu9eyvc3mVTyEbQ9B_r3TDfXFyMA_UDFYQ=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The gates that take you into the Meknes medina.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV869BBeFZmIGevugHfXE8xG_gVymBW7bBl6opKJqf7_JK1y91SOlkvdU5SAShTUB3jYpDdDVR4GVK0nDPljLPYJtVg-dBhAU_0lPCoQ6DcqaZxfIopaY4iJ9erYlUBwL_k6Rdym4O43dg9AYNAt-m6lSwg=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The grand entrance to the Mausoleum of Moulay Ismail.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84gkM7FQSXs1Z2ovvfnXfA1jzC2PbGpfXbLBLFOT5NoRr8Sm1vsSnJbx09lm28Msx4ehrfLp5zY6J1r1EVJW49U-7L9zy_t-GJuVsgBwGAJBEbJUOaAjCNURGlC96Z86cDST95lbgMidRRxrQT95uXv-g=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We took a mint tea break up on a rooftop, looking down on a peaceful square just outside the medina.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85CfEUQLxJzBBe_GzV5U35bXdg8ZdzHspmjc81xlEYKr4tH1Y9X2LEvBMNV7AZSUkxoBPOlyzEOFGiHoy26MusIC6vgDDEOyXMwFuLNrsNSSyPVGzmViKIqluP2-KpmBLdpjHZ-yMmRb2LKYDa2DK4DYA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Volubilis day! We organised to have a driver take us out to Volubilis - a partially excavated Roman-Berber city, and to Moulay Idriss Zerhoun - more on that later.<br></br><br></br>This is the remains of the town centre and Basilica. We were told at great length about the animals that were sacrificed here.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85ECOYlrkQL17-M6w2Q1uRoO5c61MHDIpbMYDA7w4ziEVWGWMExWrjb2f0XwI3R2ORUER7gEhuFsb60iv6eCA9Qw6FPXaxhsVvj3Ca24nIzZeR5DsDt00mpOyr8FlomOedaFEp8EQ5o74nQknr2b5hjDw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>These were the stairs of said animal sacrifices and public announcements.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87nFLhCxhl235AsURolVy5X2aeWh85Jg0te_EeHaN87CA0NrIfJsvoF1rrzz_vcFwuxpwS1pF0QcJDKtr-SYwOi1t6htZOcOhZlGXLA35ZP9lzK7V_3qXLfcSfWBk-pFYdWMaN24mVX4f-v-PsjOLqM7w=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Our guide told us there used to be a statue here and when we asked "oh did the earthquake take it?" he said "no, its in the Louvre".</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84QqCZ4CDsd-JykX0QYUipA8z0gprnmBMK-Yvy88seAQd6MY25x2clJ0bkQcH8jn2ZAdEJ-T5811oPQPxFZacoAx96jfU3eUKol4OPkVzUGRJGkGMYZztSF14jZamGzRsxMqubOuk-rol6j8teF_arY-Q=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>This is our guide - Mohammed, telling us about this intricate mosaic of fisherman, most likely used under the dining table, sort of like a carpet.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87lJ_qD8DuSXapCwAhziludAuUpiZYqm4t0baLVy2uYK4oLMFTmytrPVq2Gte4cYcsuDdXWPfMC7mChSbgJoaSQxqsYyrhTT6yXYN67y0ibuvEND8lCTv96EC9x1zK8k1nBMR_ESa2x9oOTCU3G_CD6Wg=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We braved harsh winds and a lot of rain here. Fortunately it meant we effectively had the place to ourselves.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87OPC8MPxoOEE6doLrc1IxhR0HS8duisQD0AUxda0dp3NHgGdtYDryoRDl-azGY1BUPxpgGJuUiamqS_Ca_pTRGt7y0KJccKzhoZyFpiR5gvWrRl6eH7SCTqE6IuSG-ulGW_4xaURH3XrWVfOkSrrShyw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>This mosaic floor is amazingly still intact after 2300 ish years, also surviving an earthquake which destroyed much of the city.<br></br><br></br>This one depicts an acrobat riding a donkey backwards, of course.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV846tgenl2CYJg0Azt5uYcbMv_peuBhExosFpbYzS8x4FnqtGHeosD1G9K20gjo1ts5gZRVw27WGO7MyIOioD6yBg8O0YYV8uWw-p91LYci13P9MSvXlC2V2gQix2OB5cXTSqgjXec8YAXfEdA28DHS1PA=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Fully committed to his job, Mohammed demonstrated how they would have used the massage parlour and solarium in the centre courtyard of some rich old royals.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87WeVOFAF-JjX_Q6XEOTBYOmyq71q46jsuSUbAeNWJMvHPShsDQhXJ8wxU460RRc-pixV3OtH_gVlN8t6gEg2Xmk3IoFBRavT3ZRXS4uQ7aPUplOxB7bbhHcAKpV8yXkqyU504QhChtNp2oHp_jzko9PA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The gate to enter Volubilis, also somehow still intact. Archeologists have partially repaired the latin inscription along the top however the rest remains the original.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV875EJwy6-puFuhNFsl4qu2RNxZgto_9j5OflDKUOV2Fgy0SngZzG72PMM7WaPL_1VHm4UJ_NLKxQp-C4qGU9aS2NKnEhpmhIrQa_TE_EOc_ATqBIxzcLyTPf-wIMwtBZciKcY7_2aeal6mkM_Wnz6Gu8A=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>This is apparently the most intricate column in all of Volubilis. The centre was a water catchment.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84AdbyzsV-vUYd0IZW7KoTpEJ4Oxbh8OUD9Gu_mpbyrnEn7JCSwfsH62-VzElYVKnHvzZL7nP-Uo4AHZsHa21HcwlE0MJ0r9ZE5PpmQGBGLDObWo45Z9xkHmkzgBW93G4y5HgC9lXnUO25j_BUj5jzWqA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>This mosaic floor depicts the feats of Hercules.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86BLvYblMs0ESurr8Y8lJdUYRrxklZGR_75IlYtnDx7PBLcOoSzKmQ6ZYYPoNq4q4o5TJv17BTnDvyY-xyhLjvjz1ei6LUhTGMvlPh5DR0rhtjOJjsRGGTZDakk0TySqW7tHdEXpM_3LEiiPs-Zut58ww=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>A design of the seasons.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85BHPGgKx8e5vMoPxo80p7EEYDnCUI0RtRbQY8vK0bfRaInpeouMp9tN6VtCTaPN9eOxFET9WVUR6A8GMs5r5evm82_YtGIlw1eOAXUxn-xd3ULdk7w4y_H9iG63SjmM8wvYuSXSxvfborBtZhO6EluIw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We jumped back in the car and drove about 20 minutes to Moulay Idriss Zerhoun - a holy city, seen as the second holiest after Mecca in Islam. Non-muslims have only been allowed into the city at all since 2005.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84xO0EbLEx2fgF0nf54j23YpLAXpN8RaMY00tPxAdCW007WDYqYte8DE9O7XC97J2iFBscvQ3GlNJnOOT-CF8zJgeCTwxxKpDf3oetvleiMWI3Y-ZClYIaLocy8ImsMsbqH6Z89FW3vRzT97QuoW6Ff0A=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>This is the main square, normally pumping with people we're told. The rain forced everyone inside today it seems.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85ykM7zRbZ1sBVo8Xzd43dbqoBsEPuXj9leaaDER3K_gV7eiWJHAECqeb1eekiwY9UD0kkkURZJkbnjL_LkeaGR_S59p72d4Qbrfg2-ilmCr5QEq6IJ_pX2OrdgfWYCBQuHPYOWYNbmmbC6XY5sPiH7-A=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Back in Meknes, this was just some cute street art around the corner from our accomodation.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86nqq-JFD646qL3tIeyPa4dxlaKrihWtEvVA1xfv4yw5rVF1g3BVk-IoqFQQk1FMHsZRNwbfBgUXVpGRdFZ-fUI7A_eVJz0nWLzRWbDJkzFqc0v-ba8VMlJ3u-YoNE7FbUdivMLuanxbvwgzaaYN24WIw=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>And thats a wrap on Meknes! We jumped on the train drenched from the rain and Ashlyn's jeans caked in mud somehow. Off to Fes we go!.</p>
            </div>
          </div>
        );
      case 'Fes':
        return (
          <div className={styles.imageBlock}>
            <div className={styles.writingCard}>
              <h2>Fes</h2>
              <p>Given we'd found Marrakech such an over-whelming and scammy experience, we were a little worried more chaos was coming our way in Fes BUT it was quite the opposite! Fes' medina was calm and collected with very little hassle from locals for the most part. The streets were smaller and more residential, without the motorbikes zooming past.<br></br><br></br>Up until this point, we had really tried to tackle everything alone. We quickly learnt that its hard to understand the winding medinas and life so decided in Fes we'd bring in some experts. We organised a medina tour through our hostel and a street food tour in outer Fes - and they were both great calls.</p>
              <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84hI54iOUtHTVxJFULuL26Wi6MsddmKPad_Yf2zjrcmD4t5WCA8cFAKpulIJF6WVEZudHHh7V_WVxjcafHMaGRii78NwAW2H2Jb07LuIm-OQzBB7WRD-AkAOK6bvvdnpcqELReX6uj_5dLRsA_6FJIzFg=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Our hostel, Riad Verus, had intensly attentive staff, (one of the men taking quite a liking to James), 6 floors of dorms, rooms, general chaos and a beautiful terrace and communcal area. This is the view 6 floors up from the roof of the sprawling Fes medina. </p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87gmGVboupMrKbo2QIZ8MnjhuWtd45UMkK5nV7tbQI51BCHfxCCVFD_KuXXW29yEI_Q-7sNWIMjzFx7i5S7HvcIWq3wSWYRvSCLA-8zOG4wx_N8nMTqEQhZncF3UyD7r_tgxdMjgEasdW8BjZvRh95MsA=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>This street had one of the local schools and this street art was done by the students.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86mcwxcHS9EFHqF4rAHYCXqznTPcEa34cGtGt7-Rv7I-v2V9sRWnyjjt6toN_-ScQCHvMhwxhf60IlvbFw0pndPBe6HzZMzPxaC7javwvjno_1CWsK-MsTfhsTmKKBus11BhLMrbI36yDa-wAwh_tNg6g=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Fes' medina is walled and open at 14 glamourous gates. This one is the most famous - the Blue Gate.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85vhy7n5WnPd699E_Ujq6KE7zZA0SbU0d8RnJY0KyFWAb3q3IUK_3mkzRNMuK4PYKCw0SbsEeP0x4mlDkzQBwx2HgDgFDDC0swzyhu4xX86V6P84YObGhx8ONRo_RKHqnlVecQwv71Y5bQNtmR2ojeHIA=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>For a confused Lunch/Dinner we heard that a certain restaurant was able to do a vegetarian version of a food we'd been seeing everywhere - a pastilla. Its a moroccan pie of sorts, filled with vermicelli noodles, spices and vegetables. It was truly delicious.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84VNpKtpDAvzVJRJaXrToPRzMl2HSZ-278XsewRtxGckIpZrXeU5sxbvM-scLcSCwZ1R30Kp0DrnG65CusD863hfSjgWX2crZ1afXDi6j_bpsFxJmMoxjldFoTB5oc22opmWIzkhACoBWIvsBWcr92jRQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The medina is a maze that only a local or heavy-maps obsessor (James) can possible navigate. Every corner is covered with signs pointing you to riads, restaurants and anything else that might help you get un-lost. Marrakech didn't seem to believe in signs, so this was a fun change.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84TM2klgfZx9x-25IHnWFhJqMb4J07VugMJIJ-Tdzx97gG5OhAStLybBZGlTFGZbuwsFCJ-5K1PeZRySQ7F4r5DQq_eJs1CXta1dpEEAvdN2iQB2Z_Wi3YOPQf1O3CXZU_2ge7_JXjttpP39YcmEyVRLg=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}> On our medina tour, Mohammed (4th tour guide named Mohammed so far in Morocco) picked us up from the hostel and off we went.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84PMH_yaQGWhfpcrLLHj7zyiJH9W0aA3Fv8NT0cplf2i7RH7PHCcJs8ebwNTCwEX4oRETRlGdHz43oyYXqyXMtb9D-UIkC-P32zxoENHuYFoCShMna6nUr-xsHnQZSLZ6uOEOjM9knnJC46mkWTHRULZQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>More cute street art along the medina streets. Mohammed explained that people are encouraged to decorate the walls but only those close to their houses.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84MlH9c-LymGyUdj_daZEYYU8tJbvaqS1-RmtNCWVZKIpqRFqKloBkpqaBrpf4KdD0mWWkg5IlWvpM1fgxMt1VzqNeQyIVpBQSzeCm1xiX2cVKqB0FBW5yU4ZGaH2_bJ7JE8t2EA3XC7UxIhS-AONkKJA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>A mosque minaret peaking up above the medina streets.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV8755St5D-oiVTmiM3yPMHN3vTVsYvlq1Y0ulXJRwLNbZT9c7Q0tLVXL6zQtC9e5ryXtaIE8IwekG1zXLzn0akRlqE5Ga09hfOhSHP1VFxybt0ykIeNGTLR9vJDTTM6xjO0QkSL6slcoX71_4uZnfIRxPg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We were taken to a look-out point to try and grasp the scale of the medina. Being the biggest in Morocco, Fes' medina is home to more than 150,000 people.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86gQisdXoVD0es5Z_vj1FH0xJGBb5By0CrirfyBdfL44R5W6W75wHhkOFwaWaLLG6tBlSrNq3JTpvMgxUvvoQDLL9UvvJuMl4I6cqWS5fuRMZ8ZAO9nVth4g2sAt6fJV54SphNR6GYPrmLcji7mEeU-4Q=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Next up on our medina tour was an unexpected forced shopping trip of sorts. Here is the Berber carpets and blankets the saleman was desperate for us to buy. He flipped gracefully between "I don't want to pressure you to buy anything" "As long as we are all smiling" and "What price would you pay?".<br></br><br></br>We unskillfully negoatiated our way out of any purchases and were off to our next shop.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87xutf582w9eBciUVYO0Ll5vMS95T34UA8Nlz2qP75HEIfXKzsxzooaqehqnQSFyGe1AwCC7HFI0sW0SgrIyLShpHX2hoAwgQjOy1nzDGk_DTwU7_xfuQCz16swtylM84v9skB0UUE7lJEpEAoKEAdPUQ=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Tannery time! To reach a viewing point for the tanneries, you have to go through one of the leather shops of course. This is the one we had to politely peruse to be allowed up. It was quite an extravagent 3 story adventure, full of everything from handbags, shoes, belts and dog beds.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV852cKNqfFp-qrol9CpCk2qYTV0_Zy2SLv_84fllC6KrHP9a7OCuX7jChnCxWwJZmRFdPLaTrwTnn75fje8aDovNFOVFkm_V8dqc1-V9XhW_KC29hixTZjlynj3CMslJQs3h0iKpPgmnZUSxsn0o7oLa3A=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We were taken up to the rooftop for a view of the workers knee deep in pits of pigeon poop, cow urine, salt and water to soften and prepare the leather.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86hr8VSclpUmp36mTljhWZB5XRX5VYg0JdVJEHxaIyPS-9WitKpr9-Vugif3a7TiYMMugcaI66kO0mXmgYz8-7lVvtPgvJ2VLKPUKmiKQk-a4e9wCIpZ8y0DYu4j2PvD5BmDeXJJZ6YfiyfK7SBqa3RSg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>It looked like absolutely miserable work and I can't even imaginge what the smell would be like being inside the pits. In winter this really doesn't smell too bad but we were given sprigs of mint just in case.</p>
            </div>
            
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85vgGyBeQJuSustVaXOZejCZMLyTND3jL5dbZBAkDW_DeA1XkR2lGk5RBviu-8MCSw_3cPybaN4sZCyumYNlkh4w7BjIQUwWx2jx7sabBwFFwLziOFt9fP5PpLJkAYaTnnhud9Ez9QvVlyT7LSDWiogNg=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Next up on the "shopping trip" was an argan oil and cosmetics shop. The churn on the right is used to press the argan into a paste which is then separated into oil.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87Ux4ZGYEYFOCp8PpfNq7z0AXN9TqNH7dNQrBnjXF3kiBJq-H4otOTHTco88CgrR8i4zXc-V00Aj8iM4IxUXXmw_HWaJSFk_4RkQxaGDWRcT0hUQTnHgdMJC4Q0qyB0kRpwaazLQm5-KXE6KWJgA0y_Kw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The saleman showed us how to use a sulphur-based hair removal product on James' fingers. It did work and it also did smell bad, funnily enough.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV8677-vOVAQOMHJowTyeJCFlkasfMfB5oElYgs5vM6SgWkZ_mq7wVgY0jha892psS9RTRHUq7_j61pwvdG09csDdPHlvHXnc2690lYgBGIL8cweieJQWhUpSXDwRocq80c6kYfu71k-ojZiDDhOsvMUyhQ=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Shopping trip stop #4 took us into a jewellery and mirror stall. It was very glitzy and pretty and we once again managed to escape without buying anything. Success.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85yIP0943cN3ucgK2HYOsX9Pu9I-UGlWGKMX_SFa3qkKkF5E8OG-GnUhtyaY5DKTSfkNcTfZc_wPGXHACZvX9mIcWRwfsmzY0H3x6jku0RUwRxB0sg8sBupVSeD8cGSmU72mKRpgXvAU7OnbRQWnxtF0Q=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Lunch up on a rooftop, so close to the famous blue gate we could basically touch it.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85Do1v0c1J9HuiF_b4TKL-F7n3CoDAxcSgkBePEPC2JAvzaHNyQRV4GFfdyHG9jJT4zn5OOI1BCLmO3JPwKDdAqFMl01Xs_aurplehD1ilvq5cwgSxJILYAEGvAZfNk0jbWz0AGksPkD6cELl4S8YWtPg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Our view down to the busy restaurant square near the gate.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84DJgB9WMHlvw6f4hB0V-rlLRyrqwkKwqi9EVA42GFK_-LzRCabiPG-P8S3J4T2Y3BUZCkI7eqyw8kIPF78h-lbpOSHS177CTqtYZaVjR_KF5bOgB6tdBFkn_2fvjF1Ubu7g8g3CGAF6WxKqR8pfOHyGg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Piles and piles of enticing pastries finally sucked us into one of the shops along the main street. We asked which ones didn't have peanuts and were given a very definitive answer so we bought a lovely little box and headed back to the hostel to try them.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87IcQwVjke5GWtnxESSCvub7nIeY-DiK0Dh2nRnH4V27BdA-x8Iz54WbfnjygyDgvA-0xUfY1nZF1lCQQ1106CI-r5uI70wEsLqHFMBRvxpz48u-GFbj0TCE6fjGYmKZzNMEa_bF5vjbM2L0NXYw5oy6g=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Turns out they weren't so peanut free after all so Ashlyn had to eat them all. Oh no! <br></br><br></br>At the end of this video James is saying "tastes like the Medina" in case you were wondering.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87ZQZeTFtYX_mkCzw9XgjtHDEJwNROcwrPM-YP-XOrZK3Nnl1_bEU_bPDPrrvE1Nyr_usTvrsg6gpJVCw5BvZCfbB2BzBcxkG0rytbkdaCYYOLiht5vurlkTl8gUo_QvUx-QxzxExzpBlS6ANqvdFR_kw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>So far we have been playing it pretty safe with foods, trying new things but only if they were cooked at high heat. Now we're heading off on a food tour with a local to try some new stuff.<br></br><br></br>These two plates are: Taktouka - spicy capsicum and tomato dip and Bakkoula - steamed spinach with preserved lemon. Very yummy.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87mi9JKvWcu78PUUHh9Cle3qYPvCed2AUP1qKk8FMn1jxX_DbQHPTgbF9wokEFktiMu0DPoFrZtHuUR0TO5MbtpTCNjgHV6j7hqD6H8xtaim5TaNDWhxeJ5jOd-pMySPbnYa2qIaCqBD6Pm3LCm5aWf9g=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Next stop was all about beans. In the picture you can see Bissara (fava bean soup with cumin), white beans in a yummy tomato based sauce and harrira (a Berber soup). There was also some deep fried eggplant.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86QNGVnCsLsRGElfh2QUnZ2sYLB2RSuQkuwt1zHxv67mey6Q2UaLVz8PTZm86SDUOWIbo2HgL_qDYGGK4Qm9Jr7TdwqzD_Gl15Yl4LBBqL_LGBNyqJ4T5k00pgp0cIEULqHQHkFOJEuxkK00pzHA_L7lg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Now for James' favourite - Ma39oda (pronounced something along the lines of Marzouda). These were mashed potato deep-fried with a spicy olive and tomato dipping sauce.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86VUu34hTkSkJLjQqdf5lhGuOdSpHEEiC7qO3pCzpLqKf84xpB1aE5CgdyV-0S8QI5m5hkewSM2ATXIkyq9UZ6ftpqdd1TWl9zzQkT9OfkvZGncelZIQRC0hfR-OSmpu0JhYcH7BqvNI_5dAdkUpC6XDA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>This is the stall that sold the Ma39oda. We learnt, interestingly, that when writing Arabic in latin characters, they use the numbers 3, 7 and 9 to make up for missing sounds in the latin alphabet. </p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84_1hVw19Ma0Q-9ztyZ-X8dw2x0uEaTxoDNnwskX8fljd1wXxj9G1ItKFS-5cqHy0iUDeT3acJO8pexZ-ZyWM5KEWyNzSZSOS92COJj-Ifd_zg5qu3SnELVA1APOYKR1caQxXD-OHut1J3MPKSDG79Sww=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>This is panache (which translates loosely to everything juice). It included avocado, orange, apple, strawberry and some unidentifiable juices and was delicious.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84O4Rry_bUrEi50rQwUn1zBhzcd9kH1iguuVXLD-ax-dBhHKPIZQqCoVBwjmN-oRKVQ_NM4-c4ATKDRooLXuFlhgzBqTAEHBcOkw5VkM5lcQ_lS3OykMQKNm8_w_j68KBLN3GIQoru40QhazqABvlYuPg=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>And to close out the food journey, we had a fruit salad drowning in avocado juice, topped with date syrup and walnuts. <br></br><br></br>This dish, normally called Za3za3, is most famously from Tetouan (where we'll be in a few days) so we'll be sure to try it again in it's home.</p>
            </div>
          </div>
        );
      case 'Chefchaouen':
        return (
          <div className={styles.imageBlock}>
            <div className={styles.writingCard}>
              <h2>Chefchaouen</h2>
              <p>Welcome to Chefchaouen, the city painted blue. The main thing about this place is just how crazy blue it is so please don't expect anything insightful in the following write up.</p>
              <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87TI5qyIOdzTSEgiWvmmxg8hqEg7K3y0kapCWmm8IOnUVs_hYFC0FzohzLEE9G_zYvkXMo3iljlts2zJeIG_hWR-s29QGxI6vQyQPM25sZw-JmLKhkGOKd6nZ7RDyFXGMcI-91DsM9_2BHcPedY78CkvA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The inside of our accomodation, Casa Amina, run by a father and son duo. It was tucked in behind a tiny door through a maze of blue streets.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86Au-liV-YLAbOaHG1gznEYDFnEBjgl7qVM2cS-E1EbDXUY7O35jAn5iXZoWp768Q0puCkzcfYpaBB51HgXQyBnPYbQvdhv_OXYFcBJqX_1gFS3-N5-lvYmBLDcVD92e0pTfdDVNnNTv2pbXqV3Yfs6Ig=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We were told by our street-food-tour-guide that goats cheese was the thing in Chefchaouen so here we are with some fresh cheese and bread.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85eDptqvbsAZAJ25aVMpYXT5AisWrFAepggdZIFCxNZeHgcSuYyschBL1X2-o4Cj3kWOCfpFaWefyequEPsnoMZ8kcsmA-o3NI0OD5k58Y_VBUK5DQSTGZBmcg7epmEXPkjOVuA1tYrTESouOCkHSVZIw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The streets really are just blue. Most of them are painted to just below James' head height though so that was a bit funny.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85V6dZGu4ELbRd1RtAYR7N8fORCsFV5WfGFtNXGwU8pCIwCLXYoItgJdpcdlur3XUD4lcQEt0dyHj60WicynFC2v3G-8j6IIwxBEoRj3f1BVObQiDfvnuSMstjCJQngS5BQcXmzQCs3iHa-rT7bmYdz2w=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The city is absolutely full of cats and dogs, they practically run the place. And somehow they are not blue?</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87siYTKOK0Cn3e8wH5E3O1LlW7wG_-FuavpeyXN6ptj4o-WMqqkEKIIhG_CcKwhYbcoPlvLGlQ3Ppqd5Ckrp87_0kWZSAv1AQB8U0hL8hu0yaugx0-wCjbawpPHjjp86UYXGV2k6VTYvj2UGzxUnLoUQA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>It is indeed blue</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV849vh7yQAMMJnqEYrUNY1UN_b3xGuqx5Vixe433UR1zg4jvdIXnYfqd_NeX9oORSGNaOdlS0mg8Zw4uQ4UneiqenDXrjJO5CKJFSvidNQSrdfCBYEq-vHXLwjOCcQPEXQRHQBqPVnY7uPZ_SPCLPdGIEg=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Smiley in the blue streets.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86lPRB_ORj_V3SuYHi4GYWL3L6U5DQfiN_sS1GI3bO2nd8nzIiNMiAyzVbIJgYaimTsq_lAdh-rwO-p0oCDxPtTz7FzREXQhnT4oS6hffOeB2h0JH-ejXmMfr1wjP9l2CeWttff4av2J98uEmkGgx4MSw=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Another day, another mildly bizarre breakfast of sandwich-pressed pastries and mint tea as always.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86rR54k5IhFk2cxQMWgCm2ZyTh25oD6jTGAm9kGjUK8dCBdY3lTILLHczXd0OCZP1WMRIY0neAdO2aypg-JGRZkvlrfUGHnyA6Kgycd4mlDk0q1Ohcd2AUoRZ_IWnVFzhe2Lx3yItSZ0OyOUmcGDX9r9w=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We "hiked" up to the Spanish Mosque for a view of the city. It was only a 20 minute walk but I say hiked as Ashlyn hasn't entirely got her lungs back post being sick. We made it though woohoo!</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85UY99aQ729Xg1YUvkPMV2DEThuPpApYWfMI-JUXTYjSL1tcRRy4EzST1pJOLysBEdLb7rX2ycJc6n9EGVcybK8IwTB5hmYWHmGsP9tlBEQhhjM9JwYFLz1K16A9xep1SLoanRAyDTuIAF-fR3hhDpXpw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Blue! But with orange too this time.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86lID4n8X6rXTqqH6paE3CO1BuGFB7Lio38kVJiryInDi6WreR4ESgPWWxuCfPCq-sOywKNywB4nxnbfL3A6bqg73ji7vzWqbCO9ZlrA1T5zfZbwikKDOIN0Pi2M5gx964VxaCUzCXDr2fQX-SWFeAf7A=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Blue stairs, blue door, blue everything. Also mildly blue stained jeans after sitting here.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV864Xh7_zvegWuBK7nx9TGY-LRHClrcKQ0weMSXOFZomH0TqAlhsTRqzUTHc1b17jI0WzgpRfIW7HLmiDU6D7zyJxtmZFXbkq1QUjqHRy2Atgj_yTg3zmNyzESwsn543G6rHF9uFxB5CEuYYAaX1d1dT6w=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Meet the king of orange juice</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87nwR6MqJ2FZja9owsUP3_OIRfU_EJ4eun5R8W-9TaJX3Y16R2idmfg8HpErxnA3DKnN9OeFJMjZNvGdnOiecBNciLENTBP-sQJZbAo7_UVZaXWP3DX8Vi1fit8nTlAIgww_eE9vvm8bihJhpKwnnnJTQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Very excitingly watching the orange juice king freshly juice our drinks.</p>
            </div>
            
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84C5tSsqFIdD5xX3twu5g3hvxOT5gPByNeLhTeSRtuIwNGXedzQ8H2KDgU2Ung23NPWPwa1BjcIAx1U_Nbuua2SzVZwyefLC73_7h1z1HhhTkxm2lNJgyxCteDpeV4XX8F6TAVZlpfImEfGRHdqlBTmig=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Enjoying the above orange juice.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85-eCYhXqX8xZvStSsIJSsGS97Hdmqjmy8hwLockDasp-qD0XvQkDJJ0Mr4nWW0dmDUhnoI4ha5V4RQj6Vqh1KlZdBFhXTqrC5N49UK4tnZ-SuJU8xmYy8otYRcvzAE81hvD74RK4fPOUJqkOncFLs5lw=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>This man's orange juice photo setup bringing all the attempting-influencers to the yard.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87va2oPpE1IxHDr9ZBhIOMr4XnlzaFMT7QQLrjx5F1cF1Q5p8XB7Lk1Un3bdQRz4SeabwyrNAUP-KKj39wSOre9Z-Qb7E3cFfH5j0LRlSHPPDSVjSMj3p8sBUVpKB0Jro1vPoWKNs4FKxC3Rd5QS72NOA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The street are all dressed up for people to take photos. There are signs everyway saying "Photo here 5dh".</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86npg0xZHLj41snXUXf4rjj1uxIR4qwPHirRdPP_j27xQN3i5uRhVS6D-ge-21Uxl-rnv12MBPjdPBtaMF_1okQisdevF0i9yxE110mjMY6MNblUyfxdGns4TFbUC-3cMAmtDgXrl8J-6C3dSMO1l2TxQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Lunch time had us off to a Lonely Planet reccomendation - a tiny restaurant that only serves 2 things - Bissara (the fava bean soup we tried in Fes) and mint tea.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85QiM4dWntisXDafA38gHOve_2I62mBx73QTVm3Xtjarakg6sE6cQdTBl0Do3kedb9nRvCilNK6cM0cpXq7_QPJdC3dSQ9_3jBDf7ieP9ajDeXg_dpZfrI91EgYZhKpEkPMr_MOj0D6k0h79UDmlIAqLQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>There's only 2 tables and the other one was taken by some local shop owners so we knew it was going to be good.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84diItNXd3c8UAla6PsozK7s4ihMIO8Fogt1bXdw6xV7g5qnFXHiBxqPna4BNvtY2Yxcq-7xY7Tu3wQwXW0Cr0Uvz557DDYJU12mnRabF0Rl6WYePEcxooYCEi0zeX9JBD9W2Vlw8pkMlnwWlVkD39bLQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>And here is the soup! Its very thick and cumin-y but a delicious and cheap lunch at $4 for 2 soups and 2 teas.</p>
            </div>     
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85g7xZmm0t8x7Szp7UwLRUbwLuwPcQNKPFHHr9PcMmut3KOgdby8IS71RcfJcIJCiRFktYmwhB0R0e0UKG34KkbIUNzaQlbm7MmCM_QSagQUsXSJr6qIfo8ScPNIlB5E07FEXbqmdHgmAjODQt7A2y42g=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Pretty doorways galore.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86EvEHmvoA58Fbo22bweArFt1Y7Fjkp8wr5x6JJ07SNpmLjEevoG9i5mgSxsy399LXqE2eNJW0VdrbiGYTkmemDqbwMuHMy3JXTMtWt2rwyK4tZm_8sUqlwfbrncfdvfZbtENzSTeiUqzDzLkI_8O-xMA=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>And blue stairs and cats again.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84zb0DLPbtungN7NXAzOLM4OIdylNyeM6ny_9cYN0SVoehweSjClIs3tuKyNu514KJ3AJF6EJyvCn8kgoI2dXzapQkLPLgAVV4foIJFUK_n7deKRN3umFcCi2f6Z6ceI13h4R5eKFLKEaKwwVRUQB_g9A=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Sneaky cat keeping a watchful eye on us.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84L2c2g5nCBELmJsJBmyxGgOUE0F4TVMw7Bhathu4nqd2Hw2pKbnO8TN98M8H2ykZ3ZfL-4mQ0RIOTa-sq3rqOj0lxkqxvhWtdLevesK9H8IiVfwvw7O7BWx2SFDUXvI-N9cXE_cgvIz11KVbMK79mDTg=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Two blue-clad women patching up some blue paint.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV854pqeSsIqKj_X4RqNo1BeBxemvrfpIQ7zLJG-0WLJ_CuqDWn3Lnv4jjCPA0O3qfAdJsUpCnkQ_3nT0-aVbNxlXWkWxr8K-_yiDxUoNYjta2eT6nf2HbnNce8I_DXqFfxp5fx7jzu7sTEXMU5l8kQMTfQ=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>These little guys.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87m1PN7RYuv8t2J_wNJ0IWpBX3t4twj66vh0rqb448mwfaHh3f8mkIoSiu6ARNC-38NSgvirFb9KMXY8LRmBhfNzVBK2inxK_Vu9tWP7JiNmLw0GZvE0E63TpYpMGPnfypkg55Yf42i_OV-Tdux5X3HFA=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Beautiful pigments for sale along the main street.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84DrTGeif8vKfK5GaeBPEmPd5pgvnsjeLHHQBwC6nIPMDGboqLvgEamHzwIaLAp-Y3xRmsDKvaJI1gEo4YV9wNmArz7yz8G0EsRmjaLaYP1bg3yGdp6jMim-elT2Rz3dyEm7_QiO3stzQlLdPmQ25aUVg=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We visited the old Kasbah (super duper old walled city within the city). There wasn't much to see but we found a nice archway.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86JVPFHZg9Fpw7nNyopW9mwHPBoG_EtPSIEhReHucA0I8p0tWumTomQoJZN4TwxEVh3CNaZCAxQ0fKQzbEl9Qw0EyStAQ2_ugyeLmZ3R1mLI30cgxZEEjgvFOb030gK5YYA5mytveqGCdnVywAmEDuexA=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>And that's a wrap on Chefchaouen! Off to Tetouan tomorrow.</p>
            </div>
            
          </div>
        );
      case 'Tetouan':
        return (
          <div className={styles.imageBlock}>
            <div className={styles.writingCard}>
              <h2>Tetouan</h2>
              <p>Tetouan was only a short stop, a little of the tourist path but meant to be a time-capsule of medina life so we thought we'd check it out.</p>
              <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87FRA2zNjv4_0wDY668pQT2b5kGB1qj9dD4GWr6znseh0QKTBExRmyA4GvAmo7pol8O3llgnteOG3LGTVri_ufPf05vsJc2ckalTHdHkkqbdtXEbLBz1aBF1c4opI5cZXPUwgt2g5gNuMsoKMT3rRSwPw=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Incredible view from our hostel's rooftop. Thats the Rif mountains and Tetouan medina.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87s-mbV_6CQ5ho5owP3_48wEgPUiOixqPwfxJKyTUuyyNAeGqLHlml0cOToFTEKN0kM57h16cnh92ang-h9YmBYzm3ryiCGZb3Iz-cPkpdFz40YWGmJhZm5WMM5h4xSGFIcm-H_Sj8GYgzYSX-f2BshuA=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Beautiful blue skies, icy wind and a nice welcome pot of mint tea.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV849KoyXNU-twyKWh-M9bjD6UOhMDsFGQkjewU94A7wgskWlnpONRFF9p6jWKPgba98RQtvN2isArPkqNe8XNUd0MyvqdryU_rSG-lqNwpXm3CrIl0D4uUsUnBshctLbpHuU5zD8JogQjn34Zj7i0l5X6w=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>James pondering the view.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87SURc67XPZF4OPAraHqag3njvGE38wkcCw0duch43Yyy32xRs-JcZ5HqLUYsIY_NwIFh0oHuHOUG4EhgqOlnFDPbSJAHV--If6AK9ycCmI6uDiqTVBebWN-SJhjfoijR9VMdLSjdKxTRpfWVBLP-8cNQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The gate into the medina.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85q5-wle1yEvdUGbmyWhQSxH9Sil3erC0TOryzRK9J_TB8yUfLaO7HDeFIEjOV5at6VZaNAzy_qutE9-e0-Z5lylFTYx45i93D0B2AxzkmZdGgtHq-RcmoCMsyUm-j5ICl65KG8Cyxt38ZWy6UrA1-tyA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Part of the produce souk in the centre of the medina. It really felt like people were just going about their lives, completely uninterested in our presence for a lovely change.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85bQKQVVOo94_rQIFTHxKMPthDud5Ntw-2ggNTZpwaCACP6llXDjB9Eaz0T9APAErUIUgPiaPCoEOUhjTVf-NiZOLkElLT1CVmFSejPIufrourWAD9UXVC8UvDjh1oLxeF7_QOOCraZbuR5bDWoino3ow=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Most of the medinas still have their communal ovens intact and in use! They were used before each residence had access to electricity or gas. People bring their dough ready to go and pop it in their community's oven.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV854nGqj9f3aO7CTGNh29G5Xrf5KXJK4zicKrWbB75SSlmGF8c1rUf7aJr3rha_wnSNbeyaMrjK-tZag0yXBtDDfS-P72vH5X5-rZ83T2oKO6JHCYTzCQ60-vBzCtZrwli31fhcaly76I3MxoZQrvCHqZg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We visited Dar El Oddi, which is an old fancy riad converted into a museum - quite an odd museum though. It focused on foreigner's opinions of Tetouan.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87UjWTAYMOghY0Q7QgmWRKNTjdXLmug2PSQ4EB3OlnFc9J-OwbvJiHCwYz-V2n1u9IP60crfsAqUpQBc41y2bR5Ti9C2QIvb4OnMpiBqU3MYh0oTOMLNneCgLV9vbi5KwAebLrw5N-m1vKE03T_4MPAZg=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>There was paintings done mostly by Germans and Spanish on what they saw when they visited Tetouan.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87EPbh5dyVUBdyiv6-M0dbIkTF0Gctz5apvt1qeS1BVPC_4_z1eN5541gsHiwYe1LYGuZIkU9KjkyVGml6pg006WHjnCDRwju0cD3CGGdkM80BnN3zU-wdeoS04h-klIVzhZ2_7v3khGEBIXfUQOEW8FA=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Another fun part of the museum is that most of the descriptions in english were almost gibberish. Someone had clearly heavily relied on google translate, or were trying to reach the word count on a year 8 essay. The writeups were available in Arabic, Spanish, French and English, so who can blame them?<br></br><br></br>This read: <br></br>Succeeding newspapers, they already own their specific language. Their covers, realized by graphic artists, use expressive means close to advertising posters. <br></br><br></br>mm yes, indeed.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84JkGUpX-mPzTKKX3bBa9bnApY1Yl6tcahdcCxIeoTsPPB3xn5z87oymWIo1xyFonrFoQGOZGBdWL6GbuCbk0dNSrtosAdL4Q8ArV2MoZD_98CFU8DJ7PHwDoOoQshxiwg-baFpg1qD6vyFEYBNE564_g=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Pretty cool old restored house complete with ornate mirrors.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84OF6RrhO2bVmD3BBtQgOXKIHzjJh7J3bwG06bZ5pxNljD-t7uKPRS9OyGQCrCV-rgY8gG64safO8rU-9x8jVcLaNCfeBHPEt6nFp5xTI7LHiOkO01i9GmkkaOBz9A7GnnTK3mqSuQtkkTGLINNFZgQDw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Hey, look at these cool geometric tile designs.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84PB6vjijcGmsIaLd-dz47kKYIY0Tv9zYSQtOdRoLqEECbFyRBDfIvRFADE7rLsSvfvAoKqkzTnsXQYy4d1cqlUAyCx3MmeVIO2cwtQBdM6-0zwg_Uj-mTCv9lWay1MKVP3XVC9GHwdJtp3WAJVf6JdwA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>A open air market street - a very different pace of life to some of the other medinas we've seen.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84Tpv1BfDTB4dEfHLrwvTJ-7PKJ0M3aZnsO7TIcBHal8Geu_pUaXfi5HCR3g9n9Kqt7Zz1QCqhXOaWUK2bp8LmGCkyGPkVtgUxe1a9T9fNEXglyQSH5NPYjzo4d-w_L2WVCtYvkhCdXpbbV0heYJ9x3NA=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Exiting the medina, the new part of the city (The Ensanche) felt very European with cobbled streets and balconies hanging over the footpath.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87EnSXa0-qPy-l9uFJxRfTxQPUJxMPBOTwGuZfiQx8Qsm1e7cjZNpsWkumpB3wR_ZNr7pBY-Id07OsB5VmTy-H0TbvdjkOYBCnWT7D-s0ZEsMNemc3ow4NLgRqL96lzytHAoannnVfs8rckV9QNV2N6RQ=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Here I accidentally filmed this man just trying to cross the road. This is a large roundabout in the commercial area of Tetouan.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV854yaRdodULUoCAsrLb6sJOOXvuwZyKtRrPwzF9L974M9nhr2Znhg77k-2V9fPd4dRd0hle2Xfrq7Lu4ZGzuVtHscSAf40ROBpfHGzVsETZmrEl9At2NL4IOFS9G5BfqkKATRdM7rlCu_H3MBUAtkj_7A=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Out of nowhere, Tetouan opens up into this giant square with the old white-washed city climbing up the hills behind.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84EKWlIaSXyRdPTwJerNY_--XHf0UMxQDuS9p0BhDhtlK6GEesD7KO1LkkbcXlAF_R5yTYWNHUIWo1KTSBlSXqkIzQkJQxYrUlQilAzBzxY7ixycLyxcVrUL_pRSWP9prxDNfJoKQsxn_V5dxbZVe5uKg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>People were sitting in the sun while their kids played soccer and it was just a really peaceful lovely space.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87ubJQg7Kw75SVJNLLeUzhdzmH_OONTziW19vbfirA1X4OncG17tFpa4ZSeK1LyfBZlY3H-GoRjpV6IkVgaQgqMkt2hVZ64eJfbEXwyd-r_3bZwDPuuVvU4Cpu4_mpGoGIFaf-nzT3awj2yh3HqJDtuxw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Za3za3 (pronounced Zeh-zeh apparently) is a dessert that was invented in Tetouan so we headed out of the centre to track it down. This place is where the idea came from so was the perfect place to try it.</p>  
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87WWR_ez9vxyN0Nreen0vS6RsNP85qJ3TMtdEclCUk9dxJ3hT6k-6E81wSjSfNt500Q1ae7lnmFWlusM3IgwreOdYyO79ejTkmJzmJy-vPffuhMzYq0k6QOslQ6L_UNwc7eJ0Uo59A6VjubjYH37AtV_w=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>This place was absolutely pumping with locals. The guy manning the cash register seemed concerned to see us but when we pointed to the Za3za3 concoction on the wall behind him, he was on board. He called to the person serving to make sure we got an extra large one.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84K82vGyMtbfB6S3ow1qEiFc37YeRjmJZie9fKRzVoDp26bpraQrPr9zYAcKqszFNFEDWHktUEjclTAnENw0IUynrTAmo0N_h7qoxa9gsYLM5zSv_gZGcX3lxuMNSy2opeax1fyVRdrkfKF3-hR6FYvJA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>And here it is - a mix of avocado and fruit juice topped with fresh and dried fruit, nuts, date syrup and honey.<br></br><br></br>James loved it - it was very sweet and refreshing.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87I0mSkmViaLEs98wN2pmS5EJCEc3wxU0eK_i1Xk4JYmSlGIBjuAIx-GTXvzJkL3obsLqjoynC0BSgWmfOuMthcvYdYKk4bqXlwPH0rueKlpuGYrzV7ADSwnArzla5OBvype7OWac3a_pDaz_AIV42-CA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>And that concludes our stop in Tetouan! Our final stop in Morocco is next up - see you in Tangier.</p>
            </div>
            
          </div>
        );
      case 'Tangier':
        return (
          <div className={styles.imageBlock}>
            <div className={styles.writingCard}>
              <h2>Tangier</h2>
              <p>Tangier is sadly our final stop of the tour of Morocco. It feels incredibly European and put on quite a show with the bluest skies we've seen.</p>
              <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84OcDgRkta2nplIQSlUlYU1kHpYBxAvla0yqlKz1EE2rUiRlxNtGFYkMpkH9OM9iA_SNMcWfAJMWR--pLgXZIwWnwf23hMWGLgcE3Nj2-E-uXhM4l1VhhwXTBDymaYFW_K2GrYRZki5SS8voFjPV7R7qg=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>It was quite the mission to find our hostel as once again the address listed was absolutely nowhere near the actual door, and for some reason no one believes in signage. We even had a few people try the old classic scam of "that road is closed."<br></br><br></br>Anyway, we found the hidden door and it had the cutest balcony we could sit out on and watch the street.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87zlIxth07pktHK7zPRchq9vDWNxyTSfAsXW7RQncZ5s7jetYgyUugIfFVjvwpPTEJTBMI6e0Z0he_T1fIOmYvhXVwIKsCMcKgbGHTy3WH9oxsosheHo4fpG3ia4DsItBYXPGbx9YNDTK4QMOmFQlQ8zQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We wandered up the hill towards the Phoenican Tombs - meant to be the best view in all of Tangier and it did not disappoint.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87bE9H6rD2K0tsfx6nNag-f8l6T1nujxqtvNr9Kt_k0hjzKPbRh1Z6zO6Vxp5y7PcUsh91my265Xms7gHpZM6KqxVCJ0d01CGELslkrWIbEbX_4HOADRNxQUKz_YobprQQrdn-XdPSH5gUOTQ5oxQSpKA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We could see Spain from up here!</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86qRZtPPrZcl9XVSE4ICWU413zr1FhhWDDjC0C9ejOo7msPTLvm-D2XNj3W0IJJieoqpmcVMdPbuq_I3b35nh3_vxhbnJECRyJ4MD4T7seDDfm73bfFmRaLxZAh0NDp63ZzLxqflf3U4L8GuVOZOxWZRw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Welcome to Cafe Hafa, the most famous and popular cafe in Tangier. We settled in for a cup of mint tea with an incredible view.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84ZsTEkX4qigewTA0L1HeYjvaZ3t6Q9KQ5foNeI0wKAEgDhe9jiTV-_iu-I9GwqkvR5lCUaxVK27cJKJUyNPcjYVd_EZaTJH86k_I3U9iJFIzMpzmgWRysXiiBMG6G1Clj5qX25axc9X3CVnNOJbp7vww=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>They take their tea delivery VERY seriously. We had tea within 20 seconds of sitting down.<br></br><br></br>And look at this guy - 20 tea carrying capacity!</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87L0mNvhDBtkWZFCztvfFus-VLL4h590Wzg9Fpbc5BGTsI9aJJIbsE5KsNCTRbm8Z8IK3DmSf29ZR7W4YM6CFpRzSeaEYO4ak6ImiNs3oftuh18FRwmVhUFK1eoQoJOpQSrL2QDvQkezKaCnBlzx5bqDA=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Lovely view of the Strait of Gibraltar.</p>
            </div>
            
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV858OmokIT4Sc_wgQWyRNyGIicPycOJC5sjhp8JjzSDbDLoanj-GWmEVeyBrI5DJfeH94tFjLcYgwDv1QH4HiOgC23M5SJyttRnTRiMN0KvcxD7pucGrbWHrErTL7Roa8Kw6EeX5pROLX0M_g3TkL3Vjsw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Tangier has a fascinating mix of the old fortified Moorish/Arab medina walls and the Spanish influenced hotels.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84jI2IodcMDMlmbxP8bNTsj2KIKqMG8j5U7W7m5JaESbePMMKkhvAQtbvBnoRlT6wDF6LdGM8aCVHmzzisRPgx3F5JZtjrtVW2SXnSZDadVNA2Jla-0R2P47Ffz42yV-DTUXDiu-By-g7J5bHQjwK_GAg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The port that we'll be heading out of Morocco from.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87JGQ0JK_PfryP4yQ2_BtsMEA3py-kIwYFPgHwVIhnqbBcgxKiEhts6t9YK8y7iD3UERLoV5SkkwDDldCX5e4rcEFndFjlZOao9hwY6gQjk0wmbJQVJx9qpLp6ka83013oUUEXDmaklsPmC_ENfzyKuvA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Cute little streets with the balconies peering down.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87BToV41-hbgX98T1uEca_Y1uYYl-r9kYu9Zr8VkyKI5XMBwT09rzjF5ZpJkTftztsmULrTqSoE-SWgVtBdVhM5F_o0uypBygX8giCnRQor7thHSDl3DN7H2jcgKoC4_PVB_CbRpNW6xP3iqFch_YWrXg=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>This guy was shouting instructions down to a friend on the street about what he wanted for lunch (I think). He's 4 floors up.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86eLAWux_I1tLqkzJrpwks__mFDDQkD-UHIWdlno7wgXUR-VM2IUhXV0IReB6RGxRjxJJkxDEV0kzHh9xUuI5xd0RKUxCInjbeKUD1T9QX4h8Sreq8TI8gisFebR6dvJqoFR4mM4iZCcnzLWWeEdW6xIw=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Enjoying our private balcony up above the business of the street below.<br></br><br></br>We've noticed, all over Morocco, that the mornings get moving VERY slowly. This street we're looking down on is basically dead until midday, then is absolutely pumping after 6pm or so.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85vEsVvNgnvwAGb_0RFmAiZLuQo6sWRwvC4823o7TlNcFYrXZgZKMTiu00zVy_Utl3-Jyc_FiYrGbr5QaXDIBy6xRHKTBgDwd0qqCMMk0-VR0vzG9MSbuvI4clkUml6vzbLyv7KW5cG39Obfr3HeOmlGA=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Joining in on the people watching, tea drinking. We stuck out a little bit though because everyone else sitting there was a middle-aged man. (Spot James in the pink jacket).</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87VUO5ziWERSC07HnXQziGMEa5ZTYzMDMgkxl66pflJsx7YbMp1NKQCBOZP6DCSUdR2J2JzOiLjrwzRbXn_FYljYhD0BevVAmKDPWaq-VQ3xS1Yx2wHpGlWjfW-lyHOYhbXyINc4zZTy3rWH-dUhiG_pQ=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Beautiful blue skies drying off our washing 5 floors up.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87xNUNg0Wt4gnW5btkyYaYNknsXLM0qvrD18WwhJdnne7q2Dh3aYlspJhIm8Wfa-Hp5EGX_xRy6QBC9_mV8g8UyDX1m3D7q5QUwp3Xrxu6X8X64EGsXUHbTUR4FJKOO0gcMmldDHsu4i4UcsL5GbxAQ5g=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The medina walls curve all the way down to the water, making for a very pretty stroll.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86R6E-bMKs8k_NIe-f0lmwmHkVw1g87RqHJt4xZDC8UE7MTUQ33SkhB67YgIb_RSYx0z0Gawxr4p_X4exFq560Goqb1beIxnbkxJdOV4ZjwUmTjGlRoaa7v2P2QdAh0x-PkPAUA_IH36bLUu4yGzPfHMQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>I just thought this street was cute.<br></br><br></br>That effectively brings our time in Morocco to an end. Tomorrow we'll board a ferry and make the long trek to Granada!</p>
            </div>
          </div>
        );
      default:
        return <p>Select an item to view details.</p>;
    }
  };

  return (
    <div className={styles.countryPage}>
      <div className={styles.countryHeader}>
        <div className={styles.returnButtonArea}>
          <button className={styles.returnButton} onClick={() => navigate('/Home')}>X</button>
        </div>
        <h1 className={styles.Title}>Welcome to Morocco!</h1>
      </div>
      <div className={styles.countryBulk}>
        <div className={styles.entries}>
          <ul className={styles.listArea}>
            {entries.map((entry, index) => (
              <li key={index}>
                <button className={styles.Entrybutton} onClick={() => handleEntryClick(entry[0])}>{entry[0]}</button>
                <p className={styles.date}>{entry[1]}</p>
              </li>
            ))}
          </ul>
        </div>

        <div className={styles.entryDetails} key={key}>
          {selectedItem ? (
            <div>
              {renderContentBasedOnEntry()}
            </div>
          ) : (
            <div className={styles.startPage}>
              <p>Morocco is up next. We'll be here for most of the rest of January drinking as much mint tea and we can stomach and surviving the chaos of the Moroccan medinas. <br></br><br></br>Click on one of the stops on the left to see.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Morocco;
