import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './Ireland.module.css';

function Ireland() {
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState(null);

  // Use a key to force remounting of the images
  const [key, setKey] = useState(0);

  const entries = [
    ['Dublin', '8 Mar'],
    ['Drive to Cork', '9 Mar'],
    ['Ring of Kerry + Dingle', '10 Mar'],
    ['Cliffs of Moher to Galway', '11 Mar'],
    ['Drive to Donegal', '12 Mar'],
    ['Giants Causeway + Derry', '13 Mar'],
    ['Belfast', '14 Mar'],
  ];

  const handleEntryClick = (entry) => {
    try {
      setSelectedItem(entry);

      // Increment the key to force remounting of the images
      setKey((prevKey) => prevKey + 1);

      const imageBlockElement = document.querySelector(`.${styles.writingCard}`);
      if (imageBlockElement) {
        /*imageBlockElement.scrollTop = 0;*/
        imageBlockElement.scrollIntoView({ block: 'start' });
      }
    } catch (error) {
      console.error('Error in handleEntryClick:', error);
    }
  };
  

  const handleCloseDetails = () => {
    setSelectedItem(null);
  };

  const renderContentBasedOnEntry = () => {
    switch (selectedItem) {
      case 'Dublin':
        return (
          <div className={styles.imageBlock}>
            <div className={styles.writingCard}>
              <h2>Dublin</h2>
              <p>We've touched down in Ireland!</p>
              <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMmvIgmBJximJlQEx-C7FjoOSuxhDLzvTTQl9FVPICoGHxYLzKZ_qAvMdwj7yuPTmV2oP3UEGHlnluoEJrtw_rq3H8RN_ZzKCFJrjRSnRT7bhmQojG0-4pfdZjWO173ALdBVFyb61uJwYdK2Vo14Dp40g=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Sadly, we only have this afternoon in Dublin so it's time to get moving. Here's Dublin castle.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOvn8qVpnPTWIjeWZRCfs8YxAkEvGH99E3qgJfHBVl297fm1DUfNPSCRS_Eon7AtUUe5JRZZufNAVG43Q3rE60YeIJ2L2VVcA_qua6VY9G3VYgyfoV_HVEfDnigx5TfuEqQCYu9x7CenMBB3VpXn5Zm2A=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>A stroll through Temple Bar.</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNrZGVgM4n_zP30sxAD4ak6VBnZEcqcpUEbOGjrGIKa5B0w8fdi74rAygCWaB_0rrnq90NcbV1CnsU9c6iuPcFIdu8_G8LdkV6kjIo8XrYMbiOC8RL2f9jkLbEIB-SUvLnKwidJ12WdSjR5ujjqbmhdoA=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Colourful and fun Dublin.</p>
            </div>  
           <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMzJKT1ZvE75AhOcGyLlAwoQ2AVhH2elcaJb6r_BvPAi5Ko4cIyRXy2csiiSCsyXVJJHmIlGjidYEjyuhSh9yEksOCdNVrBeq7rS8wLyinzW4w1ZAJBQR1easNHWGQPgdfgFiZxv0MFUiKpCai3lBNe-w=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>A busy pub.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPO8oS6D_dd1hA9eitdJ4i8ZRbTPywgkMv_dtLUuXR744-OtduIRxgJ6zeav4zJg2WYbwvbZzi9y4hLN75fHhC1l2KKQtNzI4TOmCxXEO_eh9LvxzyZgv5O4Sfyeqwd3qS7RAS5mY2Oo1hzdzi3_cNhFQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Ha'penny Bridge across the water.</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOeiAZHUXbvb0RFXgVRpucD44bzSuG8tSY6Wo6vJ2s-PJG1aJcyFs9dhWllRu9u6mglefdDlTery_xJ-yJCP3onw6Nmsm7k9jKj6EX2H9476hrJL1T7Z76bLV_jvg7ML6WSySeWquKGt0fRr00eLsOycg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>With our limited time in Dublin, we booked in a walking tour about Mythology and Folklore. It was a combination of history and mythology - we loved hearing about the fun stories of underworld fairies and fairy trees</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNtT-YiMm7J0MzllUO-50EfrajICiRpH83DPxXEsOcyxdc3elcSVDqp461kI7ppkLw-xctvcqmJpJEoBrgAY9hdmJgvexNXn8dVsqcFs2bZlgVXRySdR-faoZFmZx9WXzAoijanuCuYfdUXCghli8YSUw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Irish pub time!</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMpWJDVDl-U2gMJT4F6rOBjLNO16DsWpqjTVcoBHzk9gUfTxbP2sXmVXHFWkN3wCxuMQgMHOmjPN4bplt4fmxmbhXDXGCyvvo8PAhtopzUDc2BVhhin8xyVMMzc1ImGzjWbi308NJQcFfdcpqUZZ9hEqQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>It was very cute and ecclectic inside.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczP4-vOW_J0rYkKgXWf6G85CYBFj-j1yVF6tcOVUGYlOXOVO24hFGGe4h5AP4kOmLOUPpCdCbvluylj23WA_tVuZxQc-zrWtN4ed0zwzPJHPB-5iXQT9PcqNUod0NBg3sm_hGlIk_33FfNpcwg9BKgyKaw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Time for the first taste of Guiness.</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOk4HdItiiN28yRSgE8a4ENiJqfRQLvmDULsMpzV4zUCOqu0Bg6gT6rtZcQMF3qDPD6WcQm-Q3XukhODgQD7m7-a89rU2AJEqzoiSGjjqALLpl7dohU-ZLdQWDybF9K6OIgfabdMTM7vK5aWVQg7RXtqg=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>First taste test. James liked it, me not so much.</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMS66UHzeuHNDqmfZTuZ-3JkWICh69CAnYJn6P2kF_IMZfxZuKgJkMhQSEOBCPZ4yzgkx5Cm6YsDKvQIk6v3Hpu623WOucwCODeBrhQ9FFBWDQNVQtHpqvOd5NQWCibuP4OtQJeZ_q-4BI-9T1AJ5FPcg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>James wants you to know that he finished it.</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNn2I8W7uU5VpvY5anfD2GzNCKba-BDiGrTLrFoEZfnVAlAq1t_oE8sqP6nbf1J46J3dY6CvxnvjBR91UXnzW6r8hNpZRRjayHN9cs-tiAHKFIz0olkkS551gsxWAB7kan9qqict4vOyDzv11BS8KohDg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>And that concludes our brief stop in Dublin! To round out the experience, over night, a random brawl broke out on the street below our room, so we listened in on that at 3am.</p>
            </div>    
          </div>
        );
      case 'Drive to Cork':
        return (
          <div className={styles.imageBlock}>
            <div className={styles.writingCard}>
              <h2>A day of castles!</h2>
              <p>Up nice and early we trekked across Dublin to pick up our hire car for the week! Today we got to look forward to a guided tour of Kilkenny castle and a chance to smooch the Blarney stone.</p>
              <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNOpYxWHCtjwsnUna_PvIXOGZ-L3T-866kKoPXHYD_o8oiHc25rl1hm3QrI6aBVFK4XukdIeabLRo4dbEHQCYYzAhsdWdOfiIm59NedTiXuB8evXAHNC7BYDPNB9qBnLhrg6a_kBNqS2UQQxkVe1py2wg=w1118-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
              <p className={styles.imgText}>The colourful buildings as we pulled into Kilkenny.</p>
            </div>    
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMzcWzBn2Ms77ABq8TvGoX3la_J9AqlO6tEpEwLOZFfu1Q3uUDD3lFjynFvhHbErCN3zYt2KIlybENqfU33SljIqfdEg2NHvKfV6W1qPkVDJ5FR-8YttNros-P-ma8mBmF6OgfbpT6OvQbdIYUwvFNYYg=w1118-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
              <p className={styles.imgText}>The first peak of Kilkenny castle.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNA1V96SOrykCQGkA9HoWUKUqb786shz9R7K58Q5Eg0prfiQ-HpLyLIuUwD7FDMb9E5ZQbyrXP3yjB-4_165ki7XuOFdwo1f6aUMckcockT00ZygYPNlkOQhkC_4ZSj2rIJoEMGZSBNoQWmNdazUXjcTw=w1118-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
              <p className={styles.imgText}>Hey look! James is there</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPuRbOi1wTfzeL9FKfrvBA0Paf1TVj2uTicicFn7m84zdUbgHaKGt0Ak7cxBLV7ZvgN1mdDsCwy3eL9-bAewanTdFYRe_l1qLOVDwB-Op1v0yJbGBc-8zP1icx-Cp_CGOSqkOxzB33UiZjtuT-9JPJBkQ=w1118-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
              <p className={styles.imgText}>The grand dining room inside the castle. They're still doing quite a bit of restoration work to the castle but it was fun to see it all furnished and decorated.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczN0RBFKrSRhnE8lNzo3wgIyq_DlZBZw3OXoYs5-9Q_342JEc8KD8eZYZh0fU7fVOnRQ6RAaJKzQZRurfNgzUBPRgP3_uZ8wBEoD_rsz20TCvYJmDtcXKXDRxmmAlEDys-1CwzQxXJwVu2BEVYuAZMoyJA=w1118-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
              <p className={styles.imgText}>The gallery room. We were told in its hay day, this room would have had 350 paintings - 7 times what is currently there. Quite the gallery wall.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMDr2BglWV6u5dfKOkMTnBgK9BCWSwBCR6Pb2DJS1WqGkGmGn_JCyDXIwHKDaJ13_FwZ0aAGSht17gDHFY_5CqNOYRE7Y6fYDTGF-3OoFl-mOb5A7vSCLlY5DxN43tytDKJAe3Tc24VRT9hCiXhK4ITYQ=w1118-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
              <p className={styles.imgText}>Look at these fancy steps. Big fan.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczO2GyulLVfZDW_E7Y58N43gkde6p3qDfLUHiwsTcMsFjtL3r9HfTKZGJHVUQw87ofAfBNvxb_359MgMLCr_03G-zVzBcFmbLnQJNed1UuzKMQsiC8jrKPN5UWU_sxS4ihe4smiUxNj-HNKg82v5BKB-iw=w1118-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
              <p className={styles.imgText}>I'm not very visible but there I am for scale.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMhRxZ2UMbW21N53A79H739zuAMvI_Q213MAd5QSTLixA9i1AX19neOqr8buNJ0hMRsHqoGQcfauMzE87jldvdJ8dD1fhq5YuVZZ6DrYQj7BUySOu6WUqp-bEmhRZ-s8pyDlmEoxa9uXjoju-9Pxv4-rw=w1118-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
              <p className={styles.imgText}>Hehe - we liked this cafe's name</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOaPSNfaYF3oSBq9iaXqJJFrjvPYl_mQJbjXwchMiis1pvkBM_22baSek2v3J9SaHwC4Ft6LeRcR2M8bTRo0Exht--vXeS9ztS7UQTX8APKnokB9OdYL-9_A42GU49Ugea-PkJ55Uo8UgpmG0ET1RuVJw=w1118-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
              <p className={styles.imgText}>James is puckering up ready to kiss that blarney stone</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMjH-90OujjIzvM0DvK2YelvBxcGuFW6xRW8ESqvVYNyCIq_GqoAAeOjHf8mH4UE3DMTY1pbee72LtSzfOU1S-7bPVkBeDkY2uefeGFODTPq8HL6bjh0lzVpyJXH6DGVjV-mucl2XlE2EQZxW3ObhGejw=w1118-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
              <p className={styles.imgText}>We climbed up the spiral staircase and headed over the stone.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNdAMmICIa7N-7fJQnu0hJM_zNcOctjxVVS7swVKT3UszbvfXuRsE2y9S9kWi4w9ZGWGHQ3zjByplJh2iHdu1gvn2vNsarwVNdh6R7PT9Cu-oSAzl0zT_BA7SWd47WVKBuchTOT3-3nVJLdG2DOCkZeAw=w1118-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
              <p className={styles.imgText}>Easy peasy, look at me go.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOzt7pocEhOJZOmwcu0ao2zzVbSD2EDdtDMmSmeUqCiO454-txiTtL3rwKEr4kpw_2hZTZZuu7mm700A0aLb80RnvVclsHOlzM7hMGeC7XbgHO88UTCjmT9pYMBnpd-MJaql_cWP1FC6wzj9MmESwuEVQ=w1118-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
              <p className={styles.imgText}>Feeling fairly pleased with myself - and perhaps more eloquent?</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMseaYIAggtG6FUv2LnLw9noSq6p0YGv-o1RsiEQUR7PnYrdK3QkPTjJS0BXluH9oQcD6PVrNNg6iK2Emh1vdzBojxCgfVTHQiyPAraiS-s2GLeIsT7IeeOXdpa0yCXDWSj-rPB1Tw7d0PSjnFEQ72JnA=w240-h426-s-no-gm?authuser=0"alt="me and chippy"></img>
              <p className={styles.imgText}>James was next up. He didn't quite lean down far enough on the first go so kissed some other random part of the castle.</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNjntF1uKyiYmYHNZIY9dejhTRXUorjeuHeqnTgQA0EFVQTKPVf930pcZdTInUFM9TiVN8tw_KCGLClsLI_HvgawhMCAtCTNAbeIVbjZ9iRBquYQMPQ0RPcAQaBlKTSUqUm4M_XRmfM2Kkic1y9gDRuiQ=w1114-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
              <p className={styles.imgText}>A key highlight of Blarney Castle was this room  - the murder hole where they could throw something down a hole to kill anyone trying to enter the castle.</p>
            </div>    
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNk2eIV-wvJY5M9m2K-iD6rXyv897pavsQCZb8wbFO313sG0Ww_M7fLs_J-JcNImXpRKGdFtS22x2TfdN77pyFJEMj5PPmE7qY5FyeuzLGi_WMRRsVAp214lw5d986bvQ9fdnMyJVFrduCwb9w2U1wOTA=w1118-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
              <p className={styles.imgText}>Locked up for kissing the wrong part of the castle.</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMxPIaudF8OZlYvuP4D4Gnl1n2D4s6G8Sw48NgtTUwHrNduSiQQAeovlsrmbPAMfAalq5rOgvbl-QAbF5dOHwk8IFzgqXuFo6JpPvPRC4rwAJYU2OM_n1C90ahDHSMfWINzE63yF57QUs73LPHqSPSDKw=w1118-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
              <p className={styles.imgText}>In the poison garden of Blarney Castle grounds.</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMsdlNo3qUcOM4GEOTigPeVaG0-eQHZ3zsI6Ji2-6w4p3rc7f6YzGl55J_B4qamq9VD1M2TgNhp9l185smJe_pIVfNQeOrQ2v6ES46SgyuTj4jgNT8CYAOEZCpNTZXNFUQvyY2RVVRGPUd0aXCBVtnBBQ=w1118-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
              <p className={styles.imgText}>Blarney House.</p>
            </div>    
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMkE6Ty3p6hKsJgzr9AAr3WIqQwcHsSy1mr0SRaqxvrJs6belhLqJilBx8R2DbRnX_JiwsAjAVglTIUyVLs1WQMc8v9You12YR2HI3Ne2AUMGpX2_GD2NaztzBaLh01bDg1q4VrwpFC5QIfRM0EQiR3IA=w1118-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
              <p className={styles.imgText}>Pretty pretty tree.</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOE88Il4ncLS51buf2Vb5j1DUI76U0-xIRoyP46xg8SF_5uLT4mdpn9ZzuAfBgAd0LLDLGPghHm6iJj-wbXDAw5qgZn8ge3skJdh7Tsv89oT1Ifzg_qmAySZFiTiyg1mNCCvT_T0csNEHmhyyhsP79JzQ=w1114-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
              <p className={styles.imgText}>Kennedy merch absolutely everywhere.</p>
            </div>  
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczP9DLtT4fTvrBtjp2WVq2ubzyO80KZAFy_kVqCoCspIUjWhGxTL9ITxw5F1uI0T2ungcLENjB885LcCrxwkVPnDOut7UAKnlX77AH-jHBYxJvYf9X1DM7XSdez21qYaAMtT76srfMVitK-eCO9VdyUIQQ=w1114-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
              <p className={styles.imgText}>Arriving into Cork, we went on a bit of an genealogy tour. Here we have 5 York Street, Cork - my great grandfather's birthplace - this was less than 2 mins walk from our hostel, bizarre right?</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNnWLWwwGXv__K9YXDxMvN_ykrs0tTf3EgX5nYFku4yVYz2eZNTYgIUkFFVojf9Vt0lnyrvSaVEQDYaiBPnRmJE2Au_Rvr2-CQ57p0fPuOmZcGmq4TesCLoaAU5_08UmEuJ_0BIGrAoQSSqBlU0mkVINQ=w1118-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
              <p className={styles.imgText}>Some cute coloured houses.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczP-lQSL_UBLflldpHaAloNpBtZqVHjKaMx5J83Y55-IonmdESgCcZdmWOayihSPk4DgeW11oW2sPv5f5Pk_5jOub_jkJTwWYglRxr_XzRJHUSbmv_pbJ8Me71K5ls-xionZ7RYw37gquMGkB5E5IFvKqw=w240-h426-s-no-gm?authuser=0"alt="me and chippy"></img>
              <p className={styles.imgText}>I navigated some seriously squishy roads</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczO8hfKTaqZKEHdL9-GmO787saE7hyoWA4pfjNQe9kawlSYN6IsEMA-OYUfOWfAGrwoiJ4hSDsfo2Z-udX-dJZAQybOk8sCTZHAAkh_U0SDackFcHVlnR4FZVJG0WWnZYAVTyS2vwQEjnhu7bRfBSr5I_g=w1118-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
              <p className={styles.imgText}>Peering down the main street of Cork.</p>
            </div>       
          </div>
        );
      case 'Ring of Kerry + Dingle':
        return (
          <div className={styles.imageBlock}>
            <div className={styles.writingCard}>
              <h2>Windy roads and sheep sightings</h2>
              <p>Today's a big driving day - we'll be driving from Cork, through Killarney, around the Ring of Kerry and finishing up in Dingle.</p>
              <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOXy38EZ3i5h-L53Y275O9OmVcYw3WBLHBpE5ZvuwyXBudBZjfWecjVPVkgQJNQNQZPhW18PNRRO-wbB_UDh4o2NVQ6yr5z90RpcUG42gt6YR5FPffGJVvlgaS3LJlfwesrFR-C5jFnBI0UHL6k5dzgiQ=w1118-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
              <p className={styles.imgText}>An exciting development I forgot to mention is that we purchased ourselves a GIANT thermos. In the hostel before we departed we filled all 1.2L of it with piping hot tea, excitingly awaiting getting to drink it somewhere on the road. Here we are in Killarney gardens giddily drinking our tea.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMkqgDfOreP-XwJM99Q3ahLRKyjphO_SeI8NqSMp-HKsCGBVglR3ipstYKXImRzmELlhYkEGBIfldmFyj0ZBJAGhMtONjRh9L9S_aXxRawaZCIKTOlbHDO1g3hKjxUeunIVoewvjWbJXLB9WgOw8TAx5A=w240-h426-s-no-gm?authuser=0"alt="me and chippy"></img>
              <p className={styles.imgText}>Today's roads were tight and winding but we drove through some beautiful forests.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPZ65KbnpMtr9zfY2PYzLdDYWQvChWFTwO5rIbtrv3wZzbFnKJ3p8XM_d0Z_pGwPIA-7eLdIXic0jIv_vZChBFZw6DdO2n-D7Ls8xsFoZ13aHmGcUU7eESzW8gjNRPpUpT2sNyvVmOBsfn9riBcECiOjQ=w240-h426-s-no-gm?authuser=0"alt="me and chippy"></img>
              <p className={styles.imgText}>And under this cool arch that signs had been warning was coming for about 10 kms. The suspense was killing us.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMhUNwnZGDmPFJNKQFOaKY7zgZwuicS0tWwNghbv5Zfw1PsW6rVCQTTewbfUQBugz8k0h2sYxcE0KzGMsyC7ANA6Xy_Hju5OSwttZQcemiLKn40eehpMz0KrbbISbPb5JqLCAZ4UHF8u5MFP5bZATr_gQ=w1118-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
              <p className={styles.imgText}>Photo op at Ladies' view point just before the official start of the Ring of Kerry.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPz_3xsZMUM-UtH0_2_YKQNhMyAXMQFMQCPi9otzyRZMIjHjd9Mz50UmGdhXHd3ZGhGi9YvofayHDVkpuOCX66ByNG9Rsz3Nqp3_1CG8Tdy1WKq79c1S2f_NEYE2ZHVo-4NLDK9TQR5wuVldQ7vJJu68g=w1118-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
              <p className={styles.imgText}>Ladies' view point itself.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczM90fxHhyOQA2UGhAcxmTQE6a79D2aBYRQdGnm_nT33WmBbzP5z7fuuJZOCV9I0YeOe8KVEk8Y4gJosU28SgfqFCSW2TyY24KBJNav8MoHedY5cpj2TIWPPO-Kn8W_cYlKlNB-AHoaGiL7eilYlGNN79g=w1118-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
              <p className={styles.imgText}>We shared the road with many a sheep today, some more willing to get out of the way than others.</p>
            </div>   
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOI41mC-Rnc9VlzBftao-vNX_qIpSSodLKKJsxX2rG92FeQfbbpXO51PCjv44RAFvotxfv8Ys4sZKXeqhGkvRxfz_1EE9_S467dZVNxzX3DMWaMWW9849MpPocxr1rx7Lp-c9ysw4qYlfaVwpdXPYONlg=w1114-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
              <p className={styles.imgText}>We took a very intense detour down the tightest road I have ever driven on with complete blind corners, locals driving at crazy speeds and plenty of sheep standing on the road. All for this view.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPukl7xnQ4ew7qYp_jiA1Wa4Kzw3inXUg00y-0cDgEHxBC51VONU1ZItHwMLXe43LY2AbByweMI9xzO-jPDnQt1G9BzG321bhBEg5DZTkNMQJlvg4lEW6avrEmm_1cqtgaxYq3TrpOgicFPU8UXmDI_cQ=w1118-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
              <p className={styles.imgText}>It truly looked like one of those car commercials.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNGqXLdFiyYXRg0lwc3uzT3tZfJJcpdbilYgnAJGqD3He7aAq918A6Oq3S-WRVxSXo3JcgvGbpPcI_m04eWXRprGXQ9LbsLwNXPDkL6-eSemXtfL4ukB84nP5wIEFcE90t7gzIWihrnEZ5S_BdLw91WMQ=w1118-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
              <p className={styles.imgText}>The road had a speed limit of 80km/hr and the locals were sticking to that. SOS.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczMF_Ya91fgO2e5zuMNwqTyD_o5siTZE4fOqtpQ7_9xthQ_yDt-M4aUdEsa83hdSZp-4uuZhbLP5dJ1_UOUvues4as4hKfpgg-Xsnyrn0JIwsQ1T8EFTTg1l56pgGtpv_wi-vVK2HrYJdXnxWzJelfKxsA=w240-h426-s-no-gm?authuser=0"alt="me and chippy"></img>
              <p className={styles.imgText}>A few more sheep friends.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOJWoxwrFtFWWdNnp93JM544wukCn1qhrjkIKq4dY3X7YPSSXlgiKx4eA6Hx_5zaubqZ_jAGXKJp8wTvrVyQhIoHGav4-N-0nAIf4JZbDaDgdPRlIKpmMDqFhmtbOXD_dovnTxp23CbC2jcj6KZ67OZxA=w1114-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
              <p className={styles.imgText}>After our ridiculous detour, to calm the nerves we found this absolutely adorable traditional Irish pancake restaurant.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPLVUcmv5UcUrxsH8DIq2xpoP_HGc4lxaZ2Fx8olc3P9S-nT5o0-euzRwIjeQ_0nqIO7TFjRDh7sQ8ZRBAXPmDnJU_wIE2eL8rwmMHtrxysVd30j9u_trE_3x6K5xOiu63Bef9Ygs_tFHCNSgDEWEdwMA=w240-h426-s-no-gm?authuser=0"alt="me and chippy"></img>
              <p className={styles.imgText}>Back on the road and look at this view!</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczOHfkmMYa64Q_wGWX2GKsJ-tfliuhr9ZeKNHIDdWoJD8g94nBuPUAIGcbO5Z7ZWt3vjkrF9y99EVbHXkwemeT19G9m_8bZCdh1Hd3hmnZlltRWCBXo0mFryouhZ4fvRPCBledsS9ax0sCs7qq6a2TYosw=w240-h426-s-no-gm?authuser=0"alt="me and chippy"></img>
              <p className={styles.imgText}>Driving along the coast.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPRe3jfe820jN3E5KhfXZ-rkMqNYK1Bvc4p0nGcq30h4Xk78MtkzBN_ybZkxUtQTCjrQNjqcs3Bcc3telcVNbfriPRcRtDJNGUOmZ0P4n2JuMpV3pIztTz_GszMl38kHNyWTRsMyUizvzxk4YzPdopcOw=w1118-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
              <p className={styles.imgText}>We made it to Dingle in one piece! And to the cutest hostel we've ever seen - it was super cosy and everyone seemed to be staff living there.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczNMnAeJHFQyUhgzTmqsYrDJPYpRju8PKRxIUCmT6a6p0KKRyWWrjrGhxKQCDEBVVmHyTcq2KnHc8D6myHXTsuEMaVmVu5DH-sqzlFpAemK6zqg1XGI_glcU8EqVdWauIFnt4eMarV6Iy-8dpS94i7-AwA=w1114-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
              <p className={styles.imgText}>Dingle is the absolutely cutest little place.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPDJEYXeEBTPpl-4PtLrf_6v9baeDcQt-LXnXAGbQimg5eJ11mQXyD9-70zyAYUkxzSkgTT9SgzzD9YVZClpui1IFQm6R9_lkdC-HP56y5pSQPzxG84ZhooVzixU68TNaEcots3w77wGEHlYs2MQJqm4g=w1118-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
              <p className={styles.imgText}>This is Fungie the Dingle Dolphin. This statue is to commemorate the bottle nose dolphin famous for swimming alongside fisherman's boats in the Dingle.</p>
            </div> 
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/AP1GczPK6G1raPIeZnn69UoRWmya_eKj-Wcxq6TaAO-KIgaD0UpRciYVLECwgB1xWsDXLKOA5kx7FUugJMvm32qYoj_HGzpYha7PigQ4dwaWXbYFMnm7dbEfVYkc6H95P7ZceTTqf7CyzEp4aIqDeg2628Gzzw=w1118-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
              <p className={styles.imgText}>Adorable lolly shop.</p>
            </div>         
          </div>
        );
      case 'Cliffs of Moher to Galway':
      return (
        <div className={styles.imageBlock}>
          <div className={styles.writingCard}>
            <h2>Time to look at some nature.</h2>
            <p>Today, suprise surprise, was a massive drive day. We'll heading from Dingle via the Cliffs of Moher and up to Galway for the evening.</p>
            <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
          </div>
          <div className={styles.imgBox}>
            <img src="https://lh3.googleusercontent.com/pw/AP1GczMwQNezAJcr7yGwjWaij9dhz7FONGfDsNayAkBfl0Zmnzk91di3Cx4oQI5_dFkt1bzJ37md60jJHPPrx4tvAZyYB2hzBSPzVHk1knDalc_fiTD1mEjI6HjhX55CmeKzb9bneX3I9nvss1_s90S6PlK30g=w240-h426-s-no-gm?authuser=0"alt="me and chippy"></img>
            <p className={styles.imgText}>Stuck in traffic but rolling into Limerick.</p>
          </div>
          <div className={styles.imgBox}>
            <img src="https://lh3.googleusercontent.com/pw/AP1GczMcyswEus7u47SMXqhgO-bw3jKRqXHLXlOiGwIUtrb3Bs68eqYw_qwE44W7etB0IM5P-Fbrz4TJygjbvWahTccvUSo1zNWxNycRmYeON-z8LsZIDIw5M-JG8aFLVlgS_SJSu36dEFiGYKKhEHYU_PHhlA=w1114-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
            <p className={styles.imgText}>Quick lunch stop in a vegetarian cafe that focuses on traditional Irish ingredients. Very yummy.</p>
          </div> 
          <div className={styles.imgBox}>
            <img src="https://lh3.googleusercontent.com/pw/AP1GczOcvGbD5t1BrupFHx7JM9fOiNw5CLfpbKDqXFBe2TpnOIbXX5Owacl4DzKFJWo7gbOdk_YdSB0j3K2l78KZd5bC9LAbW5PjnpFmFJK1Wksu3tTYrClYvW2y486WavZwh3DwNkMmFC2A-95NKvNNr2hMjA=w240-h426-s-no-gm?authuser=0"alt="me and chippy"></img>
            <p className={styles.imgText}>And we've made it to the Cliffs of Moher! Look at these little shops built into the hill.</p>
          </div> 
          <div className={styles.imgBox}>
            <img src="https://lh3.googleusercontent.com/pw/AP1GczMuoUp1yCf4gaOtu6MRpwJSJC_3c7klTuuYUviV0wtLrS1-cnr_Upj-MG-YXacH54wg6Ys6rbCOAUKdD2MY-srbKtIKO5sVlRtqj1PswEs2qpgnTYSpe7cI9FEh69LOpXviPi9WoyR_kBXuWxArNpmVIg=w1114-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
            <p className={styles.imgText}>The Cliffs of Moher visitor centre has a "4D Experience" which is basically an animated drone video of the cliffs and the ocean below where it sprays you with water and blows some fans. It was very silly but made us giggle.</p>
          </div>
          <div className={styles.imgBox}>
            <img src="https://lh3.googleusercontent.com/pw/AP1GczOME0wOMrzr0AJX_IhYomE8K6vtyjk1hIut_3r-pAmSNzcUdC4HE6Sg_GAxbMtpCtV_bfy4rvbdnnCRdVyhzlK8Iiun4f997jgrJ_9D90ngWDUsoJmSqujNraPaXFuKCVYfb8jXKfC9YEmzv-MPYSXGcQ=w1118-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
            <p className={styles.imgText}>And here are the cliffs themselves!</p>
          </div> 
          <div className={styles.imgBox}>
            <img src="https://lh3.googleusercontent.com/pw/AP1GczO2OEVw1Y_3REa6E0Imd9MgKYs6odD4oM_CouRQulRa5P6YtY3W5rw6A8y3WOUpVE4AVwx61Tv7DR8xeXM-ee-kjJT8Dm5otwM4lceRDUOJ61UBkbeAcpTQ_w6pDnlalDhbdnyfTm9PXjsdX07kpPHY2g=w1118-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
            <p className={styles.imgText}>Pretty cool, right?</p>
          </div> 
          <div className={styles.imgBox}>
            <img src="https://lh3.googleusercontent.com/pw/AP1GczNoBdUmyy8KTjZ2s5sV1j1UWsbrV1ZXVT-JG8iXTgekVr6TNHxEMxnNTL_WkhLh6BFMfCm17pCfTInNh0KCe4M7tby5rF7BhLR8wNNhNyRUstij6zaJDK6ELpGV6DHLASbGTdrunFAawIRiiNNBueTVTQ=w240-h426-s-no-gm?authuser=0"alt="me and chippy"></img>
            <p className={styles.imgText}>Taking it all in.</p>
          </div> 
          <div className={styles.imgBox}>
            <img src="https://lh3.googleusercontent.com/pw/AP1GczOl_pVtPAqlMkRck1fmFGehDGa1tSqxOFJrSyKWjzRwB5ZKzJHwG2LfH2RHOIibEYqOSL7hJ9Z3y6Yhjvd_z8EyfvK5je8d3n6-QcHqMjYnQmoPajZYudLjknzgo9IQN9-KNJGx_Hbsrkh7FpTFCauW_g=w1114-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
            <p className={styles.imgText}>One of the rangers asked us where we were heading after this and after answering Galway, he gave us this hand written note of the route we should take. I thought it was very sweet.</p>
          </div>            
        </div>
      );
      case 'Drive to Donegal':
      return (
        <div className={styles.imageBlock}>
          <div className={styles.writingCard}>
            <h2>Off to Donegal</h2>
            <p>There's not too many pictures from today but I promise we were tootling through tiny towns and they were cute. We stopped in Sligo for some lunch and there was some serious prep going on for for St Patrick's day celebrations next week - banners, flags and everything.</p>
            <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
          </div>
          <div className={styles.imgBox}>
            <img src="https://lh3.googleusercontent.com/pw/AP1GczM9dtYZGTWPeL_AFulDoE65D46iBFezjMSGqaKokLeZobhkic1iq7QEsZH12I3Bu9j28r2FyQcl2_uR7EsE7BVIkX9u1aMmH7RBpmdPEO-cqkUBb1N1m1mM3qY0fi9RvXA98j7asWrDtA489mY1Gq1rgQ=w1114-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
            <p className={styles.imgText}>Heading out of Galway, we strolled along the beach and saw this cute (sadly closed) cafe.</p>
          </div> 
          <div className={styles.imgBox}>
            <img src="https://lh3.googleusercontent.com/pw/AP1GczMCYpQAPORQovFNR_fxdtrCEBBW31UA28Ku4dKfwm_VfiC4nl0OTcLAn9z6CnKDgTq0m1x5UR_y3KlV8UUdOHmHms_a0I0PIJcSCdhzw9Ydc-KEGKftjgoeVP0APa8vNsQ7SKwUS5iFDnm942c6RbDfFQ=w1118-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
            <p className={styles.imgText}>The famous Blackrock Diving Board along Salthill Promenade in Galway.</p>
          </div>
          <div className={styles.imgBox}>
            <img src="https://lh3.googleusercontent.com/pw/AP1GczNvuhfPBb2EiuhD8kGXOG1eZ2ViPOxghYXQLvXJj14projl6v0EEOrLYYLQvGBvLH3xCpMBOukZ3rUS1gATTX20pP7adJZGPMC8lUPuiIj6mc-CmusVwvcAE7fv47CK8aMKRI81g0D5mT88q3VgRJG0VQ=w1114-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
            <p className={styles.imgText}>At our airbnb near Donegal there was an airfrier and that was pretty exciting. We made vegan nuggets :) .</p>
          </div>           
        </div>
      );
      case 'Giants Causeway + Derry':
      return (
        <div className={styles.imageBlock}>
          <div className={styles.writingCard}>
            <h2>Northern Ireland here we come!</h2>
            <p>The time has come - we're heading across the border.</p>
            <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
          </div>
          <div className={styles.imgBox}>
            <img src="https://lh3.googleusercontent.com/pw/AP1GczMloLh9uy3LiGMrc4iKcDU6N9fbzrT5L7-dmR33K0rXUmO3wxmzFmNRwhLQCdp2XqetnR7YGL8yqvzc8VnymGnCR_h9A-BrWv83HtCpzGzhP4NPu1WrZx93VIzPaqsavbW3Td1a1S751NgQa8TmPxitZw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
            <p className={styles.imgText}>The Derry Girls mural in central Derry.</p>
          </div>
          <div className={styles.imgBox}>
            <img src="https://lh3.googleusercontent.com/pw/AP1GczMaN_3yx_CQJ0uLUd7Yc171c9n-0-quER6qYMG4vahFFL889ZYPhVZPDLyr0533U6tw99xTgrscnb7I2YKjEiogdKwzsRRorui9puqITnuuhpQLWAbVPs330aHF0etGcHTdhBpNTAmRDc9X7N1RYgaj9g=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
            <p className={styles.imgText}>Derry is very proud of it's intact city walls. Here's one of the gates in.</p>
          </div>   
          <div className={styles.imgBox}>
            <img src="https://lh3.googleusercontent.com/pw/AP1GczOxRbQYfmou1tKkVQcKOk0r2yXW7vkDoSoB5Ny8X_JbwVmpFBJ9ps-2ol2yAtRamehMGRJNItuHOwd0rL2bYT79IWBtalV__0FQaWb18JiNTrYq9Ths_hR2ZmbDFTwkWtPJQ60z8N6rEg1jPLcRT_AvPw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
            <p className={styles.imgText}>Taking a stroll along the top of the city walls.</p>
          </div>   
          <div className={styles.imgBox}>
            <img src="https://lh3.googleusercontent.com/pw/AP1GczOf5YcH_ncrdLeqMgrJHNh0ket_zaLUSNL-ywpOOlCk3btqVybYPQoy0eagWDA5WvUgxWzfPa_Dlan6UK1p-9ktTBwE1P2WKp9s06-BHs6y2lSazOhVhqWWQwcLbX6XnZgjvA6wqkomIuqHhEQIlZ3IXw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
            <p className={styles.imgText}>Main street inside the walls.</p>
          </div>   
          <div className={styles.imgBox}>
            <img src="https://lh3.googleusercontent.com/pw/AP1GczO44cNE91-OFavfijKq0X61lnzBZiELKS16YitTdrgrfusglSvGv5sINq3kvUPuGDDZvGFk1DcrSGOiOYkhkpNvhnhxjj7Cg_i-8xbc_XYCudrLldKt_yI_KI-JzQrrhD4f5znKdcocp1qnT5ZXwgpQnQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
            <p className={styles.imgText}>Bogside or the Free Derry area - the site of the originally peaceful protest, turned attack.</p>
          </div>   
          <div className={styles.imgBox}>
            <img src="https://lh3.googleusercontent.com/pw/AP1GczPgbVDExyeti8d3hvOzrTj1n9dYvVGuTNTwwdIehD65fG0PXqPc9G3kZT8VwAw5JRLaDTRjvTxf8uMhTqrPbsTfDNOYOe6KwsajjwMcK1UyejCuToU0Ev9pDvbywTqVEvmFpCXS7-QNQOnYT5IuTY9kWw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
            <p className={styles.imgText}>Now it has a rotating collection of political/nationalist murals, the Free Derry museum and monuments to bloody Sunday and hunger strikers.</p>
          </div>   
          <div className={styles.imgBox}>
            <img src="https://lh3.googleusercontent.com/pw/AP1GczNSpXejB5iAbTIEzLSkh5HTgdhxVX24RV3VJ9-sdKeJgPBSJ1TpH1mcug_iQKq6TGyRSA9Bytvmr8CtMLVzy7-uKwfIQ_XyODNQH1ETI6qhhwShtxLR2bIx7Hz-LSqJcbpjZFqtXuvviFcZaAkNhHRr7g=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
            <p className={styles.imgText}>Lunch break in the sunny (but cold) coastal town of Portstewart.</p>
          </div>   
          <div className={styles.imgBox}>
            <img src="https://lh3.googleusercontent.com/pw/AP1GczNw1F2oNqek5e7IMslFWyV8VTBqDr--6ejxSqiKrtusn9mwoKoMn7mjTEGTHlrN52NIicWTXS0VEZWkLOFMqtjWyQ79GdIBHpzwrl8puw4LHJpkfhdkyLqKSsi1nEuoDcBpWWv6OwTZ50vsNM4hEfXUyQ=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
            <p className={styles.imgText}>We were very excited to see the sun.</p>
          </div>   
          <div className={styles.imgBox}>
            <img src="https://lh3.googleusercontent.com/pw/AP1GczNXyeCuMAhzNd919gpzHvPqREhl0KcN9e8oC-FTzdEKh_E6qgp7BvthYTMcWo_s3N3av0-c0S6nX8QE3VRAdv3gtMqzw8GUYIb-b8XAQkoqAjnhQywbQUay2rG_mkAsAyj--UFSf-zEZ5gBo3eccdlQtg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
            <p className={styles.imgText}>Dunluce Castle</p>
          </div>  
          <div className={styles.imgBox}>
            <img src="https://lh3.googleusercontent.com/pw/AP1GczMXkDTD8VBAHZ1lL0wCQemD-x2iF_sGXFr091ULapen4jNC5piJmDJXixXUfmU8lMftFvKp6OgqZP7_-x9bo-hroqVEGtAYwM5aKrPLMpCiHi0R2bKoMRIm21s0InegpmIxUwe3Y0f3mDvCEF63Z-oGDQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
            <p className={styles.imgText}>The path below Dunluce Castle.</p>
          </div>
          <div className={styles.imgBox}>
            <img src="https://lh3.googleusercontent.com/pw/AP1GczO3ywFUJhWMJvaj2YWD0JR6VQXgJkI3ea807iY3A7cWmK9XeeFejdC2X19McJolsEfBAUMucNwoBjyQNBJPmdK9rdJb6LMXqcFdnVeDg7HHaC4Y3u3CrmYO-cxRL9G35JTkJBKd-Iboy5tH1GVgtOIpDg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
            <p className={styles.imgText}>Giant's Causeway time!</p>
          </div>  
          <div className={styles.imgBox}>
            <img src="https://lh3.googleusercontent.com/pw/AP1GczP7YPjBnqf5fa9nsab8W7A_bEi20DSUHg6aYLIbLBogLqcbHBvBxuhP1O4O_ewt4z_sGz_GIX9fUbWqzOXmhsPT560E_2cN6eLvQ9XrUasMSTllFJswYGjmPse7V_78Onqxe-900wk4p_CWj5PQvJMd-A=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
            <p className={styles.imgText}>There it is behind me in the distance.</p>
          </div> 
          <div className={styles.imgBox}>
            <img src="https://lh3.googleusercontent.com/pw/AP1GczNyLogpLeYHL06CqwGvISLMBTPJmiP13xM10tPj3W583pd2pkk_qGXDljJYYcIjFZ5BnbhW2ezAt4lzKxwoIUmVNXeYV2Tp_v8BB0X9kxz518it8Q8z8sXPRRDlJ3w92LWwZwat14LYv27nk1LhEjNTjA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
            <p className={styles.imgText}>It really is quite peculiar.</p>
          </div> 
          <div className={styles.imgBox}>
            <img src="https://lh3.googleusercontent.com/pw/AP1GczPrI5GbtCAEHE5UMgibSuf_QXVuyg2LhzRSQSV-AQR712Kcra_sxVaEFBGVVCelMwT2z-3L4HO2pIq_eg-pcePt-ibjycJJhUa4anjxY-NQ8nF_3QdoPTNmQn7aauTqkrznT5FJziClXX6i4w8xwM5a_g=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
            <p className={styles.imgText}>The shapes look really fake. They also just let you clamber all over it interestingly.</p>
          </div> 
          <div className={styles.imgBox}>
            <img src="https://lh3.googleusercontent.com/pw/AP1GczPthGmLvphfPE-0OZPk6Ys83tLFzkHpb-J8O40fv0XZtvm-u24xgWXsDZ9dMHKVQsGe0P7qxuEXDunnhe0yOuGSRy2N14fiX4EhYr1y6CEcxVhomfk6AFfE44Uq0e3oKyr_tAunyiBERlHZrciir5R8EQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
            <p className={styles.imgText}>You can't tell me that doesn't look fake.</p>
          </div>  
          <div className={styles.imgBox}>
            <img src="https://lh3.googleusercontent.com/pw/AP1GczMpsHXKzIeNxczddM_Qc7Bz2REJtmNtJb-bd_cp1I7fckS8y4oVIkyRK-FZxGJI1n0iPcUBDeVqKRjvj8-15fjSEj0MxXm3F2Q_totOcilxR-zgC_4fWsefp3r5NS9UmR9yeAmd4Iy8_qwvubofr1ohWQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
            <p className={styles.imgText}>The dark hedges - apparently a game of thrones filming location. The trees were nice either way.</p>
          </div> 
          <div className={styles.imgBox}>
            <img src="https://lh3.googleusercontent.com/pw/AP1GczP1VyPVYk0dYYII7H6kB5qTzplGTsX1cfQfi2VzIA9SAFolVOyWHgZnr7hGwY8PwQVriejW-W_70HiDJlcMJFlNTF9FIq1QS9XID0URt6k-WTojvBUO08tBMocjXqMYC0deTqBBHjbFdBChOuHLDlNtig=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
            <p className={styles.imgText}>And we arrived into Balleymoney! We're staying at the B&B in this gorgeous manor house.<br></br><br></br>The hosts were INCREDIBLY chatty and very very Irish. We could barely get a word in.</p>
          </div>  
          <div className={styles.imgBox}>
            <img src="https://lh3.googleusercontent.com/pw/AP1GczMcZxjQhUnwDo3-nW4aRp4s5KHUGkfYkJ6X4wjf18CbGCylpS9aBazm-h_kuFMUrCHaAlIy48-_HQp3QSjmwyLef0A4kiIfkWJq78gokybIXGlcixXkG7jSBoiFDd8UiBkwxy1pOI39cW8M0qNBz6VdTg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
            <p className={styles.imgText}>Our room was the absolute cutest chaos of vintage pink.</p>
          </div> 
          <div className={styles.imgBox}>
            <img src="https://lh3.googleusercontent.com/pw/AP1GczPLm354tJWThT15rSTVjUwFYOpDAOXrdjOAuTX2qpfdxk7MgjtZ-dq5rW_2VzI846KLHWNaMd5cyXqlhRY8M6wTzQOJELJLpkM9xpYbnX_S0cfQcasDAG0MnYxkCC-r2vgoW4KylDM_9j6uMrhwSNc4Dw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
            <p className={styles.imgText}>Read the sign, James.</p>
          </div> 
          <div className={styles.imgBox}>
            <img src="https://lh3.googleusercontent.com/pw/AP1GczNe-Ne06Lwf2t7nvdkxlQ2slQqC0FFlP-B2NrmMixl2u4S8m3F7p0WOd9efB3qiUNoz54RleYxMwdCT9nLFnOiUOINpPm3OkyjVpluuQ79lWj7sJnVlhA95YZ4JXd7V_jnHe-xEZmuO_uVZprlcUINuDg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
            <p className={styles.imgText}>Very cute. Very pink.</p>
          </div>
          <div className={styles.imgBox}>
            <img src="https://lh3.googleusercontent.com/pw/AP1GczN3NhO0y4I98Dq1o1moUEp6GeJfGiAfWTCsSLSMJWyY9zqBbbdnf6YUYYp0hU_Hv6s6baoZDszoiXOuBHHJbG3oCh4zuK2KUmp-xZmmq53-4X_I6UCLnEghruvx4pR2Z9AQYdti8jDFaa2Tx1zcAToRVg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
            <p className={styles.imgText}>We were reccomended a wander down to the local pub (maybe 3 minutes down the road). After being there for no more than 10 minutes, a lady popped up to our table with a very friendly "You're not from around here. Who are you?"<br></br>We were whisked away into an intense but very lovely chat with 3 locals, whether we were onboard or not. They told us about Ballymoney, the time they lived in Barcelona and how Liverpool is one of the best cities in the world. Quite a funny evening.</p>
          </div>         
        </div>
      );
      case 'Belfast':
          return (
            <div className={styles.imageBlock}>
              <div className={styles.writingCard}>
                <h2>Logistical nightmare day</h2>
                <p>So the plan for today was to drive from:<br></br>1. Ballymoney to Belfast and drop off our bags<br></br>2. Continue the 3hr drive down to Dublin and drop off the car<br></br>3. Get on a 2hr train back to Belfast<br></br>4. Enjoy Belfast for a few hours, then<br></br>5. Jump on the overnight ferry to Liverpool.<br></br><br></br>Fortunately, this all went very smoothly. We will not be doing it again though.</p>
                <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
              </div>
              <div className={styles.imgBox}>
                <img src="https://lh3.googleusercontent.com/pw/AP1GczMOCUtEVtsGBXj6Da4cLk4qZMTKsUQxRqFZEcn6SBjAhec1dWRuBP-Cp2DpIZjcy6aZq8h74YgIhVcxrSbzmXavGx8XQgE6WnZe_lheMC_FfPkAX4MUekhzO6U_6dD6d6t2obLqKpzLKuwMQYq3CvUhTw=w1118-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
                <p className={styles.imgText}>Touch down in Belfast.</p>
              </div>  
              <div className={styles.imgBox}>
                <img src="https://lh3.googleusercontent.com/pw/AP1GczN39wDkeWsc9kwTb2Yo-dkjaF1nNTUoJSFnQ0YuKAKTjvHAmeGbkOMBJMAax4ACjqO9cWmBtxwvsZgGuLYTYR2dV4CFG2pzjLqJSiYf1fslAWxlaT9da4LuGfOPCGxmxe_25rxPFgXj4rm6092eLhxc5w=w1118-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
                <p className={styles.imgText}>While it absolutely bucketed outside, we ducked into a bright pink cafe for a bailey's hot chocolate.</p>
              </div>  
              <div className={styles.imgBox}>
                <img src="https://lh3.googleusercontent.com/pw/AP1GczNxHMqWbF8mXUAFSJJJLGwVEgKN8jnQ0fqpW2fvlwIWp29t7wsBOAYpYHZNTD_Kcy6Gc38ceB78yrJdIg25RPn-PuLBDUn1kuwOb8mHOQttXAw321_QactQXs0l8pHQ2lViTDLB5PCdvXi3C_BIZ5pptw=w1114-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
                <p className={styles.imgText}>Archway entry into the Cathedral Quarter.</p>
              </div>  
              <div className={styles.imgBox}>
                <img src="https://lh3.googleusercontent.com/pw/AP1GczNYY9Owt4mQqfiEDlMtuuge_iQWuwUhCXgua47sfEF6J9AYDJZcjHM_WlZ7aAXSgzbNkhk7nsR44KNmX34cS7HlER5129V_pu4T6oESsJ6IBTAAKGKac6hxxZNsapLlfz-Wq5D_z_Sulm2YfT6UuSpBDg=w1114-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
                <p className={styles.imgText}>More cathedral quarter.</p>
              </div>  
              <div className={styles.imgBox}>
                <img src="https://lh3.googleusercontent.com/pw/AP1GczP_SV_DD5NyjeaSq6CYLYrzezCymrNCcraor0a3CI0pGE9VEb3FC6JDQRErXENNJZXZJCxWMzn0VhsEjiKU_tjeAFU4F-gj7OTbR0dxBnhLvkEEsAJIhjCwmRU5zSqQogIisdCNrWixibrYLFn4p65kYg=w1118-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
                <p className={styles.imgText}>Plenty of neon signs to go around.</p>
              </div>  
              <div className={styles.imgBox}>
                <img src="https://lh3.googleusercontent.com/pw/AP1GczN0KWq_kXgvzZQRkg1z-Wtb481zKFHt6mCGgrsdqtwfQikeypJKEykHaLgHEUQGTKYVvCpnGHtdK94OA4aKee0m5XEYRVVy6fIjfXzgFM2wn-Rb5e_WmS5hBMphEHOZaX-a67qyHDDG0J6QRvhiiZN9vw=w1118-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
                <p className={styles.imgText}>If you say so.</p>
              </div>  
              <div className={styles.imgBox}>
                <img src="https://lh3.googleusercontent.com/pw/AP1GczMpVbhtMDoFMAEwwV38GeR4JimQzOZkcya0s16bbDtio871jxU9JLCtKq4U3FaLYMnffoFtiE-BaPVgm3Z0yrPlnUNMYKZm68LqNBWLR4vf8uRJqJS4FVjdZCahwQLeB8eMTCfqW1R_wGQpmwy29lMb1w=w1114-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
                <p className={styles.imgText}>The area is full of colour, even on a rainy day.</p>
              </div>  
              <div className={styles.imgBox}>
                <img src="https://lh3.googleusercontent.com/pw/AP1GczMB3oc2q96A_WLNyyD6rDfDRcaDTCHHvC1kFhqp7GLROA6AFgSyMjFoEx4ce_9XhdUXCvWUHcC4-NjhjqjkHecpJzrehwbrImG7tLD0c_NH0iEnbWMTTL9fj-ZzXINuA6yakeaBBdzfaEU7K-BEjGPY2Q=w1114-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
                <p className={styles.imgText}>Very wet but pretty cobbled streets.</p>
              </div>  
              <div className={styles.imgBox}>
                <img src="https://lh3.googleusercontent.com/pw/AP1GczPEFE4DRbGw_ZYApdhpfjh-ifuHDQ6ccB5a9pnYj0iWGiSl08W2ghVP3TPkM3ot5qfFUWHTgiEYccMTozTfuYyWJNZZAe2wW0-HXnJGwGZwTNXDgl4kdJcAvA5ngjrqKeAsQH-0Q0Bi-H9mPgLmN9zZ1g=w1118-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
                <p className={styles.imgText}>Quick stop in the Duke of York.</p>
              </div>  
              <div className={styles.imgBox}>
                <img src="https://lh3.googleusercontent.com/pw/AP1GczNMj2V5YdaDcLgkQljcpn7u67PZkI5W6kbSIZeGKZUC4G_VmeYdGQGa6p-u1zrEa-zWbaxVpzCWzazJB_IWLL7kYosoT_Eudh6nzK-CyQbIVj-999dJnmaUpkj7pnI8iy0l1Lb_2BmXofPxxmds6P-1Iw=w1118-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
                <p className={styles.imgText}>Enjoying a final Guiness on our last day in Ireland.</p>
              </div> 
              <div className={styles.imgBox}>
                <img src="https://lh3.googleusercontent.com/pw/AP1GczPLPoXQk-31Zq0Wwy0jNSCaYB2IC6XtFLAYnQrd--qY4qycN4qWJ8LXhDjGqZk8alNpG7Hc9-qDlyzNvrVO7hXFgXmOA-VvopEHOYt47HDcGxQKmxRp1Vls3d_vYM4MM09R6Sdn0cpUYhDSvEXcUrFiPw=w1118-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
                <p className={styles.imgText}>Just a sparsely decorated pub.</p>
              </div> 
              <div className={styles.imgBox}>
                <img src="https://lh3.googleusercontent.com/pw/AP1GczOjetgMILuuLarj8YMHU6b45B4Ww7tfixXE1rP1ydD7Fu-k9QjHWNB3bIdyY6L5GkSMvb63AwI5LKQrqNMihKQJXj8vAJV7HxOYhaE0Y9UNDm2PWHN-agUTcoz0ZI0YUMxYi0yVV2PKY8Mwx6aWTDieRg=w1118-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
                <p className={styles.imgText}>And finally some pizza before we head off to board the boat to England.</p>
              </div> 
              <div className={styles.imgBox}>
                <img src="https://lh3.googleusercontent.com/pw/AP1GczMSHgb_lldUdFCQzyQ2uA5RFaECWUAQOQb__a03aInwsnfJFmOw_5TsP-yQDZ3r8f_8POAvyjLAbhndME5oCH-uyBkV26giqIymQLMo_uPh5Wa2K4YUKYs-fuJslXIbmJuMKzRXWQ5_jneF8cn0Kc_KxQ=w1114-h1486-s-no-gm?authuser=0"alt="me and chippy"></img>
                <p className={styles.imgText}>On the boat, checked into our cabin and we're ready to go. See you in Liverpool at 6am.<br></br><br></br>On a side note, this boat had multiple movie rooms, arcade game rooms and maybe 7 restaurants.</p>
              </div>           
            </div>
          );
      default:
        return <p>Select an item to view details.</p>;
    }
  };

  return (
    <div className={styles.irelandCountryPage}>
      <div className={styles.countryHeader}>
        <div className={styles.returnButtonArea}>
          <button className={styles.returnButton} onClick={() => navigate('/Home')}>X</button>
        </div>
        <h1 className={styles.Title}>Welcome to Ireland!</h1>
      </div>
      <div className={styles.countryBulk}>
        <div className={styles.entries}>
          <ul className={styles.listArea}>
            {entries.map((entry, index) => (
              <li key={index}>
                <button className={styles.Entrybutton} onClick={() => handleEntryClick(entry[0])}>{entry[0]}</button>
                <p className={styles.date}>{entry[1]}</p>
              </li>
            ))}
          </ul>
        </div>

        <div className={styles.entryDetails} key={key}>
          {selectedItem ? (
            <div>
              {renderContentBasedOnEntry()}
            </div>
          ) : (
            <div className={styles.startPage}>
              <p>Hello Emerald Isle!<br></br><br></br>We flew in from Barcelona and have just over a week in a hire car to tootle around and see the sights.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Ireland;
