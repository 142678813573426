import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './Portugal.module.css';

function Portugal() {
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState(null);

  // Use a key to force remounting of the images
  const [key, setKey] = useState(0);

  const entries = [
    ['Faro', '1 - 3 Feb'],
    ['Lagos', '3 - 4 Feb'],
    ['Lisbon', '5 - 12 Feb'],
    ['Sintra', '6 Feb'],
    ['Cascais', '10 Feb'],
    ['Coimbra', '12 - 14 Feb'],
    ['Aveiro', '14 - 15 Feb'],
    ['Porto', '15 - 19 Feb'],
    ['Douro Valley', '17 Feb'],
  ];

  const handleEntryClick = (entry) => {
    try {
      setSelectedItem(entry);

      // Increment the key to force remounting of the images
      setKey((prevKey) => prevKey + 1);

      const imageBlockElement = document.querySelector(`.${styles.writingCard}`);
      if (imageBlockElement) {
        /*imageBlockElement.scrollTop = 0;*/
        imageBlockElement.scrollIntoView({ block: 'start' });
      }
    } catch (error) {
      console.error('Error in handleEntryClick:', error);
    }
  };
  

  const handleCloseDetails = () => {
    setSelectedItem(null);
  };

  const renderContentBasedOnEntry = () => {
    switch (selectedItem) {
      case 'Faro':
        return (
          <div className={styles.imageBlock}>
            <div className={styles.writingCard}>
              <h2>Faro</h2>
              <p>Popped on a bus and we're in Portugal! First stop is in the Algarve - Faro. It's a cute seaside town, seemingly very popular with the English. </p>
              <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV8521ufpGJUtA8OBefttJXbxrXH2u7gL4gC3jc8httU9gDl3ECB_wwtqgbL94J0T-d9lKJaNCBLnJiQsRmqNuKQ1IoK4FNwgryoUuOCuqFpKuQoXpfaVPg_MliY91wpl58_ZI0FxsAxSybEga7dbKYbmpA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Proof that we were, indeed, in Faro.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86x6xCtjPNFMi5xb-YO4rxTeniLwuEwiywsD0--L6UKilVe4_nvX132pcbUOZVkqNK_7AgFnIMBgLCHdZDViPmWLY6IxI6n2vrjj5VKrVebJpzC3KrUGXaEDf9Mmw6aAu-NxaxFLhEAH6olSMfweMu3JA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Big fan of the patterned cobblestones and pastel buildings.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV846XhsEcJSk1OoXYjcd8M3TJyGs-Q5hLVA6PWFaYAL_Jvpod3-K0e3cdClFcqu-pHmd6EBvTk_QXr8mQgiS5Wfq2zwYa_aU5Ou1tbJ_c5RRfZfDgRMzcfjanjBp3X4SRHsDdNHNN4oW4bquQbEvu200gQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The sunny marina. Do not be fooled, it was only 16 deg.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87iIkZEmE3UE-1p-DPBBxW51zCSih6W98THaSpM0cBWPfL7tr1CbeVxbClTtDaD6b6JtopfPBtS_s4VlDu5QJocql9hGaQxk9a6Q45OoLoGMPz0EyN_7wXymA3hByyhrU6rCrARp-JY212iA3BLw75Heg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Apparently Portugal LOVES canned fish. This shop was very cute and they even had a vegan version we could try - carrot and chickpeas cured like fish. Odd but good.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84NYnJ-9Jfs7cEbIKxy6zLfVrV1TRUECN_UuSR6uiihUG6Q97B6yEC2CCh7mcnpkI01XIpO0D-3P8yvAmCODnpOkcD_fNDe2IcY4AM4Gthy3hfXnSIuCm298DzSjIJDRdf4U_pCW1MFt-hASt0P2RHXwQ=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Our first day in Portugal would not be complete without pastel de nata!</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87FbrJYn1lvkerhyGYYhwOH_X0povA9oBZZIq5ceI3ZraO9MwPZkBXxn3XNDPm28oVybCNtvblR3FdMOhZbLRit1Ausi53L2jjx2S-d1GOeG-EJ2gMeKjsJ029HZrwkbxv0fgbUgNY_08weoow7NGyOkA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We found a rooftop bar, looking out into the bay, to enjoy the sunset. </p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86OKexBcPbRDYSKc53f0b9RVnH6_QrUc2ym5z4TgPf5DPRjW9yKdQ5jjOhkfAfVOPg6nXdpB8TFSeM-mJUEK6j90T0Xtmc_04kVIr8wqjPtASYtbh0o1pq6ArA6NaNJC7Xrt0Z1Vo-X-wBOBViGBJQXlg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The marina and old city walls at golden hour.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86OluuRtw2w_HK9Zbm2X0cOkxgeV7-oLMDGuafOKOmkHAd2gkkBEHo17_NilW99GTEclAj-60qtdU0QUhEMyszBe3nBbQpgEIjkD_l2SUfYWMwLdsa4zrwMI_EKj6uVcvc7JnUCYQuJDVJDmWUByqSWMA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The bone chapel - yes as spooky and weird as it sounds. Apparently it was built to remind people of their mortality but people like to get married in it now. Each to their own.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85Q6RjJp2paqF3bEi6Df5fO4r08CahTwkKRQYjXm2Aa4OqOE_tdKyAtg5vlQY5Sh1GGZuRSp7hlJw9BVI8F3YXdpOCaWf-P1Iycq5VPZJ97CS0i0HrACaHcBgGRrV8dhuOvE4Hwh9OXiq-EyEA7ihm9YQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>From Faro, you can jump on an easy ferry out to a number of islands.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85a2f33rjWaODpYMDVVlv5m2M6jISQ9G1qNKx4C7jWK17g74oOgefwzOJ_GAV1f-aPu4422PPg3YQ8d3EpXSdU0VKb1yQzxnrzPHReJW-5NbMSEAjPX6q41EvwjFtmJPHDTcFN7nL1SYK0RWdfOCB0bMw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We're off to Ihla Deserta!</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV8472jR68ksvu_EughS_w8Q8v2vWk93WGAOgK0ioPCty15FntZ7P2yQh1k1j3witACx4v3THQhqdoCR-NbpfBMj24xoYQm_aoPwh8chHIegrGpL8Z0hUxai2t_IaaT4PvSGO-NaAFjf_nl-YhdGxBJCIUA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>A boardwalk wraps around most of the island to protect the native birds. A lot of the old English people were very excited about the birds. There were some flamingos off in the distance, which we will admit was pretty cool.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86Ty-RIUbx_WrJdBmN9at0IJ2YUo9PqQ0ORQ9wS0hpiyuVNn91Ed081FxWBzJwRqZHApCbK-G59ETWw8N-UJVi8j-6T9V-I20g3KY2yymHdu19wnqwD3f1wrORdzVBDAo5qOz29uDa6MgkaDaWT6k9Fqw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>A group of more English people asked us to take a photo of them in front of this thing (its a yearly tradition) so we thought it best to get one too.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86W-Bja3VNJF5gBcLeIlGMzqD0N1nUEuvr4viwdt8QXX7W29nZt623p8NWa-P9Ksr7aQdWpIYeZbtqrm-7I2etNnGBsrfSeb-8DjhntnEVicYdbwZQRRG9oHGpU1XPgNmcN4yObmFRmhPUvCLOROGn1wQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>I found a pretty shell on the beach.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84lt71u61VV8kch4sA4dyKICDTy69968YvM2YtLJZF2uL5EoaMcIeJC4sJJ9u1fT-8-OtB3IFdDiSqPfzI4BQ3JH2oVOCE1Ne18YYVw5rpaDi0Sv3JXgJ6_jUt82xWH1Mq6h4r9k_fcsb5Oi1--fAjjig=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We had the beach basically all to ourselves.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86cJDkDZNchghE-traWNNio8nAh6RdNtGGtLoFXN5lUovXC3ShkWib6Veg3jm9b-FLzJ9qbx8ySeLcdi5cM-4ww20-dvXHyrle0WjgR4S8UQ4PRbtMKyrz96SbBKVzE5Lnk_fAghzC2FuSnCF8c9xmcag=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>A funny little lighthouse at the edge of the island.</p>
            </div>
          </div>
        );
      case 'Lagos':
        return (
          <div className={styles.imageBlock}>
            <div className={styles.writingCard}>
              <h2>Lagos</h2>
              <p>Algarve stop #2 is Lagos! In summer this place is meant to be a chaos of tourists, nightlife and drunk english people but at this time of year almost everything was closed and it was quite peaceful. </p>
              <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84xPBamlSU5sDQFBftUKyRzNwbNJQL62WXfr9S3bmQnFCQs9cLGRMs6cmGevbM7viYE0Z0qoNhyVAQH38PxlBVoiFK7IRDp2O0XgXFZqyzLVdK-57D63Xem1aNAARVPLp0OMjpSwXwN_Sv3MibKgwGeJg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Pretty flowers blooming along a wall that has rotating street art.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84dpEXYNfif2PFL5edrdIMo_uN2GozVi4axktYEECQn7XfTjfRodOjKJITLlLKFaoc95mn8rOt1pH5EWlnj8eP0nwLpcGVk_Rt_3_bHXCmMWc8HIUY9QK1KVrAcqzKwWBocgDS593SajsAVLipOEiaKgQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We walked out of the town along the coast to Ponta da Piedade.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84WYRmUEiehPfLXCZu8FEzHWv5nPsptk3QfbHchnEm7DZ31Y7xM-UytR2NFCiO2ayhNUqvzfPEHFbppE6rgzgTa0zT25E-CWID5AnkX-HwIt3IfsX8sdGtf6OWTJtWMDcA7DjBC8jwy9aMh329rszCi1Q=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Very reminiscent of Great Ocean Road but beautiful against the very blue water.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85IelJ9ZMTliNF3gkYXKqNX9_enPhkJYaYbKdA0IWifXSAUIDqq4S6uNzQ2EaQV6B1jGB6xXhMmdHvQQ17wJTl5hCW8DVP59LwaTwDFfMlXFO-C6ppUUaEahZvGN653I1hs_W_xCirlyy6ZK3-Dq0rJ1Q=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>If you zoom in enough, you'll spot Ashlyn.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84WC0T5ooiNrGYrvakyVqSO4yyOSPAzk4geBezSbWXwjTzaP7Xd2UdGCwShck6UiQzlYmMx0JlmKj1FSOTf0G32qrqhTdDMBn82dYHR3cEJ39g8PV31ePU_N3pRuQbx8lasIRICqGIPUbUt1BK6pxSTTA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>This is the famous arch shaped bit everyone likes to take photos of. Pretty cool.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86I7-c__oStezBzbPc4EvYvPZwUvNjfxV_Evg6jXbVGdxnHvOQMkfKeY-nZfH__JxXX1zpt83tnThahOsFcO2aoru5SPEMy9BkozeZU1wCLgR_OU7CZEBt0YVeQCxM4n6PV5GkAyiq44zAyrzblvXgjJw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Cutely painted houses down the street our hostel was on.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84y922jCkchImDRPJM5dfQ35QxWoWb0H5UGsaNID0EIqiOuiuxuXvL9-16YWLEhRpw3rj0XDVcqRYIO_wtRrSWDTYmwXQtqJAz5Mvyq1vM-wivub9uuwkuvvOEPeTMob9p-Joqo551gHFeiZMqGGT6Hjg=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Ashlyn walking down one of the many cute streets. (walking very carefully as these stones are all very slippery)</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84fakfSjF30CGbzeXv4TW7QJt9KoIqquxIPBhNcRFmTlafBYmeIxt7nm8XNmv17MNYCoj9JhRM_OEyvwJgfEmKNGAGJp8s27KMDi1V-NMSY01k7WgXW8zcOAaJ0Ih0IMp7daPlJnK5d8PkRU6zrBNbzjg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Another cute door complete with a fish mosaic.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84NrJDTvKvwRncLXKpxZktRiFXb0rnM8NpK4IFfk6VYerjLLwtLULKkecf-JR49XBy450sWNevx0k_ExkDyX7JJ2iLxGjNaEELHu5t5alRyMlKCkFFAucRg_RYZPe5w6LHaZf_r_u_KSpNHOF1BBnTthA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>I (Ashlyn) continue to be a fan of multi-pastel coloured things. On that theme, here are some boats.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87yp4JKU0oLySEljgW3-Na6gTVy0Mf51epIQ0TC8B3hBTuNhAZsc9fIBIswUOlavuGglPFW2sqN5tTbUk3VxSRfqK7dS27Ga47rpjGkzvZqNQg4gV297bs3V9e7bCfpjdJ2xnsoXDTUatkW0WHeBYuwYg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Fun green tiled building in the Lagos old town.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86HVY3GPlICt0XvzaYCsSgCMG1RS5ef91HFmdIdGQjHWdCNMmd0XCdhJGqGrOxxM5Cy8eOoJxavo0jk8fHp9sbIgL7x8so5FruCFpF8KVY_vacL66i7cCzHF-cSDGvRSY4HQVQuVzPrqeXxFDJOuRmF7g=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>This was a pretty quick stop so sadly we're outta here! See you in Lisbon!</p>
            </div>
          </div>
        );
      case 'Lisbon':
        return (
          <div className={styles.imageBlock}>
            <div className={styles.writingCard}>
              <h2>Lisbon</h2>
              <p>Welcome to the hilly Lisbon! We're here for a little over a week to take a bit of rest, give James some time to apply for some more jobs and generally because Ashlyn likes Lisbon. </p>
              <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85qDDxSwFofTvnhuZi7NtDIfNX5PSpTGVdXVtRgB1KKKWCnRYAbOeLT7IgSmtxoL2_jriVymg0-M0ydO1LMoe1VKREVh6BkFCvP3CxgMkfbxPWgyRLT_E1pceRvLTa5KLXvGRvd7k4O8WTRFhn3rqhjYA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>A view of valley of the Baxia district from the Santa Justa Elevator. That's the S. Jorge Castle in the background too.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86sDjzTu3Oh4Bl8a1UbYn0_MmbsDkU0nrhnbVFvfKjtJtRxqPa2vras5wZPmYA2rfzxIVrzK3MrLvrw4rH2iqdBfejeb1jAynspZU_vxCz3cVDYfpa5UluRxxsbHQHI3YlH2YXhWr5i9cMEkDHNxmbB2g=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>There are a tonne of fancy vegan "concept" restaurants and we found one that specialised in veganised versions of Portuguese food. What you see here is a Francesinha - typically a sandwich assorted meats, cheese and a beer sauce on top. Ours was filled with seitan and shitake mushrooms and was a decadent but delicious experience.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86072b5_m-OFBnKl5h0qJ9DffinfZtKQXfNwASXt1P41M7m_1gMBuTuDAPtrK6cq1vS0Yq-08T6gPNHnM-CJOTo7etUyzt4pQku_yry2YYjk_HUvDEuqEXxVZutZTpSkFiSGkEfnWJT-BQVCns7Zkor9w=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>It was served with potato chips which was odd but hey.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85WxoBniQJUl8hR5gLuSkpaJeRtP5pzWdM_TkovkHqXsVPUumC4s1m1hHWjE80_sr6_7_eCHUafB3NtDTb161OG8VQ-5iPMQwm2dQrqP7OhXcKsq8ej83qB5iVDLbcuZO4qBFUyV1mH1SE-vcs9auhu3A=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>View down to one of the pedestrian streets in the Baixa area.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87_HmNMouApsRM9QROV78R6mUFOfsiRQKhEh_JykblG6Er92kKjIw3Ha-WtW-W0a0IwF6lZx11HqRND1FWtXCakTymJXTeuCEANslsNZ0pFT-jwQG9O7Q71zXeB42mFmuYD6WQrUO9zvXRHWUYAAO2RZA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Praça do Comércio - a giant square that opens up out of nowhere from tiny streets. Though, if you stand still too long here, a random man will come past and creepily whisper in your ear "marijuana?"</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84rHqjTH7CGER584bjPoe76RpHI_QL7U4-zzEpSTtjoFTwFVKhaWKxmnjZGrTJDTzcvcnEFWwqJ88l3-ZyJ7aZEmVjFd7Pt_R7Q1RILXNQiTXDpp5MZKTZ6lNmKO4y-c2iadOUTpOnamjHuOMpouQ5Nqg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Traditional cod fish cakes - we didn't try them obviously but other people were excited.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86nDJ54J2teKD0GhfDd_-p6MIVifd_-HSMbAHwz0s8Bexp6LJDD6PDy_abLKtmAbe4pOFm7fK_ykvkKa5p4kTau99EhJflMYKQ5XVJyGDoz8xMOTIb8nLcYS9O7OsQXEP3oOqEMj3Cz2I-GfWItnBSeYg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>On a walking tour we were told we must try Ginjinha (cherry liquer) and that the best place to get it was from "the grumpy old man on the corner". His description was not misleading, he was indeed a grumpy guy.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV870PY59NG2iNdm7HRrs7fzh6ee1AwLv-8nRS7ZE9rQ0PDVKKGoDxQ9EQE9PHPfzZ4QPoqix3Z-C1WV1VmxgYDLGA_LXpS9fDjfG-AKv-e9J8AsKsMSGAdnQusv2YOoD1mHq_6yF6YIZYNvvm2cHMn1bwA=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Here we are trying our little glasses of Ginjinha - its quite sweet and thick.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86ZeRvE5J1Rl87CyGy8qRrDb_I4DoFNcKtMPHv3CW88GSMmGlAagmLVD0k7WG0rux8Dxipe8jri4aeoH0AioIObipNvY-R-q2pALF-uZ176GcNoxCW115hV2NDQL5pBl2w27L2DFIp-UqU1x5U5CMcnEw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Cutesy side street in Belem.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86YdweLK0_w-OSFBJAttjlFrrrf0QVonCni-OZgQjLYZ7A5qsOW48eZtDWYbzDWfhJMoJt9bXIq5t4j79C5gaCrO5MxwqPh7MSVVIwaCO_UQwfF1F253y09uKsopLVpFCw2oq1VaAX0Q-21Uw0dBeiWmg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>An art installation I was a fan of - it was titled "weird guy". 10/10</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85aZFSzsPHrdXNRIezuNKME17w3BfJ60Rjq5RovaRHcibggIoA0JOPx0q0vwMpz3r1G-SzrSxSiV6XMI9zToHhpxclLUOzAfaxMvdeyRm4G7rkVPBHg_F7ol1m-cC4s3X9BUjbanMrPO0CCshjHS6x6Bg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Happy happy James with a pastel de nata from the supposed best in Lisbon - Pasteis de Belem.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84ffy29lWJjcYTzSSuTcIQx-90gQlGTPKMKfi-oi12SFPTNTK5IXGtiSPWZy4Q2u5I-94tp5mEYPxcpe9xEpv6iCOXQMTai3g2QdoFwcCRCtLr9tjGB5sGAH61wBT8FB3DfzDpGj9MjfEM9vCIYaMasfw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The pastry shop in question - this place apprently sells 20,000 portuguese tarts a day. It was quite the operation and the tarts were very good so we believe them.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87ix7x3j8ACYQDHZvu96VYqT6zs2oUBTaeXssCXKRyh6A2TuTttPvrEPLNV_YptYoN97E0WVdQRrPMEKrgciU9goY3d2VvPtBU2t55pvgYQpY3wsV1moHl6M_rXykpj5WAe_X8s6dDcDpoG3ePgRQEdaQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Absolutely absurdly giant statue installed to comemorate the "Age of Discovery. James in front for scale.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV852wykhulUIzhkzllLaGykCphwRkRQ1TzxXQ74qqHQeHWOYDqqGC-ukGir-4NUmivOfbNp8EyL63zvkjRO9WzfvkXgDfRfvRcloiJ4LXkMV3W9XCt86YWVoPo72L4ik6pGzk8BFlBSLNb1Zh-B-wcVUwg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Tower of Belem.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84YFvCucYuvK5g47bw81HM3ytdR5uU0KEUeyk4r39GtBTsW2I9ZAjSxHHMycWWDvxpF1vYq3PLCesLHicbrSWRVdZHPyLosGUPoLy3uZ0x53eBbh3GjN7WwdZXaxhO5l_CCgiF37Y0aRXsVKN4F2Pr33A=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The view out of the window of our room.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84WNwoBtzv2_h0e72tMNgGr3RIaEUmQUOEjghmQwAm6X-laksSnOFHDdtVeT3CsKvcdByfAFp0p4GdAJCTPpKKNaqIucaSTkwd8XYTvPPprpkFmXfVrnVHCNbIOCLi5WVPvjNqJJffjrpXzAuoR9HakYA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Painted tile mural.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV848paDaJ5rVVZmugeY2cYAoQom4hXQxY_vPB0A_Ikq2e3NjMNLCtWB1xg2F67_2f2V3DMuYQdJKDW9PQD8ekY-piRCxcP8Wy8-HQqjrQF6yT1zZ9FEbmrG6N0Bm1M4_Q00boW9hUIqBrP2i2NuPo4r8Hg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Time for a very rainy wander around the Alfama district. This is meant to be the most traditional and authentically Portuguese part of the city.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84FEAJUM7FwKZzqfJKS2TycEQlAZC6YmoZ8hFs-CdbTxcX1Elc8ANUPi8MeQIJkCizvXnezhV3qtpS1xY0rFEnIgQNb5-0mG1pGZwd_9OOSmvY6ThUX0cD1T0yUD6Ki4mrzi7u1qf5VoN0XdZENmInFSg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Cute tiled building in the Alfama district.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87MljIBjPv1S8CIVZXNiRfIJLWKWXzDNoZtvREzyr-xLLHLZOn7f5lF4eFuLOM8zO-NBTwiIV5oaBpW0O2QGQ2PPGoV3qjGceMupKkXtSxH8_4f042vKSPFWAL_CS8EmOHZWESur6cNLyFFXpXKDCXU9g=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Collection of coloured houses down the hill.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85hryGNwGzqvLGDIldkZ7hzo-a5xZhFBxcUB3qc8WIb-nQ1A6A242ck60uuoufE4LoocVtRsxdqb8CbPJmNBE716i6WaKzEz_sn5W7MNIj1EeuFn9--7tclAkvj7G6sbpttk3SbV5-tC99DxEyIKLsRIw=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Fado verses on tiles on the walls throughout the big Fado club street.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84NqOTUB4xviaLFISaWI-FlEafbAHAT2QBtusM8pFx0EI2YSu8V8XQp_-7djKP9zLBAGZ0s553OSkd5oRLR6ZCvvLWuGz-SZvRE6hghsP2vEHcMcl2bIM1ddFBJVOHpm4WcDqwwA0jBB4536_QouUcSDA=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Outdoor escalator up through the hills.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV861tWiUb_O3PoexR1Mej6GkqZYmCIZiAHeFH5aCAVHiiH47hjTDa21faMWdlz83jGyf0JKsBJL2mjcmqVFboXsPUdRBg0VJjQoqcEf4pbqVHpRGunMK_iLm_G0-km2cnZD8gGJ40-izPvmSAx-kYlkhgw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We booked in a traditional Portuguese tile painting workshop (big thanks to Tom and Liv for the christmas gift that funded this :) )<br></br><br></br>The class is run by a family tile painting company thats been going for 4 generations.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86TE-cpGTj0HlAxRSEbNnWOs5BoJyjOBOn-BucptEnNJxzHv4SFWb-5KSHgT0L0bevNih-nLHuedmUJeHlqb_AyGszxTwxDSulqJ3UoSBRnfIhhPC9pjcPp4NW801SJxwgOdjwcaRM_Swm9uaKy7VqAKA=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The tiles are covered in a layer of silica making it quite a strange experience to paint.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87RYj6z9RgaagvYrU2HI82U96UIPZjjrAH2XoM21G882GbNWz5cLkCxv6q_EfeHQysAUcEckymLYSYJBT_EXHj4hM-4L3HxLmrIONRzw0YeqHjs8TsDca2GU0H7z2Nb0DAMnWYwdP5biS45VyUiRL2Ogg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We used stencils of tiles that can actually be found around Lisbon.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86rHhHDbMyKlz3p7WxOa2wqwL1kYRDduojV_6AH3OR_UZh_TLAmfJshtX3oSdbVanVo89ZJKwD8ecbK4EW9Da89n1WFXFosxpe7bY5RtQT98ePSlfLXGlPVUwHVr37eg2I0JzvI6pQy0EDLbt8DOdA9Nw=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Trying a few new techniques.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87OzEGKGi2J1RN_EhEL3Y8lBTVHNIV8fHtyENQgmAhZ8gX-P_sJHcCh8WGiKtheaLPJ4edZQBZwD4Od0OcFttiGIqo7F_AJWN44iVTuQVSnSrQCghQv_kB9royScyPnontwMr5GQDFTEFNISrgChRG7cg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>James' hand painted tiles! We'll have them shipped off to the Netherlands once they're fired.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86YQehHD74WSo-UlE19Ry_s1md4TTzUNf-9dTIz6TUiK3ODnG6SstPpyqzWXVNT-FgyavnQy6nvQLRieoKsWlDnkbhEUywdVIaZDyBs4WDv5dqrNTHF-zETpA8Ibz_ozdjtQa_YtIOb0N7N9e-QJIjUbw=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>And my (Ashlyn's) tiles!</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85Y0HoPN13TODfyFDmasgzbb0W1IdyLzjIDCqRPoS-f9GfDszKouv5veaOlpbBkNotM4FceE8RaHc705ZL_GC9H-28aFTVseoeCFufdQqZdAjJltcKgXT4QePmlwaedbJqXE-o_eqGQ345ROauJHEXh4A=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The class had about 8 others.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87PUwDaEpPYwfxoyuRs43kxwRqkqzgM-SibcRGM3Y0_o_LYwLhXF51ZGFUWAsCsFXKTgmkOpo0t2BB7tPWrzxrUvFp_X28J2Sraubkb4cTSwnZY5xfR1wTeUbJHAXQws5ihZkxZRE8TN__dNadQLPprhw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>A ride on the famous 28 tram that goes through the hills.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV848ONBGwG_FWYIou8mfnqcA2sUiItvOkEy8gvOURfLxnIf7V9-7iKRLJV0kKklT9JxWqBpkE4VNywz4OUwbxOz3iVm6O_K-YuYAPZsMH7fFuWZYVIquRje7MBvwrLhs877pNrXmIzRiJ0ErULdwaim92A=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>It was quite a rough ride.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84aPpe3DxPRj1vUpKtD3WyYF6-Yopepebe5EMIujkMmn38gzUR9QV9Z6WUsX52CQMf4GlWcKk0Ap0lQ-dLllk173ndAAGM-jVy7LDgccotJIdNqNQ4oiCWt2aNY0Ts3lho2Zlcr7s-_iJZFGzS3fxSGig=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Fancy schmancy A Brasileira for a hot chocolate and Brazillian pastry.</p>
            </div>
          </div>
        );
      case 'Sintra':
        return (
          <div className={styles.imageBlock}>
            <div className={styles.writingCard}>
              <h2>Sintra</h2>
              <p>We jumped on the train off to Sintra for a day trip.<br></br><br></br>Sintra is covered in cute palaces among the forest-y hills.</p>
              <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87L85z5A5jA_4qAam7yXFEwYfaOfUsNcsCVB3Xiu-C5GYT4qUibsNQGxyH_x0pqiBMdOIl0SraAxBAXTfz210JSYF0thZsTq6XvvQvaaNcdLJWYTiUXnpTbtg6oqH4awA5og0o_NXxzS_p1Dezq0Zc28A=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>In an effort to keep costs low, we opted to walk up to the famous castles. Boy, would this turn out to be a choice.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85MLI9oDIfEMDGaR54SCEUjWYgNB3nMdV6AJyXElEXhAE_qAWR7eugDYBY0pBIXA4jTjbhDYBJmxjJPo2zoRtp1ru6rCXd8M-jPcedOmVNXMsO7v5XR_uUc_AwheprO_Mw4p-4afv0ZDLpdk8Qt7LG_FA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The garden paths on the way up were incredibly slipperly and steep but would occasionally open up to very cute fairytale-like courtyard like this crown-shaped one.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86doxRsJmZPx3YYQ6NtGm-Vt0GWaZP5O1wDc_8p9F1ffQNGm6zjheI1vwZS1iSTE579M29TCDxqlGj7fqYAQBzBo-UTnTQ0JcHIEiWftV8GJ-tsYZs5JwlTKsn98Q4k5xfUAaPAB3IX3Ubz3vRV1UEOJg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>And we made it to Pena Palace!</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV858-WjmgCncnwh0lqIESlTa-d3_nDXLIEKJ1JZ0S2fq15CvSHyVQj8meV_iNg_c2N2T8WoEw5Y4qEPWjZt-JuOKAUI1ZUv8EF9J_lyoITeiaAqYxLC-or8x-5IqMvOQnwtQN4e4K39aQ_Wr5XUNNyKVeg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Incredible  views from the red pillars.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84DjSlcXdZtMNKUD5COtkaPebo-OhUeHluLqRrMztgpnU-K695foe8YlJwzTMbuIG4WCCV-WA7NIct2V0hf60XP0hkiiiTtFsgDoI2KW-CE9Jz8mWWiWChiVt2kJpWirgVkdOaq0zfHQvTTGm9ehF-EGg=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Enjoying the yellow and red colours of the palace.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87DWTc3Az7I155W0-QJ50wxU68LbLW5tJd3xotwKgY1kPJb_mGQbvo-vp3Ar1BlJrzBWFerqsep6TSurQhk_mE3tUbHp9MuweLEItHjcmQP_XuALIf5Cj3vOeLIkd7ZYKlUJ7zaPNzScae0EAEe_i6Vsw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Very pretty yellow and red Pena Palace.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV868vXQPCF28R5m6lIdmBMVFvYhDYL76ooOKi8MQ7v_W0v4GNuRMf9e1u_L8kpn1qnkVI6w1XY5c1l2pcXxZaiz0wqyREsNCJz8unfn3p_RprF2txk8yacRq1he6UJcKUnOhc173FPkg4m_f6UeGFLTu7A=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>And a matching view up the other way.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84TeveCLBc6-4qUcZ20hLvNZbSxkoyegbfeWG3D02hfERJvrvCJHlp-4RCqGbgrjW9BfzCiqzV_DdH0-kYid6RUtzLl6k-CmW4JuCmii7Ss4LDjRV_KnPauu2awCL3mICFDpGbUBkElWjQVWOlx3zrlww=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>More Pena Palace. We walked a long way to get here, and yes it was cute and worth it.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87U7XGWqhVQp7AUF048H82vjT1GY2ypq39ipcsfXJbsNa5ElDJceiAV6KWrLoRtUVWuxnPlmztkKzuL-nTUWqEDPnokMq9PlE_plhxeX5HIWQfTVT_yOx3AKe9rNyWugN-fzKf8mozkDP5V9gN5y--s8Q=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Stop 2 on the Sintra adventure was Castelo dos Mouros (or the Moorish castle)</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85h7qRBmFUg7gJlB0R8DbXcVApZ66RLsevhxY_BMxc87naTHkqdis_0wm-gFZm8CgyJf1HPGR1Gfb3bJPtJmeMQPZmx-WBwd9yySDZP73uPjshLiY-YG94PLiHbvjN0QxWcjDB7Vcm8GdBKWbfag070iw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Pena Palace view from the Moorish Castle.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86lnjHX7i5IyRPiWT-LLuXW6ibilqn_OeSM0yDBNDc_rdm7grHJeCUAFWvyuXkjVX1q97aWbsqPihmIlQfnB0Vzei6Y7X_oavCiwdIIpKUr2T11pMMrlOxTZOpMZsRWwCXrTqLFmYPpwpVOZGDxdfUxaQ=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Climbing up the steps of the Moorish castle.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84kB8BBBcVFPhtS3CdxQKiToiu-MCA2Ff42K1w2T7sYy0YdbwoJ-rv8B9NA5Yv6hDdH1T0xBfs73T4BeIVQf-bVFStGTw1Rpi-vS5rNBqnvixgtoUa8Jlq8QHnWMqZ89jJKelt4RSpTegCY1JhRewrOjQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The walls of this castle climb up the mountain.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86fo4BprQF2BDw40oohZcvsAZiCOBlhKSaka8T7_6mXbO21aO4MD4KBIxYfEwkmq8wD4D94I-nwjfVyrA7DczxLVigrP1SxnxN-DtVK_wQ0kjLxMAX34kzmC6_1_0mSGj-150UEnWNbVuTL3W251qU8lA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>One of the towers of the castle.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85Iu8kpbRh2FuN6BX82_HVdc6cwRrSFmjoceEldyCgVG1Dsb7grv6wfUu-4nlYn3csP2Hf3rBhgTuGRe7CMdl4DBGvXntuBksueTyL6zs4KtULfGdP7xtMqdUD2TVR_2LJL5EEJr9Sg2h72eCF-XZrxtw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Cute mossy walls at the castle.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85R0tc5pqW0OCA6o_2bstw1Kjtlc4C_FIWJVGnOn6qu17vTF5WKIqxOhFOaMjgV2tfaGuTiOTZxz6t49v8SUGd8OwaxwERvg6ZSfh1gzD7xkbUa2LrN3HfnzA2FJFmNeMEpZMuJLKhZgqaZS8YMvB7FKQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Another arch not quite built for James' height.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87-pZVTBPaaNeXjAvkqIzbFFaWcCx1tMQKr3hRTb0uqHN2zLXy-mivrESkgeQBXyVo8587P_hhN6PlohL1vHtrLSpF_D_EWhD-wfr13hnuX1-MCylrjPIR3fr-oUWOqgUInozHQs_8tkpG8CwkOIKgKVg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Old town Sintra.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86c_NhRb9zmnBpeNemrHQWgrigP--ITscpdRcpbwNCvIC3NzfTddU0utJSvRfX-ztkJEngLyVk_TF6fzZBb0wR-frnhwecbX9yj3mq_PR9q-A24PR0osZ6A9O_iUpQhQGynejuR0OQAnon9KvtVW2UEzQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>A local pastry from Sintra - Queijada.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87fvqlRybfJwr6CnfBU4YR-FXSh2Smdz5uOliYBWmIqpb4N4LwcV67j6F1EVfi6siC2IXS6AEgNpFD_zXVnqMDmaDyQ2IdwA4Bn-GbXV9n-FD0CWV3XqR8YlSVYuTHE8JVeUdiGOy_PWWJNF8zxpU9MVg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>And another famous pastry - Travesseiro which translate to pillow and was a custard filled puff pastry situation. 12/10.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87YVk5AoaBcEP9JBUjjFbYskby9LU_VBDov2QFcePh5QIUd5XuLTMFuUZxreRKA9DPJOoV9pnZIQ58nZORobu7HXGWiXY_4Xq_FkVLJjb_hsY-UVko-VEY-_l-FZiiqE6du1iCzEYBNN6SiodTdWmM7XA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Proof of deliciousness.</p>
            </div>
          </div>
        );
      case 'Cascais':
        return (
          <div className={styles.imageBlock}>
            <div className={styles.writingCard}>
              <h2>Cascais</h2>
              <p>Cascais is a coastal fishing town about 45 mins west of Lisbon and a perfect little day trip.</p>
              <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87qF8b5yRKyie35mtxuDJoaIM1gY-1ZzR7DX4ckl00MJLd2cLtqj6CMszUxwsl-usSvit44pTYn98f0KvY_M4Ig31TebouqgsDKu_YTzuB7VOYTx-JKL9r_pGBkVD0o4qILCB44LX1UGQQblV_d5bJD2A=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The cutesy waterfront.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87qgiFMyfkSsLDAtXsBchtGBoqPWDtdvw2Rq3fjhGzC2Smw9VN5hpIVRrJeOW9vrQISuifvLgdPsfzXJOjeDAsQGoIUj3S4GEfQ5cI0AybRjtdn0l_s6snnfl-gq_n3JiU7U4UHGUe5RHK3ya1bFsuK7g=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Patterned cobble streets in the old town.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84RnZ9UtMbrRDqo64ZbvaKN2-sKadXwmPpS6tJJbahrhuJK3dQQE2E2MQgkS8x4uKSkNphFUaNlaTVZvhOH3Yy-FIuorp2CyoLNs3aEfPDnPHZW125VtnyBwUIw7oC5uHm5nxap_g5OCyRC1ycfxn_3SA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>So we found what we thought was an absolute bargain for lunch - €1 and €2 sandwiches. Turns out they were incredibly small..</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84rCQ1JxLPJQwGbPPyGwprP3eokGMewFtAGavtc5qYleyGOvTzBLQ3J_f4tkBeVRSno4vgtxa0VvNd4X88fD23iN52nEonKrmDx0eKR9Jmctnd_okjN38uRBvscoeHxby55Te6syl1oJEOuRKkuYHI2qA=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>James protecting his tiny sandwich from a giant seagull.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87pbnBT8_bIzfaiMrKT69BAVIk6Z0nVPJDhXUVAJBX0M3AiubCon9l9GgudWA4I33eYJM_j2zQ2C9CqgClPoTkuP-qWlU_a7kWZraEUssMycPNEgCGzPiaqp5H1yGhjSNSckwP-lfH9wxOZoTw-eoh2AQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>It rained a lot this day too but thats what you get for travelling in winter I suppose.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86qKtwWK-8pxz2gBe-Hrj3UYane7vXB-rO2Z3nmsRpzLA_Lzs-IsJlZ6OCAAjH8v-D8d5V9WT5zx61yFykF69_SDcGaIr4Nqbb4LVMK9RqAIuY75lV3JhLZRxpMLkiKrddbTCuzRUUSR-D8NtPUC2JP8g=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Arrays of yellow portuguese pastries.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87njIlA-a2MC39_FC-4hPxcTsDCEdR0G99WeA0A4RLxvw5dux6bmHaCwz9600dVi7fVl7i4W6I_x0Z_PuS2nQ9fqA4l0uFyw5Buw535T1Wmuk4vOtROyza009AY2KtoLduQ1bHTEq5UBcQjgIVMV8t8gw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Strolling along the water as we head back to Lisbon.</p>
            </div>
          </div>
        );
      case 'Coimbra':
        return (
          <div className={styles.imageBlock}>
            <div className={styles.writingCard}>
              <h2>Coimbra</h2>
              <p>Next up we're off to Coimbra - a very pretty preserved medieval old town and a fancy university. I will note it is also VERY HILLY.</p>
              <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84yPItmkTKzubOnSESWH8dS2caCRw51JZjeDamT4W6OFFkYqk7SSIEQFLrzO_my6zTctGlFTzjc88K3zDs6ydZA3CQi-I52g-YLN0zYP11S0TF5osk5f2iBxW2MwH3kBFEhXWKnzyhhp-uwJCriNOMBdw=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Here is our very, very lovely hostel room. This hostel has been a nursery, hospital, school and orphanage throughout its life. Now it is a very nicely designed hostel. And hey it had actually hot showers! Big win</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87Y_ToY9MEdQBoXAUexCbRe-5dK2IlPwzHS81j3MtnKB5bBtwX1oP4OZ8zQ7kpGHlZvmZhZV0VSCVp7tuthTeygDsbdxKZ9ZC_ioWnE91c4r2_wAhh8I0jAVPxW-NeMnLh4V6bVo5AXDg1diYska0_g0w=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>This is the view from the baclony off our room - a very lovely people-watching spot.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86XIgaDYaMIA2scsbtq3wPynkZIAj-ekaMmei7oGQPkxgJ9KYEKCEbzT416rHQESFaQGcPq0ST9qdo3g81R9_wFX1VghosueruHGlmPqJ5X28HFXjgYQ_O0jidKznCj6Z9DVx52FHVENFO38E5oDYH-zQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Some student housing decorated in a beautifully chaotic fashion.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87LEFtCffV0MpM0iiY-36sqNz7KBYLPIHuv8AWrWRWm90dBX84YNweAjecHkm5LEha2dgCtdcbDbb0rlbVQ5Si9CXe5bjyDmrCtG5Zu07k6XRVJUXJ15U5BYoPH5T_5NLRIJXoDqMsfrU8_kC-0OZhk5Q=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>A fun mural among the university buildings.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV8501kfGTG6p0OySRATRetK6hdnziMKVyayoGvMkQDshtVD533vZ6Y6LwnPUfRsuCLSkjRNL2lMLQAjpQ8e54Rf4tr9FngvocT3MjY0VP5BosIyOE-nVAM1e9r_IDLFJ-Ze0nUbCRjrjhnOIfhpsHD5Tag=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Map man keeping us on track.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86vBkHROhpMkYIIBpQHlgsXu6urXSVqPlIfOocOnZtQyOGyrEqkw13nk-GYZbjY8fYyYJHNepUa42CeDetSzqdhH1ZfoEbJMjUURfAPhsomqJEu1Z7Hx2Clw9ZF9zgHgwDHsEPPlqSHzGz_UAYYNX1hGg=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We were reccomended a good rooftop spot to get the best view in Coimbra. Take a look, it was beautiful.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV869VzrmxoC0xXLhggtLEBIBKw0TjulE75PjGGKUDjq2RwdyXIFj0a1OeHYZHhNcWpFnf6INxxR25XRJs6e2aczhi1jxOtBP-oIRyjWZBWMxeSEYoSYDyu61t8XXxoGDjdE5bcQzR1vFByICge2_MR3tkA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Pretty view.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84sIWsFmE4ac13YzTwxDvjBseoale5plLLcB2ZJAeamVr-H9j8Z6lRYujquSJMv5PiVS70xA0I9S00KblTLRxuBso0mWdfDgpnyNozcditq5Ngp4ng1-KNSTE4yigUztmSVZb7w-WvV8a9NC7afEbP1pQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>This place is crazy hilly and a maze of steep 1-way cobbled roads.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87oZXlEJYPm26CKOV6XJWuGNctXOu1UPne8rg_863EEB6LVJnPyV_SQSWOJMsnD-kZZcm3Le2gszeNg1nhzjwE2__-psIXuFH8ffUYfGcHSTNbEiAt42Ylo2UlqjsVutoIN1ecgRLwE2RhxzswscYMVoQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The beautiful Cafe Santa Cruz in a former chapel. </p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV846PiPUWOxSuLqmQyfHr9o7sTn4Jn9KBf9Ua4HmStugmr2xLzLNe5ZeuvfcV9RZbF0LTz99ynCP60bwLaAmCIjMcwgjNCzIDw7Uq9MlXWd55f4OECS6iXvR9rK4BxR2tv5NIvLwB9WYf9t73N5oCgeQbA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>This cafe is the creator of the famous local pastry - Cruzios. A Cruzios is another egg based sugary pastry but this time it had some almonds on front.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV851gZNtLwfwhs_-THmbgrfafrH3y9-0gHO08rjqnxF4UB3KVKwKpFtPQ-g7t7_Wll06CXgfQuotfAUYXngGEjst21J8iAZqYhejGRSDSSvuIdNxSbF5lDiNOwQpxP88QZ8c7adExQpuPjav1gx3re0IgA=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Funny little walking man.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86Z_biQ3KkYo1TKMcKMnTzL11WEyNrbV91LylLqc27wjEMJ8tPyXvQ6E09S4cx9Q7l-_6U5VRHC4MZ57QE_dBOV2r7Wx_0pcHfVWIKg9G9SzIzMZOksNkApfJLoHkdJZlimz74E-KADdj5nrGGgB1ix-w=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>And an even funnier stop man.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84NN4v0wgNkjS8ZU9_ho7QaycGjpOunX7pO6DY1Dx6d5nPxNflDLw0K0khlyemphP5wHizwKI9fkOSGzc7wdO4ogIjeDBIv-ffqLFTAt7DjTuvYqBM2cZCYB-jxzQdigc2QU4VDK11kqNNdP3pSRoRGrQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Local beer - James thinks the name is very funny.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV877twpp8UoNq2mhenYhxQ6HTqR9QwaonGawjCzEXUi2RzolFuzWojIwZMCCr4DCyGIG5tjh3uRDMJzCAJ0KX79GwGHImHekvTFR6axEUHi812hPUkgbP-V_k9BFFL2ZKCzZEzJ51PRuwztFWTsDkU1OYw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Rainy rainy cobbled streets.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV871PzXQ21hDTfUCLKIcbjb441ez1VuhkukjDWODrvDNi3hzozsqdikynGI3KgEL7yC6nEtEb1eOhpXZg8YtNEL1M5nYjYQBI4KkbMw77Z1fHGv8vfjQOnz2T2iUfN_P0sZv88fh0mnx7NFqQoLoE1cRRQ=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Right up on the side of a hill.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV856A9LJsIpzmMAYD9ODrQV6pWhhywqrRKN-ajlwfksvDOLf-q5QMMWSW-X3ai6nnMK_HriOpk-_q7dCs6B5iME77zH3knfWsEeoqpCfRF9tJoCW2O27a5Oej7Fknx2yi-IKERe-C0EgZyQIxMx_CYHSAQ=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Trying to not slip down the silly combination of cobbled paths, steepness and constant rain.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84Q4OWSbEEt9V5wu75Z5hnrwl9kIk9YEEffVeAlBvJMYJU9_sTD-97IABZszb3Dy1A-zrGMHNfcyeoUUdRUSJ4qNZQBFOm5nBYLujtOKHaaCC2DS0upvXeav646iMx-Fqr34eeTD_jtnCbOmflyBzALIA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The big fancy square at the centre of the University of Coimbra.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV842-T18Dyt1ia97tiQYVssbcDGjpL5gd3_1K8F233AI-V2g6RprXLTAF-ESGeSpawAk1KCVwmUdgFh7vT0rXkxlumQTuYVWd16plcTrX4V3fnnoB-uEzust9CRKkgDmtkKGLEh_HEh86PF26qXV1263eg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The old city walls and pretty arches.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85o-CbTg-tAVBAbEYsuAitkaVmXuN5tiNeEZdrz9sXLt2f-BAz2BnlxZ3gMWk0izyBMIkOmloMxTXZS3JeGRkwlWws2qZ3Qn9hDtKKVr6E4zfpvie2KByzhTo1vYoXfntYGfHOVFTCM3kNT9ka9p8HV4A=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We took a stroll through the botanic gardens.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87fa-u_kExcYI8DL4SWmlmr9DtnmbjfLadQRIyicOsmfbTYYS_AJ09PohV6BLXBMCLJFxx6Fszqri-YMYQS1YlJbq_qsADZnZY9KUk9F0GnWqfJky6PdxCuik9_CNtpfRWZKKLIGRyTk3r3D_yj3jgyNA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Eating breakfast out the front of the hostel.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84JYwNA9SirtoTVGv3VR40RGj6gYOYv41ljoE4ZXMIAmMwMA9R1igGTL8OlWsgLvLwJYGGpqavRqneyk6wc8JX45A5EnCsiWYv3uIGOL0sMY_-xCkFzA7a6xO037BN3jRtfn_Sb-hZXNQ0X3WsRoHDaSA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>And we're off to Aveiro! I was a very big fan of the train's colour scheme.</p>
            </div>
          </div>
        );
      case 'Aveiro':
        return (
          <div className={styles.imageBlock}>
            <div className={styles.writingCard}>
              <h2>Aveiro</h2>
              <p>Aveiro is described in guidebooks as "Portuguese Venice" with it's canals and pretty coloured fishing boats.</p>
              <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87J6XJY40ubE8nqjoETXDkbIkKisp8P-_kKuknoqe3vlhrOcqNbpGqsIOGkdIW_5Z5ardZBiGlGbSdotHHcUAYtKP08VRycPDkWq7w3LjoWBA80Q85A5LVdu3CxNa_yXOhETXeGyWroVMda3vxCK9YPMA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The canals and the boats on first arrival.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84qzPy7KIhcqMNQIacrkjs_KB0JVHBt-aqdZhhA-RWs-fk0eCzHFHmirzoBOR5dBe8RYfcONfu8xMaNLk4dv5Inhbw75a9ui9WYEfAr4mielYDID_vvx90VNFXhN4oF0ph3V8zKQfpF7i17X5j2S82Neg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>I just thought this was a bit cute.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86SHNyyhk4IfiecXWhmm7OXiDAgPiIh2G_SGmBjiKSNPbKMZ0vSxMrh6GiAnyEDL1Y5nzBAqCxmayroEyTB0E5wQnWlnO0Pn6CsZKwEdjSZa1EI-UoLVEpFcQ0OANyAkxyBIFf6Gqm3-XGP0STeZ9e4ug=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The boats are a little bigger and substantially quicker than the gondolas in Venice. Absolutely zooming.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86frzjzQZlfsHNgVFbKZqmwR6Nq8gymTPWdffSv-1nXR183Jt_Gk7crA6FVUCmGk8DsfU7RBX19aaku18WS4J1vpdI8zeb_AMXpKgWiza9SjRQtviO0Le9UPTlRUI5fJlvbWf2gXflGW-6O5H9An_04kw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>These boats - or moliceiro - are also famous for their interesting painting designs.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85crxKYEieH67SthIl9y_TNaKozUSbeXaJ9XvUnv97leOUIuHy_tfpJmVAZZt79JyggXpGON1Hutz7dZN-qLrAx1TJm0Tm8O6ugUm5K5DBkUPGm3r9Yt1KymNW_a5CQCId-rZYzRs-bUfE-oAXkrivCxA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Indeed.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86Ei1kCA7uEO40WMXvaEYMUVLjTh5o97YkAwNh_0mt6uYIDNgrjo2-TKZW0IEJs9CcvapqbW8fjSfyIR1qpC1mIv6JiVqVqXgao4riyC4126htomn1HgHJtOS285Iy8mzcttMLu1FflQB67BqiWj6vMBw=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>More moliceiros.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85gETOXVQ01m8OQlpxm78dccTV8pm_zeNvESnuoeqGV-MgBtzVxNMOVemvTyoKS0qyLoW033o_Hw7CXnqkQdJCTSpegGYFaFRrEri0eg17Oum7hhF_umey515bb9e4ipLiy-cCQKVBbzcLIm6uQpJJrTA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Quick game of cards over lunch.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87jQlLUmX3xjAwI3i2J-8k8i-0KVdJoUJ0SrsN3MLr_bbWSBNj6LBbW9npa9SJYOeqNmF0afv7aLIQHnoLg58IEMd-ijCDp-TE6iLSc31Hq1ROVuufuRH29Ipgyjl4oOxvmdfBqbUdFXEpG1k_cGSXjmw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Pretty canal views.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV8482xWkMpCKqCPH8QOs-1cROJvp9jZHQKEoVPZyVgOG8fbCYCczZ8QCZlmFpbxrWCWCQXDvvVPosbzkkoj3MlFTkgIuZLWku5uxzZkmXz7v9d2_uaR9tngk8bO7U_bZmQY82_ZZyXDDm7Lw2TJjmUBChA=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We booked ourselves a little moliceiro tour. Spot the suzuki motor in the background.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87EqcZqafsmRHkcgd65yvUnPKGC5I8WPoePpWe9_-YiWHRYRJYuZPa-xbnHIcTXyeWKc6dHGl-G8Nx7fAn8MBifgUnNXinu0KaSpol1Ly6cLOOBjVQtafOEh1lXmgCB3_Tt_Sy3Me_Avu-oiCoCEXzHkQ=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Even more of the colourful houses from the water.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85Itw8IYldCc_VPtLpMYjUnNnnUBwSvfL13jnMc36XJ04cuWHqKe7GtSBQX9A8zn94NquyFZ1QV4iUnPgNg6kW9nu5sx4XS0wsynYmsWTIcMWnhkJA5RWkl1kIPisDDTHcmAZkj0UeLYmKPgnL3rRTOmA=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>In Aveiro, rather than lock bridges, they have ribbon bridges. Same concept. </p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87qn1BEAouuwqAO_Dn5xlEyhyg_LBpFFrHYj0GXIOZUb3OzLzxa6cjX7sVJii-ImEWKXgiGW5X27LWPMeDhZBFC68eQvKriWdSU529hzBQMmlyHDK4PpRd1avAHwJgZzIq5X0KnARMhXeiiMKIr4Vdp7A=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>And here is our little ribbon on the first bridge as you enter Aveiro.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85qphbGsAhQt8U_iwInvQi_8UThAiku5eKaiO9DSGcuPjGTN7ju7x9fnTPMeN9OmtDC-I0V-wiNZTo4Iaj5Y-X4j3YW2YGfW2rXdAEodRMVnyKC9S8Ou_497aN4p6bQ0b3-4C9uVXn_bK5Anf3sFQ6-UA=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Dare you to spot the purple ribbon</p>
            </div>
          </div>
        );
      case 'Porto':
        return (
          <div className={styles.imageBlock}>
            <div className={styles.writingCard}>
              <h2>Porto</h2>
              <p>Hello Porto!<br></br><br></br>The goal is really just to eat pastel de nata and stroll around.</p>
              <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84cccUrl3h0_MrQM_Gltnr66B9QUju5ypY6_JRmd1W3UxDI-j5guPpEt0T6e-tjGg777qp1y8mutthqqvPFfKcJPloJeFW-h9QtZnVcHW6jule8CoRkPE0OWsPMMV1fPuQEQVLoJyFNC5cKB1ZOn1rr1g=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We took the cable car up the hill along side the river for a pretty view.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85-lvrnBgGm1fl70p_XQjAp0I954bYs7DhAFe0oAnHfMHPVYVHKm7steriuxDcTnHNowp4jEuV08cvz4uQFeBz-YPwHTEdKEFk7MF35R2WBTni_HMyh0uLQLKuhjGmBatQTwhpog_IAb9NK1HFj086jsw=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Said views.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85FVpmTt2TNQfOPCYt2NcXiV8Mq9zz4Ff3m2G4sRU2gEJKriBbTWILPy2ubOeMWfbqYWbRu5XdSL3illsUk187I2oL_Raznpfy-nvGmCXiFd9mXOHIL5s6tHhA7RMgKa2IyOIJxpdooJUv8Al0gPSAl_Q=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Dom Luis Bridge.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84ZUxUqvzgXoLPXeo79On_ysNgsTq5oC0a02FhR7y_lV_URioK026INh7ouEtoYlVNNBF_l9PkzVKvDJAON-PQLzQDMO4zyTKW7IET1o0i84WLUYet74oJjwamHxxhSfjqJl7mUnnAl-Kr2uTGzbcEuOQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We thought we should have a second go at the Portuguese sandwich - Francesinha given it's from Porto.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84nAkjhsCfkqIx8chWcVyclkOWuhhAdIs64HjeFBby_VV5mLaKcmN8IR6gN9FrGhm6vi-K-SHg95Tq-RnpcUzU3FqrUU4HVCdnkch2DJMCNHv2caPlKn3dP3X-oQ2LIRPP4nRzOi07H2AWlo3V8HCPsPw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The Chapel of Souls with the beautiful tiled exterior.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84rTqESeAT9nNeIbrLt3yTNazYwJsopbxqhICBHAQ93_XxHgcegg0Zsk0lOXKRAaKkglaSot_KVxkxoO7GgA39vR_gFWyhW6U8xW5Wf9bjRO80ybt3_BkMJ9nqHh5VqKD_3iYI9jbVXcpAgNmG72UbSeA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Strolling through the Mercado do Bolhao.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV850tEOWeExihLMDV0ng6B_A2wW5FhyOKUeq57C0Autmt55-zikTrX_g81wCB1SmltVoIgHbOJNq7HeTaYZ6NgjQqzk3KeDR8nM-CU1VfTSR6AR_nZjJ9-nWE4M45wBun6fUX_A4KNs_0BUW7N7DrEa8Og=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Enjoying the sun with a warm pastel de nata along the river, fending off giant seagulls.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87FdeLTRXCuc4XmTUxXkus3cP9C2U1NCS8TTCA2oOqQBHw6GJXjeLG4dYMZSoN4vojJOuXU0XO43ym2tOFc7Ok_3ACAC70aRzhDq1Jw6pkpLTFUoz-1s85RS-z1wZGZKC-9tjBleWGX4OhV51keaKQ7kg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Strolling across the Dom Luis Bridge.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85SL4ivWghOtf-FkDbZhKmdef5iBhGLe7tE8gjslNm2XeNpdiavBN87_7NmC1_yIEhfbkUkGaQVhjt6ytWjPK6Ny84oD9RCCAWcb5zsYqhFsxdDK2Rakx0t6WYYGpxwyqlc2G_1nSdSNpX-Su0y-83w6A=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Excited for a lunch of deep fried portuguese style empinada style things.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87HYU0kRan8qblGa1NWBL4cQpsbmTkiXtZTiKFXLUFHw3DYLuhbzEqlpuqjKSpfmM4Yfb4DEcuvvwsPwAL6W2YqJZ1YHstVKuSJJhRpgHVyvNJwHRRqo0OteP53fYV08zGj4H_vJotRROFWdvZmLM28Hw=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Tracking down the Porto croissant - which was basically very sweet brioche rolled into a croissant-ish shape.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87J9nrmosl65styY31Ld6ujjBgnJAsq7YooLKFL1a8q0DiDTP1VYxnO8ioYh_JFeKNkkROboNZ5-i1_ybO1xxmdLrW89utEbvUTXlabcciI7S5qIVHJ2ymLNXL_UvEPCRksQhTJnodm3JDoOC9H6OQ_xg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The colourful waterfront.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86ODoUfwls1F59r9ywQn18MpOpL-7uR3oJ8yazC9KCuAYIn0TkD5GnQ-OS0UJSvE27yqx6-P4bP5n-0i5UfImdHd7TDO9KiD4sG_TsodldMPDhgiKT0NqWTOcB1tUQAuPurkWLFN1SxOWEx5hQRFcMpnw=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>One of the famous pastel de nata shops, where we could watch them being made. There was some gossiping going on between these women.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87cq2eJJxbu3dHE5-K4mqfJqwYMKosD0ThkQIiBKenCuDMvyBMBFG546FM0o-BxeAUTnpikFQBIlYM9ZG5sT_I_64R25rM72zGDNHYlVvoPZF_Xy5CE5co9ZHB9wfKiXVlJ8Cfgnub8g9DDslt6lzTnNA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We forgot to take a photo but we had some more delicious pastel de nata here.</p>
            </div>
          </div>
        );
      case 'Douro Valley':
        return (
          <div className={styles.imageBlock}>
            <div className={styles.writingCard}>
              <h2>Douro Valley</h2>
              <p>We took a cute little day trip to the Douro Valley - the home of Port wine and pretty vineyards.</p>
              <button className={styles.closeButton} onClick={handleCloseDetails}>Close</button>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86q0W6661fnezRqAJ-sr8ScnyGd8Bk_35lADPTsH5yw0Mvjs2_3GxU7D7V1aOsgP6dAt7CnWYv8QWbwVr83uOr58taVg1uH90D-nYrWUhoqVJilUNQUv_uFc-1FtZdexZiT3hqhnnr2DQ6-6Xn8bJPoIw=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We jumped on this adorable train with the back drop of the colourful Porto houses.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85BHKdkVo82FJH2J0Zj0jcGtIpSHt58VNO9jqZU0AnoGFv70DSyzTkFPBmNIwIsOGXkGUvSk_t3Ywr4eYC93C2dHTQqQYDV8-tqg92Qjru29pQqZQUuibb_KDcl0LZYNQ-LS9k4ttotLvwCyhIO2KtJnQ=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Look how cute the inside of the train is! We'll be on here for about 2.5 hours on the way to Pinhao.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84HpQcdpPriKjRjGKVVEHJJ4ErwPd7P4ELtuIOrHlq6tMsRnpbz_lJHh8pNXCDuv3U056GMPfap7IZa9V51nmke0pEkJF-u2OrS-dhO9pAifDXG7j0qfoXeCMyOb3WMBB0G_SSh50r3dFITGoMgowLWZA=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Most of this trainline is along the river, making it a beautiful trip. Some people even just get the train back and forth and call that their day trip.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84lX5t2S7YhK_mJjq8PHDSKn1Uou0E4ZTod0PskNQMkFOVN1acRfX8X7Vj_OARprDulbcS_E0-8ywNZNfLdeMlX7svaDAYdiFTVDeUj8LMSekz7usqa1omra1w7tapjTNQrRNm2l2QLflVncwGb8UobvQ=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Sunny trainride along the water.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86H2zPtdjU_5t7gWMz7MH00BAsl0BSrL10QjC9o457wqVAeLs9-x_IefJ2VYGgp78GweXgvWWKzF7cs0MCRkzC9F7366YzgUme2yan3szmFjVEl2r6XKIu_on-cd0s3C7g7G2Jd80CdAW4MmMjxWy9Glg=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>The train dropped us off in Pinhao and the people on the train waved goodbye.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87fIpf_fjKbJaAnStK9f8CxJicldinP_PNdk1VP0XqUuI_MD3zjw_udCRUGYkhoESbTKO5ndMgoPBoqq6RECqXEHtYPaa9sGFb_J-UyKWYnnP9DuU9l-4Le4YIXgzwpa-lPnValJ-G_UbsAxsEwuxNXHA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>I found a nice walk for us to do up to what was meant to be the best view in the Douro Valley.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV86bfW1J1Sf3jW4Kk2bjeRlqUh5GzWq1pnsfGs3uRWYK7MR2gnsWk0MF0UbOjMNfd6DDyQJ6Wj8KqVFRXW8kYJB2rtOr3WghsyOf4ZE9xm8XfrDJ4Qc3T2YPKL_A-vA-Co9sxiQAldwEnVSzM6g60Aom6w=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>This little walk ended up being quite an intense uphill battle. It was crazy steep for the entire hour up.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87r3-xVD-PmE8wc8jK7ufVs-jvyzpMdmElOR_fxofyQoUCJJTg3bSMFK2CDOumExn10T379jKyVTKiRgtq2-VVEwgm1yjBTsyAYlfsN8DjEHcYKzJQ2WXSa73kgL3yk3J_1iIbqP4gcpVO66B_0m140Ag=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>But look at the view!</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87-qrJGIRDr8gKMxmEwANwLS3E27ULNOVk4vwQJ29FjPP4zSkzOpI9BNQUGv77hhuLXB4GsDDe_McKOiUSVFeNBXBEfY4k__OL1MvuWcXOcNaGvXnd9kTiTwLDGd9q4_972vgAJof3d4i7ZkPvh_6is2w=w240-h426-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>How lovely.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84CR9u-v38xXLW2a9L05wVU9aamHbJUxIk-ilhnbhQlEo5I1gccnrUMtD9e1rQZ93J8LB2YsbbHAanAUkxx4cMJZ6xYFZCIfGwC_wCQk5xXVTFvxK0s1geua6kdUkchWx8JvXzrjmLh3TB09e1ZG88lWg=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>Happy smiles that we made it up the hill.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV84ioP8lsjDs7cY18iaWZVshwlSdyrjPLdrQPe09dYuHATKRk7J2Xb5-J6SHOLCung0ccI4I8L-OKtm_BNrmw8cK-BxvcGQgm_M-eJOGNxN3vYkHqvmuWHI7gjG4HSzeQ-zA7vwmjbi2WFBeeEiTzBYZTA=w1114-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>We brought up our own little picnic lunch and sat an enjoyed the view with our cheese and bread.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV87IuAl-ij7PFzTCppGzBytNuYvWZ4O1ECEwZsiLObtTT4LHlffPysKgL5yBdV4QLEY4WctWe6C4m0fgsdkDyBA8zyTsKqtk1rTxaX8tI4zfULHqEfZzwZ_yPXHuOJpBDpVVzmINm88eGX5UScTVuKErMg=w1140-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>After climbing back down the hill, we found a vineyard to try a bit of Port wine.</p>
            </div>
            <div className={styles.imgBox}>
              <img src="https://lh3.googleusercontent.com/pw/ABLVV85R3rlaOkwtZXvWHGsv-rr8aZKaQhUPLSEqy_UF3OE3mAVjsFLM7r9dIvGEHEYRKT7RGIuMaO4fkRpfRP0SnXwh7Q_IL4TO-ot4-QUkzf_OQjgARbHXJkrQXaPBRD9czHbp0duui4KVtWHtObj4wkNWuA=w1118-h1486-s-no-gm?authuser=0" alt="me and chippy"></img>
              <p className={styles.imgText}>I don't think either of us liked the Port very much but it was a lovely place to sit and look down at the river. And it felt very alcoholic.</p>
            </div>
          </div>
        );
      default:
        return <p>Select an item to view details.</p>;
    }
  };

  return (
    <div className={styles.portugalCountryPage}>
      <div className={styles.countryHeader}>
        <div className={styles.returnButtonArea}>
          <button className={styles.returnButton} onClick={() => navigate('/Home')}>X</button>
        </div>
        <h1 className={styles.Title}>Welcome to Portugal!</h1>
      </div>
      <div className={styles.countryBulk}>
        <div className={styles.entries}>
          <ul className={styles.listArea}>
            {entries.map((entry, index) => (
              <li key={index}>
                <button className={styles.Entrybutton} onClick={() => handleEntryClick(entry[0])}>{entry[0]}</button>
                <p className={styles.date}>{entry[1]}</p>
              </li>
            ))}
          </ul>
        </div>

        <div className={styles.entryDetails} key={key}>
          {selectedItem ? (
            <div>
              {renderContentBasedOnEntry()}
            </div>
          ) : (
            <div className={styles.startPage}>
              <p>And just like that, we are in Portugal!<br></br><br></br>James is excited to eat Pastel de Nata and Ashlyn is excited to look at pretty tiles. Win win.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Portugal;
